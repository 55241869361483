import styled from "styled-components";
import Card from "../../kanopy-ui/Card";
import { Hr } from "../../kanopy-ui/Divider";
import { Title11, Label2 } from "../../kanopy-ui/Texts/Texts";
import { GoalStatus } from "./GoalCard.types";

export const CardLayout = styled(Card)`
   box-sizing: border-box;
   display: grid;
   grid-template-rows: auto 1fr auto;
   padding: ${({ theme }) => theme.spacing(2.5, "px")};
   user-select: none;
   width: 100%;
   max-width: 360px;
   min-height: 451px;

   & > div {
      width: 100%;
   }
`;

export const Text = styled("p")`
   font-family: ${({ theme }) => theme.paragraph9.fontFamily};
   color: ${({ theme }) => theme.paragraph9.color};
   font-size: ${({ theme }) => theme.paragraph9.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph9.weight};
   line-height: ${({ theme }) => theme.paragraph9.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph9.letterSpacing}em;
`;

export const CardHeader = styled("div")``;

export const CardBody = styled("div")``;

export const CardRow = styled("div")`
   padding: ${({ theme }) => theme.spacing(2, "px")} 0;
`;

export const CardCol = styled("div")`
   display: flex;
   flex-direction: row;
   column-gap: ${({ theme }) => theme.spacing(3, "px")};

   & > div {
      flex-basis: 100%;
   }
`;

export const CardActions = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   gap: 8px;
   margin-top: 16px;
`;

export const ActionButtonsColRight = styled("div")`
   display: flex;
   column-gap: 8px;
`;

export const Divider = styled(Hr)`
   margin: 0;
`;

export const CardStatus = styled("div")`
   display: flex;
   align-items: center;
   gap: ${({ theme }) => theme.spacing(0.5, "px")};
   margin-bottom: ${({ theme }) => theme.spacing(1.5, "px")};

   & > img {
      height: 20px;
      width: 20px;
   }
`;

export const CardTitle = styled("div")`
   margin-bottom: ${({ theme }) => theme.spacing(1.5, "px")};
`;

export const StatusText = styled(Label2).withConfig({})<{ statusType?: GoalStatus }>`
   font-weight: 700;
   ${props => props.statusType === GoalStatus.Activated && `color: ${props.theme.colors.primary2}`};
   ${props => props.statusType === GoalStatus.Done && `color: ${props.theme.colors.primary4}`};
   ${props =>
      props.statusType === GoalStatus.PastDue && `color: ${props.theme.colors.importantColor}`};
`;

export const Title = styled(Title11)`
   color: ${({ theme }) => theme.colors.gray4};
   line-height: 1;
   margin-bottom: ${({ theme }) => theme.spacing(0.5, "px")};
`;

export const CardsParagraph = styled(Text)`
   margin: 0;
`;

export const CardIllustration = styled(CardRow)`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;

   & > img {
      max-width: 150%;
      max-height: 100%;
   }
`;

export const CardsContainer = styled("div")`
   max-width: 1300px;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   gap: 24px;
   margin-top: 16px;
`;

export const DisplayValue = styled("div").withConfig({})<{ statusType?: GoalStatus }>`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   line-height: 1.3;
   ${({ theme }) =>
      `padding: ${theme.spacing(1, "px")} ${theme.spacing(2, "px")} ${theme.spacing(0.5, "px")}`};

   background: ${({ theme }) => theme.colors.gray6};
   border-width: 1px;
   border-style: solid;
   box-sizing: border-box;
   border-radius: 9px;

   ${({ statusType, theme }) => {
      switch (statusType) {
         case GoalStatus.Activated:
            return `border-color: ${theme.colors.primary1}`;
         case GoalStatus.Done:
            return `border-color: ${theme.colors.primary4}`;
         case GoalStatus.PastDue:
            return `border-color: ${theme.colors.importantColor}`;
         default:
            return ``;
      }
   }};
`;

export const Label = styled("h4")`
   margin: 0;
   font-weight: 700;
   font-family: ${({ theme }) => theme.label2.fontFamily};
   color: ${({ theme }) => theme.colors.gray4};
   font-size: ${({ theme }) => theme.label2.fontSize}px;
   line-height: 1.3;
   letter-spacing: ${({ theme }) => theme.label2.letterSpacing}em;
   display: block;
`;

export const CardDescriptionTitle = styled(Label)`
   margin-bottom: ${({ theme }) => theme.spacing(1, "px")};
`;
