import React, { FC } from "react";
import {
   CardsLayout,
   CardsIcon,
   CardsContainer,
   CardsTitle,
   CardsParagraph
} from "./CardsFromJson.styles";
import { CardVariant } from "../../../kanopy-ui/Card";
import { FormComponentProps } from "../tools/typings";

const CardsFromJson: FC<FormComponentProps> = ({ formElementJson }) => {
   return (
      <CardsContainer>
         {formElementJson.cards?.map(infoCardsData => (
            <CardsLayout variant={CardVariant.MediumShadow}>
               <CardsIcon src={infoCardsData.icon} />
               <CardsTitle>{infoCardsData.title}</CardsTitle>
               <CardsParagraph>{infoCardsData.paragraph}</CardsParagraph>
            </CardsLayout>
         ))}
      </CardsContainer>
   );
};

export default CardsFromJson;
