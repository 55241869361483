import React, { FC } from "react";
import {
   RadioContainer,
   RadioEmpty,
   RadioFilled,
   RadioButtonContainer,
   RadioLabel,
   Frame
} from "./RadioButton.styles";

interface PropsRadioButtonBox {
   label: string;
   checked: boolean;
   onChange: (checked: boolean) => void;
}

const RadioButton: FC<PropsRadioButtonBox> = props => {
   const { label, checked, onChange } = props;

   return (
      <RadioButtonContainer onClick={() => onChange(!checked)}>
         <RadioContainer>
            <Frame />
            {checked ? <RadioFilled /> : <RadioEmpty />}
         </RadioContainer>
         <RadioLabel>{label}</RadioLabel>
      </RadioButtonContainer>
   );
};

export default React.memo(RadioButton);
