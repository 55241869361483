import styled from "styled-components";
import DatePicker from "react-date-picker";
import Calendar from "react-calendar";
import { InputVariant } from "../Input/Input";
import Card from "../Card";

export const RDatePickerStyled = styled(DatePicker).withConfig({
   shouldForwardProp: p => !["error", "variant", "readOnly"].includes(p)
})<{ error?: string | undefined; variant: InputVariant; readOnly?: boolean }>`
   height: 44px;
   flex-grow: 1;

   font-family: ${({ theme }) => theme.input.text.fontFamily};
   color: ${({ theme }) => theme.input.text.color};
   font-size: ${({ theme }) => theme.input.text.fontSize}px;
   font-weight: ${({ theme }) => theme.input.text.weight};
   line-height: ${({ theme }) => theme.input.text.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.input.text.letterSpacing}em;

   ${({ readOnly, theme }) =>
      readOnly && theme.input.text.colorReadOnly ? `color: ${theme.input.text.colorReadOnly};` : ""}

   .react-date-picker__wrapper {
      border: none;
      border-radius: unset;
      background-color: unset;
   }

   .react-date-picker__inputGroup {
      display: flex;
      align-items: center;

      & > :nth-child(2) {
         padding-left: 17px;
      }

      & > :last-child {
         padding-right: 17px;
         flex-grow: 1;
         width: unset;
      }
   }
`;

export const CalendarCard = styled(Card)`
   width: 350px;
   height: 290px; // This is needed because the calendar height changes depending on which month is selected and that size change has problems with the floating positioning while interacting
   box-shadow: ${({ theme }) => theme.contextMenu.shadow};
   border-radius: ${({ theme }) => theme.contextMenu.borderRadius};
   padding-left: 18px;
   padding-right: 18px;
   padding-top: 10px;
`;

export const RCalendarStyled = styled(Calendar)`
   border: none;
   border-radius: inherit;

   & * {
      font-family: ${({ theme }) => theme.input.text.fontFamily};
   }

   .react-calendar__month-view__weekdays__weekday {
      abbr {
         text-decoration: none;
      }
   }

   .react-calendar__tile {
      border-radius: 4px;
   }

   // react-calendar__tile--active is the selected day tile react-calendar__tile--now is the current day tile
   .react-calendar__tile--active,
   .react-calendar__tile--active:hover,
   .react-calendar__tile--now--active {
      background: ${({ theme }) => theme.calendar.selectedDayBackgroundColor};
   }

   // Color of the day when is the current day
   .react-calendar__tile--now:not(.react-calendar__tile--active) {
      background: transparent;
      color: black;
   }

   // Hovering of not selected days
   .react-calendar__tile:enabled:hover:not(.react-calendar__tile--active:hover),
   .react-calendar__tile--now:hover:not(.react-calendar__tile--active) {
      background: ${({ theme }) => theme.calendar.notSelectedDayHoverBackgroundColor};
   }

   .react-calendar__tile {
      padding: 6.5px;
   }
`;
