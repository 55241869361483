import { useEffect, useState } from "react";
import { FormJson } from "../../../../tools/api/forms/useServerJsonForm";

export function useFormFirstTitle(
   formJson: FormJson | undefined,
   onTitleFound: ((title: string) => void) | undefined
) {
   const [title, setTitle] = useState<string | undefined>(undefined);

   useEffect(() => {
      const formGroupWithTitle = formJson?.formGroups?.find(formGroup =>
         formGroup.formElements?.find(
            formElement => formElement.type === "title" || formElement.type === "small-title"
         )
      );

      if (formGroupWithTitle === undefined) {
         return;
      }

      const titleElement = formGroupWithTitle!.formElements!.find(
         formElement => formElement.type === "title" || formElement.type === "small-title"
      );

      if (!titleElement?.label) {
         return;
      }

      setTitle(titleElement.label);
      onTitleFound?.(titleElement.label);
   }, [formJson, onTitleFound]);

   return title;
}
