import { FC } from "react";
import PageContainer from "../../common/PageContainer/PageContainer";
import {
   BackgroundIllustration,
   TextsContainer,
   TitleIllustrationContainer,
   TextsContainer2
} from "./Maintenance.styles";
import maintenance from "../../../assets/maintenance.svg";
import { ColumnCentered } from "../../kanopy-ui/Column/Column";
import { AbsoluteCenter } from "../../common/PageContainer/PageContainer.styles";

const Maintenance: FC = () => {
   return (
      <PageContainer>
         <AbsoluteCenter>
            <TitleIllustrationContainer>
               <ColumnCentered>
                  <BackgroundIllustration src={maintenance} />
               </ColumnCentered>
               <ColumnCentered>
                  <TextsContainer>We're down for maintenance</TextsContainer>
               </ColumnCentered>
               <TextsContainer2>We'll be back online shortly</TextsContainer2>
            </TitleIllustrationContainer>
         </AbsoluteCenter>
      </PageContainer>
   );
};

export default Maintenance;
