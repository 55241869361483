import React, { FC, useRef, useState } from "react";
import { DashboardChartData } from "../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import {
   LineChartCardContainer,
   ChartControlsContainer,
   ChartTitle,
   ChartButtonsContainer,
   ButtonDashboard,
   TitleContainer,
   TitleTextContainer,
   AlertLabel,
   ChartContainer
} from "./ChartsMenu.styles";
import { IconImage } from "../../../../kanopy-ui/Icon/Icon";
import { IconTooltip } from "../../../../kanopy-ui/IconTooltip/IconTooltip.styles";
import ChartWithFilter from "./ChartWithFilter/ChartWithFilter";
import { useChartSettingsFromUrlParams } from "./tools/useFilterFromUrlParams";

interface PropsChartsMenu {
   chartsData: DashboardChartData[];
}

const ChartsMenu: FC<PropsChartsMenu> = props => {
   const { chartsData } = props;
   const [selectedChart, setSelectedChart] = useState(chartsData[0]);
   const cardContainerRef = useRef<HTMLDivElement>(null);
   const { alert, filter, targetChartIndex } = useChartSettingsFromUrlParams(selectedChart);
   const animDelayMs = 100;
   const animDurationMs = 500;

   const handleChartButtonClick = (chartData: DashboardChartData) => {
      setSelectedChart(chartData);
      setTimeout(() => {
         const y = (cardContainerRef.current?.getBoundingClientRect().top ?? 0) + window.scrollY;
         window.scrollTo({ top: y, behavior: "smooth" });
      }, animDurationMs + animDelayMs);
   };

   return (
      <>
         <LineChartCardContainer
            id="chart"
            chartsAmount={selectedChart.chartGraphics.length}
            ref={cardContainerRef}
            animDurationMs={animDurationMs}
            animDelayMs={animDelayMs}
         >
            <ChartControlsContainer>
               <TitleContainer>
                  <TitleTextContainer>
                     <ChartTitle>{selectedChart.title}</ChartTitle>
                     <IconTooltip
                        data-tip={`${selectedChart.tooltip}`}
                        data-for={`headerTooltip`}
                        iconImage={IconImage.BiInfoCircle}
                     />
                  </TitleTextContainer>
               </TitleContainer>
               <ChartButtonsContainer>
                  {chartsData.map((chartData, i) => (
                     <ButtonDashboard
                        active={selectedChart.tabButtonTitle === chartData.tabButtonTitle}
                        onClick={() => handleChartButtonClick(chartData)}
                        key={i}
                        fullWidth
                        id={`${chartData.id}`} // This is used to send a fake click event, for more info see the Highlighter component
                     >
                        {chartData.tabButtonTitle}
                     </ButtonDashboard>
                  ))}
               </ChartButtonsContainer>
            </ChartControlsContainer>
            {alert && <AlertLabel dangerouslySetInnerHTML={{ __html: `<b>Alert</b>: ${alert}` }} />}
            <ChartContainer chartsAmount={selectedChart.chartGraphics.length}>
               {selectedChart.chartGraphics.map((chartGraphic, i) => (
                  <ChartWithFilter
                     chartGraphic={chartGraphic}
                     key={`${chartGraphic.id}${i}`}
                     filter={targetChartIndex === i ? filter : undefined}
                  />
               ))}
            </ChartContainer>
         </LineChartCardContainer>
      </>
   );
};

export default ChartsMenu;
