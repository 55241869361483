import Home from "./Home/Home";

import NotFound from "../NotFound/NotFound";

import { Routes, Route } from "react-router-dom";
import MyBusiness from "./MyBusiness/MyBusiness";
import Profile from "./Profile/Profile";
import MyBusinessFinances from "./MyBusinessFinances/MyBusinessFinances";
import Client from "./Client/Client";
import Goals from "./Goals/Goals";
import FinancialHealth from "./FinancialHealth/FinancialHealth";

const Dashboard = () => {
   return (
      <Routes>
         <Route path="home" element={<Home />} />
         <Route path="financial-health" element={<FinancialHealth />} />
         <Route path="loan-performance" element={<FinancialHealth />} />
         <Route path="fico-reports" element={<FinancialHealth />} />
         <Route path="reports" element={<FinancialHealth />} />
         <Route path="goals" element={<Goals />} />
         <Route path="profile" element={<Profile />} />
         <Route path="my-business" element={<MyBusiness />} />
         <Route path="my-business-finances" element={<MyBusinessFinances />} />
         <Route path="client/:clientId" element={<Client />} />
         <Route element={<NotFound />} />
      </Routes>
   );
};

export default Dashboard;
