import { UseFormReturn, useWatch } from "react-hook-form";
import { FormJson } from "../../../../tools/api/forms/useServerJsonForm";
import { useJsonFormElementsAsRecord } from "./useJsonFormElementsAsRecord";
import { getFieldError } from "../../../../tools/validation/getFieldError";

/**
 * Returns true if the form has a required field that is incomplete or has error
 */
export function useFormHasIncompleteField(
   form: UseFormReturn,
   formJson: FormJson | undefined
): boolean {
   const updatedForm = useWatch({ control: form.control });
   const jsonFormElements = useJsonFormElementsAsRecord(formJson);

   const firstRequiredIncomplete = Object.keys(updatedForm).find(formKey => {
      const formElementJson = jsonFormElements[formKey];
      const formValue = updatedForm[formKey];
      const isRequired = Boolean(formElementJson?.required);
      const isEmpty = formValue === "" || formValue === undefined || formValue === null;
      const hasError = Boolean(getFieldError({ formElementJson, form }));

      if (isRequired && (isEmpty || hasError)) {
         return true;
      }

      return false;
   });

   return Boolean(firstRequiredIncomplete);
}
