import React, { forwardRef, useEffect } from "react";
import { IFloatingFilterParams } from "ag-grid-community";
import { InputContainer, InputStyled } from "./CustomFloatingFilter.styles";

export interface FloatingFilterParams extends IFloatingFilterParams {
   suppressFilterButton: boolean;
   suppressInput: boolean;
}

/**
 * This is a custom floating filter to search for string values that adds some features like suppressInput
 * and also is designed to be compatible with other custom filters
 */
export default forwardRef((props: FloatingFilterParams, ref) => {
   // In case something needs to be done when the parent filter changes this can be uncommented and implemented
   /*
   useImperativeHandle(ref, () => {
      return {
         onParentModelChanged(parentModel: { value: string }) {
            const parentValue = parentModel?.value;
         }
      };
   });
   */

   const onInputBoxChanged = (input: React.ChangeEvent<HTMLInputElement>) => {
      // The filter menu opens automatically and may be annoying when typing in the floating filter, so when typing it closes
      closeFilterMenu();

      /**
       * The parent filter is the one that implements the filter logic, here we only pass the value
       * to that component. This is how filters and floating filters should be implemented according
       * to the ag grid documentation:
       * https://www.ag-grid.com/react-data-grid/component-floating-filter/#floating-filter-lifecycle
       */
      props.parentFilterInstance(instance => {
         instance.onFloatingFilterChanged("equals", input.target.value);
      });
   };

   const closeFilterMenu = () => {
      props.api.hidePopupMenu();
   };

   const onInputBoxFocus = () => {
      if (
         props?.api?.getColumnDef?.(props?.column)?.floatingFilterComponentParams
            ?.suppressFilterButton === true
      ) {
         return;
      }

      // Users may not discover the filter popup unless it appears automatically
      props.showParentFilter();
   };

   const getColumnIndex = (): number => {
      return (
         props.columnApi
            .getColumns()
            ?.findIndex(col => col.getColId() === props.column.getColId()) ?? -1
      );
   };

   /**
    * When suppressing the input some style fixes are required since this is not a supported feature by ag grid.
    */
   const hiddenInputStyleFix = () => {
      if (!props.suppressInput) {
         return;
      }

      const filterInputContainer = document.querySelector<HTMLElement>(
         ".ag-header-row-column-filter > div[aria-colindex='" +
            (getColumnIndex() + 1) +
            "'] > .ag-floating-filter-body"
      );

      if (filterInputContainer) {
         filterInputContainer.style.display = "none";
      }

      const filterButtonElement = document.querySelector<HTMLElement>(
         ".ag-header-row-column-filter > div[aria-colindex='" +
            (getColumnIndex() + 1) +
            "'] .ag-floating-filter-button"
      );

      if (filterButtonElement) {
         filterButtonElement.style.marginLeft = "0";
      }
   };

   useEffect(() => {
      hiddenInputStyleFix();
   }, []);

   if (props.suppressInput) {
      return null;
   }

   return (
      <InputContainer>
         <InputStyled onInput={onInputBoxChanged} onFocus={onInputBoxFocus} />
      </InputContainer>
   );
});
