import { useEffect } from "react";

/**
 * Disables the wheel mouse action to change number, by default the input fields of type number
 * changes number on mouse wheel, that leads to undesired number changes when scrolling the page
 */
export function useMouseWheelFix() {
   useEffect(() => {
      document.querySelectorAll(".react-date-picker__inputGroup input").forEach(input => {
         input.addEventListener("wheel", e => {
            e.preventDefault();
         });
      });
   }, []);
}
