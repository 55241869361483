import React, { FC } from "react";
import Button, { ButtonVariant } from "../../../kanopy-ui/Button";
import { FormComponentProps } from "../tools/typings";

const ButtonCancelFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;

   const handleClick = () => {
      formTools.cancelForm();
   };

   return (
      <Button
         variant={
            formElementJson.variant
               ? (formElementJson.variant as ButtonVariant)
               : ButtonVariant.TextOnly
         }
         onClick={handleClick}
      >
         {formElementJson.label}
      </Button>
   );
};

export default ButtonCancelFromJson;
