import { FC, useState } from "react";
import Checkbox from "../../../kanopy-ui/Checkbox/Checkbox";
import { Paragraph } from "../../../kanopy-ui/Texts/Texts";
import { FormComponentProps } from "../tools/typings";
import { CheckBoxContainer } from "./checkbox.styles";

const CheckboxFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form } = formTools;
   const [checked, setChecked] = useState(Boolean(formElementJson.value ?? false));

   const handleClick = (checked: boolean) => {
      setChecked(checked);
      form.setValue(formElementJson.name, checked ? 1 : 0);
   };

   form.register(formElementJson.name, {
      required: Boolean(formElementJson.required),
      setValueAs: (value: number) => Boolean(value) // This line converts a number to a boolean because react-hook-form doesn't allow to set a form value as a boolean directly on the setValue function
   });

   return (
      <CheckBoxContainer>
         <Checkbox checked={checked} onChange={handleClick} />
         <Paragraph dangerouslySetInnerHTML={{ __html: formElementJson.label ?? "" }} />
      </CheckBoxContainer>
   );
};

export default CheckboxFromJson;
