import styled from "styled-components";

export const Input1 = styled("input")`
   height: 44px;
   padding: 0 22px;
   -webkit-box-flex: 1;
   color: ${({ theme }) => theme.colors.gray4};
   font-size: 16px;
   font-weight: 400;
   line-height: 22px;
   border: 1px solid ${({ theme }) => theme.colors.gray1};
   border-radius: 4px;
   background-color: unset;
`;
export const InputContainer = styled("div")`
   display: flex;
   flex-direction: column;
   position: relative;
   row-gap: 3px;
   width: 100%;
`;
export const DropDownItems = styled("div")`
   margin-top: 75px;
   z-index: 9999;
   position: absolute;
   max-height: 20vh;
   overflow: auto;
   width: 100%;
   box-shadow: 0px 4px 24px 0px rgb(0 0 0 / 25%);

   ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
   }
   div {
      background-color: ${({ theme }) => theme.colors.white};
      cursor: pointer;
      padding-bottom: 8px;
      padding-top: 14px;
      color: ${({ theme }) => theme.colors.gray4};
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
      padding-left: 22px;
   }
   div:hover {
      background-color: ${({ theme }) => theme.colors.grey9};
   }
   div:active {
      padding-bottom: 8px;
      color: black;
      padding-top: 14px;
      padding-left: 22px;
   }
`;
