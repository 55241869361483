import React, { FC } from "react";
import { CenterContent, HeaderContainer, HeaderLogo, RightSectionContainer } from "./Header.styles";
import Container from "../../kanopy-ui/Container";
import { currentTheme } from "../../../tools/themes/themeConfig";

export enum HeaderVariant {
   RegisteredUser,
   NonRegisteredUser
}

export interface PropsHeader {
   variant?: HeaderVariant;
   renderRightContent?: () => React.ReactNode;
   applyZIndex?: boolean;
   position?: "static" | "fixed" | "absolute" | "relative" | "sticky";
}

const Header: FC<PropsHeader> = props => {
   const {
      variant = HeaderVariant.NonRegisteredUser,
      renderRightContent,
      applyZIndex = true,
      position
   } = props;

   return (
      <HeaderContainer variant={variant} applyZIndex={applyZIndex} position={position}>
         <Container fixedWidth noMarginBottom>
            <CenterContent>
               <HeaderLogo src={currentTheme.images.logo} />
               <RightSectionContainer>{renderRightContent?.()}</RightSectionContainer>
            </CenterContent>
         </Container>
      </HeaderContainer>
   );
};

export default Header;
