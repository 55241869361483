import React, { FC } from "react";
import PageContainer, { PageContainerVariant } from "../../common/PageContainer/PageContainer";
import { HeaderVariant } from "../../common/Header/Header";
import FormFromJson from "../../common/FormFromJson/FormFromJson";
import { FormJson } from "../../../tools/api/forms/useServerJsonForm";
import WizardLeftPanel from "../Wizard/tools/WizardLeftPanel/WizardLeftPanel";

import testForm from "./testForm2.json";

/**
 * This is an internal developers page to do any frontend testings
 * Currently mapped to the /dev-testing route
 */
const DevTesting: FC = () => {
   return (
      <PageContainer
         variant={PageContainerVariant.Wizard}
         headerConfig={{ variant: HeaderVariant.RegisteredUser }}
      >
         <FormFromJson
            alreadyLoadedJson={{ json: testForm as unknown as FormJson }}
            // renderFormComponent={({ formElementJson }) =>
            //    formElementJson.type === "input[type=address]" ? <>asd</> : null
            // }
            renderLeftColumn={(formJson, form) => (
               <WizardLeftPanel leftPanelJson={formJson.leftPanel} formLeftPanel={form} />
            )}
         />
      </PageContainer>
   );
};

export default DevTesting;
