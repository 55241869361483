import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "../../../../kanopy-ui/Column/Column";
import { Container } from "../../../../kanopy-ui/Container";
import Icon, { IconImage } from "../../../../kanopy-ui/Icon/Icon";
import { Label10, Title5 } from "../../../../kanopy-ui/Texts/Texts";
import {
   CornerButtonsContainer,
   UserAvatarStyled,
   TitleRow,
   BackButton,
   HeaderLink,
   IconClientNotifications
} from "./DashboardHeader.styles";
import Notifications from "../../../../common/Notifications/Notifications";
import { NotificationsResponse } from "../../../../../tools/api/notifications/useNotifications";

interface PropsDashboardHeader {
   title?: string;
   subtitle?: string;
   backButtonRedirectUrl?: string;
   label?: string;
   email?: string;
   messageIcon?: string;
   name?: string;
   clientId?: number;
}

const DashboardHeader: FC<PropsDashboardHeader> = props => {
   const navigate = useNavigate();
   return (
      <Container noSideMargin>
         <CornerButtonsContainer>
            <Notifications />
            <UserAvatarStyled menuPlacement={"bottom-end"} />
         </CornerButtonsContainer>
         <TitleRow>
            {props.backButtonRedirectUrl && (
               <BackButton
                  iconImage={IconImage.MdArrowBack}
                  onClick={() => navigate(`/${props.backButtonRedirectUrl}`)}
                  size={28}
               />
            )}
            <Column>
               <Title5>{props.title}</Title5>
               {props.subtitle && <Label10>{props.subtitle}</Label10>}
               <Label10>{props.name}</Label10>
               {props.label && (
                  <HeaderLink href={"mailto:?to=" + props.name + "<" + props.email + ">"}>
                     <img src={props.messageIcon} alt={""} /> {props.label}
                  </HeaderLink>
               )}
               {props.clientId && (
                  <Notifications
                     clientId={props.clientId}
                     buttonComponent={(notifications?: NotificationsResponse) => (
                        <HeaderLink>
                           <IconClientNotifications
                              iconImage={IconImage.MdNotifications}
                              size={23}
                           />
                           {`Client notifications${` (${notifications?.unread_count})`}`}
                        </HeaderLink>
                     )}
                  />
               )}
            </Column>
         </TitleRow>
      </Container>
   );
};

export default DashboardHeader;
