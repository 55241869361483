import styled from "styled-components";

export const Container = styled("div").withConfig({
   shouldForwardProp: p => !["noSideMargin", "noMarginBottom", "fixedWidth"].includes(p)
})<{ noMarginBottom?: boolean; noSideMargin?: boolean; position?: string; fixedWidth?: boolean }>`
   box-sizing: border-box;
   position: ${({ position }) => position || "relative"};
   margin-left: ${({ theme, noSideMargin }) => `${noSideMargin ? 0 : theme.spacing(2, "px")}`};
   margin-right: ${({ theme, noSideMargin }) => `${noSideMargin ? 0 : theme.spacing(2, "px")}`};
   margin-bottom: ${({ theme, noMarginBottom }) => (noMarginBottom ? 0 : theme.spacing(3, "px"))};
   ${({ fixedWidth }) =>
      fixedWidth &&
      `
         max-width: 1200px;
         margin-left: auto;
         margin-right: auto;
   `};
`;
