import React, { FC } from "react";
import Button from "../../../kanopy-ui/Button";
import { FormComponentProps } from "../tools/typings";

const ButtonDownloadFromJson: FC<FormComponentProps> = props => {
   const { formElementJson } = props;

   const handleClick = () => {
      window.open(formElementJson.link, "ButtonDownloadFromJsonName");
   };

   return <Button onClick={handleClick}>{formElementJson.label}</Button>;
};

export default ButtonDownloadFromJson;
