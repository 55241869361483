import { jsonStringifySafe } from "./jsonStringifySafe";

function tryToStringifyJson(object: any) {
   return jsonStringifySafe(object).result ?? String(object);
}

export function tryToGetErrorMessage(error: any): string {
   let defaultErrorString = "Unknown error";

   if (error == null) {
      return defaultErrorString;
   }

   if (error.response?.data?.error?.message !== undefined) {
      return tryToStringifyJson(error.response.data.error.message);
   }

   if (error.response?.data?.msg !== undefined) {
      return tryToStringifyJson(error.response.data.msg);
   }

   if (error.response?.data?.[0]?.message !== undefined) {
      return tryToStringifyJson(error.response.data[0].message);
   }

   if (error.response?.data !== undefined) {
      return tryToStringifyJson(error.response.data);
   }

   if (error.response !== undefined) {
      return tryToStringifyJson(error.response);
   }

   if (error.message !== undefined) {
      return tryToStringifyJson(error.message);
   }

   if (error.msg !== undefined) {
      return tryToStringifyJson(error.msg);
   }

   if (jsonStringifySafe(error).success) {
      return jsonStringifySafe(error).result!;
   }

   return defaultErrorString;
}
