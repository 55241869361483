/**
 * A wrapper of JSON.stringify that does not throw error when the json cannot be converted to string,
 * instead of the error an object is returned with a success boolean.
 *
 * @param object
 * @returns
 */
export function jsonStringifySafe(object: any) {
   let success = false;
   let result: string | undefined = undefined;

   if (typeof object === "string") {
      return { result: object, success: true };
   }

   try {
      result = JSON.stringify(object);
      success = true;
   } catch (e) {
      success = false;
   }

   return { result, success };
}
