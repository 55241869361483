import React, { FC, useEffect, useRef } from "react";
import { FormJson } from "../../../../tools/api/forms/useServerJsonForm";
import LoadingSpinner from "../../../kanopy-ui/LoadingSpinner/LoadingSpinner";
import ContainedInModalPageStructure from "../../ContainerInModalPageStructure/ContainedInModalPageStructure";
import TwoColumnsPageStructure from "../../TwoColumnsPageStructure/TwoColumnsPageStructure";
import NormalContainer from "./NormalContainer/NormalContainer";
import { Form, FormFromJsonVariant } from "../tools/typings";
import { Divider } from "../formFromJson.styles";
import { FormTools } from "../tools/useFormTools";

interface PropsFormContainerFromJson {
   formTools: FormTools;
   onFixedBottomRef: (ref: React.RefObject<HTMLDivElement>) => void;
   renderCustomContainer?: () => React.ComponentType;
   renderLeftColumn?: (formJson: FormJson, form: Form) => React.ReactNode;
}

const FormContainerFromJson: FC<PropsFormContainerFromJson> = props => {
   const { formTools, children, onFixedBottomRef, renderCustomContainer, renderLeftColumn } = props;
   const { formVariant, formJson, form } = formTools;

   const previousFormJson = useRef<FormJson>();

   useEffect(() => {
      if (formJson === undefined) {
         return;
      }

      previousFormJson.current = { ...formJson };
   }, [formJson]);

   if (!formJson && !previousFormJson.current) {
      return <LoadingSpinner />;
   }
   if (formVariant === FormFromJsonVariant.TwoColumns) {
      return (
         <TwoColumnsPageStructure
            onFixedBottomRef={onFixedBottomRef}
            renderCustomContainer={renderCustomContainer}
            renderLeftColumn={() => renderLeftColumn?.(formJson ?? previousFormJson.current!, form)}
         >
            {formJson ? (
               <>
                  {children}
                  <Divider height={120} />
               </>
            ) : (
               <LoadingSpinner />
            )}
         </TwoColumnsPageStructure>
      );
   }

   if (formVariant === FormFromJsonVariant.ContainedInModal) {
      return (
         <ContainedInModalPageStructure
            onFixedBottomRef={onFixedBottomRef}
            renderCustomContainer={renderCustomContainer}
         >
            {formJson ? (
               <>
                  {children}
                  <Divider height={60} />
               </>
            ) : (
               <LoadingSpinner />
            )}
         </ContainedInModalPageStructure>
      );
   }

   return <NormalContainer>{formJson ? children : <LoadingSpinner />}</NormalContainer>;
};

export default FormContainerFromJson;
