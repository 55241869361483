import React, { FC, useEffect, useState } from "react";
import {
   ChartGraphic,
   DashboardChartContent
} from "../../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import { useTheme } from "styled-components";
import { arrToggleItem } from "../../../../../../tools/array/arrToggleItem";
import ChartFilterTool from "../ChartFilterTool/ChartFilterTool";
import {
   ChartIndicator,
   ChartIndicatorIcon,
   ChartIndicatorsContainer,
   FilterToolsContainer,
   MainContainer
} from "./ChartWithFilter.styles";
import { Label } from "../../../../../kanopy-ui/Texts/Texts";
import LineChart from "../../../../../common/Charts/LineChart/LineChart";

interface PropsChartWithFilter {
   chartGraphic: ChartGraphic;
   filter?: string[];
}

/**
 * LineChart wrapper that incorporates a tool to hide lines (filter)
 */
const ChartWithFilter: FC<PropsChartWithFilter> = props => {
   const { chartGraphic, filter: filterFromParams } = props;
   const { data } = chartGraphic;

   const theme = useTheme();
   const [dataFiltered, setDataFiltered] = useState<DashboardChartContent[]>(data);
   const [lineColors, setLineColors] = useState<string[]>(theme.lineChart.lineColors);
   const [filter, setFilter] = useState<string[]>(
      filterFromParams ?? chartGraphic.defaultFilter ?? []
   );

   const filterData = (newFilter: string[]) => {
      setDataFiltered(data.filter(item => !newFilter.includes(item.id)) ?? []);
      setFilter(newFilter);
   };

   const onFilterToolChange = (filter: string[]) => {
      filterData(filter);
   };

   const onChartIndicatorClick = (id: string) => {
      const newFilter = arrToggleItem(filter, id);
      filterData(newFilter);
   };

   useEffect(() => {
      if (!filterFromParams) {
         return;
      }

      filterData(filterFromParams);
   }, [filterFromParams]);

   return (
      <MainContainer id={chartGraphic.id}>
         <FilterToolsContainer>
            <ChartFilterTool
               chartGraphic={chartGraphic}
               filter={filter}
               onFilterChange={onFilterToolChange}
               colors={lineColors}
            />
            <ChartIndicatorsContainer>
               {chartGraphic.data.map(({ title, id }, i) => (
                  <ChartIndicator key={i} onClick={() => onChartIndicatorClick(id)}>
                     <ChartIndicatorIcon
                        colorIndex={i}
                        colors={lineColors}
                        onlyBorder={filter?.includes(id)}
                     />
                     <Label>{title}</Label>
                  </ChartIndicator>
               ))}
            </ChartIndicatorsContainer>
         </FilterToolsContainer>
         <LineChart
            data={dataFiltered}
            dataUnfiltered={chartGraphic.data}
            valueYFormat={chartGraphic.valueYFormat}
            tooltipValueYFormat={chartGraphic.tooltipValueYFormat}
            markers={chartGraphic.markers}
            onLineColors={setLineColors}
         />
      </MainContainer>
   );
};

export default ChartWithFilter;
