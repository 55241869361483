import React, { FC, useState } from "react";
import Modal from "../../kanopy-ui/Modal/Modal";
import FormFromJson from "../FormFromJson/FormFromJson";
import { FormFromJsonVariant, PropsFormFromJson } from "../FormFromJson/tools/typings";

export interface PropsFormFromJsonAsModal extends PropsFormFromJson {
   modalVisible?: boolean;
   onModalClose?: (formWasModified: boolean) => void;
   onFormModified?: (() => void) | undefined;
   modalTitle?: string;
   disableAutomaticModalTitleFromJson?: boolean;
   disableClosing?: boolean;
   redirectToNextFormOnSubmit?: boolean;
}

const FormFromJsonAsModal: FC<PropsFormFromJsonAsModal> = props => {
   const {
      onFormModified,
      modalVisible = true,
      onModalClose,
      disableClosing,
      disableAutomaticModalTitleFromJson,
      redirectToNextFormOnSubmit = false,
      ...otherProps
   } = props;
   const [modalTitle, setModalTitle] = useState<string | undefined>(otherProps.modalTitle);

   if (otherProps.jsonUrl === undefined) {
      return null;
   }

   const setModalTitleFromJson = (newTitle: string) => {
      if (disableAutomaticModalTitleFromJson) {
         return;
      }

      setModalTitle(newTitle);
   };

   const handleModificationsSubmit = () => {
      if (!disableClosing) {
         onModalClose?.(true);
      }

      onFormModified?.();
   };

   return (
      <Modal
         visible={modalVisible}
         onClose={() => onModalClose?.(false)}
         title={modalTitle}
         disableClosing={disableClosing}
      >
         <FormFromJson
            isInsideModal={true}
            variant={FormFromJsonVariant.ContainedInModal}
            disableFirstTitleRendering={!disableAutomaticModalTitleFromJson}
            redirectToNextFormOnSubmit={redirectToNextFormOnSubmit}
            onSubmitFinish={handleModificationsSubmit}
            onFormTitleReceived={setModalTitleFromJson}
            onCancelForm={() => onModalClose?.(false)}
            {...otherProps}
         />
      </Modal>
   );
};

export default FormFromJsonAsModal;
