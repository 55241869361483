import { useEffect, useState } from "react";
import { useUrlParams } from "../../../../../../tools/browser/useUrlParams";
import {
   ChartGraphic,
   DashboardChartData
} from "../../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import { useLocation } from "react-router-dom";

/**
 * Url params can contain chart line filters, used when clicking a notification to show only the chart line
 * that the notification talks about. But the url contains the lines that should be visible instead of a list
 * 2of lines to hide, so we need to add some extra code to invert that
 */
export function useChartSettingsFromUrlParams(selectedChart: DashboardChartData) {
   const {
      lines: linesFilterFromUrlParams,
      click,
      alert
   } = useUrlParams<{ click: string; lines: string[] | string; alert: string }>();
   const { key } = useLocation();
   const [updateFilters, setUpdateFilters] = useState<boolean>(false);
   const [filter, setFilter] = useState<string[] | undefined>(undefined);
   const [targetChartIndex, setTargetChartIndex] = useState<number>(0);

   useEffect(() => {
      if (linesFilterFromUrlParams == null) {
         return;
      }

      setUpdateFilters(true);
   }, [key]);

   useEffect(() => {
      if (!updateFilters) {
         return;
      }

      if (linesFilterFromUrlParams == null) {
         return;
      }

      // The click item in the url is the chart that the notification is talking about
      // is also the button to click in the chart
      if (selectedChart.id !== click) {
         return;
      }

      const targetChartIndex = selectedChart.chartGraphics.findIndex(value =>
         value.data.find(val => linesFilterFromUrlParams.includes(val.id))
      );

      const targetChart = selectedChart.chartGraphics[targetChartIndex];

      // The lines from the url are the ones that should be visible so we add all ids to the
      // filter except the present on the url
      setFilter(
         targetChart.data
            .map(item => item.id)
            .filter(item => !linesFilterFromUrlParams.includes(item))
      );
      setTargetChartIndex(targetChartIndex);

      setUpdateFilters(false);
   }, [updateFilters, selectedChart]);

   if (click && alert && selectedChart?.id === click) {
      return { alert, filter, targetChartIndex };
   } else {
      return {};
   }
}
