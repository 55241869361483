import React, { FC } from "react";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import WizardLeftPanel from "../tools/WizardLeftPanel/WizardLeftPanel";
import { HeaderVariant } from "../../../common/Header/Header";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";

const ReviewStep: FC = () => {
   return (
      <PageContainer
         variant={PageContainerVariant.Variant3}
         headerConfig={{ variant: HeaderVariant.RegisteredUser }}
      >
         <FormFromJson
            jsonUrl={"wizard/step-page/review"}
            disableSubmitButtonWhenFormIsIncomplete={false}
            renderLeftColumn={formJson => <WizardLeftPanel leftPanelJson={formJson.leftPanel} />}
         />
      </PageContainer>
   );
};

export default ReviewStep;
