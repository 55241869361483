import styled from "styled-components";
import Container from "../../../../kanopy-ui/Container";
import { FormFromJsonVariant } from "../../tools/typings";

export const FixedBottomContainer = styled("div").withConfig({
   shouldForwardProp: p => !["variant"].includes(p)
})<{ variant: FormFromJsonVariant }>`
   width: 100%;
   z-index: 3;

   height: ${({ variant, theme }) =>
      variant === FormFromJsonVariant.ContainedInModal ? "80px" : "84px"};

   background-color: ${({ variant, theme }) =>
      variant === FormFromJsonVariant.ContainedInModal ? theme.colors.grey9 : theme.colors.white};

   box-shadow: ${({ variant, theme }) =>
      variant === FormFromJsonVariant.ContainedInModal
         ? theme.shadows.shadow6
         : theme.shadows.shadow5};
`;

export const FixedBottomWrapper = styled(Container)`
   height: 100%;
   display: flex;
   align-items: center;
`;

export const FixedBottomContent = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-end;
   column-gap: 16px;
   width: 100%;
   height: 100%;
`;

export const NonFixedBottomContainer = styled("div")`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   flex-wrap: wrap;
   column-gap: 13.5px;
   margin-top: ${({ theme }) => theme.spacing(4, "px")};
`;
