import React, { FC } from "react";
import { LabelContainer } from "./Avatar.styles";
import CircularButton from "../CircularIconButton/CircularButton";

interface PropsAvatar {
   label?: string;
   variant?: AvatarVariant;
   color?: string;
}

export enum AvatarVariant {
   Normal = "Normal",
   Small = "Small",
   Big = "Big"
}

const Avatar: FC<PropsAvatar> = props => {
   const { label, variant = AvatarVariant.Normal, color } = props;

   let size = 40;
   let offsetX = 0.5;
   let offsetY = 1.3;
   let labelFontSize = 16;
   let labelDisplacement = 1;

   if (variant === AvatarVariant.Small) {
      size = 30;
      offsetX = 0;
      offsetY = 0;
      labelFontSize = 12;
      labelDisplacement = 0;
   }

   if (variant === AvatarVariant.Big) {
      size = 48;
      offsetX = 0.5;
      offsetY = 1.3;
      labelFontSize = 16;
      labelDisplacement = 0;
   }

   return (
      <CircularButton circleSize={size} color={color} offsetX={offsetX} offsetY={offsetY}>
         {label && (
            <LabelContainer labelFontSize={labelFontSize} labelDisplacement={labelDisplacement}>
               {label}
            </LabelContainer>
         )}
      </CircularButton>
   );
};

export default Avatar;
