import React, { FC } from "react";
import {
   BoxContainer,
   BoxEmpty,
   BoxFilled,
   CheckBoxContainer,
   CheckBoxLabel,
   Frame,
   IconStyled
} from "./Checkbox.styles";
import CheckIconSvg from "./tools/CheckIconSvg";
import { useTheme } from "styled-components";
import Icon, { IconImage } from "../Icon/Icon";

export interface PropsCheckBox {
   label?: React.ReactChild;
   checked: boolean;
   onChange: (checked: boolean) => void;
   dangerouslySetInnerHTML?: string;
   className?: string | undefined;
}

const Checkbox: FC<PropsCheckBox> = props => {
   const { dangerouslySetInnerHTML, label, checked, className, onChange } = props;
   const theme = useTheme();
   const isIcon =
      typeof label === "string"
         ? Object.values(IconImage).includes(label as IconImage)
            ? true
            : false
         : false;

   return (
      <CheckBoxContainer onClick={() => onChange(!checked)} className={className}>
         <BoxContainer>
            <Frame />
            {checked ? (
               <BoxFilled>
                  <CheckIconSvg color={theme.checkBox.checkColor} />
               </BoxFilled>
            ) : (
               <BoxEmpty />
            )}
         </BoxContainer>
         {label &&
            (isIcon ? (
               <IconStyled iconImage={label as IconImage} />
            ) : (
               <CheckBoxLabel>{label}</CheckBoxLabel>
            ))}
         {dangerouslySetInnerHTML && (
            <CheckBoxLabel dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }} />
         )}
      </CheckBoxContainer>
   );
};

export default React.memo(Checkbox);
