import React, { FC } from "react";
import { CellLabel, LinkStyled } from "./DataGridCell.styles";
import { ICellRendererParams } from "ag-grid-community";

interface PropsDataGridCell {
   params: ICellRendererParams;
   columnIdWithLink?: string;
   customRenderer?: ((params: any) => React.ReactElement | string | null) | undefined;
   fontWeight?: number;
}

const DataGridCell: FC<PropsDataGridCell> = props => {
   const { params, customRenderer, columnIdWithLink, fontWeight } = props;

   if (columnIdWithLink) {
      return (
         <LinkStyled to={`/${params.data[columnIdWithLink]}`}>
            <CellLabel handCursor={true} style={{ fontWeight }}>
               {customRenderer ? customRenderer(params) : props.params.value}
            </CellLabel>
         </LinkStyled>
      );
   } else {
      return (
         <CellLabel style={{ fontWeight }}>
            {customRenderer ? customRenderer(params) : props.params.value}
         </CellLabel>
      );
   }
};

export default DataGridCell;
