import { useEffect, useMemo } from "react";
import { useAtom } from "jotai";
import { floatComponentsOpen } from "../../tools/atoms/mainAtom";

/**
 * When the float component is open is registered into a global list of currently open ones.
 * The idea is that other components can be aware of how many float components are open.
 * A timeout is used to give time for mouse up and for animations.
 */
export function useFloatCompGlobalList(visible: boolean) {
   const [, setFloatCompOpen] = useAtom(floatComponentsOpen);
   const instanceId = useMemo(() => Math.random().toString(36).substring(2), []);

   useEffect(() => {
      let timeout: NodeJS.Timeout;

      if (visible) {
         setFloatCompOpen(value => new Set(value).add(instanceId));
      } else {
         timeout = setTimeout(() => {
            setFloatCompOpen(value => {
               const newSet = new Set(value);
               newSet.delete(instanceId);
               return newSet;
            });
         }, 800);
      }

      return () => {
         clearTimeout(timeout);
      };
   }, [visible]);
}
