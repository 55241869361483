import styled from "styled-components";
import Card from "../Card";

export const GroupedButtonsContainer = styled("div")`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   column-gap: 20px;
   width: 100%;
`;

export const BigCardButton = styled(Card).withConfig({
   shouldForwardProp: p => !["selected"].includes(p)
})<{ selected?: boolean }>`
   display: flex;
   align-items: center;
   justify-content: center;
   flex: 1;
   flex-basis: 30%;
   box-sizing: border-box;
   padding: 10px 22px;
   margin-bottom: 24px;
   height: 80px;
   cursor: pointer;
   user-select: none;
   transition: all 250ms;
   text-align: center;
   color: ${({ theme }) => theme.colors.text2};
   ${props => (props.selected ? `background-color: ${props.theme.colors.primary1};` : "")}
   ${props => (props.selected ? `color: ${props.theme.colors.white};` : "")}
   ${props => (!props.selected ? `&:hover{background-color: ${props.theme.colors.grey11};}` : "")}
`;
