import React, { FC } from "react";
import { currentTheme } from "../../../tools/themes/themeConfig";

export enum TaskIndicatorState {
   Pending,
   InProgress,
   Completed
}

interface PropsTaskIndicator extends React.ImgHTMLAttributes<HTMLOrSVGElement> {
   state: TaskIndicatorState;
}

const TaskIndicator: FC<PropsTaskIndicator> = props => {
   if (props.state === TaskIndicatorState.InProgress) {
      return (
         <svg
            style={{ fill: "none" }}
            id="svg12"
            version="1.1"
            viewBox="0 0 28 28"
            height="28"
            width="28"
         >
            <defs id="defs16" />
            <circle
               style={{ stroke: currentTheme.wizardLeftPanel.borderColor, strokeWidth: 2 }}
               id="circle8"
               r="13"
               cy="14"
               cx="14"
            />
            <circle
               style={{ fill: currentTheme.wizardLeftPanel.checkCircleColor }}
               id="circle10"
               r="7"
               cy="14"
               cx="14"
            />
         </svg>
      );
   }

   if (props.state === TaskIndicatorState.Completed) {
      return (
         <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none">
            <circle
               cx="14"
               cy="14"
               r="13"
               stroke={currentTheme.wizardLeftPanel.checkCircleColor}
               strokeWidth="2"
            />
            <circle
               cx="14"
               cy="14"
               r="13"
               fill={currentTheme.wizardLeftPanel.checkCircleColor}
               stroke={currentTheme.wizardLeftPanel.checkCircleColor}
               strokeWidth="2"
            />
            <path
               d="M10.6593 17.9049L7.48315 14.4049C7.12924 14.0149 6.56661 14.0149 6.2127 14.4049C5.85879 14.7949 5.85879 15.4149 6.2127 15.8049L10.015 19.9949C10.3689 20.3849 10.9406 20.3849 11.2945 19.9949L20.9136 9.4049C21.2675 9.0149 21.2675 8.3949 20.9136 8.0049C20.5597 7.6149 19.9971 7.6149 19.6431 8.0049L10.6593 17.9049Z"
               fill={currentTheme.wizardLeftPanel.tickColor}
            />
         </svg>
      );
   }

   return (
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
         <circle
            cx="14"
            cy="14"
            r="13"
            stroke={currentTheme.wizardLeftPanel.borderColor}
            strokeWidth="2"
         />
      </svg>
   );
};

export default TaskIndicator;
