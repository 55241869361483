import React, { FC } from "react";
/**
 * To add more icons search here: https://react-icons.github.io/react-icons/icons?name=md
 */
import {
   MdVisibility,
   MdVisibilityOff,
   MdInfoOutline,
   MdInfo,
   MdCancel,
   MdEdit,
   MdAccountCircle,
   MdClose,
   MdKeyboardArrowDown,
   MdHome,
   MdFavorite,
   MdBusiness,
   MdBusinessCenter,
   MdInsertChart,
   MdAssistantPhoto,
   MdEventNote,
   MdShowChart,
   MdArrowBack,
   MdUpdate,
   MdSave,
   MdHelp,
   MdCheckCircleOutline,
   MdError,
   MdStar,
   MdOutlineUnpublished,
   MdNotifications,
   MdAssessment,
   MdAnalytics,
   MdOutlineAnalytics
} from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RiBriefcase4Line } from "react-icons/ri";
import { VscHome, VscHeart } from "react-icons/vsc";
import { IoBusiness, IoBusinessOutline } from "react-icons/io5";

import { BiHelpCircle, BiInfoCircle } from "react-icons/bi";

import { IconContainer } from "./Icon.styles";

// From assets
import { ReactComponent as PauseCircleIcon } from "../../../assets/icons/pause-circle-icon.svg";
import { ReactComponent as CheckedPlusIcon } from "../../../assets/icons/checked-plus-icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-icon.svg";
import { ReactComponent as CheckDoubleIcon } from "../../../assets/icons/check-double.svg";
import { ReactComponent as AlarmOffIcon } from "../../../assets/icons/alarm-off.svg";
import { ReactComponent as TimelineBrightIcon } from "../../../assets/icons/timeline-bright.svg";
import { ReactComponent as GoalsIcon } from "../../../assets/icons/goals.svg";

export enum IconImage {
   MdAccountCircle = "MdAccountCircle",
   AlarmOffIcon = "AlarmOffIcon",
   MdArrowBack = "MdArrowBack",
   IoIosArrowBack = "IoIosArrowBack",
   IoIosArrowForward = "IoIosArrowForward",
   MdAssistantPhoto = "MdAssistantPhoto",
   RiBriefcase4Line = "RiBriefcase4Line",
   MdBusiness = "MdBusiness",
   MdBusinessCenter = "MdBusinessCenter",
   CheckDoubleIcon = "CheckDoubleIcon",
   CheckedPlusIcon = "CheckedPlusIcon",
   MdCancel = "MdCancel",
   MdClose = "MdClose",
   MdEdit = "MdEdit",
   MdEventNote = "MdEventNote",
   MdFavorite = "MdFavorite",
   IoBusiness = "IoBusiness",
   MdHelp = "MdHelp",
   MdHome = "MdHome",
   GoalsIcon = "GoalsIcon",
   MdInfoOutline = "MdInfoOutline",
   BiInfoCircle = "BiInfoCircle",
   MdInfo = "MdInfo",
   MdInsertChart = "MdInsertChart",
   VscHeart = "VscHeart",
   BiHelpCircle = "BiHelpCircle",
   VscHome = "VscHome",
   IoBusinessOutline = "IoBusinessOutline",
   PauseCircleIcon = "PauseCircleIcon",
   MdSave = "MdSave",
   MdShowChart = "MdShowChart",
   TimelineBrightIcon = "TimelineBrightIcon",
   MdKeyboardArrowDown = "MdKeyboardArrowDown",
   TrashIcon = "TrashIcon",
   MdUpdate = "MdUpdate",
   MdVisibilityOff = "MdVisibilityOff",
   MdVisibility = "MdVisibility",
   MdCheckCircleOutline = "MdCheckCircleOutline",
   MdError = "MdError",
   MdStar = "MdStar",
   MdOutlineUnpublished = "MdOutlineUnpublished",
   MdNotifications = "MdNotifications",
   MdAssessment = "MdAssessment",
   MdOutlineAnalytics = "MdOutlineAnalytics",
   MdAnalytics = "MdAnalytics"
}

export interface PropsIcon extends React.HTMLAttributes<HTMLDivElement> {
   iconImage: IconImage;
   size?: string | number;
   color?: string;
}

/**
 * To add more icons search here: https://react-icons.github.io/react-icons/icons?name=md
 */
const Icon: FC<PropsIcon> = props => {
   const { size = 24, iconImage, color, ...otherProps } = props;
   let icon: JSX.Element | null = null;

   switch (iconImage) {
      case IconImage.MdAccountCircle:
         icon = <MdAccountCircle size={size} />;
         break;
      case IconImage.AlarmOffIcon:
         icon = <AlarmOffIcon width={size} height={size} />;
         break;
      case IconImage.MdArrowBack:
         icon = <MdArrowBack size={size} />;
         break;
      case IconImage.IoIosArrowBack:
         icon = <IoIosArrowBack size={size} />;
         break;
      case IconImage.IoIosArrowForward:
         icon = <IoIosArrowForward size={size} />;
         break;
      case IconImage.MdAssistantPhoto:
         icon = <MdAssistantPhoto size={size} />;
         break;
      case IconImage.RiBriefcase4Line:
         icon = <RiBriefcase4Line size={size} />;
         break;
      case IconImage.MdBusiness:
         icon = <MdBusiness size={size} />;
         break;
      case IconImage.MdBusinessCenter:
         icon = <MdBusinessCenter size={size} />;
         break;
      case IconImage.CheckDoubleIcon:
         icon = <CheckDoubleIcon width={size} height={size} />;
         break;
      case IconImage.CheckedPlusIcon:
         icon = <CheckedPlusIcon width={size} height={size} />;
         break;
      case IconImage.MdClose:
         icon = <MdClose size={size} />;
         break;
      case IconImage.MdCancel:
         icon = <MdCancel size={size} />;
         break;
      case IconImage.MdEdit:
         icon = <MdEdit size={size} />;
         break;
      case IconImage.MdEventNote:
         icon = <MdEventNote size={size} />;
         break;
      case IconImage.MdFavorite:
         icon = <MdFavorite size={size} />;
         break;
      case IconImage.IoBusiness:
         icon = <IoBusiness size={size} />;
         break;
      case IconImage.MdHelp:
         icon = <MdHelp size={size} />;
         break;
      case IconImage.MdHome:
         icon = <MdHome size={size} />;
         break;
      case IconImage.MdInfoOutline:
         icon = <MdInfoOutline size={size} />;
         break;
      case IconImage.BiInfoCircle:
         icon = <BiInfoCircle size={size} />;
         break;
      case IconImage.MdInfo:
         icon = <MdInfo size={size} />;
         break;
      case IconImage.MdInsertChart:
         icon = <MdInsertChart size={size} />;
         break;
      case IconImage.VscHeart:
         icon = <VscHeart size={size} />;
         break;
      case IconImage.BiHelpCircle:
         icon = <BiHelpCircle size={size} />;
         break;
      case IconImage.VscHome:
         icon = <VscHome size={size} />;
         break;
      case IconImage.IoBusinessOutline:
         icon = <IoBusinessOutline size={size} />;
         break;
      case IconImage.PauseCircleIcon:
         icon = <PauseCircleIcon width={size} height={size} />;
         break;
      case IconImage.MdSave:
         icon = <MdSave size={size} />;
         break;
      case IconImage.MdShowChart:
         icon = <MdShowChart size={size} />;
         break;
      case IconImage.MdNotifications:
         icon = <MdNotifications size={size} />;
         break;
      case IconImage.TimelineBrightIcon:
         icon = <TimelineBrightIcon width={size} height={size} />;
         break;
      case IconImage.MdKeyboardArrowDown:
         icon = <MdKeyboardArrowDown size={size} />;
         break;
      case IconImage.TrashIcon:
         icon = <TrashIcon width={size} height={size} />;
         break;
      case IconImage.GoalsIcon:
         icon = <GoalsIcon width={size} height={size} />;
         break;
      case IconImage.MdUpdate:
         icon = <MdUpdate size={size} />;
         break;
      case IconImage.MdVisibilityOff:
         icon = <MdVisibilityOff size={size} />;
         break;
      case IconImage.MdVisibility:
         icon = <MdVisibility size={size} />;
         break;
      case IconImage.MdCheckCircleOutline:
         icon = <MdCheckCircleOutline size={size} />;
         break;
      case IconImage.MdError:
         icon = <MdError size={size} />;
         break;
      case IconImage.MdStar:
         icon = <MdStar size={size} />;
         break;
      case IconImage.MdOutlineUnpublished:
         icon = <MdOutlineUnpublished size={size} />;
         break;
      case IconImage.MdAssessment:
         icon = <MdAssessment size={size} />;
         break;
      case IconImage.MdAnalytics:
         icon = <MdAnalytics size={size} />;
         break;
      case IconImage.MdOutlineAnalytics:
         icon = <MdOutlineAnalytics size={size} />;
         break;
   }

   if (icon == null) {
      console.error("Missing icon: " + iconImage);
   }

   return (
      <IconContainer {...otherProps} color={color}>
         {icon}
      </IconContainer>
   );
};

export default Icon;
