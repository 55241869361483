import React, { FC, useState } from "react";
import CurrencyInput from "../../../../../kanopy-ui/CurrencyInput/CurrencyInput";
import Input, { BorderVariant } from "../../../../../kanopy-ui/Input/Input";
import {
   TargetCardContainer,
   Title,
   CardParagraph,
   FormContainer,
   SetGoalButton,
   DifEstContainer,
   TargetInputContainer
} from "./TargetCard.styles";
import { TargetCardData } from "../../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import { getNumberFromString } from "../../../../../../tools/string/getNumberFromString";
import { httpRequest } from "../../../../../../tools/api/tools/httpRequest";

interface PropsTargetCard {
   serverData?: TargetCardData;
}

const TargetCard: FC<PropsTargetCard> = props => {
   const { serverData } = props;
   const [targetCash, setTargetCash] = useState<number | undefined>(
      getNumberFromString(serverData?.columns?.[0].form?.inputPrice?.value)
   );
   const [difference, setDifference] = useState<string | undefined>(serverData?.columns?.[1].value);
   const [time, setTime] = useState<string | undefined>(serverData?.columns?.[2].value);
   const [loading, setLoading] = useState<boolean>(false);

   if (!serverData) {
      return null;
   }

   const handleSetGoalClick = async () => {
      const url = serverData?.columns?.[0].form?.buttonSubmit?.api?.url;

      if (!url) {
         return;
      }

      setLoading(true);

      const response = await httpRequest<unknown, SetGoalResponse>({
         url,
         params: { target_cash_balance: targetCash },
         method: "POST"
      });

      setLoading(false);

      if (response.target_cash_balance !== undefined) {
         setTargetCash(response.target_cash_balance);
      }
      setDifference(response.baseline_difference);
      setTime(response.estimated_time_to_target);
   };

   return (
      <TargetCardContainer>
         <Title>{serverData.title}</Title>
         <CardParagraph>{serverData.paragraph}</CardParagraph>
         <FormContainer>
            <TargetInputContainer>
               <CurrencyInput
                  label={serverData.columns?.[0].title}
                  value={targetCash}
                  onChange={e => setTargetCash(getNumberFromString(e.target.value))}
                  currencySymbol=""
               />
               <SetGoalButton onClick={handleSetGoalClick} loading={loading}>
                  {serverData.columns?.[0].form?.buttonSubmit.label}
               </SetGoalButton>
            </TargetInputContainer>
            <DifEstContainer>
               <Input
                  label={serverData.columns?.[1].title}
                  value={difference}
                  borderVariant={BorderVariant.Bottom}
                  readOnly
               />
               <Input
                  label={serverData.columns?.[2].title}
                  value={time}
                  borderVariant={BorderVariant.Bottom}
                  readOnly
               />
            </DifEstContainer>
         </FormContainer>
      </TargetCardContainer>
   );
};

export default TargetCard;

interface SetGoalResponse {
   target_cash_balance: number;
   baseline_difference: string;
   estimated_time_to_target: string;
}
