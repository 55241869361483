import React, { FC, useState } from "react";
import LoadingSpinner from "../../../kanopy-ui/LoadingSpinner/LoadingSpinner";
import Tabs, { Tab, TabPanel } from "../../../kanopy-ui/Tabs/Tabs";
import { useGoalsApi } from "../../../../tools/api/goals/useGoalsApi";
import DashboardPageContainer from "../components/DashboardPageContainer/DashboardPageContainer";
import DashboardHeader from "../components/DashboardHeader/DashboardHeader";
import Button, { ButtonSize, ButtonVariant } from "../../../kanopy-ui/Button";
import GoalCard from "../../../common/GoalCard";
import FormFromJsonAsModal from "../../../common/FormFromJsonAsModal/FormFromJsonAsModal";
import Container from "../../../kanopy-ui/Container";
import { RightContentContainer, TabList, UnderLine } from "../../../kanopy-ui/Tabs";
import { PanelContainer } from "./Goals.styles";
import MessageContent from "../FinancialHealth/MessageContent/MessageContent";
import { MessageContentJson } from "../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";

interface PropsGoals {}

const GoalsPage: FC<PropsGoals> = props => {
   let { isLoading, data } = useGoalsApi({ pageNo: 1 });
   const [modalFormUrl, setModalFormUrl] = useState<string>();
   const [modalFormOpen, setModalFormOpen] = useState(false);

   const buttons = data?.pages
      ?.filter(page => page.tabTitle)
      .map(page => ({ label: page.tabTitle }));

   const handleOnClick = (next?: string) => {
      setModalFormUrl(next);
      setModalFormOpen(true);
   };

   const handleFormModalClose = () => {
      setModalFormOpen(false);
   };

   if (!data || isLoading) {
      return <LoadingSpinner />;
   }

   return (
      <DashboardPageContainer>
         <DashboardHeader title={data.title} subtitle={data.paragraph} />
         {data.pages && (
            <Tabs id="goals-tab">
               <Container noSideMargin>
                  <TabList>
                     {buttons?.map((button, index) => (
                        <Tab index={index} label={button.label} key={button.label} />
                     ))}
                     <RightContentContainer>
                        {data?.actionButtons.map(action => (
                           <Button
                              key={action.label}
                              variant={ButtonVariant.Filled}
                              size={ButtonSize.Medium}
                              onClick={() => handleOnClick(action.next)}
                           >
                              {action.label}
                           </Button>
                        ))}
                     </RightContentContainer>
                  </TabList>
                  <UnderLine />
               </Container>
               {data?.pages.map((page, index) => (
                  <TabPanel index={index} key={page.tabTitle}>
                     <PanelContainer>
                        {page?.cards?.map(card => (
                           <GoalCard key={card.title} {...card} onClick={handleOnClick} />
                        ))}
                     </PanelContainer>
                  </TabPanel>
               ))}
            </Tabs>
         )}
         {data.pages && (
            <FormFromJsonAsModal
               modalVisible={modalFormOpen}
               onModalClose={handleFormModalClose}
               jsonUrl={modalFormUrl}
               onFormModified={handleFormModalClose}
            />
         )}
         {data.page && <MessageContent serverData={data.page as MessageContentJson} />}
      </DashboardPageContainer>
   );
};

export default GoalsPage;
