import React, { FC, useState } from "react";
import { Column } from "../Column/Column";
import RadioButton from "../RadioButton/RadioButton";
import { LabelError } from "../Texts/Texts";
import { LabelAndValue } from "../tools/types";
import {
   RadioGroupContainer,
   RadioGroupExtraLabel,
   RadioGroupLabel
} from "./RadioButtonGroup.styles";

export interface PropsRadioButtonGroup {
   label?: string;
   extraLabel?: string;
   error?: string;
   options: LabelAndValue[];
   selectedByDefault?: string;
   onChange: (selectedValue: string) => void;
}

const RadioButtonGroup: FC<PropsRadioButtonGroup> = props => {
   const { label, extraLabel, options, selectedByDefault, error, onChange } = props;
   const [selectedValue, setSelectedValue] = useState<string | undefined>(selectedByDefault);

   const handleRadioButtonClick = (value: string) => {
      setSelectedValue(value);
      onChange(value);
   };

   return (
      <Column>
         {label && <RadioGroupLabel>{label}</RadioGroupLabel>}
         {extraLabel && <RadioGroupExtraLabel>{extraLabel}</RadioGroupExtraLabel>}
         <RadioGroupContainer>
            {options &&
               options.map(option => (
                  <RadioButton
                     label={option.label}
                     checked={selectedValue === option.value}
                     onChange={checked => handleRadioButtonClick(option.value)}
                     key={option.value}
                  />
               ))}
         </RadioGroupContainer>
         {error && <LabelError>{error}</LabelError>}
      </Column>
   );
};

export default RadioButtonGroup;
