import { UseFormReturn } from "react-hook-form";
import { FormElementJson, FormJson } from "../../../../tools/api/forms/useServerJsonForm";
import { FormTools } from "./useFormTools";
import { AblyData } from "../../../../tools/ably/useAbly";
import { AblyMessage } from "../AblyListenerFromJson/AblyListenerFromJson";

/**
 * Props of the FormFromJson component
 */
export interface PropsFormFromJson {
   jsonUrl?: string;

   /* You can pass a json so this component will not make a request, instead it will use what you provide in the json prop */
   alreadyLoadedJson?: { json: FormJson };

   /* Default: true. When this is true it will redirect to the url the json request to redirect after submit */
   redirectToNextFormOnSubmit?: boolean;

   /* The form variant prop changes general styling of the form */
   variant?: FormFromJsonVariant;

   /* We were requested to disable the submit button when the form is incomplete for some forms */
   disableSubmitButtonWhenFormIsIncomplete?: boolean;

   /** When set to true, after form submit redirection is not possible to go back, used for example in the login form or signup form */
   redirectWithoutHistory?: boolean;

   /* This changes some behaviors that are different when the form is inside a modal */
   isInsideModal?: boolean;

   /* This event is executed when a new form json is received */
   onDataFromServer?: (
      formJson: FormJson | undefined,
      refetch: () => void,
      remove: () => void
   ) => void;

   /* This event is executed when the form json request fails */
   onErrorFromServer?: (e: any) => void;

   /* This event is executed when the form json request finishes */
   onSubmitFinish?: <T>(submitResponse: T | undefined) => void;

   /** When this form is rendered within a modal this event is used to close it */
   onCancelForm?: () => void;

   /** When a new form json is received a "form title" is inferred based on the first title component, this event is executed every time a new title is inferred and it can be used to set a modal title when the modal contains the form */
   onFormTitleReceived?: (title: string) => void;

   // TODO: This feature should be extracted outside of this component
   /** To render specific content in a left column*/
   renderLeftColumn?: (formJson: FormJson, form: Form) => React.ReactNode;

   /** If you return an element on this render function it will be used as a container for the entire form children, for custom styling adjustments */
   renderCustomContainer?: () => React.ComponentType;

   /** Ably is a library that uses web sockets to do things in real time, currently used for special page redirects after some backend processes are done when some forms are submit. Normally the form json contains everything needed for ably but those props are here just in case you need a special ably "intervention" or event handling outside the component */
   ably?: {
      /** To prevent ably from connecting return false on this function */
      onAblyListener?: (ablyData: AblyData) => boolean | undefined | void;
      onAblyMessage?: (message?: AblyMessage) => void;
   };

   /** Lottie is a library to render vector animations, forms may contain animated elements */
   lottie?: {
      /** If you return an element on this render function it will be used as a container for the lottie animation useful to adjust the animation size, margins, etc */
      renderCustomLottieAnimationContainer?: () => React.ComponentType;
   };

   /** Use this function to replace form components, prevent them from rendering or to render a "used once" component that is not required to be implemented inside FormFromJson. If this function returns a component it will replace the default form component by what this returns, if the function is undefined or returns undefined it will do nothing. To prevent a form component from rendering return <></> in this function. Example usage: Use formElement.type to conditionally replace all form elements of specific a specific type, this is useful to render a component with different style or functionality for a specific form without adding extra code inside FormFromJson */
   renderFormComponent?: FormElementReplacementFn;

   /* You may want the first title to not render when the form is contained in a modal because it's rendered in the modal itself using the onFormTitleReceived prop */
   disableFirstTitleRendering?: boolean;
}

/**
 * Variant changes general styling of the form
 */
export enum FormFromJsonVariant {
   Normal,
   TwoColumns,
   ContainedInModal
}

export type JsonFormData = Record<string, string | number | object>;

/** The react-hook-form object that manages the form validation and other form tasks */
export type Form = UseFormReturn<JsonFormData>;

export type FormElementReplacementFn = (
   props: FormComponentProps
) => React.ReactNode | undefined | null;

/**
 * All form components needs to have the same props, this is the interface for them.
 */
export interface FormComponentProps {
   formElementJson: FormElementJson;
   formTools: FormTools;
   isLastElement: boolean;
}
