import React, { FC } from "react";
import { Circle1, Circle2, Circle3, Spinner, SpinnerContainer } from "./LoadingSpinner.styles";

const LoadingSpinner: FC = () => {
   return (
      <SpinnerContainer>
         <Spinner>
            <Circle1 />
            <Circle2 />
            <Circle3 />
         </Spinner>
      </SpinnerContainer>
   );
};

export default LoadingSpinner;
