import { useCallback, useState, useEffect } from "react";
import { ISetValuesProps } from "./GroupedButtons.types";
import { initialState, toggleOptionInArray } from "./utils";

const useSetValues = ({ initialValues, multiSelect }: ISetValuesProps) => {
   const [values, setValues] = useState(initialValues ?? initialState(multiSelect));

   const handleOnChange = useCallback(
      selectedOption => {
         if (multiSelect && Array.isArray(values)) {
            setValues(toggleOptionInArray(values, selectedOption));
         } else {
            setValues(selectedOption === values ? undefined : selectedOption);
         }
      },
      [values, multiSelect]
   );

   useEffect(() => {
      setValues(initialState(multiSelect, initialValues));
   }, [multiSelect, initialValues]);

   return {
      values,
      setValues: handleOnChange
   };
};

export default useSetValues;
