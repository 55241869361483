export function tryToGetErrorStatusCode(error: any): number {
   if (!error) {
      return 0;
   }

   if (error.response) {
      if (error.response.status) {
         return error.response.status;
      }
   }

   return 0;
}
