import React, { FC } from "react";
import { getFieldError } from "../../../../tools/validation/getFieldError";
import CurrencyInput from "../../../kanopy-ui/CurrencyInput/CurrencyInput";
import { FormComponentProps } from "../tools/typings";

const CurrencyInputFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form } = formTools;

   return (
      <CurrencyInput
         label={formElementJson.label ?? ""}
         currencySymbol={"$"}
         defaultValue={formElementJson.value}
         {...form.register<any>(formElementJson.name, {
            required: Boolean(formElementJson.required)
         })}
         error={getFieldError({ formElementJson, form })}
      />
   );
};

export default CurrencyInputFromJson;
