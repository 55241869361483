import React, { FC, useEffect, useRef } from "react";
import { TwoColumns } from "../../kanopy-ui/Column/Column";
import Container from "../../kanopy-ui/Container";
import {
   PageStructure,
   FixedBottomContainer,
   FormContainer,
   ScrollContainer
} from "./TwoColumnsPageStructure.styles";

interface PropsTwoColumnsPageStructure {
   renderLeftColumn: () => React.ReactNode | void;
   onFixedBottomRef?: (ref: React.RefObject<HTMLDivElement>) => void;
   renderCustomContainer?: () => React.ComponentType;
}

const TwoColumnsPageStructure: FC<PropsTwoColumnsPageStructure> = props => {
   const { renderLeftColumn, onFixedBottomRef, renderCustomContainer, children } = props;
   const fixedBottomRef = useRef<HTMLDivElement>(null);
   const CustomContainer = renderCustomContainer?.() ?? React.Fragment;

   useEffect(() => {
      onFixedBottomRef?.(fixedBottomRef);
   }, [fixedBottomRef, onFixedBottomRef]);

   return (
      <PageStructure>
         <Container fixedWidth>
            <TwoColumns>
               {renderLeftColumn?.()}
               <ScrollContainer>
                  <FormContainer>
                     <CustomContainer>{children}</CustomContainer>
                  </FormContainer>
               </ScrollContainer>
            </TwoColumns>
         </Container>
         <FixedBottomContainer ref={fixedBottomRef} />
      </PageStructure>
   );
};

export default TwoColumnsPageStructure;
