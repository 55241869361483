import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import PageContainer from "../../../common/PageContainer/PageContainer";
import { AuthCard, AuthFormTitle } from "./GenericAuthForm.styles";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import { CardVariant } from "../../../kanopy-ui/Card";
import { AbsoluteCenter } from "../../../common/PageContainer/PageContainer.styles";
import { PropsFormFromJson } from "../../../common/FormFromJson/tools/typings";

const GenericAuthForm: FC<Partial<PropsFormFromJson>> = props => {
   const location = useLocation();
   const search = useLocation().search;
   const source = new URLSearchParams(search).get("source");

   return (
      <PageContainer>
         <AbsoluteCenter top="35%">
            <AuthCard variant={CardVariant.StrongShadow}>
               <FormFromJson
                  jsonUrl={location.pathname + (source !== null ? "?source=" + source : "")}
                  renderFormComponent={({ formElementJson }) =>
                     formElementJson.type === "title" ? (
                        <AuthFormTitle>{formElementJson.label}</AuthFormTitle>
                     ) : null
                  }
                  {...props}
               />
            </AuthCard>
         </AbsoluteCenter>
      </PageContainer>
   );
};

export default GenericAuthForm;
