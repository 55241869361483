import React, { FC, useState } from "react";
import {
   NotificationData,
   NotificationStatus,
   NotificationsResponse,
   useNotifications
} from "../../../tools/api/notifications/useNotifications";
import {
   ContextMenuStyled,
   MessageItem,
   NotificationItem,
   NotificationTypeText
} from "./Notifications.styles";
import Icon from "../../kanopy-ui/Icon/Icon";
import ButtonNotifications from "./ButtonNotifications/ButtonNotifications";
import HeaderNotifications, {
   NotificationsListMode
} from "./HeaderNotifications/HeaderNotifications";
import { ContextMenuItemData } from "../../kanopy-ui/ContextMenu/ContextMenu";
import { httpRequest } from "../../../tools/api/tools/httpRequest";
import Badge from "../../kanopy-ui/Badge/Badge";

interface PropsNotifications {
   /**
    * If no client id is provided then the session object is used. clientId is currently used for "client notifications"
    */
   clientId?: number;
   buttonComponent?: (notifications?: NotificationsResponse) => React.ReactNode;
}

const Notifications: FC<PropsNotifications> = props => {
   const { clientId, buttonComponent } = props;
   const notifications = useNotifications({ params: { clientId: clientId } });
   const [mode, setMode] = useState<NotificationsListMode>(NotificationsListMode.ShowAll);
   const isLoading = !notifications?.data;
   const notificationsListIsEmpty =
      (notifications.data?.read ?? []).length === 0 &&
      (notifications.data?.unread ?? []).length === 0;
   const unreadNotificationsAmount = notifications?.data?.unread?.length ?? 0;
   const readNotificationsAmount = notifications?.data?.read?.length ?? 0;

   /**
    * If the notificationId is not specified then all notification are marked as read
    */
   const sendSetAsRead = async (notification?: NotificationData) => {
      // If the notification is already read then stop
      if (notification && notification.status === NotificationStatus.Read) {
         return;
      }

      const url = `notifications/set_read${notification ? `/${notification.id}` : ""}${
         clientId ? `?clientId=${clientId}` : ""
      }`;
      await httpRequest({ url, method: "POST" });
      notifications.refetch();
   };

   const getNotificationItems = (
      notificationsData?: NotificationData[],
      notFoundMessage: string = "No items found"
   ): ContextMenuItemData[] => {
      if ((notificationsData ?? []).length === 0) {
         return [
            {
               notInteractive: true,
               disableMenuCloseOnClick: true,
               render: () => <MessageItem text={notFoundMessage} />
            }
         ];
      }

      return (
         notificationsData?.map(notification => ({
            disableMenuCloseOnClick: true,
            onClickRedirectTo: `/${notification.link}&alert=${notification.message}`,
            disableSeparatorLine: true,
            onMouseUp: () => sendSetAsRead(notification),
            render: () => (
               <NotificationItem
                  renderIcon={() => <Icon iconImage={notification.icon} />}
                  text={notification.message}
                  date={notification.created_at}
                  isRead={notification.status === NotificationStatus.Read}
               />
            )
         })) ?? []
      );
   };

   if (isLoading) {
      return null;
   }

   if (notificationsListIsEmpty) {
      return (
         <ContextMenuStyled
            placement={clientId ? "bottom-start" : "bottom-end"}
            menuItems={[
               {
                  notInteractive: true,
                  disableMenuCloseOnClick: true,
                  render: () => <MessageItem text={"There are no notifications yet"} />
               }
            ]}
         >
            {buttonComponent ? buttonComponent(notifications?.data) : <ButtonNotifications />}
         </ContextMenuStyled>
      );
   }

   return (
      <ContextMenuStyled
         placement={clientId ? "bottom-start" : "bottom-end"}
         renderHeader={() => (
            <HeaderNotifications
               mode={mode}
               onModeChange={mode => setMode(mode)}
               onMarkAllAsRead={() => sendSetAsRead()}
               hideMarkAllAsReadButton={unreadNotificationsAmount === 0}
               titleText={clientId ? "Client notifications" : "Notifications"}
            />
         )}
         menuItems={
            mode === NotificationsListMode.ShowAll
               ? [
                    ...(unreadNotificationsAmount > 0
                       ? [
                            {
                               notInteractive: true,
                               disableMenuCloseOnClick: true,
                               disableSeparatorLine: true,
                               render: () => <NotificationTypeText>New</NotificationTypeText>
                            },
                            ...getNotificationItems(notifications.data?.unread)
                         ]
                       : []),
                    ...(readNotificationsAmount > 0
                       ? [
                            {
                               notInteractive: true,
                               disableMenuCloseOnClick: true,
                               disableSeparatorLine: true,
                               render: () => <NotificationTypeText>Read</NotificationTypeText>
                            },
                            ...getNotificationItems(notifications.data?.read)
                         ]
                       : [])
                 ]
               : mode === NotificationsListMode.ShowRead
               ? getNotificationItems(
                    notifications.data?.read,
                    unreadNotificationsAmount > 0 ? "All notifications are unread" : undefined
                 )
               : getNotificationItems(
                    notifications.data?.unread,
                    readNotificationsAmount > 0 ? "All notifications are read" : undefined
                 )
         }
      >
         {buttonComponent ? (
            buttonComponent(notifications?.data)
         ) : (
            <Badge value={notifications?.data?.unread_count ?? 0}>
               <ButtonNotifications />
            </Badge>
         )}
      </ContextMenuStyled>
   );
};

export default Notifications;
