import styled from "styled-components";
import ContextMenu from "../../kanopy-ui/ContextMenu/ContextMenu";
import ContextMenuItem from "../../kanopy-ui/ContextMenuItem/ContextMenuItem";

export const ContextMenuStyled = styled(ContextMenu)`
   position: relative;
   min-width: 300px;
`;

export const LogoutMenuItem = styled(ContextMenuItem)`
   padding-left: 50px;
`;

export const RevealMyAccountMenuItem = styled(ContextMenuItem)`
   padding-left: 50px;
`;
