import React, { useState, useRef, useEffect, FC, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import Input, { BorderVariant, InputColorVariant, InputVariant } from "../Input/Input";
import {
   ColumnOptions,
   DataGridColumnsData,
   DataGridOptions,
   DataGridRowsData
} from "./DataGrid.types";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { AgGridReactStyled } from "./DataGrid.styles";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import DataGridCell from "./DataGridCell/DataGridCell";
import CheckboxesFilter from "./CheckboxesFilter/CheckboxesFilter";
import CustomFloatingFilter from "./FloatingFilter/CustomFloatingFilter";

interface PropsDataGrid {
   columns: DataGridColumnsData;
   rows: DataGridRowsData;
   options?: DataGridOptions;
   /** Example: 500px or 100%. If this is unset the size will be determined by the data grid content */
   height?: string;
   /** If a click on a row should trigger a redirection add a column containing the link, then set the column id in this parameter to enable the feature. This kind of columns should be also configured as invisible */
   columnIdWithLink?: string;
   /** The key of this object is the columnId (field) and the value is the cell renderer function. If this is not set the Ag Grid library will render the cell. Use params.value to get the cell text or params.column to get column data */
   renderCell?: Record<string, (params: any) => React.ReactElement | string | null>;
   /** The key of this object is the columnId (field) and the value is the column settings object that will be merged with the one provided in the columns parameter */
   columnSettings?: Record<string, ColumnOptions>;
}

const DataGrid: FC<PropsDataGrid> = props => {
   const {
      options = {},
      columns,
      rows,
      height,
      columnIdWithLink,
      renderCell,
      columnSettings
   } = props;
   const { defaultColumnSettings = {}, pagination } = options;

   // The following are default options that should always be set so we add them if there are not coming.
   defaultColumnSettings.sortable = defaultColumnSettings.sortable ?? true;
   defaultColumnSettings.resizable = defaultColumnSettings.resizable ?? true;
   defaultColumnSettings.floatingFilter = defaultColumnSettings.floatingFilter ?? false; // Floating filters are the filters always visible behind the column name
   defaultColumnSettings.floatingFilterComponent =
      defaultColumnSettings.floatingFilterComponent ?? CustomFloatingFilter; // Floating filters are the filters always visible behind the column name
   defaultColumnSettings.floatingFilterComponentParams =
      defaultColumnSettings.floatingFilterComponentParams ?? {};
   defaultColumnSettings.filter = defaultColumnSettings.filter ?? CheckboxesFilter; // Filters are the modal component filter that contains more filter options
   // defaultColumnSettings.filter = defaultColumnSettings.filter ?? "agTextColumnFilter"; // An example of a built in filter
   defaultColumnSettings.flex = defaultColumnSettings.flex ?? 1;

   // AgGrid default comparator is case sensitive and we need case-insensitive so we write this comparator
   defaultColumnSettings.comparator = (valueA, valueB) =>
      valueA.toLowerCase().localeCompare(valueB.toLowerCase());

   const [finalColumns, setFinalColumns] = useState<DataGridColumnsData>();
   const [searchInput, setSearchInput] = useState("");

   const gridRef = useRef<AgGridReact>(null);

   // Effect to apply the search filter from the search input
   useEffect(() => {
      gridRef?.current?.api?.setQuickFilter?.(searchInput);
   }, [searchInput]);

   // Effect to inject the columns object with extra data provided from params
   useEffect(() => {
      setFinalColumns(
         columns?.map(columnFromServer => {
            let column = { ...columnFromServer };
            const customRenderer = renderCell?.[columnFromServer.field!];
            const columnFromProps = props.columnSettings?.[columnFromServer.field!];

            /*
             * All cells needs a custom renderer since we need to use the <a> tag to handle links instead of javascript because <a> tag
             * has standard features that cannot be called from javascript. DataGridCell is a wrapper that contains an <a> tag and receives
             * a custom renderer.
             * */
            column = {
               ...column,
               cellRenderer: (params: ICellRendererParams) => (
                  <DataGridCell
                     params={params}
                     columnIdWithLink={columnIdWithLink}
                     customRenderer={customRenderer != null ? customRenderer : undefined}
                     fontWeight={columnFromProps?.fontWeight}
                  />
               )
            };

            if (columnFromProps) {
               column = { ...column, ...columnFromProps };
            }

            return column;
         })
      );
   }, [columns]);

   const popupParent = useMemo<HTMLElement | null>(() => {
      return document.querySelector("body");
   }, []);

   const onGridReady = () => {
      // gridRef.current?.columnApi.autoSizeAllColumns();
   };

   return (
      <div
         className="ag-theme-alpine" /** We cannot use styled components on this one because this line is required by ag grid and by styled components as well */
         style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: !height ? "fit-content" : height
         }}
      >
         {options.enableSearchInput && (
            <Input
               value={searchInput}
               onChange={e => setSearchInput(e.target.value)}
               type={"text"}
               placeholder={"Search..."}
               variant={InputVariant.Short}
               borderVariant={BorderVariant.Complete}
               colorVariant={InputColorVariant.WithBackground}
            />
         )}
         <AgGridReactStyled
            ref={gridRef}
            rowData={rows}
            columnDefs={finalColumns}
            defaultColDef={defaultColumnSettings}
            rowSelection={"single"}
            domLayout={!height ? "autoHeight" : "normal"}
            suppressCellFocus={true}
            suppressMiddleClickScrolls={true}
            useHandCursor={Boolean(columnIdWithLink)}
            suppressRowHoverHighlight={!columnIdWithLink}
            suppressRowClickSelection={!columnIdWithLink}
            enableCellTextSelection={!columnIdWithLink}
            ensureDomOrder={!columnIdWithLink}
            popupParent={popupParent}
            pagination={pagination}
            paginationAutoPageSize={pagination}
            useMinHeight={false}
            colorVariant={options?.colorVariant}
            onGridReady={onGridReady}
         />
      </div>
   );
};

export default DataGrid;
