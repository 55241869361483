import React, { FC, useState } from "react";
import { IconImage } from "../../../../kanopy-ui/Icon/Icon";
import FormFromJsonAsModal from "../../../FormFromJsonAsModal/FormFromJsonAsModal";
import { EditButtonStyled } from "./EditButton.styles";

interface PropsEditButton {
   editFormJsonUrl: string;
}

const EditButton: FC<PropsEditButton> = props => {
   const { editFormJsonUrl } = props;
   const [editModalVisible, setEditModalVisible] = useState(false);

   const handleEditButtonClick = () => {
      setEditModalVisible(true);
   };

   const handleEditModalClose = () => {
      setEditModalVisible(false);
   };

   return (
      <>
         <EditButtonStyled iconImage={IconImage.MdEdit} onClick={handleEditButtonClick} />
         <FormFromJsonAsModal
            modalVisible={editModalVisible}
            onModalClose={handleEditModalClose}
            jsonUrl={editFormJsonUrl}
         />
      </>
   );
};

export default EditButton;
