import React, { FC } from "react";
import { Label10, Label2 } from "../Texts/Texts";
import {
   ItemContainer,
   TextsContainer,
   IconContainer,
   Title4Styled
} from "./ContextMenuItem.styles";

export interface PropsContextMenuItem {
   renderIcon?: () => React.ReactNode;
   title?: string;
   text?: string;
   date?: string;
   adjustIcon?: boolean;
   className?: string | undefined;
}

const ContextMenuItem: FC<PropsContextMenuItem> = props => {
   const { renderIcon, title, text, date, className, adjustIcon } = props;

   return (
      <ItemContainer className={className}>
         {renderIcon && <IconContainer adjustIcon={adjustIcon}>{renderIcon()}</IconContainer>}
         <TextsContainer>
            {title && <Title4Styled>{title}</Title4Styled>}
            {text && <Label10>{text}</Label10>}
            {date && <Label2>{date}</Label2>}
         </TextsContainer>
      </ItemContainer>
   );
};

export default ContextMenuItem;
