import React, { FC, useState, KeyboardEvent, useRef, useEffect } from "react";
import { DateOrRange } from "./DatePicker.types";
import Floating from "../Floating/Floating";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { CalendarCard, RCalendarStyled, RDatePickerStyled } from "./DatePicker.styles";
import { ErrorIcon, ErrorText, InputContainer, InputTextContainer } from "../Input/Input.styles";
import { Label } from "../Texts/Texts";
import { BorderVariant, InputColorVariant, InputVariant } from "../Input/Input";
import { IconImage } from "../Icon/Icon";
import { useMouseWheelFix } from "./tools/useMouseWheelFix";

export interface PropsDatePicker {
   label?: string;
   error?: string;
   defaultValue?: string;
   onChange?: (date: Date) => void;
   variant?: InputVariant;
   colorVariant?: InputColorVariant;
   borderVariant?: BorderVariant;
   readOnly?: boolean;
}

const DatePicker: FC<PropsDatePicker> = props => {
   const {
      label,
      error,
      variant = InputVariant.Long,
      colorVariant = InputColorVariant.WithBackground,
      borderVariant = BorderVariant.Complete,
      readOnly,
      defaultValue,
      onChange
   } = props;

   const [selectedDate, setSelectedDate] = useState<DateOrRange>(
      defaultValue ? new Date(defaultValue) : new Date()
   );
   const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);
   useMouseWheelFix();

   useEffect(() => {
      if (defaultValue) {
         setSelectedDate(new Date(defaultValue));
      }
   }, [defaultValue]);

   const handleOpenCalendar = () => {
      if (readOnly) {
         return;
      }

      setCalendarIsOpen(true);
   };

   const handleCloseCalendar = () => {
      setCalendarIsOpen(false);
   };

   const handleChange = (value: DateOrRange) => {
      handleCloseCalendar();
      setSelectedDate(value);
      onChange?.(value as Date);
   };

   // Enter key handler
   const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
         handleCloseCalendar();
      }
   };

   return (
      <Floating
         content={() => (
            <CalendarCard>
               <RCalendarStyled value={selectedDate} onChange={handleChange} />
            </CalendarCard>
         )}
         visible={calendarIsOpen}
         onClose={handleCloseCalendar}
         placement="bottom"
         offsetY={6}
      >
         <InputContainer>
            {label && <Label>{label}</Label>}
            <InputTextContainer
               error={error}
               colorVariant={colorVariant}
               borderVariant={borderVariant}
               readOnly={readOnly}
            >
               <RDatePickerStyled
                  value={selectedDate}
                  onChange={handleChange}
                  onClick={handleOpenCalendar}
                  onKeyDown={handleKeyPress}
                  clearIcon={null}
                  variant={variant}
                  readOnly={readOnly}
                  disabled={readOnly}
                  error={error}
                  disableCalendar
               />
               {error !== undefined && <ErrorIcon iconImage={IconImage.MdInfo} />}
            </InputTextContainer>
            {error && <ErrorText>{error}</ErrorText>}
         </InputContainer>
      </Floating>
   );
};

export default DatePicker;
