import styled from "styled-components";
import { LabelError } from "../../../../../kanopy-ui/Texts/Texts";

export const MainContainer = styled("div")`
   display: flex;
   flex-direction: column;
   height: 100%;
`;

export const ChartIndicatorsContainer = styled("div")`
   display: flex;
   flex-direction: row;
   column-gap: 16px;
   flex-wrap: wrap;
`;

export const ChartIndicator = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   user-select: none;
   cursor: pointer;
`;

export const ChartIndicatorIcon = styled("div").withConfig({
   shouldForwardProp: p => !["colorIndex", "colors", "onlyBorder"].includes(p)
})<{ colorIndex: number; colors: string[]; onlyBorder?: boolean }>`
   width: 10px;
   height: 10px;
   margin-right: 8px;
   background-color: ${({ colorIndex, colors, onlyBorder }) =>
      !onlyBorder ? colors[colorIndex] : "transparent"};
   border-radius: ${({ theme }) => theme.lineChart.indicatorsIconRadius};
   ${({ onlyBorder, colors, colorIndex }) =>
      onlyBorder
         ? `border-width: 2px; border-color: ${colors[colorIndex]}; border-style: solid;`
         : ""}
`;

export const FilterToolsContainer = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-top: 16px;
`;
