import { atom } from "jotai";
import { ButtonVariant } from "../../components/kanopy-ui/Button";

export const atomGlobalSnackbars = atom<Array<GlobalSnackbar & { snackbarId: string }>>([]);

export interface GlobalSnackbar {
   message?: string;
   /* Time in milliseconds until the snackbar disappears. Default: 4000 */
   durationMs?: number;
   buttons?: GlobalSnackbarButton[];
   disableClosing?: boolean;
   disableCloseButton?: boolean;
}

export interface GlobalSnackbarButton {
   text?: string;
   onClick?: () => void;
   variant?: ButtonVariant;
}
