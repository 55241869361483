import React, { FC, useState } from "react";
import { httpRequest } from "../../../../tools/api/tools/httpRequest";
import { Link2 } from "../../../kanopy-ui/Texts/Texts";
import { AddPanelButtonContainer, AddPanelButtonIcon } from "./ButtonRevealNextForm.styles";
import { FormComponentProps } from "../tools/typings";

const ButtonRevealNextForm: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const [hideButton, setHideButton] = useState(false);

   const handleAddPanelClick = () => {
      // TODO: Instead of using the formTools object directly we should use executeFormAction() when the new json structure is implemented for this form element.
      formTools.hiddenFormsTools.revealNextForm();

      // After revealing the hidden form is required that the button is hidden because the revealed form cannot be hidden again. It's a kind of "load more form" functionality.
      setHideButton(true);

      // The form json may require to make a request when the user reveals a form.
      if (formElementJson.api?.url) {
         httpRequest({
            url: formElementJson.api?.url,
            method: "POST"
         });
      }
   };

   return (
      <AddPanelButtonContainer onClick={handleAddPanelClick} hideButton={hideButton}>
         <AddPanelButtonIcon src={formElementJson.icon} />
         <Link2>{formElementJson.label}</Link2>
      </AddPanelButtonContainer>
   );
};

export default ButtonRevealNextForm;
