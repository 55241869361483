import styled from "styled-components";
import ContextMenu from "../ContextMenu/ContextMenu";
import ContextMenuItem from "../ContextMenuItem/ContextMenuItem";
import Icon from "../Icon/Icon";
import Input from "../Input/Input";

export const InputContainer = styled("div")`
   display: flex;
   flex-direction: column;
   position: relative;
   row-gap: 3px;
   width: 100%;
`;

export const ContextMenuStyled = styled(ContextMenu)`
   max-height: 40vh;
   overflow-y: auto;
`;
export const MenuItemStyled = styled(ContextMenuItem)`
   padding-left: 22px;
`;

export const InputStyled = styled(Input)`
   cursor: pointer;
`;

export const ToggleIcon = styled(Icon).withConfig({
   shouldForwardProp: p => !["hovering"].includes(p)
})<{ hovering?: boolean }>`
   transition: color 250ms;
   color: ${({ theme }) => theme.select.toggleIconColor};
   ${({ theme, hovering }) => (hovering ? `color: ${theme.select.toggleIconColorOnHover};` : "")}
`;
