import React, { FC } from "react";
import Drawer from "../../../../kanopy-ui/Drawer/Drawer";
import PageContainer, {
   PageContainerVariant
} from "../../../../common/PageContainer/PageContainer";
import { SettingsPageContent } from "./SettingsPageContainer.styles";
import { useSidePanel } from "../../../../../tools/api/settings/side-panel/useSidePanel";
import LoadingSpinner from "../../../../kanopy-ui/LoadingSpinner/LoadingSpinner";
import RenderDrawer from "./RenderDrawer";

const SettingsPageContainer: FC = ({ children }) => {
   const { data: sidePanelData } = useSidePanel();

   if (sidePanelData === undefined) {
      return <LoadingSpinner />;
   }

   return (
      <PageContainer variant={PageContainerVariant.LoggedIn}>
         <Drawer renderContent={() => sidePanelData && <RenderDrawer data={sidePanelData} />}>
            <SettingsPageContent>{children}</SettingsPageContent>
         </Drawer>
      </PageContainer>
   );
};

export default SettingsPageContainer;
