import { ColDef, ColGroupDef } from "ag-grid-community";

export interface DataGridOptions {
   enableSearchInput?: boolean;
   defaultColumnSettings?: ColumnOptions;
   pagination?: boolean;
   colorVariant?: ColorVariant;
}

export type DataGridRowsData = Array<Record<string, string | number | Date>>;
export type DataGridColumnsData = ColDef[] | null;
export enum ColorVariant {
   Default,
   Transparent
}
export interface ColumnOptions extends ColDef {
   fontWeight?: number;
}
