import styled from "styled-components";

export const CurrencySymbol = styled("div")`
   position: relative;
   left: 13px;
   font-family: ${({ theme }) => theme.currencyInput.currencySymbol.fontFamily};
   color: ${({ theme }) => theme.currencyInput.currencySymbol.color};
   font-size: ${({ theme }) => theme.currencyInput.currencySymbol.fontSize}px;
   font-weight: ${({ theme }) => theme.currencyInput.currencySymbol.weight};
   line-height: ${({ theme }) => theme.currencyInput.currencySymbol.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.currencyInput.currencySymbol.letterSpacing}em;
`;
