import styled from "styled-components";
import { Label12 } from "../../../../kanopy-ui/Texts/Texts";

export const SettingsPageContent = styled("div")`
   width: 100%;
   height: 100%;
   padding-top: 46px;
   padding-left: 24px;
   padding-right: 32px;
   background-color: ${({ theme }) => theme.colors.primaryWashedMore};
   overflow-y: auto;
   padding-bottom: 32px;
`;

export const SettingsDrawerContainer = styled("div")``;

export const LogoImg = styled("img")`
   max-width: 168px;
   margin-top: 28px;
   padding-left: ${({ theme }) => theme.spacing(2, "px")};
   padding-right: ${({ theme }) => theme.spacing(2, "px")};
   margin-bottom: ${({ theme }) => theme.spacing(3, "px")};
`;

export const SeparatorText = styled(Label12)`
   padding-left: 10px;
   user-select: none;
   margin-bottom: 4px;
`;

export const ButtonGroup = styled("div")`
   width: 100%;
   margin-bottom: 26px;
`;
