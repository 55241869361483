import { FC, useEffect, useState } from "react";
import { Line } from "@nivo/line";
import { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";
import { ResponsiveContainer, ChartContainer, ChartErrorMessage } from "./LineChart.styles";
import HoverTooltip from "./HoverTooltip/HoverTooltip";
import { NumberFormatOptions } from "../../../../tools/string/formatNumber";
import { useFormatterAxisY } from "./tools/useFormatterAxisY";
import TooltipOverflowFix from "./HoverTooltip/NotOverflowTooltipWrapper/TooltipOverflowFix";
import { CartesianMarkerProps } from "@nivo/core";

interface PropsLineChart {
   data: LineChartDataItem[];
   /** If you implemented a filter for the data you should pass the original data here in order to not have problem with the colors -> id relationships */
   dataUnfiltered?: LineChartDataItem[];
   valueYFormat?: NumberFormatOptions;
   tooltipValueYFormat?: NumberFormatOptions;
   className?: string;
   onLineColors?: (lineColors: string[]) => void;
   markers: CartesianMarkerProps[];
}

export interface LineChartDataItem {
   id: string | number;
   title: string;
   data: Array<{
      x: string | number;
      y: string | number;
   }>;
}

const LineChart: FC<PropsLineChart> = props => {
   const {
      data,
      dataUnfiltered,
      className,
      valueYFormat,
      tooltipValueYFormat,
      onLineColors,
      markers
   } = props;
   const theme = useTheme();
   const [containerSize, setContainerSize] = useState<{ width?: number; height?: number }>();
   const { ref } = useResizeObserver<HTMLDivElement>({ onResize: setContainerSize });
   const [lineColors] = useState<string[]>(theme.lineChart.lineColors);
   const { handleLeftAxisFormat, leftTickCharsAmount } = useFormatterAxisY(data, valueYFormat);
   const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

   useEffect(() => {
      onLineColors?.(lineColors);
   }, [lineColors, onLineColors]);

   const gradients = [
      {
         id: "gradient0",
         type: "linearGradient",
         /**
          * In the graphic design the first line does not have a gradient, we need to add it
          * only when there is a single line
          */
         colors:
            data.length === 1
               ? [
                    { offset: 50, color: "inherit", opacity: 1 },
                    { offset: 100, color: "inherit", opacity: 0 }
                 ]
               : []
      },
      {
         id: "gradient1",
         type: "linearGradient",
         colors: [
            { offset: 60, color: "inherit", opacity: 1 },
            { offset: 100, color: "inherit", opacity: 0 }
         ]
      },
      {
         id: "gradient2",
         type: "linearGradient",
         colors: [
            { offset: 50, color: "inherit", opacity: 1 },
            { offset: 100, color: "inherit", opacity: 0 }
         ]
      }
   ];

   const getLineColor = (id: string) => {
      const index = (dataUnfiltered ?? data).findIndex(dataItem => dataItem.id === id);
      return lineColors[index];
   };

   const handleMouseMove = (event: any) => {
      const containerRect = event.target.getBoundingClientRect();
      const x = event.clientX - containerRect.x;
      const y = event.clientY - containerRect.y;
      setMousePosition({ x, y });
   };

   if (data?.length === 0) {
      return <ChartErrorMessage>All lines are hidden</ChartErrorMessage>;
   }

   return (
      <ResponsiveContainer ref={ref} onMouseMove={handleMouseMove} className={className}>
         {containerSize?.width != null && containerSize?.height != null && (
            <ChartContainer>
               <Line
                  data={data}
                  markers={markers}
                  colors={item => getLineColor(item.id)}
                  width={containerSize?.width ?? 100}
                  height={containerSize?.height ?? 100}
                  margin={{
                     top: 20,
                     right: 25,
                     bottom: 40,
                     left: Math.max(55, 9 * leftTickCharsAmount)
                  }}
                  xScale={{ type: "point" }}
                  yScale={{
                     type: "linear",
                     min: "auto",
                     max: "auto",
                     reverse: false
                  }}
                  yFormat=" >-.2f" // TODO: Check if this line does something and if it can be removed, probably this is being replaced by handleLeftAxisFormat
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  gridXValues={12}
                  axisBottom={{
                     tickValues: 12,
                     tickSize: 0,
                     tickPadding: 20,
                     tickRotation: 0,
                     legendOffset: 36,
                     legendPosition: "middle"
                  }}
                  gridYValues={9}
                  axisLeft={{
                     tickValues: 9,
                     tickSize: 0,
                     tickPadding: 10,
                     tickRotation: 0,
                     legendOffset: -40,
                     legendPosition: "middle",
                     format: handleLeftAxisFormat
                  }}
                  enableGridX={false}
                  lineWidth={4}
                  pointSize={11}
                  pointColor={theme.lineChart.pointColor}
                  pointBorderWidth={3}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  enableArea={true}
                  areaOpacity={0.1}
                  useMesh={true}
                  defs={gradients}
                  fill={data.map((d, i) => ({
                     match: { id: d.id },
                     id: gradients[i]?.id ?? gradients[gradients.length - 1]?.id ?? ""
                  }))}
                  tooltip={point => (
                     <TooltipOverflowFix mousePosition={mousePosition}>
                        <HoverTooltip
                           valueX={point.point.data.x}
                           valueY={point.point.data.y}
                           label={data.find(d => d.id === point.point.serieId)?.title}
                           valueYFormat={tooltipValueYFormat}
                        />
                     </TooltipOverflowFix>
                  )}
                  theme={{
                     fontFamily: theme.lineChart.axisTexts.fontFamily,
                     textColor: theme.lineChart.axisTexts.color,
                     axis: {
                        ticks: {
                           text: {
                              fontFamily: theme.lineChart.axisTexts.fontFamily,
                              color: theme.lineChart.axisTexts.color,
                              fontWeight: theme.lineChart.axisTexts.weight,
                              fontSize: theme.lineChart.axisTexts.fontSize,
                              lineHeight: theme.lineChart.axisTexts.lineHeight,
                              letterSpacing: theme.lineChart.axisTexts.letterSpacing
                           }
                        }
                     },
                     grid: {
                        line: {
                           stroke: theme.lineChart.backgroundGridLinesColor,
                           strokeWidth: 0.6,
                           strokeDasharray: "6 4"
                        }
                     }
                  }}
               />
            </ChartContainer>
         )}
      </ResponsiveContainer>
   );
};

export default LineChart;
