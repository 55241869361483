import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { httpRequest } from "../tools/httpRequest";
import { RequestError } from "../tools/typings";
import { IconImage } from "../../../components/kanopy-ui/Icon/Icon";

interface UseNotificationsProps {
   clientId?: number;
}

export function useNotifications<
   Params extends UseNotificationsProps,
   Error extends RequestError,
   Response extends NotificationsResponse
>(props?: { params?: Params; options?: UseQueryOptions<Params, Error, Response> }) {
   const clientId = props?.params?.clientId;
   const url = `notifications${clientId ? `?clientId=${clientId}` : ""}`;

   return useQuery<Params, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}

/**
 * This is the same than useNotifications but returns dummy data for testing
 */
/*
export function useNotifications<
   Error extends RequestError,
   Response extends NotificationsResponse
>(props?: { options?: UseQueryOptions<void, Error, Response> }) {
   const data = {
      read: [
         {
            id: 12238,
            message: "This calendar quarter your pre-tax loss is in excess of $500,000",
            status: 0,
            created_at: "2022-12-24T00:28:48.834Z",
            type: 1,
            link: "dashboard/loan-performance",
            icon: "MdAssessment"
         },
         {
            id: 12237,
            message:
               "For 3 consecutive months with your revenue is than less than $40,000 for each month",
            status: 0,
            created_at: "2022-12-24T00:28:48.834Z",
            type: 1,
            link: "dashboard/loan-performance",
            icon: "MdAssessment"
         },
         {
            id: 12236,
            message: "This month your pre-tax loss is in excess of 175% of your revenue",
            status: 0,
            created_at: "2022-12-24T00:28:48.834Z",
            type: 1,
            link: "dashboard/loan-performance",
            icon: "MdAssessment"
         }
      ],
      unread: [
         {
            id: 12238,
            message: "This calendar quarter your pre-tax loss is in excess of $500,000",
            status: 1,
            created_at: "2022-12-24T00:28:48.834Z",
            type: 1,
            link: "dashboard/loan-performance",
            icon: "MdAssessment"
         },
         {
            id: 12237,
            message:
               "For 3 consecutive months with your revenue is than less than $40,000 for each month",
            status: 1,
            created_at: "2022-12-24T00:28:48.834Z",
            type: 1,
            link: "dashboard/loan-performance",
            icon: "MdAssessment"
         },
         {
            id: 12236,
            message: "This month your pre-tax loss is in excess of 175% of your revenue",
            status: 1,
            created_at: "2022-12-24T00:28:48.834Z",
            type: 1,
            link: "dashboard/loan-performance",
            icon: "MdAssessment"
         }
      ],
      unread_count: 3
   };

   return { data } as unknown as UseQueryResult<Response, Error>;

   // return {
   //    data: {
   //       all: [],
   //       unread: [],
   //       unread_count: 0
   //    }
   // } as unknown as UseQueryResult<Response, Error>;
}
*/

export interface NotificationsResponse {
   read: NotificationData[];
   unread: NotificationData[];
   unread_count: number;
}

export interface NotificationData {
   id: string;
   message: string;
   status: NotificationStatus;
   created_at: string;
   type: number; // deprecated
   link: string;
   icon: IconImage;
}

export enum NotificationStatus {
   Read,
   Unread
}
