import styled from "styled-components";
import Card from "../../../../../kanopy-ui/Card";
import Icon from "../../../../../kanopy-ui/Icon/Icon";
import ProgressBar from "../../../../../kanopy-ui/ProgressBar/ProgressBar";
import { Label10, Label11, Label9 } from "../../../../../kanopy-ui/Texts/Texts";

export const TopExpensesContainer = styled(Card)`
   flex-grow: 1;
   padding-top: 24px;
   padding-left: 32px;
   padding-right: 32px;
   padding-bottom: 24px;
`;

export const TopExpensesTitle = styled(Label11)`
   margin-bottom: 23px;
`;

export const ItemsContainer = styled("div")`
   display: flex;
   flex-direction: column;
   row-gap: 14px;
`;

export const ItemContainer = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   min-height: 22px;
`;

export const ExpenseLabel = styled(Label10)`
   min-width: 54%;
   max-width: 54%;
`;

export const ExpenseCashLabel = styled(Label9)`
   min-width: 100px;
`;

export const ExpenseProgressBar = styled(ProgressBar)`
   height: 22px;
   margin: 0;
`;
export const TooltipIcon = styled(Icon)`
   transition: color 0.5s;
   height: 0;
   position: absolute;
   padding-left: 345px;
   cursor: pointer;
   :hover {
      color: #6bb97f;
   }
`;
