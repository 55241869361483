import styled from "styled-components";

export const TitleIllustrationContainer = styled("div")`
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   position: relative;
   max-width: 705px;
   margin-right: 45.46px;
   padding-top: 33px;
`;

export const TextsContainer = styled("div")`
   text-align: center;
   font-size: 35px;
   font-weight: 800;
   font-family: "Overpass";
   color: #404040;
`;

export const BackgroundIllustration = styled("img")`
   width: 325px;
   height: 314px;
   padding-bottom: 25px;
   position: relative;
   left: 72px;
`;

export const TextsContainer2 = styled("div")`
   text-align: center;
   font-size: 16px;
   font-weight: 400;
   color: #404040;
   line-height: 21.82px;
`;
