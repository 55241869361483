import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { LeftPanel } from "../../../../../tools/api/forms/useServerJsonForm";
import ProgressBar, { ProgressBarVariant } from "../../../../kanopy-ui/ProgressBar/ProgressBar";
import TaskIndicator, {
   TaskIndicatorState
} from "../../../../kanopy-ui/TaskIndicator/TaskIndicator";
import {
   Bottom,
   LenderLogo,
   ProgressTitle,
   StepInformationItem,
   StepsCard,
   StepsContainer,
   StepsInformationContainer,
   StepsTitle,
   StepTitle,
   Top
} from "./WizardLeftPanel.styles";
import { useDialog } from "../../../../common/GlobalDialogs/tools/useDialog";
import { ButtonVariant } from "../../../../kanopy-ui/Button";
import { Form } from "../../../../common/FormFromJson/tools/typings";
// import { useSaveButtonDisable } from "../../../../common/FormFromJson/tools/saveButtonDisable/useSaveButtonDisable";

interface PropsWizardLeftPanel {
   leftPanelJson?: LeftPanel;
   formLeftPanel?: Form;
}
const WizardLeftPanel: FC<PropsWizardLeftPanel> = props => {
   const { leftPanelJson } = props;
   const [isDirty, setIsDirty] = useState(false);
   const { openDialog } = useDialog();

   const navigate = useNavigate();
   // @TODO: disabled this functionality React Router v6
   // let location = useLocation();

   const handleOnKeypress = useCallback(() => {
      setIsDirty(true);
   }, []);

   const handleOnConfirm = (redirectUrl: string) => {
      navigate(`/${redirectUrl}`);
      setIsDirty(false);
   };

   const openConfirmDialog = (redirectUrl: string) => {
      openDialog({
         title: "Confirmation",
         text: "You have unsaved changes, are you sure you want to leave?",
         buttons: [
            { text: "No", variant: ButtonVariant.Outlined },
            { text: "Yes", onClick: () => handleOnConfirm(redirectUrl) }
         ]
      });
   };

   // @TODO: rewrite this code listener
   useEffect(() => {
      document.addEventListener("keydown", handleOnKeypress);
      return () => document.removeEventListener("keydown", handleOnKeypress);
   }, [handleOnKeypress]);

   if (leftPanelJson?.steps === undefined) {
      return null;
   }

   // @TODO: Disabled this functionality
   // useEffect(() => {
   //    // @TODO: check this functionality
   //    console.log('location changed', location);
   //    setIsDirty(false);
   // }, [location]);

   return (
      <StepsContainer>
         <StepsCard>
            <Top>
               <LenderLogo src={leftPanelJson.lender_icon!} />
               <ProgressTitle>{leftPanelJson.progress_title}</ProgressTitle>
               <ProgressBar
                  variant={ProgressBarVariant.LeftPanel}
                  percentage={leftPanelJson.progress ?? 0}
                  showText={true}
               />
            </Top>
            <Bottom>
               <StepsTitle>{leftPanelJson.steps_title}</StepsTitle>
               <StepsInformationContainer>
                  {leftPanelJson.steps.map((step, i) => (
                     <StepInformationItem
                        active={Boolean(step.active)}
                        onClick={() =>
                           step.link &&
                           // && formSaveButtonIncompleteField === false
                           isDirty
                              ? openConfirmDialog(step.link)
                              : step.link && navigate(`/${step.link}`)
                        }
                        key={i}
                     >
                        <span>
                           <TaskIndicator
                              state={
                                 Boolean(step.active)
                                    ? TaskIndicatorState.InProgress
                                    : step.checked
                                    ? TaskIndicatorState.Completed
                                    : TaskIndicatorState.Pending
                              }
                           />
                           <StepTitle>{step.title}</StepTitle>
                        </span>
                     </StepInformationItem>
                  ))}
               </StepsInformationContainer>
            </Bottom>
         </StepsCard>
      </StepsContainer>
   );
};

export default WizardLeftPanel;
