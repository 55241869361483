import React, { FC } from "react";
import { useAtom } from "jotai";
import {
   GlobalSnackbarButton,
   atomGlobalSnackbars
} from "../../../tools/atoms/atomGlobalSnackbars";
import Button, { ButtonVariant } from "../../kanopy-ui/Button";
import { SnackBar } from "../../kanopy-ui/SnackBar/SnackBar";
import { SnackbarsContainer } from "./GlobalSnackbars.styles";
import { useTheme } from "styled-components";

export interface PropsGlobalSnackbars {
   positionX?: SnackbarsPositionX;
   positionY?: SnackbarsPositionY;
   offsetX?: number;
   offsetY?: number;
   gap?: number;
}

export enum SnackbarsPositionX {
   Left = "Left",
   Right = "Right",
   Center = "Center"
}

export enum SnackbarsPositionY {
   Top = "Top",
   Bottom = "Bottom"
}

/**
 * This component needs to be placed at the root of the project. Not to be used as a normal component.
 */
const GlobalSnackbars: FC<PropsGlobalSnackbars> = props => {
   const {
      positionX = SnackbarsPositionX.Center,
      positionY = SnackbarsPositionY.Bottom,
      offsetX = 32,
      offsetY = 128,
      gap = 6
   } = props;
   const theme = useTheme();

   const [snackbars, setSnackbars] = useAtom(atomGlobalSnackbars);

   const closeSnackbar = (snackbarId: string) => {
      setSnackbars(snackbars.filter(snackbar => snackbar.snackbarId !== snackbarId));
   };

   const handleButtonClick = (snackbarId: string, button: GlobalSnackbarButton) => {
      closeSnackbar(snackbarId);
      button.onClick?.();
   };

   return (
      <SnackbarsContainer
         positionX={positionX}
         positionY={positionY}
         offsetX={offsetX}
         offsetY={offsetY}
         gap={gap}
      >
         {snackbars.map((snackbar, i) => (
            <SnackBar message={snackbar.message} animateHeight={i > 0} key={i}>
               {/*This button feature is not currently being used*/}
               {snackbar?.buttons?.map((button, j) => (
                  <Button
                     variant={button.variant ?? ButtonVariant.TextOnly}
                     style={{ color: theme.colors.primaryWashedMore }}
                     onClick={() => handleButtonClick(snackbar.snackbarId, button)}
                     key={j}
                  >
                     {button.text}
                  </Button>
               ))}
            </SnackBar>
         ))}
      </SnackbarsContainer>
   );
};

export default GlobalSnackbars;
