import styled from "styled-components";

export const TitleBase = styled("h1")`
   margin: 0;
   padding: 0;
`;
export const ParagraphBase = styled("p")`
   margin: 0;
   padding: 0;
`;
export const SpanBase = styled("span")``;
export const LinkBase = styled("span")`
   transition: color 100ms ease-in;
   margin: 0;
   padding: 0;
   cursor: pointer;
`;
export const LabelBase = styled("label")`
   display: block;
`;

export const Title = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title.fontFamily};
   color: ${({ theme }) => theme.title.color};
   font-size: ${({ theme }) => theme.title.fontSize}px;
   font-weight: ${({ theme }) => theme.title.weight};
   line-height: ${({ theme }) => theme.title.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title.letterSpacing}em;
`;

export const Title2 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title2.fontFamily};
   color: ${({ theme }) => theme.title2.color};
   font-size: ${({ theme }) => theme.title2.fontSize}px;
   font-weight: ${({ theme }) => theme.title2.weight};
   line-height: ${({ theme }) => theme.title2.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title2.letterSpacing}em;
`;

export const Title3 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title3.fontFamily};
   color: ${({ theme }) => theme.title3.color};
   font-size: ${({ theme }) => theme.title3.fontSize}px;
   font-weight: ${({ theme }) => theme.title3.weight};
   line-height: ${({ theme }) => theme.title3.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title3.letterSpacing}em;
`;

export const Title4 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title4.fontFamily};
   color: ${({ theme }) => theme.title4.color};
   font-size: ${({ theme }) => theme.title4.fontSize}px;
   font-weight: ${({ theme }) => theme.title4.weight};
   line-height: ${({ theme }) => theme.title4.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title4.letterSpacing}em;
`;

export const Title5 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title5.fontFamily};
   color: ${({ theme }) => theme.title5.color};
   font-size: ${({ theme }) => theme.title5.fontSize}px;
   font-weight: ${({ theme }) => theme.title5.weight};
   line-height: ${({ theme }) => theme.title5.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title5.letterSpacing}em;
`;

export const Title6 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title6.fontFamily};
   color: ${({ theme }) => theme.title6.color};
   font-size: ${({ theme }) => theme.title6.fontSize}px;
   font-weight: ${({ theme }) => theme.title6.weight};
   line-height: ${({ theme }) => theme.title6.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title6.letterSpacing}em;
`;

export const Title7 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title7.fontFamily};
   color: ${({ theme }) => theme.title7.color};
   font-size: ${({ theme }) => theme.title7.fontSize}px;
   font-weight: ${({ theme }) => theme.title7.weight};
   line-height: ${({ theme }) => theme.title7.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title7.letterSpacing}em;
`;

export const Title8 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title8.fontFamily};
   color: ${({ theme }) => theme.title8.color};
   font-size: ${({ theme }) => theme.title8.fontSize}px;
   font-weight: ${({ theme }) => theme.title8.weight};
   line-height: ${({ theme }) => theme.title8.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title8.letterSpacing}em;
`;

export const Title9 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title9.fontFamily};
   color: ${({ theme }) => theme.title9.color};
   font-size: ${({ theme }) => theme.title9.fontSize}px;
   font-weight: ${({ theme }) => theme.title9.weight};
   line-height: ${({ theme }) => theme.title9.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title9.letterSpacing}em;
`;
export const Title10 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title10.fontFamily};
   color: ${({ theme }) => theme.title10.color};
   font-size: ${({ theme }) => theme.title10.fontSize}px;
   font-weight: ${({ theme }) => theme.title10.weight};
   line-height: ${({ theme }) => theme.title10.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title10.letterSpacing}em;
`;
export const Title11 = styled(TitleBase)`
   font-family: ${({ theme }) => theme.title11.fontFamily};
   color: ${({ theme }) => theme.title11.color};
   font-size: ${({ theme }) => theme.title11.fontSize}px;
   font-weight: ${({ theme }) => theme.title11.weight};
   line-height: ${({ theme }) => theme.title11.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.title11.letterSpacing}em;
`;

export const Paragraph = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph.fontFamily};
   color: ${({ theme }) => theme.paragraph.color};
   font-size: ${({ theme }) => theme.paragraph.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph.weight};
   line-height: ${({ theme }) => theme.paragraph.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph.letterSpacing}em;

   span {
      font-weight: ${({ theme }) => theme.paragraph.spanWeight};
   }
`;

export const Paragraph2 = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph2.fontFamily};
   color: ${({ theme }) => theme.paragraph2.color};
   font-size: ${({ theme }) => theme.paragraph2.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph2.weight};
   line-height: ${({ theme }) => theme.paragraph2.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph2.letterSpacing}em;

   span {
      color: ${({ theme }) => theme.paragraph2.spanColor};
   }
`;

export const Paragraph3 = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph3.fontFamily};
   color: ${({ theme }) => theme.paragraph3.color};
   font-size: ${({ theme }) => theme.paragraph3.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph3.weight};
   line-height: ${({ theme }) => theme.paragraph3.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph3.letterSpacing}em;

   span {
      color: ${({ theme }) => theme.paragraph3.spanColor};
   }
`;

export const Paragraph4 = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph4.fontFamily};
   color: ${({ theme }) => theme.paragraph4.color};
   font-size: ${({ theme }) => theme.paragraph4.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph4.weight};
   line-height: ${({ theme }) => theme.paragraph4.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph4.letterSpacing}em;
`;

export const Paragraph5 = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph5.fontFamily};
   color: ${({ theme }) => theme.paragraph5.color};
   font-size: ${({ theme }) => theme.paragraph5.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph5.weight};
   line-height: ${({ theme }) => theme.paragraph5.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph5.letterSpacing}em;
`;

export const Paragraph6 = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph6.fontFamily};
   color: ${({ theme }) => theme.paragraph6.color};
   font-size: ${({ theme }) => theme.paragraph6.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph6.weight};
   line-height: ${({ theme }) => theme.paragraph6.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph6.letterSpacing}em;

   span {
      color: ${({ theme }) => theme.paragraph6.spanColor};
   }
`;

export const Paragraph7 = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph7.fontFamily};
   color: ${({ theme }) => theme.paragraph7.color};
   font-size: ${({ theme }) => theme.paragraph7.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph7.weight};
   line-height: ${({ theme }) => theme.paragraph7.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph7.letterSpacing}em;
`;

export const Paragraph8 = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph8.fontFamily};
   color: ${({ theme }) => theme.paragraph8.color};
   font-size: ${({ theme }) => theme.paragraph8.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph8.weight};
   line-height: ${({ theme }) => theme.paragraph8.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph8.letterSpacing}em;
`;
export const Paragraph9 = styled(ParagraphBase)`
   font-family: ${({ theme }) => theme.paragraph9.fontFamily};
   color: ${({ theme }) => theme.paragraph9.color};
   font-size: ${({ theme }) => theme.paragraph9.fontSize}px;
   font-weight: ${({ theme }) => theme.paragraph9.weight};
   line-height: ${({ theme }) => theme.paragraph9.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.paragraph9.letterSpacing}em;
`;

export const Link = styled(LinkBase)`
   font-family: ${({ theme }) => theme.link.fontFamily};
   color: ${({ theme }) => theme.link.color};
   font-size: ${({ theme }) => theme.link.fontSize}px;
   font-weight: ${({ theme }) => theme.link.weight};
   line-height: ${({ theme }) => theme.link.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.link.letterSpacing}em;

   &:hover {
      color: ${({ theme }) => theme.link.hoverColor};
   }
`;

export const Link2 = styled(LinkBase)`
   font-family: ${({ theme }) => theme.link2.fontFamily};
   color: ${({ theme }) => theme.link2.color};
   font-size: ${({ theme }) => theme.link2.fontSize}px;
   font-weight: ${({ theme }) => theme.link2.weight};
   line-height: ${({ theme }) => theme.link2.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.link2.letterSpacing}em;

   &:hover {
      color: ${({ theme }) => theme.link2.hoverColor};
   }
`;

export const Label = styled(SpanBase)`
   font-family: ${({ theme }) => theme.label.fontFamily};
   color: ${({ theme }) => theme.label.color};
   font-size: ${({ theme }) => theme.label.fontSize}px;
   font-weight: ${({ theme }) => theme.label.weight};
   line-height: ${({ theme }) => theme.label.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label.letterSpacing}em;
`;

export const Label2 = styled(SpanBase)`
   font-family: ${({ theme }) => theme.label2.fontFamily};
   color: ${({ theme }) => theme.label2.color};
   font-size: ${({ theme }) => theme.label2.fontSize}px;
   font-weight: ${({ theme }) => theme.label2.weight};
   line-height: ${({ theme }) => theme.label2.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label2.letterSpacing}em;
`;

export const Label3 = styled(SpanBase)`
   font-family: ${({ theme }) => theme.label3.fontFamily};
   color: ${({ theme }) => theme.label3.color};
   font-size: ${({ theme }) => theme.label3.fontSize}px;
   font-weight: ${({ theme }) => theme.label3.weight};
   line-height: ${({ theme }) => theme.label3.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label3.letterSpacing}em;
`;

export const Label4 = styled(LabelBase)`
   color: ${({ theme }) => theme.label4.color};
   font-family: ${({ theme }) => theme.label4.fontFamily};
   font-size: ${({ theme }) => theme.label4.fontSize}px;
   font-weight: ${({ theme }) => theme.label4.weight};
   line-height: ${({ theme }) => theme.label4.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label4.letterSpacing}em;
`;

export const Label5 = styled(LabelBase)`
   color: ${({ theme }) => theme.label5.color};
   font-family: ${({ theme }) => theme.label5.fontFamily};
   font-size: ${({ theme }) => theme.label5.fontSize}px;
   font-weight: ${({ theme }) => theme.label5.weight};
   line-height: ${({ theme }) => theme.label5.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label5.letterSpacing}em;
`;

export const Label6 = styled(LabelBase)`
   color: ${({ theme }) => theme.label6.color};
   font-family: ${({ theme }) => theme.label6.fontFamily};
   font-size: ${({ theme }) => theme.label6.fontSize}px;
   font-weight: ${({ theme }) => theme.label6.weight};
   line-height: ${({ theme }) => theme.label6.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label6.letterSpacing}em;
`;

export const Label7 = styled(LabelBase)`
   color: ${({ theme }) => theme.label7.color};
   font-family: ${({ theme }) => theme.label7.fontFamily};
   font-size: ${({ theme }) => theme.label7.fontSize}px;
   font-weight: ${({ theme }) => theme.label7.weight};
   line-height: ${({ theme }) => theme.label7.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label7.letterSpacing}em;
`;

export const Label8 = styled(LabelBase)`
   color: ${({ theme }) => theme.label8.color};
   font-family: ${({ theme }) => theme.label8.fontFamily};
   font-size: ${({ theme }) => theme.label8.fontSize}px;
   font-weight: ${({ theme }) => theme.label8.weight};
   line-height: ${({ theme }) => theme.label8.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label8.letterSpacing}em;
`;

export const Label9 = styled(LabelBase)`
   color: ${({ theme }) => theme.label9.color};
   font-family: ${({ theme }) => theme.label9.fontFamily};
   font-size: ${({ theme }) => theme.label9.fontSize}px;
   font-weight: ${({ theme }) => theme.label9.weight};
   line-height: ${({ theme }) => theme.label9.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label9.letterSpacing}em;
`;

export const Label10 = styled(LabelBase)`
   color: ${({ theme }) => theme.label10.color};
   font-family: ${({ theme }) => theme.label10.fontFamily};
   font-size: ${({ theme }) => theme.label10.fontSize}px;
   font-weight: ${({ theme }) => theme.label10.weight};
   line-height: ${({ theme }) => theme.label10.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label10.letterSpacing}em;
`;

export const Label11 = styled(LabelBase)`
   color: ${({ theme }) => theme.label11.color};
   font-family: ${({ theme }) => theme.label11.fontFamily};
   font-size: ${({ theme }) => theme.label11.fontSize}px;
   font-weight: ${({ theme }) => theme.label11.weight};
   line-height: ${({ theme }) => theme.label11.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label11.letterSpacing}em;
`;

export const Label12 = styled(LabelBase)`
   color: ${({ theme }) => theme.label12.color};
   font-family: ${({ theme }) => theme.label12.fontFamily};
   font-size: ${({ theme }) => theme.label12.fontSize}px;
   font-weight: ${({ theme }) => theme.label12.weight};
   line-height: ${({ theme }) => theme.label12.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label12.letterSpacing}em;
`;

export const Label13 = styled(LabelBase)`
   color: ${({ theme }) => theme.label13.color};
   font-family: ${({ theme }) => theme.label13.fontFamily};
   font-size: ${({ theme }) => theme.label13.fontSize}px;
   font-weight: ${({ theme }) => theme.label13.weight};
   line-height: ${({ theme }) => theme.label13.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label13.letterSpacing}em;
`;

export const Label14 = styled(LabelBase)`
   color: ${({ theme }) => theme.label14.color};
   font-family: ${({ theme }) => theme.label14.fontFamily};
   font-size: ${({ theme }) => theme.label14.fontSize}px;
   font-weight: ${({ theme }) => theme.label14.weight};
   line-height: ${({ theme }) => theme.label14.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label14.letterSpacing}em;
`;

export const Label15 = styled(LabelBase)`
   color: ${({ theme }) => theme.label15.color};
   font-family: ${({ theme }) => theme.label15.fontFamily};
   font-size: ${({ theme }) => theme.label15.fontSize}px;
   font-weight: ${({ theme }) => theme.label15.weight};
   line-height: ${({ theme }) => theme.label15.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label15.letterSpacing}em;
`;
export const Label16 = styled(LabelBase)`
   color: ${({ theme }) => theme.label16.color};
   font-family: ${({ theme }) => theme.label16.fontFamily};
   font-size: ${({ theme }) => theme.label16.fontSize}px;
   font-weight: ${({ theme }) => theme.label16.weight};
   line-height: ${({ theme }) => theme.label16.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.label16.letterSpacing}em;
`;
export const LabelError = styled(SpanBase)`
   font-family: ${({ theme }) => theme.labelError.fontFamily};
   color: ${({ theme }) => theme.labelError.color};
   font-size: ${({ theme }) => theme.labelError.fontSize}px;
   font-weight: ${({ theme }) => theme.labelError.weight};
   line-height: ${({ theme }) => theme.labelError.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.labelError.letterSpacing}em;
`;
