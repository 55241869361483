import { ValueType } from "./GroupedButtons.types";

export const optionExistInArray = (option: ValueType, values?: ValueType) => {
   if (!option) {
      return false;
   }

   if (Array.isArray(values)) {
      if (typeof option === "string") {
         return values?.includes(option);
      } else if (typeof option === "number") {
         return !!values[option];
      } else {
         return false;
      }
   } else {
      return values === option;
   }
};

export const toggleOptionInArray = (options: Array<string>, selectedOption: string) => {
   if (Array.isArray(options)) {
      const index = options.indexOf(selectedOption);
      if (index > -1) {
         options.splice(index, 1);
      } else {
         options.push(selectedOption);
      }
      return [...options];
   }
   return options;
};

export const initialArray = (initialValues?: ValueType) =>
   Array.isArray(initialValues) ? initialValues : [];

export const initialString = (initialValues?: ValueType) =>
   initialValues ? initialValues : undefined;

export const initialState = (multiSelect?: boolean, initialValues?: ValueType) =>
   multiSelect ? initialArray(initialValues) : initialString(initialValues);
