/**
 * Determines the z-index values for the application.
 * Never write a z-index without using this enum.
 */
export enum ZIndexValues {
   /**
    * Modals should be on top of everything except other floating elements.
    */
   Modals = 10,

   /**
    * Snackbars are notifications, they should go on top of everything except other similar floating elements that are interactive.
    */
   Snackbars = 150,

   /**
    * Used in Select, ContextMenu, DatePicker, etc. They should be on top of everything because they are interactive and nothing should hide an interactive floating element with temporal visibility.
    */
   FloatingInteractiveElements = 200
}
