import styled from "styled-components";
import Button from "../../../../../kanopy-ui/Button";
import Checkbox from "../../../../../kanopy-ui/Checkbox/Checkbox";

export const ButtonStyled = styled(Button)`
   text-decoration: underline;
   padding-left: 0;
`;

export const CheckboxStyled = styled(Checkbox).withConfig({
   shouldForwardProp: p => !["textColor"].includes(p)
})<{ textColor?: string }>`
   padding-left: 18px;
   padding-right: 32px;
   padding-top: 16px;
   padding-bottom: 16px;
   ${({ textColor }) => (textColor ? `& > * {color: ${textColor};}` : "")};
`;
