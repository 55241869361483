import React, { FC } from "react";
import ReactDOM from "react-dom";
import { useAtom } from "jotai";
import { IconImage } from "../Icon/Icon";
import { Label11 } from "../Texts/Texts";
import {
   CloseButton,
   ModalChildren,
   ModalContainer,
   ModalTitleBar,
   ModalWindow,
   ModalPaddingsContainer
} from "./Modal.styles";
import { floatComponentsOpen } from "../tools/atoms/mainAtom";
import DimmerBackground from "./DimmerBackground/DimmerBackground";

interface PropsModal {
   visible: boolean;
   onClose?: () => void;
   title?: string;
   disableClosing?: boolean;
   disableCloseButton?: boolean;
   className?: string | undefined;
   variant?: ModalVariant;
   renderOnDocumentBody?: boolean;
   /** Default: false. When the modal contains a link tag like <a> and is clicked the modal closes */
   closeOnLinkClick?: boolean;
}

export enum ModalVariant {
   Scrollable,
   AdjustToContent
}

const Modal: FC<PropsModal> = props => {
   const {
      children,
      visible,
      onClose,
      disableClosing,
      disableCloseButton,
      className,
      title,
      variant = ModalVariant.Scrollable,
      renderOnDocumentBody = true,
      closeOnLinkClick
   } = props;

   const [floatCompOpen] = useAtom(floatComponentsOpen);

   if (!visible) {
      return null;
   }

   const handleClose = () => {
      if (!disableClosing) {
         onClose?.();
      }
   };

   const handleDimmerBackgroundClick = () => {
      // If there is a float component open the "click elsewhere" action should be reserved to those components and not close the modal
      if (floatCompOpen.size > 0) {
         return;
      }

      handleClose();
   };

   const onChildrenClick = (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement;
      /**
       * The modal may contain html text using dangerouslySetInnerHTML prop, inside that text
       * there may be a link tag <a>, when that tag is clicked it should close the modal.
       * So here we check the element clicked in the children is an A tag.
       */
      if (closeOnLinkClick && target?.tagName === "A") {
         handleClose();
      }
   };

   const renderModal = () => (
      <ModalContainer>
         <DimmerBackground onClick={handleDimmerBackgroundClick} />
         <ModalPaddingsContainer>
            <ModalWindow className={className ?? undefined} variant={variant}>
               {!disableClosing && !disableCloseButton && (
                  <CloseButton iconImage={IconImage.MdClose} onClick={handleClose} size={28} />
               )}
               {title !== undefined && (
                  <ModalTitleBar>
                     <Label11>{title}</Label11>
                  </ModalTitleBar>
               )}
               <ModalChildren onClick={onChildrenClick}>{children}</ModalChildren>
            </ModalWindow>
         </ModalPaddingsContainer>
      </ModalContainer>
   );

   if (renderOnDocumentBody) {
      return ReactDOM.createPortal(renderModal(), document.body);
   } else {
      return renderModal();
   }
};

export default Modal;
