import styled from "styled-components";
import { HeaderVariant } from "./Header";

export const HeaderContainer = styled("div").withConfig({
   shouldForwardProp: p => !["variant", "applyZIndex", "position"].includes(p)
})<{
   variant?: HeaderVariant;
   applyZIndex?: boolean;
   position?: "static" | "fixed" | "absolute" | "relative" | "sticky";
}>`
   width: 100%;
   ${props => props.applyZIndex && "z-index: 2;"}
   ${props =>
      props.variant === HeaderVariant.RegisteredUser &&
      `
         position: relative;
         box-shadow: ${props.theme.shadows.headerShadow};
         background-color: ${props.theme.colors.header};
         ${props.applyZIndex && "z-index: 2;"}
      `}

   ${({ position }) => (position ? `position: ${position}; ` : "")};
`;

export const CenterContent = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: left;
   min-height: 76px;
`;

export const HeaderLogo = styled("img")`
   height: 55px;
   user-select: none;
   top: 14px;
`;

export const RightSectionContainer = styled("div")`
   display: flex;
   position: absolute;
   right: 0;
   padding-right: 140px;
`;
