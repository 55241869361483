export enum ButtonVariant {
   Filled = "Filled",
   Outlined = "Outlined",
   InvertedColors = "InvertedColors",
   TextOnly = "TextOnly"
}

export type ButtonVariantType = keyof typeof ButtonVariant;

export enum ButtonSize {
   Large = "Large",
   Medium = "Medium",
   Small = "Small"
}

export type ButtonSizeType = keyof typeof ButtonSize;

export interface PropsButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   variant?: ButtonVariant;
   fullWidth?: boolean;
   loading?: boolean;
   size?: ButtonSize;
}
