import styled from "styled-components";
import { Paragraph4 } from "../../../../kanopy-ui/Texts/Texts";

export const ReviewFileContainer = styled("div").withConfig({
   shouldForwardProp: p => !["isLastElement"].includes(p)
})<{ isLastElement?: boolean }>`
   display: flex;
   flex-direction: row;
   align-items: center;
   position: relative;
   height: 83px;
   width: 100%;
   margin: 0;
   padding-top: 20px;
   padding-bottom: 16px;
   padding-left: 26px;
   padding-right: 27px;
   height: fit-content;

   span {
      line-height: unset;
   }

   margin-top: ${props => (props.isLastElement ? "5px" : "unset")};
   border-bottom: ${props => (!props.isLastElement ? props.theme.borders.border4 : "unset")};
`;

export const FilePartContainer = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   width: 100%;
   cursor: pointer;
   user-select: none;
`;

export const FileDescription = styled("div")`
   display: flex;
   flex-direction: column;
   margin-left: 13.3px;
`;

export const UploadBoxLabel = styled(Paragraph4)`
   margin-top: 6px;
`;

export const TypeIcon = styled("img")`
   height: 27px;
   margin: 0;
   margin-top: 5px;
`;

export const FileUploadedThumbnail = styled("img")`
   height: 70px;
   border-radius: ${({ theme }) => theme.fileUploadBox.borderRadiusThumbnail};
`;
