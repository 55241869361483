import styled from "styled-components";

export const DividerContainer = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   column-gap: 20px;

   font-family: ${({ theme }) => theme.divider.text.fontFamily};
   color: ${({ theme }) => theme.divider.text.color};
   font-size: ${({ theme }) => theme.divider.text.fontSize}px;
   font-weight: ${({ theme }) => theme.divider.text.weight};
   line-height: ${({ theme }) => theme.divider.text.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.divider.text.letterSpacing}em;

   margin: 22px 0;
`;

export const HalfLine = styled("div")`
   flex-grow: 1;
   height: 1.53px;
   background-color: ${({ theme }) => theme.divider.line.primary};
`;

export const Hr = styled("hr")`
   width: 100%;
   border-bottom: ${({ theme }) => `1px solid ${theme.divider.line.main}`};
   border-top: 0;
`;
