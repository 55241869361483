import styled from "styled-components";
import { BadgePosition } from "./Badge.types";
import { Label } from "../Texts/Texts";

export const BadgeContainer = styled("div")`
   position: relative;
`;

export const BadgeCircle = styled("div").withConfig({
   shouldForwardProp: p => !["offsetX", "offsetY", "position", "size"].includes(p)
})<{ offsetX: number; offsetY: number; position: BadgePosition; size: number }>`
   border-radius: 50%;
   background-color: ${props => props.theme.colors.importantColor};
   height: ${({ size }) => size}px;
   width: ${({ size }) => size}px;
   position: absolute;
   right: ${({ offsetX }) => offsetX}px;
   top: ${({ offsetY }) => offsetY}px;
   transform: translate(50%, -50%);
   z-index: 1;
   pointer-events: none;
   display: flex;
   align-items: center;
   justify-content: center;
`;

export const BadgeLabel = styled(Label).withConfig({
   shouldForwardProp: p => !["fontSize"].includes(p)
})<{ fontSize: number }>`
   color: ${({ theme }) => theme.colors.white};
   font-size: ${({ fontSize }) => fontSize}em;
`;
