import styled from "styled-components";
import { Paragraph } from "../../../kanopy-ui/Texts/Texts";

export const ParagraphStyled = styled(Paragraph)`
   text-align: center;

   a {
      font-weight: 400;
   }
`;
