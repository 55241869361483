import styled from "styled-components";
import { Label, Label7 } from "../Texts/Texts";

export const BannerContainer = styled("div").withConfig({
   shouldForwardProp: p => !["center"].includes(p)
})<{
   center?: boolean;
}>`
   background-color: ${props => props.theme.banner.backgroundColor};
   color: ${props => props.theme.banner.color};
   box-sizing: border-box;
   position: relative;
   align-items: center;
   display: flex;
   column-gap: 6px;
   padding: ${({ theme }) => theme.spacing(1.5, "px")} ${({ theme }) => theme.spacing(3.5, "px")};
   z-index: 1;

   ${({ center }) => center && "justify-content: center;"};
`;

export const IconContainer = styled("div")`
   color: ${props => props.theme.banner.color};
   position: relative;
`;

export const CloseButton = styled("button").withConfig({
   shouldForwardProp: p => !["center"].includes(p)
})<{ center?: boolean; noPadding?: boolean; position?: string }>`
   border: none;
   background-color: transparent;
   cursor: pointer;
   display: flex;
   align-items: center;
   padding-top: 0;
   padding-bottom: 0;

   ${({ center }) => !center && "position: absolute;"};
   ${({ center, theme }) => !center && `right: ${theme.spacing(2, "px")}`};
`;

export const BannerText = styled(Label)`
   color: ${props => props.theme.banner.color};
   align-items: center;
   position: relative;
   overflow: hidden;
`;

export const BannerBoldText = styled(Label7)`
   color: ${props => props.theme.banner.color};
   overflow: hidden;
   position: relative;
`;

export const BackgroundIllustration = styled("img")``;
