import styled from "styled-components";
import Icon from "../Icon/Icon";

export const InputContainer = styled("div")`
   display: flex;
   flex-direction: column;
   position: relative;
   row-gap: 3px;
   width: 100%;
`;

export const TextAreaContainer = styled("div").withConfig({
   shouldForwardProp: p => !["error"].includes(p)
})<{ error?: string | undefined }>`
   display: flex;
   flex-direction: row;
   align-items: center;
   transition: border 500ms;
   border: ${props =>
      props.error === undefined ? props.theme.input.border : props.theme.input.borderError};
   border-radius: ${({ theme }) => theme.input.borderRadius};
   background-color: ${({ theme }) => theme.input.backgroundColor};
   z-index: 2;
`;

export const TextAreaStyled = styled("textarea").withConfig({
   shouldForwardProp: p => !["error"].includes(p)
})<{ error?: string | undefined }>`
   width: 100%;
   min-height: 103px;
   padding: 17px 10px 17px 22px;
   flex-grow: 1;

   font-family: ${({ theme }) => theme.input.text.fontFamily};
   color: ${({ theme }) => theme.input.text.color};
   font-size: ${({ theme }) => theme.input.text.fontSize}px;
   font-weight: ${({ theme }) => theme.input.text.weight};
   line-height: ${({ theme }) => theme.input.text.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.input.text.letterSpacing}em;
   border: unset;
   border-radius: unset;
   background-color: unset;
`;

export const ErrorText = styled("div")`
   color: red;
   font-size: 14px;
   position: absolute;
   bottom: -23px;
   z-index: 1;
   animation: error-appear-animation 200ms ease-out;
   animation-play-state: running;

   @keyframes error-appear-animation {
      from {
         transform: translateY(-25px);
      }
      to {
         transform: translateY(0px);
      }
   }
`;

export const ErrorIcon = styled(Icon)`
   transition: all 350ms;
   object-position: 0% 0%;

   svg {
      width: 18px;
      height: 18px;
   }

   color: ${({ theme }) => theme.input.iconColorError};

   animation: icon-appear-animation 300ms ease-out;
   animation-play-state: running;
   @keyframes icon-appear-animation {
      from {
         transform: scale(0);
      }
      to {
         transform: scaly(1);
      }
   }
`;
