import styled from "styled-components";
import { Title } from "../../../kanopy-ui/Texts/Texts";
import { Paragraph } from "../../../kanopy-ui/Texts/Texts";
import { ColumnCentered } from "../../../kanopy-ui/Column/Column";

export const ProfileSetupContainer = styled(ColumnCentered)`
   @media only screen and (min-width: 780px) {
      min-width: 780px;
   }

   @media only screen and (min-width: 950px) {
      min-width: 950px;
   }
`;

export const StartStepPageContainer = styled("div")`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   max-width: 948px;

   [class*="CardsContainer"] {
      width: 100%;
      justify-content: center;
   }
`;

export const StartTitle = styled(Title)`
   margin-top: 20px;
   text-align: center;
   font-size: 38px;
   font-weight: 800;
   margin-bottom: 16px;
   width: 100%;
`;
export const StartSubTitle = styled(Title)`
   margin-top: 30px;
   text-align: center;
   font-size: 25px;
   font-weight: 800;
   margin-bottom: 10px;
   width: 100%;
`;

export const StartStepParagraph = styled(Paragraph)`
   margin-bottom: 20px;
   text-align: center;
   width: 100%;
   max-width: 588px;
`;
