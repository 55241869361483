import React, { FC } from "react";
import Masonry, { MasonryProps, ResponsiveMasonry } from "react-responsive-masonry";

interface PropsMasonryGrid extends MasonryProps {
   /**
    * A Object containing Keys as breakpoints in px and values as the columns count
    *
    * Default Value = { 350: 1, 750: 2, 900: 3 }
    */
   columnsCountBreakPoints?: {
      [key: number]: number;
   };
}

/**
 * Masonry is a type of grid where each cell can have it's unique height to avoid ugly empty spaces.
 * Currently browsers didn't implement the css specification "grid-template-rows: masonry" of the css
 * grid, so a library is useful.
 * This component is currently a wrapper of react-responsive-masonry, it has responsive features.
 */
const MasonryGrid: FC<PropsMasonryGrid> = props => {
   const { children, columnsCountBreakPoints, ...restOfProps } = props;
   if (children) {
      if (columnsCountBreakPoints) {
         return (
            <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
               <Masonry {...restOfProps}>{children!}</Masonry>
            </ResponsiveMasonry>
         );
      } else {
         return <Masonry {...restOfProps}>{children!}</Masonry>;
      }
   } else {
      return null;
   }
};

export default MasonryGrid;
