import { useEffect, useState } from "react";

// const getCookies = (): Record<string, string> =>
//    Object.fromEntries(
//       document.cookie.split(";").map(it => {
//          const [key, value] = it.split("=");
//          return [key.trim(), value];
//       })
//    );
//
// const getCookie = (cookie: string) => getCookies()[cookie];
const getCookie = (cookie: string) : string => localStorage.getItem(cookie) ?? '';
const cookieExist = (cookie: string) => getCookie(cookie) !== '';

/**
 * Checks for cookie existence and re-renders the component when that changes.
 */
export const useCookieExistsWatcher = (cookie: string, pollingRate = 150) => {
   const [exist, setExist] = useState(cookieExist(cookie));

   useEffect(() => {
      const interval = setInterval(() => setExist(cookieExist(cookie)), pollingRate);
      return () => clearInterval(interval);
   });

   return exist;
};

/**
 * Checks for cookie changes and re-renders the component on change.
 * If you don't need to watch for changes use useCookie hook instead.
 */
export const useCookieWatcher = (cookie: string, pollingRate = 250) => {
   const [cookieData, setCookieData] = useState<string>(getCookie(cookie));

   useEffect(() => {
      const interval = setInterval(() => {
         const newCookieData = getCookie(cookie);
         if (newCookieData !== cookieData) {
            setCookieData(newCookieData);
         }
      }, pollingRate);
      return () => clearInterval(interval);
   });

   return cookieData;
};

/**
 * Gets the cookie. If you need to watch and re-render on cookie change use useCookieWatcher ir useCookieExistsWatcher instead.
 */
export const useCookie = (cookie: string) => getCookie(cookie);
