import { createContext, FC, useState } from "react";

interface TabsProvider {
   id: string;
   initialTab?: number;
}

interface TabsContext {
   selectedTab: number;
   setSelectedTab: (number: number) => void;
   id?: string;
}

export const TabContext = createContext<TabsContext>({
   selectedTab: 0,
   setSelectedTab: () => {}
});

const TabProvider: FC<TabsProvider> = ({ id, children, initialTab }) => {
   const [selectedTab, setSelectedTab] = useState(initialTab || 0);

   return (
      <TabContext.Provider
         value={{
            id,
            selectedTab,
            setSelectedTab: (selectedTab: number) => setSelectedTab(selectedTab)
         }}
      >
         {children}
      </TabContext.Provider>
   );
};

export default TabProvider;
