import styled from "styled-components";
import { CardVariant } from "./Card.types";

export const Card = styled("div").withConfig({
   shouldForwardProp: p => !["variant"].includes(p)
})<{ variant?: CardVariant }>`
   display: flex;
   flex-direction: column;
   width: 100%;
   padding: 0;
   background-color: ${({ theme }) => theme.card.backgroundColor};
   border-radius: ${({ theme }) => theme.card.borderRadius};
   box-shadow: ${({ theme }) => theme.card.shadow};

   ${props =>
      props.variant === CardVariant.StrongShadow
         ? `
      box-shadow: ${props.theme.card.shadowVariant2};
      padding-top: 41px;
      padding-left: 30px;
      padding-right: 24px;
      padding-bottom: 41px;
   `
         : ""}

   ${props =>
      props.variant === CardVariant.MediumShadow
         ? `
      box-shadow: ${props.theme.card.shadowVariant3};
   `
         : ""}

   ${props =>
      props.variant === CardVariant.DashboardCard
         ? `
         padding: 26px;
   `
         : ""}
`;

export default Card;
