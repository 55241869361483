import { Action } from "../../../../tools/api/forms/useServerJsonForm";
import { FormTools } from "./useFormTools";

/**
 * Form "actions" are json parts that describe frontend functions to be executed in a form.
 * This function is the one doing that job, mapping the json actions to the functions of FormTools
 * and executing them.
 */
export function executeFormAction(action: Action | undefined, formTools: FormTools) {
   if (action === undefined) {
      return;
   }

   if (action.type === "logout") {
      formTools.logout();
   }

   if (action.type === "redirect") {
      const param = action.params![0];
      formTools.navigate(`/${param.redirectUrl!}`);
   }

   if (action.type === "openWindow") {
      const param = action.params![0];
      formTools.cancelForm?.(); // This will close the form when is a modal form.
      formTools.openWindow(param);
   }

   if (action.type === "openDialog") {
      const param = action.params![0];
      /*
         In this line closeForm() is executed because according to the graphic designer 2 modals
         should not be open at the same time, when closeForm function is not undefined it means
         the form is in a modal and should be closed before opening the dialog.
      */
      formTools.cancelForm?.();
      formTools.openDialog({
         ...param,
         buttons: param.buttons?.map(button => ({
            ...button,
            onClick: () => executeFormAction(button.actions?.[0], formTools)
         }))
      });
   }
}
