import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { GoalActionButtons, GoalCardProps } from "../../../components/common/GoalCard";
import { httpRequest } from "../tools/httpRequest";
import { RequestError } from "../tools/typings";
import { MessageContentJson } from "../dashboard/financial-health/useDashboardFinancialHealth";

export interface GoalsApiResponse {
   title: string;
   paragraph: string;
   actionButtons: GoalActionButtons[];
   pages: Array<{
      tabTitle: string;
      tabType: number;
      cards: [GoalCardProps];
   }>;
   page: MessageContentJson;
}

export function useGoalsApi<Error extends RequestError, Response extends GoalsApiResponse>(props?: {
   pageNo: number;
   options?: UseQueryOptions<void, Error, Response>;
}) {
   // https://api-dev.kanopy-test.us/api/v6/forms/profile-setup/step-page/1
   const url = `forms/dashboard/goals`;

   return useQuery<void, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}
