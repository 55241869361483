import React, { FC } from "react";
import Button, { ButtonSize, ButtonVariant } from "../../kanopy-ui/Button";
import { CardVariant } from "../../kanopy-ui/Card";
import {
   CardActions,
   CardIllustration,
   CardLayout,
   CardHeader,
   CardBody,
   CardStatus,
   Title,
   CardsParagraph,
   StatusText,
   Divider,
   DisplayValue,
   Label,
   CardRow,
   CardCol,
   CardTitle,
   CardDescriptionTitle,
   ActionButtonsColRight
} from "./GoalCard.styles";
import { GoalCardProps, GoalStatus } from "./GoalCard.types";
import NotStartedSVG from "../../../assets/goals-not-started.svg";
import Icon, { IconImage } from "../../kanopy-ui/Icon/Icon";
import { readableDateFromUnixDate } from "../../../tools/dates/readableDateFromUnixDate";

/**
 * Notes:
 * CardType is not necessary
 */
const GoalCard: FC<GoalCardProps> = ({
   status,
   title,
   // @TODO change paragraph to subtitle
   paragraph,
   description,
   actionButtons,
   statusType,
   number,
   target,
   started,
   timeLeft,
   onClick
}) => {
   const getIcon = () => {
      switch (statusType) {
         case GoalStatus.Activated:
            return <Icon iconImage={IconImage.CheckedPlusIcon} />;
         case GoalStatus.Done:
            return <Icon iconImage={IconImage.CheckDoubleIcon} />;
         case GoalStatus.NotStarted:
            return <Icon iconImage={IconImage.PauseCircleIcon} />;
         case GoalStatus.PastDue:
            return <Icon iconImage={IconImage.AlarmOffIcon} />;
         default:
            return undefined;
      }
   };

   return (
      <CardLayout variant={CardVariant.MediumShadow}>
         <CardHeader>
            <CardStatus>
               {status && (
                  <>
                     {getIcon()}
                     <StatusText statusType={statusType}>{status}</StatusText>
                  </>
               )}
            </CardStatus>
            <CardTitle>
               {title && <Title>{title}</Title>}
               {paragraph && <CardsParagraph>{paragraph}</CardsParagraph>}
            </CardTitle>
         </CardHeader>
         <CardBody>
            {statusType === GoalStatus.NotStarted ? (
               <>
                  <Divider />
                  <CardIllustration>
                     <img src={NotStartedSVG} alt={`${title}`} />
                  </CardIllustration>
               </>
            ) : (
               <>
                  {number && target && (
                     <>
                        <Divider />
                        <CardRow>
                           <CardCol>
                              {number && (
                                 <DisplayValue statusType={statusType}>
                                    <Label>{number.label}</Label>
                                    <span>{number.value}</span>
                                 </DisplayValue>
                              )}
                              {target && (
                                 <DisplayValue statusType={statusType}>
                                    <Label>{target.label}</Label>
                                    <span>
                                       {readableDateFromUnixDate(parseInt(target.value)) ?? ""}
                                    </span>
                                 </DisplayValue>
                              )}
                           </CardCol>
                        </CardRow>
                     </>
                  )}
                  {started && timeLeft && (
                     <>
                        <Divider />
                        <CardRow>
                           <CardCol>
                              {started && (
                                 <div>
                                    <Label>{started.label}</Label>
                                    {readableDateFromUnixDate(parseInt(started.value)) ?? ""}
                                 </div>
                              )}
                              {timeLeft && (
                                 <div>
                                    <Label>{timeLeft.label}</Label>
                                    {timeLeft.value}
                                 </div>
                              )}
                           </CardCol>
                        </CardRow>
                     </>
                  )}
                  {description && (
                     <>
                        <Divider />
                        <CardRow>
                           {/* @TODO: create description translation on the service */}
                           <CardDescriptionTitle>Description</CardDescriptionTitle>
                           <CardsParagraph>{description}</CardsParagraph>
                        </CardRow>
                     </>
                  )}
               </>
            )}
         </CardBody>
         <div>
            <Divider />
            <CardActions>
               <div>
                  {actionButtons
                     .filter(a => a.label === "Delete")
                     ?.map(({ label, next, type }) => {
                        return (
                           <Button
                              key={label}
                              onClick={() => onClick?.(next)}
                              variant={type as ButtonVariant}
                              size={ButtonSize.Medium}
                           >
                              <Icon iconImage={IconImage.TrashIcon} />
                           </Button>
                        );
                     })}
               </div>
               <ActionButtonsColRight>
                  {actionButtons
                     .filter(a => a.label !== "Delete")
                     ?.map(({ label, next, type }) => {
                        return (
                           <Button
                              key={label}
                              onClick={() => onClick?.(next)}
                              variant={type as ButtonVariant}
                              size={ButtonSize.Medium}
                           >
                              {label}
                           </Button>
                        );
                     })}
               </ActionButtonsColRight>
            </CardActions>
         </div>
      </CardLayout>
   );
};

export default GoalCard;
