import React, { FC, useState } from "react";
import {
   MessageImage,
   MessagePageContainer,
   MessageTitle,
   MessageParagraph,
   MessageButton
} from "./MessageContent.styles";
import { ColumnCentered } from "../../../../kanopy-ui/Column/Column";
import { MessageContentJson } from "../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import FinicityConnectModal from "../../../../common/FinicityConnectModal/FinicityConnectModal";
import FormFromJsonAsModal from "../../../../common/FormFromJsonAsModal/FormFromJsonAsModal";
import { useQueryClient } from "@tanstack/react-query";

interface PropsMessageContent {
   serverData: MessageContentJson;
}

const MessageContent: FC<PropsMessageContent> = props => {
   const { title, paragraph, titleImage, type, button } = props.serverData;
   const [finicityConnectModalOpen, setFinicityConnectModalOpen] = useState(false);
   const [modalFormUrl, setModalFormUrl] = useState<string>();
   const [modalFormOpen, setModalFormOpen] = useState(false);
   const queryClient = useQueryClient();

   const handleOnClick = (next?: string) => {
      if (type === "Finicity") {
         setFinicityConnectModalOpen(true);
      } else {
         setModalFormUrl(next);
         setModalFormOpen(true);
      }
   };

   const onFinicityConnectModalClose = () => {
      // After Finicity Connect modal closes there may be backend changes, so cache needs update
      queryClient.refetchQueries();
      setFinicityConnectModalOpen(false);
   };

   const handleFormModalClose = () => {
      // After Finicity Connect modal closes there may be backend changes, so cache needs update
      queryClient.refetchQueries();
      setModalFormOpen(false);
   };

   return (
      <>
         <MessagePageContainer>
            <ColumnCentered>
               <MessageImage src={titleImage} />
            </ColumnCentered>
            <ColumnCentered>
               <MessageTitle>{title}</MessageTitle>
            </ColumnCentered>
            <ColumnCentered>
               <MessageParagraph>{paragraph}</MessageParagraph>
            </ColumnCentered>
            {button && (
               <MessageButton onClick={() => handleOnClick(button?.next)}>
                  {button?.label}
               </MessageButton>
            )}
         </MessagePageContainer>
         {type === "Finicity" && (
            <FinicityConnectModal
               visible={finicityConnectModalOpen}
               onFinish={onFinicityConnectModalClose}
            />
         )}
         {type !== "Finicity" && (
            <FormFromJsonAsModal
               modalVisible={modalFormOpen}
               onModalClose={handleFormModalClose}
               jsonUrl={modalFormUrl}
               onFormModified={handleFormModalClose}
            />
         )}
      </>
   );
};

export default MessageContent;
