import React, { FC, RefObject } from "react";
import { LayoutType } from "../../../../tools/api/forms/useServerJsonForm";
import { IconImage } from "../../../kanopy-ui/Icon/Icon";
import FixedBottom from "./FixedBottom/FixedBottom";
import {
   ColumnCenteredSmallContainer,
   ColumnCenteredSmallContent,
   EditPanelContainer,
   EditPanelOwnersChildren,
   EditPanelOwnersContainer,
   EditPanelOwnersIcon,
   EditPanelOwnersLeftDecoration,
   FormGroupContainerStyles,
   ColumnMyAccountContainer,
   RowCenteredSmallContent
} from "./FormGroupContainer.styles";
import { FormFromJsonVariant } from "../tools/typings";

export interface PropsFormGroupContainer {
   layoutType: LayoutType;
   hidden?: boolean;
   bottomContainerRef: RefObject<HTMLDivElement> | undefined;
   isLastElement?: boolean;
   space?: boolean;
   variant: FormFromJsonVariant;
}

const FormGroupContainer: FC<PropsFormGroupContainer> = props => {
   const { children, layoutType, hidden, bottomContainerRef, isLastElement, variant, space } =
      props;

   if (layoutType === "edit-panel") {
      return (
         <EditPanelContainer isLastElement={isLastElement} space={space}>
            {children}
         </EditPanelContainer>
      );
   }

   if (layoutType === "edit-panel-owner") {
      return (
         <EditPanelOwnersContainer isLastElement={isLastElement}>
            <EditPanelOwnersLeftDecoration />
            <EditPanelOwnersIcon iconImage={IconImage.MdAccountCircle} />
            <EditPanelOwnersChildren>{children}</EditPanelOwnersChildren>
         </EditPanelOwnersContainer>
      );
   }

   if (layoutType === "fixed-bottom") {
      return (
         <FixedBottom bottomContainerRef={bottomContainerRef} variant={variant}>
            {children}
         </FixedBottom>
      );
   }
   if (layoutType === "columns") {
      return <ColumnMyAccountContainer>{children}</ColumnMyAccountContainer>;
   }

   if (layoutType === "column-centered-small" || layoutType === "column-small-buttons") {
      return (
         <ColumnCenteredSmallContainer layoutType={layoutType}>
            <ColumnCenteredSmallContent>{children}</ColumnCenteredSmallContent>
         </ColumnCenteredSmallContainer>
      );
   }

   if (layoutType === "row-small-buttons") {
      return (
         <ColumnCenteredSmallContainer layoutType={layoutType}>
            <RowCenteredSmallContent>{children}</RowCenteredSmallContent>
         </ColumnCenteredSmallContainer>
      );
   }

   return (
      <FormGroupContainerStyles layoutType={layoutType} hidden={hidden}>
         {children}
      </FormGroupContainerStyles>
   );
};

export default FormGroupContainer;
