import { createGlobalStyle, DefaultTheme, GlobalStyleComponent } from "styled-components";

export const KanopyUiGlobalStyles: GlobalStyleComponent<{}, DefaultTheme> = createGlobalStyle`
   html, body {
      font-family: ${props => props.theme.fonts.font1};
      color: ${props => props.theme.colors.text1};
   }
   
   a:hover, a:visited, a:link, a:active {
      text-decoration: none;   /* Removes underline in link texts */ 
      border:          none;   /* Removes border in linked items */
      outline:         none;   /* Removes points border in images */
   }
   
   *:focus {
      outline: none;           /* Removes the selection outline from inputs and buttons */
   }
   
   *, :after, :before {
      box-sizing: border-box;  /* Do not include border and margin in the sizes numbers */
   }
   
   a {
      transition: color 100ms ease-in;
      margin: 0;
      padding: 0;
      font-family: ${({ theme }) => theme.link.fontFamily};
      color: ${({ theme }) => theme.link.color};
      font-size: ${({ theme }) => theme.link.fontSize}px;
      font-weight: ${({ theme }) => theme.link.weight};
      line-height: ${({ theme }) => theme.link.lineHeight}px;
      letter-spacing: ${({ theme }) => theme.link.letterSpacing}em;
      cursor: pointer;

      &:hover {
         color: ${({ theme }) => theme.link.hoverColor};
      }
   }
`;
