import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpRequest } from "../../tools/httpRequest";
import { IconData, RequestError } from "../../tools/typings";

export function useSidePanel<
   Error extends RequestError,
   Response extends SidePanelResponse
>(props?: { options?: UseQueryOptions<void, Error, Response> }) {
   const url = "forms/dashboard/side-panels";

   return useQuery<void, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}

export interface SidePanelResponse {
   contactAdvisorButton?: {
      label: string;
      mailTo: string;
      name: string;
   };
   sidePanelButtons: SidePanelButtons;
}

export type SidePanelButtons = Record<string, SidePanelButton[]>;

export interface SidePanelButton {
   name: string;
   link: string;
   icon: IconData;
   type: string;
}
