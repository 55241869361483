import React, { useState } from "react";
import Input from "../Input/Input";

export interface PropsAutoFormatInput extends React.ComponentProps<typeof Input> {
   defaultValue?: InputValue;
   formatFunc: (userTyped: InputValue) => { value: InputValue; carrotPos?: number };
}
export type InputValue = string | readonly string[] | number | undefined;

/**
 * This input wrapper allows to modify the content of the input after the user types to
 * automatically format the content. Use formatFunc prop for that.
 * In your FormatFunc if you add characters to the input content remember to remove all previos
 * ones before adding to avoid infinite loop.
 * See CurrencyInput component for an usage example.
 */
const AutoFormatInput = React.forwardRef<HTMLInputElement, PropsAutoFormatInput>((props, ref) => {
   const { defaultValue, formatFunc, ...restOfProps } = props;
   const [value, setValue] = useState<InputValue>(defaultValue);

   const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const carrotPos = formatFunc(e.target.value).carrotPos;
      if (carrotPos !== undefined) {
         // A timeout is used here because the carrot is also set by browser on change and there is race condition
         setTimeout(() => {
            e.target.selectionStart = carrotPos;
            e.target.selectionEnd = carrotPos;
         }, 16);
      }
      setValue(e.target.value);
      props.onChange?.(e);
   };

   return (
      <Input
         {...restOfProps}
         ref={ref}
         value={formatFunc(props.value ?? value).value}
         onChange={handleOnChange}
      />
   );
});

export default AutoFormatInput;
