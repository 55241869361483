import styled from "styled-components";
import Card from "../../../../kanopy-ui/Card";
import Icon from "../../../../kanopy-ui/Icon/Icon";
import { Row } from "../../../../kanopy-ui/Row/Row";

export enum DataGridIconColor {
  Gray,
  Green,
   Red,
  Gold
}

export const DataGridIconContainer = styled(Row)`
   display: flex;
   align-items: center;
   height: 100%;
`;

export const IconStyled = styled(Icon).withConfig({
   shouldForwardProp: p => !["iconColor"].includes(p)
})<{ iconColor: DataGridIconColor }>`
   color: ${props =>
      props.iconColor === DataGridIconColor.Green
         ? props.theme.colors.primary2
         : props.iconColor === DataGridIconColor.Red
           ? props.theme.colors.importantColor
           : props.iconColor === DataGridIconColor.Gold
              ? props.theme.colors.gold
              : props.iconColor === DataGridIconColor.Gray
                ? props.theme.colors.gray4
                : "black"};
`;

export const ContainerCard = styled(Card).withConfig({
   shouldForwardProp: p => !["height"].includes(p)
})<{ height?: number | string }>`
   width: 100%;
   padding: 8px;
   background-color: #f8f8f8; /** This color should be the same of the header bar of the data grid */
   height: ${props => (!props.height ? "fit-content" : props.height)};
`;
