import { useSendJsonForm } from "../../../../tools/api/forms/useSendJsonForm";
import { FormJson } from "../../../../tools/api/forms/useServerJsonForm";
import { JsonFormData, PropsFormFromJson } from "./typings";
import { useRedirect } from "../../../../tools/browser/useRedirect";

/**
 * Skipping a form means we don't make a form change and get redirected to the next form.
 */
export function useSkipForm(props: {
   formJson?: FormJson;
   formProps?: PropsFormFromJson;
}): SkipFormTools {
   const { formJson, formProps } = props;
   const skipFormMutation = useSendJsonForm();
   const { redirect } = useRedirect();

   const skipForm = async (skipProps?: { callUrl?: string; redirectUrl?: string }) => {
      let response: JsonFormData | undefined = undefined;

      // The json may request to make a call when the user skips the form
      if (skipProps?.callUrl !== undefined) {
         response = await skipFormMutation.mutateAsync({
            url: skipProps.callUrl
         });
      }

      const url = (response?.next || skipProps?.redirectUrl || formJson?.next) as string;
      const withoutHistory = formProps?.redirectWithoutHistory;
      if (url) {
         redirect({ url, withoutHistory });
      } else {
         console.error("The server didn't return a route to go after this form");
      }
   };

   return { skipForm, isLoading: skipFormMutation.isLoading };
}

/**
 * Skipping a form means we don't make a form change and get redirected to the next form.
 * This contains the function to do that.
 */
export interface SkipFormTools {
   skipForm: SkipFormFn;
   isLoading: boolean;
}

export type SkipFormFn = (skipProps?: { callUrl?: string; redirectUrl?: string }) => Promise<void>;
