import React, { FC } from "react";
import { TitleStyled } from "./TitleFromJson.styles";
import { FormComponentProps } from "../tools/typings";

const TitleFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { textToNotRender } = formTools;

   if (formElementJson.label === textToNotRender) {
      return null;
   }

   return <TitleStyled>{formElementJson.label}</TitleStyled>;
};

export default TitleFromJson;
