import { useRef } from "react";
import { useAtom } from "jotai";
import { GlobalSnackbar, atomGlobalSnackbars } from "../../../../tools/atoms/atomGlobalSnackbars";

export function useSnackbar() {
   const [snackbars, setSnackbars] = useAtom(atomGlobalSnackbars);
   const lastSnackbarId = useRef<string>();

   const openSnackbar = (snackbarProps: GlobalSnackbar) => {
      const { durationMs = 4000 } = snackbarProps;

      const snackbarId = String(Math.random());
      lastSnackbarId.current = snackbarId;

      setSnackbars([...snackbars, { ...snackbarProps, snackbarId }]);

      if (durationMs > 0) {
         setTimeout(() => {
            closeSnackbar(snackbarId);
         }, durationMs);
      }

      return snackbarId;
   };

   const closeSnackbar = (snackbarId: string) => {
      setSnackbars(dialogs =>
         dialogs.filter(snackbar => snackbar.snackbarId !== (snackbarId ?? lastSnackbarId.current))
      );
   };

   return { openSnackbar, closeSnackbar };
}

export interface UseSnackbar {
   /** Opens a snackbar, If called multiple times multiple snackbars are added. It returns the snackbarId to be used to close it "manually" with the closeSnackbar function. But there is auto close feature. */
   openSnackbar: (snackbarProps: GlobalSnackbar) => string;
   /** @param snackbarId The snackbarId returned by openSnackbar function. If it's not set it will close the last one open by this hook instance */
   closeSnackbar: (snackbarId: string) => void;
}
