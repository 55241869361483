import { FC } from "react";
import {
   BannerText,
   CloseButton,
   BannerBoldText,
   IconContainer,
   BannerContainer,
   BackgroundIllustration
} from "./Banner.styles";
import Icon, { IconImage } from "../Icon/Icon";
import close from "../../../assets/close.svg";

export interface BannerProps {
   isShown?: boolean;
   message?: string | React.ReactNode;
   boldText?: string | React.ReactNode;
   onClose?: () => void;
   center?: boolean;
   style?: React.CSSProperties;
}

export const Banner: FC<BannerProps> = props => {
   const { isShown, message, onClose, boldText, style, center } = props;

   if (!isShown) {
      return null;
   }

   return (
      <BannerContainer center={center} style={style}>
         <IconContainer>
            <Icon iconImage={IconImage.MdSave} size={20} />
         </IconContainer>
         <BannerText>{message}</BannerText>
         <BannerBoldText> {boldText}</BannerBoldText>
         <CloseButton center={center} onClick={onClose}>
            <BackgroundIllustration src={close} />
         </CloseButton>
      </BannerContainer>
   );
};

export default Banner;
