import styled from "styled-components";

export const ChildrenContainer = styled("div")`
   display: grid;
   grid-template-columns: auto 1fr;
   grid-template-rows: 1fr;
   width: 100%;
   height: 100%;
   grid-column-gap: ${({ theme }) => theme.spacing(3, "px")};
   padding-right: ${({ theme }) => theme.spacing(3, "px")};
`;

export const DrawerContainer = styled("div")`
   min-height: 100vh;
   z-index: 1;
   background-color: ${({ theme }) => theme.drawer.backgroundColor};
   box-shadow: ${({ theme }) => theme.drawer.shadow};
   max-width: ${({ theme }) => theme.spacing(9, "px")};
   overflow: hidden;

   @media only screen and (min-width: 600px) {
      min-width: 256px;
      max-width: "initial";
   }
`;
