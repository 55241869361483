import React, { FC } from "react";
import { TitleImage, TitleImageContainer } from "./TitleImageFromJson.styles";
import { FormComponentProps } from "../tools/typings";

const TitleImageFromJson: FC<FormComponentProps> = ({ formElementJson }) => {
   return (
      <TitleImageContainer>
         <TitleImage src={formElementJson.image} />
      </TitleImageContainer>
   );
};

export default TitleImageFromJson;
