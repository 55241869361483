import styled from "styled-components";

export const Column = styled("div")`
   display: flex;
   flex-direction: column;
`;

export const ColumnCentered = styled("div").withConfig({
   shouldForwardProp: p => !["fullWidth"].includes(p)
})<{ fullWidth?: boolean }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}
`;

export const TwoColumns = styled("div")`
   display: grid;
   grid-template-columns: auto 1fr;
   column-gap: 48px;
   justify-items: center;

   & > div {
      width: 100%;
   }
`;

export default Column;
