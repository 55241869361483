import styled from "styled-components";
import { Paragraph } from "../../../kanopy-ui/Texts/Texts";
import Icon from "../../../kanopy-ui/Icon/Icon";

export const InfoParagraph = styled(Paragraph)`
   padding-top: 10px;
   padding-left: 5px;
   font-size: 14px;
   font-weight: 400;
   span {
      color: #3f8450;
      font-size: 14px;
      font-weight: 400;
   }
`;
export const InfoIcon = styled(Icon)`
   padding-top: 8px;
   color: #323232;
`;
