import React, { FC, useEffect, useState } from "react";
import { getFieldError } from "../../../../tools/validation/getFieldError";
import Select from "../../../kanopy-ui/Select/Select";
import { LabelAndValue } from "../../../kanopy-ui/tools/types";
import { FormComponentProps } from "../tools/typings";

const SelectFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form } = formTools;

   const [value, setValue] = useState<string>(formElementJson.value);

   useEffect(() => {
      if (formElementJson.type !== "select") {
         return;
      }

      form.setValue(formElementJson.name, value);
   }, [value]);

   return (
      <Select
         label={formElementJson.label ?? ""}
         placeholder={formElementJson.placeholder}
         options={formElementJson.options as LabelAndValue[]}
         value={value}
         onChange={setValue}
         error={getFieldError({ formElementJson, form })}
      />
   );
};

export default SelectFromJson;
