import { FormElementJson, FormJson } from "../forms/useServerJsonForm";

export function getFormElement(
   formJson: FormJson | undefined,
   formElementType: string
): FormElementJson | undefined {
   for (const formGroup of formJson?.formGroups ?? []) {
      for (const formElement of formGroup.formElements ?? []) {
         if (formElement.type === formElementType) {
            return formElement;
         }
      }
   }

   return undefined;
}
