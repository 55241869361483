import React, { FC } from "react";
import { ChartGraphic } from "../../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import { ButtonVariant } from "../../../../../kanopy-ui/Button";
import { ButtonStyled, CheckboxStyled } from "./ChartFilterTool.styles";
import ContextMenu from "../../../../../kanopy-ui/ContextMenu/ContextMenu";

interface PropsChartFilterTool {
   chartGraphic: ChartGraphic;
   /** Optional prop, in case the filter needs to be controlled from outside */
   filter?: string[];
   /** If this is set each filter text will match the color of the line */
   colors?: string[];
   onFilterChange: (itemsIdsToHide: string[]) => void;
}

/**
 * Some lines or bars in a chart needs to be hidden so the user can focus on less data,
 * this is a component for the user to select which lines to hide.
 */
const ChartFilterTool: FC<PropsChartFilterTool> = props => {
   const { chartGraphic, filter, colors, onFilterChange } = props;

   const onFilterCheckboxClick = (clickedItemId: string, checked: boolean) => {
      const selectedDataFilter = new Set(filter);

      if (checked) {
         selectedDataFilter.delete(clickedItemId);
      } else {
         selectedDataFilter.add(clickedItemId);
      }

      const newFilter = Array.from(selectedDataFilter);
      onFilterChange(newFilter);
   };

   return (
      <ContextMenu
         placement={"bottom-start"}
         menuItems={chartGraphic.data.map((item, i) => ({
            disableMenuCloseOnClick: true,
            disablePadding: true,
            render: () => (
               <CheckboxStyled
                  label={item.title}
                  key={item.id}
                  onChange={checked => onFilterCheckboxClick(item.id, checked)}
                  checked={!filter?.includes(item.id)}
                  textColor={colors?.[i] ?? undefined}
               />
            )
         }))}
      >
         <ButtonStyled variant={ButtonVariant.TextOnly}>Hide lines</ButtonStyled>
      </ContextMenu>
   );
};

export default ChartFilterTool;
