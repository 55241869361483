import { FC } from "react";
import { HeightAnimationContainer, SnackbarContainer } from "./SnackBar.styles";

export enum SnackbarVariant {
   Variant1
}

export interface SnackBarProps {
   message?: string;
   animateHeight?: boolean;
   className?: string;
}

export const SnackBar: FC<SnackBarProps> = props => {
   const { message, className, animateHeight, children } = props;

   return (
      <HeightAnimationContainer animate={animateHeight}>
         <SnackbarContainer className={className}>
            {message && message}
            {children}
         </SnackbarContainer>
      </HeightAnimationContainer>
   );
};
