import { useState } from "react";
import { LineChartDataItem } from "../LineChart";
import { NumberFormatOptions } from "../../../../../tools/string/formatNumber";
import { applyFormat } from "./applyFormat";

/**
 * Returns the formatter function and a value with the amount of chars of the biggest tick value
 * to be used to set margins or paddings making room for the text.
 */
export function useFormatterAxisY(data: LineChartDataItem[], formatAxisY?: NumberFormatOptions) {
   const [leftTickCharsAmount, setLeftTickCharsAmount] = useState<number>(0);

   const handleLeftAxisFormat = (value: string | number | Date) => {
      const newValue = applyFormat(value, formatAxisY);

      const newValChars = String(newValue).length;

      if (newValChars > leftTickCharsAmount) {
         setTimeout(() => setLeftTickCharsAmount(newValChars), 0); // This setTimeout is needed because this function is executed on the root component of Nivo line internally and state cannot be set in the root component but we need it
      }

      return newValue;
   };

   return { handleLeftAxisFormat, leftTickCharsAmount };
}
