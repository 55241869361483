import React, { FC, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DrawerButton from "../../../../kanopy-ui/Drawer/DrawerButton/DrawerButton";
import { ButtonGroup, DrawerContentContainer, LogoImg } from "./DashboardPageContainer.styles";
import {
   SidePanelButton,
   SidePanelResponse
} from "../../../../../tools/api/dashboard/side-panel/useSidePanel";
import { ButtonVariant } from "../../../../kanopy-ui/Button";
import Button from "../../../../kanopy-ui/Button";
import { currentTheme } from "../../../../../tools/themes/themeConfig";

const RenderDrawer: FC<{ data: SidePanelResponse }> = ({ data }) => {
   const navigate = useNavigate();
   const location = useLocation();
   const [isHover, setHover] = useState<number>();

   const getStateActive = (button: SidePanelButton) => {
      return location.pathname.split("/").join("") === button.link.split("/").join("");
   };

   const handleOnClick = (button: SidePanelButton) => {
      button.type === "external-link" ? window.open(`${button.link}`) : navigate(`/${button.link}`);
   };

   return (
      <DrawerContentContainer>
         <LogoImg src={currentTheme.images.logoForDrawer} />
         {Object.keys(data.sidePanelButtons).map((buttonGroupKey, i) => (
            <ButtonGroup key={i}>
               {data.sidePanelButtons[buttonGroupKey].map((button, i) => {
                  const isActive = getStateActive(button);
                  return (
                     <DrawerButton
                        onMouseEnter={() => setHover(i)}
                        onMouseLeave={() => setHover(undefined)}
                        label={button.name}
                        icon={button.icon}
                        onClick={() => handleOnClick(button)}
                        active={isActive}
                        hovered={i === isHover}
                        key={i}
                     />
                  );
               })}
            </ButtonGroup>
         ))}
         {data.contactAdvisorButton && (
            <a
               href={
                  "mailto:?to=" +
                  data.contactAdvisorButton.name +
                  "<" +
                  data.contactAdvisorButton.mailTo +
                  ">"
               }
            >
               <Button variant={ButtonVariant.Outlined} fullWidth>
                  {data.contactAdvisorButton.label}
               </Button>
            </a>
         )}
      </DrawerContentContainer>
   );
};

export default RenderDrawer;
