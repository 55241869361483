import styled from "styled-components";
import { Title, Paragraph } from "../../../../kanopy-ui/Texts/Texts";
import Button from "../../../../kanopy-ui/Button";

export const MessagePageContainer = styled("div")`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
`;

export const MessageImage = styled("img")`
   height: 287px;
`;

export const MessageTitle = styled(Title)`
   text-align: center;
   font-size: 35px;
   font-weight: 800;
   margin-bottom: 16px;
   margin-top: 16px;
`;

export const MessageParagraph = styled(Paragraph)`
   text-align: center;
   font-size: 16px;
`;

export const MessageButton = styled(Button)`
   margin-top: 30px;
   width: 150px;
`;
