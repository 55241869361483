import React, { FC } from "react";
import { Divider } from "../formFromJson.styles";
import { FormComponentProps } from "../tools/typings";

const DividerFromJson: FC<FormComponentProps> = props => {
   const { formElementJson } = props;

   let size: number;
   switch (formElementJson.variant) {
      case DividerVariant.Small:
         size = 8;
         break;
      case DividerVariant.Medium:
         size = 32;
         break;
      case DividerVariant.Large:
         size = 64;
         break;
      default:
         size = 32;
         break;
   }

   return <Divider width={size} height={size}></Divider>;
};

export enum DividerVariant {
   Small = "Small",
   Medium = "Medium",
   Large = "Large"
}

export default DividerFromJson;
