import React, { FC, useState } from "react";
import { IconImage } from "../Icon/Icon";
import { InputColorVariant, PropsInput } from "../Input/Input";
import { Label } from "../Texts/Texts";
import { LabelAndValue } from "../tools/types";
import {
   ToggleIcon,
   InputStyled,
   InputContainer,
   ContextMenuStyled,
   MenuItemStyled
} from "./Select.styles";

export interface PropsSelect extends Omit<PropsInput, "variant" | "onChange"> {
   label?: string;
   value?: string;
   onChange: (newValue: string) => void;
   options: LabelAndValue[];
}

const Select: FC<PropsSelect> = props => {
   const { options, label, onChange, value, ...inputProps } = props;
   const [cursorOver, setCursorOver] = useState(false);

   return (
      <InputContainer
         onMouseEnter={() => setCursorOver(true)}
         onMouseLeave={() => setCursorOver(false)}
      >
         {label && <Label>{label}</Label>}
         <ContextMenuStyled
            menuItems={options.map(option => ({
               render: () => <MenuItemStyled text={option.label} />,
               onMouseUp: () => onChange(option.value)
            }))}
            placement={"bottom-start"}
            offsetY={2}
            matchWidth
         >
            <InputStyled
               {...inputProps}
               placeholder={props.placeholder ?? "Select..."}
               label={undefined}
               value={options?.find(option => option.value === value)?.label}
               colorVariant={InputColorVariant.Transparent}
               renderRightIcon={() => (
                  <ToggleIcon hovering={cursorOver} iconImage={IconImage.MdKeyboardArrowDown} />
               )}
               readOnly
            />
         </ContextMenuStyled>
      </InputContainer>
   );
};

export default Select;
