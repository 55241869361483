import React, { FC } from "react";
import { HeaderVariant } from "../../../common/Header/Header";
import InProgressModal from "../../../common/InProgressModal/InProgressModal";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";

const InProgressStep: FC = () => {
   return (
      <PageContainer
         variant={PageContainerVariant.Variant3}
         headerConfig={{ variant: HeaderVariant.RegisteredUser }}
      >
         <InProgressModal redirectToNextFormOnSubmit />
      </PageContainer>
   );
};

export default InProgressStep;
