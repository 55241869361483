import React, { FC } from "react";
import {
   ContainerCard,
   DataGridIconColor,
   DataGridIconContainer,
   IconStyled
} from "./MyClients.styles";
import { MyClientsPageJson } from "../../../../../tools/api/dashboard/home/useDashboardHome";
import DataGrid from "../../../../kanopy-ui/DataGrid/DataGrid";
import { IconImage } from "../../../../kanopy-ui/Icon/Icon";
import { CardVariant } from "../../../../kanopy-ui/Card";

interface PropsMyClients {
   serverData: MyClientsPageJson;
}

const MyClients: FC<PropsMyClients> = props => {
   const serverData = props.serverData as MyClientsPageJson;
   const paginationEnabled = serverData.dataGrid?.options?.pagination;

   return (
      <ContainerCard
         variant={CardVariant.MediumShadow}
         height={paginationEnabled ? "100%" : "unset"}
      >
         <DataGrid
            {...serverData.dataGrid!}
            height={paginationEnabled ? "100%" : undefined}
            columnIdWithLink={"redirect_on_click"}
            columnSettings={{ status_icon: { width: 60, flex: 0 } }}
            renderCell={{
               status_icon: params => (
                  <DataGridIconContainer>
                     <IconStyled
                        iconImage={params.value}
                        iconColor={
                           params.value === IconImage.MdError
                              ? DataGridIconColor.Red
                              : params.value === IconImage.MdStar
                              ? DataGridIconColor.Gold
                              : params.value === IconImage.MdOutlineUnpublished
                              ? DataGridIconColor.Gray
                              : DataGridIconColor.Green
                        }
                     />
                  </DataGridIconContainer>
               )
            }}
         />
      </ContainerCard>
   );
};

export default MyClients;
