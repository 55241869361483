import React, { FC } from "react";
import { ValidationRule } from "react-hook-form";
import { getFieldError } from "../../../../tools/validation/getFieldError";
import { handleRemoteValidation } from "../../../../tools/validation/handleRemoteValidation";
import Input, { InputVariant } from "../../../kanopy-ui/Input/Input";
import { FormComponentProps, FormFromJsonVariant } from "../tools/typings";

const InputFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form, formVariant } = formTools;
   const error = getFieldError({ formElementJson, form });

   const handleValidation = (value: string | number | object) => {
      if (formElementJson.match) {
         if (form.getValues()[formElementJson.match] !== form.getValues()[formElementJson.name]) {
            return formElementJson.match_error;
         }
      }

      handleRemoteValidation(form, formElementJson);
   };

   const handleFocus = () => {
      form.clearErrors(formElementJson.name);
   };

   const propsFromValidator = form.register(formElementJson.name, {
      required: Boolean(formElementJson.required),
      validate: handleValidation,
      pattern: (formElementJson.regex
         ? new RegExp(formElementJson.regex)
         : undefined) as ValidationRule<RegExp>
   });

   // TODO: The type prop should change in the json to match the component so we can remove this string "translation" present below
   return (
      <Input
         label={formElementJson.label ?? ""}
         type={
            formElementJson.type === "input[type=password]"
               ? "password"
               : "text" && formElementJson.type.trim() === "input[type=text]"
               ? "email"
               : "text"
         }
         readOnly={formElementJson.readOnly}
         placeholder={formElementJson.placeholder ?? ""}
         variant={formVariant === FormFromJsonVariant.TwoColumns ? InputVariant.Short : undefined}
         onFocus={handleFocus}
         error={error}
         {...propsFromValidator}
      />
   );
};

export default InputFromJson;
