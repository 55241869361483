import React, { FC } from "react";
import Button, { ButtonVariant } from "../../../kanopy-ui/Button";
import { FormComponentProps, FormFromJsonVariant } from "../tools/typings";

const ButtonSkipFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { formVariant } = formTools;

   const handleClick = () => {
      formTools.skipFormTools.skipForm({
         callUrl: formElementJson?.api?.url,
         redirectUrl: formElementJson?.next
      });
   };

   return (
      <Button
         onClick={handleClick}
         loading={formTools.skipFormTools.isLoading}
         variant={ButtonVariant.Outlined}
         fullWidth={formVariant === FormFromJsonVariant.TwoColumns ? false : true}
      >
         {formElementJson.label}
      </Button>
   );
};

export default ButtonSkipFromJson;
