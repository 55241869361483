import React, { InputHTMLAttributes, useRef, useState } from "react";
import { IconImage } from "../Icon/Icon";
import { Label } from "../Texts/Texts";
import {
   ErrorIcon,
   ErrorText,
   InputContainer,
   InputStyled,
   InputTextContainer,
   ToggleIconButtonStyled
} from "./Input.styles";
import { useBlurEventDisabler } from "./tools/useBlurEventDisabler";

export interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
   label?: string;
   error?: string | undefined;
   variant?: InputVariant;
   colorVariant?: InputColorVariant;
   borderVariant?: BorderVariant;
   renderLeftIcon?: () => React.ReactNode;
   renderRightIcon?: () => React.ReactNode;
}

export enum InputVariant {
   Long = "Long",
   Short = "Short"
}

export enum InputColorVariant {
   WithBackground = "WithBackground",
   Transparent = "Transparent"
}

export enum BorderVariant {
   Complete = "Complete",
   Bottom = "Bottom"
}

const Input = React.forwardRef<HTMLInputElement, PropsInput>((props, ref) => {
   const {
      label,
      error,
      type = "text",
      variant = InputVariant.Long,
      colorVariant = InputColorVariant.WithBackground,
      borderVariant = BorderVariant.Complete,
      readOnly,
      renderRightIcon,
      renderLeftIcon,
      ...restOfProps
   } = props;
   const [drawCharactersAsDots, setDrawCharactersAsDots] = useState<boolean>(true);
   const inputRef = useRef<HTMLInputElement>();
   const { disableNextBlurEvent, handleBlur } = useBlurEventDisabler({
      onBlur: restOfProps?.onBlur,
      inputRef
   });
   const finalType = type !== "password" ? type : drawCharactersAsDots ? "password" : "text";

   const handleToggleIconClick = (newValue: boolean) => {
      setDrawCharactersAsDots(newValue);
      // Blur event is triggered when clicking a button in this component and it should not, so we disable it.
      disableNextBlurEvent();
   };

   // This component forwards the native input's ref but also we need to have the ref
   const onRef = (r: HTMLInputElement) => {
      inputRef.current = r;
      if (typeof ref === "function") {
         ref(r);
      }
   };

   return (
      <InputContainer>
         {label && <Label>{label}</Label>}
         <InputTextContainer
            error={error}
            colorVariant={colorVariant}
            borderVariant={borderVariant}
            readOnly={readOnly}
         >
            {renderLeftIcon?.()}
            <InputStyled
               {...restOfProps}
               onBlur={handleBlur}
               ref={onRef}
               type={finalType}
               variant={variant}
               readOnly={readOnly}
            />
            {error !== undefined && <ErrorIcon iconImage={IconImage.MdInfo} />}
            {type === "password" && (
               <ToggleIconButtonStyled
                  value={drawCharactersAsDots}
                  onToggle={handleToggleIconClick}
                  onIcon={IconImage.MdVisibilityOff}
                  offIcon={IconImage.MdVisibility}
                  triggerOnMouseDown
               />
            )}
            {renderRightIcon?.()}
         </InputTextContainer>
         {error && <ErrorText>{error}</ErrorText>}
      </InputContainer>
   );
});

export default Input;
