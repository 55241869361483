import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import SignUp from "./components/pages/Registration/SignUp/SignUp";
import Login from "./components/pages/Authentication/Login/Login";
import ForgotPassword from "./components/pages/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/pages/Authentication/ResetPassword/ResetPassword";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import Home from "./components/pages/Dashboard/Home/Home";
import Settings from "./components/pages/Settings/Settings";
import NotFound from "./components/pages/NotFound/NotFound";
import Maintenance from "./components/pages/Maintenance/Maintenance";
import VerificationCode from "./components/pages/Registration/VerificationCode/VerificationCode";
import Wizard from "./components/pages/Wizard/Wizard";
import ProfileSetup from "./components/pages/ProfileSetup/ProfileSetup";

import { useFinicityConnectIframeFix } from "./components/common/FinicityConnectModal/tools/useFinicityConnectIframeFix";
import ConnectModalStep from "./components/pages/Wizard/ConnectModalStep/ConnectModalStep";
import FinicityConnectStep from "./components/pages/Wizard/FinicityConnectStep/FinicityConnectStep";
import InProgressStep from "./components/pages/Wizard/InProgressStep/InProgressStep";
import { RequireLogin } from "./components/common/Navigation/RequireLogin";
import { RequireLogout } from "./components/common/Navigation/RequireLogout";
import DevTesting from "./components/pages/DevTesting/DevTesting";

const MainRoutes: FC = () => {
   useFinicityConnectIframeFix();

   return (
      <Routes>
         <Route
            path="/"
            element={
               <RequireLogin>
                  <Home />
               </RequireLogin>
            }
         />
         <Route
            path="dashboard/*"
            element={
               <RequireLogin>
                  <Dashboard />
               </RequireLogin>
            }
         />
         <Route
            path="settings/*"
            element={
               <RequireLogin>
                  <Settings />
               </RequireLogin>
            }
         />
         <Route
            path={"wizard/*"}
            element={
               <RequireLogin>
                  <Wizard />
               </RequireLogin>
            }
         />
         <Route
            path="edit-modal/*"
            element={
               <RequireLogin>
                  <ConnectModalStep />
               </RequireLogin>
            }
         />
         <Route
            path="edit-modal/connect-page/*"
            element={
               <RequireLogin>
                  <ConnectModalStep />
               </RequireLogin>
            }
         />
         <Route
            path="edit-modal/finicity-connect"
            element={
               <RequireLogin>
                  <FinicityConnectStep />
               </RequireLogin>
            }
         />
         <Route
            path="edit-modal/in-progress"
            element={
               <RequireLogin>
                  <InProgressStep />
               </RequireLogin>
            }
         />
         <Route
            path={"profile-setup/*"}
            element={
               <RequireLogin>
                  <ProfileSetup />
               </RequireLogin>
            }
         />
         <Route path="/" element={<Login />} />
         <Route
            path="/sign-in"
            element={
               <RequireLogout>
                  <Login />
               </RequireLogout>
            }
         />
         <Route
            path="/sign-up"
            element={
               <RequireLogout>
                  <SignUp />
               </RequireLogout>
            }
         />
         <Route path="/sign-up/verification-code" element={<VerificationCode />} />
         <Route path="/sign-up/verification-code/:userId" element={<VerificationCode />} />
         <Route path="/forgot-password/email" element={<ForgotPassword />} />
         <Route path="/forgot-password/verification-code" element={<VerificationCode />} />
         <Route path="/forgot-password/verification-code/:userId" element={<VerificationCode />} />
         <Route path="/notfound" element={<NotFound />} />
         <Route path="/reset-password" element={<ResetPassword />} />
         <Route path="/maintenance" element={<Maintenance />} />
         <Route path="/dev-testing" element={<DevTesting />} />
         <Route path="*" element={<NotFound />} />
      </Routes>
   );
};

export default MainRoutes;
