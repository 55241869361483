import { FC } from "react";
import PageContainer from "../../../common/PageContainer/PageContainer";
import {
   ArrowImg,
   IconsIllustrationCard,
   FileImg,
   LogoImg,
   WizardStartContainer,
   WizardStartButton,
   WizardIllustrationContainer,
   WizardStartTitle
} from "./WizardStartStep.styles";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import { CardVariant } from "../../../kanopy-ui/Card";
import { ColumnCentered } from "../../../kanopy-ui/Column/Column";
import { currentTheme } from "../../../../tools/themes/themeConfig";

const WizardStartStep: FC = () => {
   return (
      <PageContainer>
         <WizardStartContainer>
            <FormFromJson
               jsonUrl={"wizard/start"}
               renderFormComponent={({ formElementJson, formTools }) =>
                  formElementJson.type === "title" ? (
                     <ColumnCentered>
                        <WizardStartTitle>{formElementJson.label}</WizardStartTitle>
                     </ColumnCentered>
                  ) : formElementJson.type === "start-panel" ? (
                     <WizardIllustrationContainer>
                        <IconsIllustrationCard variant={CardVariant.StrongShadow}>
                           <ArrowImg src={currentTheme.images.startArrow} />
                           <FileImg src={formElementJson?.document_icon ?? ""} />
                           <LogoImg src={formElementJson?.lender_icon ?? ""} />
                        </IconsIllustrationCard>
                     </WizardIllustrationContainer>
                  ) : formElementJson.type === "input[type=submit]" ? (
                     <WizardStartButton
                        onClick={() =>
                           formTools.submitFormTools.submitForm({
                              submitUrl: formElementJson?.api?.url,
                              redirectUrl: formElementJson?.next
                           })
                        }
                        loading={formTools.submitFormTools.isLoading}
                     >
                        {formElementJson.label}
                     </WizardStartButton>
                  ) : null
               }
            />
         </WizardStartContainer>
      </PageContainer>
   );
};

export default WizardStartStep;
