import styled from "styled-components";
import Card from "../../../../../kanopy-ui/Card";
import { Label13, Label6 } from "../../../../../kanopy-ui/Texts/Texts";

export const InfoCardContainer = styled(Card)`
   box-sizing: border-box;
   transition: width 1s ease-in-out;
   display: flex;
   flex-direction: row;
   flex-grow: 1;
   padding-top: ${({ theme }) => theme.spacing(3, "px")};
   padding-bottom: ${({ theme }) => theme.spacing(3, "px")};
   padding-left: ${({ theme }) => theme.spacing(2, "px")};
   padding-right: ${({ theme }) => theme.spacing(2, "px")};
   will-change: width;

   @media only screen and (min-width: 780px) {
      flex-basis: calc(50% - 24px);
   }

   @media only screen and (min-width: 1200px) {
      flex-basis: calc(20% - 24px);
   }
`;

export const InfoCardIconContainer = styled("div")`
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   background-color: ${({ theme }) => theme.infoCard.iconContainerColor};
   min-width: 50px;
   max-width: 50px;
   min-height: 50px;
   max-height: 50px;
   margin-right: 16px;

   img {
      position: relative;
      top: -1px;
   }
`;

export const InfoCardTextPart = styled("div")`
   display: flex;
   flex-direction: column;
   width: 100%;
`;

export const InfoCardTitle = styled(Label6)``;

export const InfoCardSubTitle = styled(Label13)``;

export const ReactTooltipStyled = styled("div")`
   .__react_component_tooltip {
      font-weight: 400;
      font-size: 14px;
      width: 268px;
      transition: opacity 0s ease-out;
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
   }
`;
