import styled from "styled-components";
import { Title4 } from "../Texts/Texts";

export const ItemContainer = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   user-select: none;
   padding-right: ${({ theme }) => theme.spacing(1.5, "px")};
   padding-left: ${({ theme }) => theme.spacing(1.5, "px")};
`;

export const IconContainer = styled("div").withConfig({
   shouldForwardProp: p => !["adjustIcon"].includes(p)
})<{ adjustIcon?: boolean }>`
   display: flex;
   position: relative;
   ${({ adjustIcon }) => (adjustIcon ? "bottom: 4px;" : "")}
   height: 30px;
   width: 30px;
   margin-right: ${({ theme }) => theme.spacing(1, "px")};
`;

export const TextsContainer = styled("div")`
   display: flex;
   flex-direction: column;
`;

export const Title4Styled = styled(Title4)`
   margin-bottom: -4px;
`;
