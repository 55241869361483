import styled from "styled-components";
import Card from "../Card";
import { Link } from "react-router-dom";

export const ChildrenContainer = styled("div")`
   cursor: pointer;
`;

export const MenuContainer = styled(Card)`
   box-shadow: ${({ theme }) => theme.contextMenu.shadow};
   border-radius: ${({ theme }) => theme.contextMenu.borderRadius};
`;

export const ItemsContainer = styled("div")`
   display: flex;
   flex-direction: column;
   overflow-y: auto;
`;

export const ItemContainerLink = styled(Link).withConfig({
   shouldForwardProp: p =>
      ![
         "isFirstItem",
         "isLastItem",
         "notInteractive",
         "disableSeparatorLine",
         "disablePadding"
      ].includes(p)
})<{
   isFirstItem?: boolean;
   isLastItem?: boolean;
   notInteractive?: boolean;
   disableSeparatorLine?: boolean;
   disablePadding?: boolean;
}>`
   padding-bottom: ${({ theme, disablePadding }) =>
      !disablePadding ? theme.spacing(1, "px") : "0"};
   ${({ isFirstItem, disablePadding }) =>
      disablePadding ? "" : isFirstItem ? "padding-top: 14px" : "padding-top: 8px"};
   ${({ isLastItem, disablePadding }) =>
      isLastItem && !disablePadding ? "padding-bottom: 14px;" : ""}
   border-bottom: ${({ theme, isLastItem, disableSeparatorLine }) =>
      !isLastItem && !disableSeparatorLine ? theme.contextMenu.separatorLine : "none"} !important;
   ${({ isFirstItem, isLastItem, theme }) =>
      isFirstItem
         ? `border-top-left-radius: ${theme.contextMenu.borderRadius}; border-top-right-radius: ${theme.contextMenu.borderRadius};`
         : isLastItem
         ? `border-bottom-left-radius: ${theme.contextMenu.borderRadius}; border-bottom-right-radius: ${theme.contextMenu.borderRadius};`
         : ""}
   ${({ notInteractive }) => (notInteractive !== true ? "cursor: pointer;" : "cursor: default;")}
   ${({ notInteractive }) =>
      notInteractive !== true
         ? `& * {
      cursor: pointer;
   }`
         : `& * {
            cursor: default;
         }`}

   transition: background-color 250ms;

   color: unset;
   &:hover {
      ${({ theme, notInteractive }) =>
         notInteractive !== true
            ? `background-color: ${theme.contextMenu.itemHoverBackgroundColor};`
            : ""};
      color: unset;
   }
`;

export const TippyContainer = styled("div")`
   position: relative;
   & > div {
   }
`;
