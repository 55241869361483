import styled from "styled-components";
import Card from "../../../kanopy-ui/Card";
import { Paragraph9 } from "../../../kanopy-ui/Texts/Texts";
import { Title11 } from "../../../kanopy-ui/Texts/Texts";

export const CardsTitle = styled(Title11)`
   padding-top: 5px;
`;
export const CardsParagraph = styled(Paragraph9)`
   text-align: center;
   padding-right: 30px;
   padding-left: 30px;
   @media only screen and (max-width: 950px) {
      padding-right: 0px;
      padding-left: 0px;
   }
`;

export const CardsContainer = styled("div")`
   display: flex;
   flex-direction: column;
   gap: 24px;
   margin-top: 16px;

   @media only screen and (min-width: 600px) {
      flex-direction: row;
   }
`;

export const CardsLayout = styled(Card)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
   user-select: none;
   padding: 27px;
   height: 188px;

   @media only screen and (max-width: 950px) {
      width: 100%;
      height: auto;
   }
`;

export const CardsIcon = styled("img")`
   height: 50px;
`;
