import styled from "styled-components";
import Card from "../../../kanopy-ui/Card";
import { Title } from "../../../kanopy-ui/Texts/Texts";

export const AuthFormTitle = styled(Title)``;

export const AuthCard = styled(Card)`
   width: 450px;
   min-width: 370px;
`;
