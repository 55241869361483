import React, { FC, useEffect, useMemo, useState } from "react";

export interface PropsTooltipOverflowFix {
   mousePosition: { x?: number; y?: number };
}

/**
 * Nivo tooltip has a bug where it can be rendered outside the chart area and also call
 * reflows in the whole page. This fixes the issue. See: https://github.com/plouc/nivo/issues/580
 */
const TooltipOverflowFix: FC<PropsTooltipOverflowFix> = props => {
   const { mousePosition } = props;

   const [tooltipSize, setTooltipSize] = useState<{
      width: number;
      height: number;
   }>({ width: 0, height: 0 });

   // Dynamically get the size of the tooltip
   useEffect(() => {
      const tooltip = document.querySelector(".nivo_tooltip");
      if (tooltip) {
         const { width, height } = tooltip.getBoundingClientRect();
         setTooltipSize({ width, height });
      }
   }, []);

   // Only show it to the right of the pointer when we are close to the left edge
   const newPosX = useMemo(
      () => ((mousePosition?.x ?? 0) < (tooltipSize.width * 1.3) / 2 ? 0 : -tooltipSize.width),
      [tooltipSize, mousePosition.x]
   );

   // Only show it below the pointer when we are close to the top edge
   const newPosY = useMemo(
      () => ((mousePosition.y ?? 0) < (tooltipSize.height * 1.3) / 2 ? 0 : -tooltipSize.height),
      [tooltipSize, mousePosition.y]
   );

   return (
      <div
         className={"nivo_tooltip"}
         style={{
            position: "absolute",
            left: newPosX,
            top: newPosY,
            width: "fit-content",
            whiteSpace: "nowrap"
         }}
      >
         {props.children}
      </div>
   );
};

export default TooltipOverflowFix;
