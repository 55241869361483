import styled from "styled-components";

export const TabList = styled("div").attrs(props => ({
   role: "tablist",
   ...props
}))`
   display: flex;
   flex-direction: row;
   position: relative;
   column-gap: 24px;
`;

export const ButtonTab = styled("button").attrs(props => ({
   selected: false,
   ...props
}))`
   position: relative;
   background: transparent;
   border: 0;
   color: ${({ theme }) => theme.label9.color};
   font-family: ${({ theme }) => theme.label9.fontFamily};
   font-size: ${({ theme }) => theme.label9.fontSize}px;
   line-height: ${({ theme }) => theme.spacing(3.5, "px")};
   padding-bottom: ${({ theme }) => theme.spacing(1, "px")};
   font-weight: ${({ selected }) => (selected ? 700 : 400)};
   ${({ selected }) => selected && `&::after { content: "" }`};

   &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: ${({ theme, selected }) =>
         selected ? theme.tabs.underlineColor : "transparent"};
      animation: underline-tab-appear-animation 0.4s ease-out;
      animation-play-state: running;
      transform-origin: 50% 100%;

      @keyframes underline-tab-appear-animation {
         from {
            width: 0;
         }
         to {
            width: 100%;
         }
      }
   }

   &,
   & * {
      cursor: pointer;
      user-select: none;
   }
`;

export const UnderLine = styled("div")`
   width: 100%;
   border-bottom: ${({ theme }) => theme.tabs.borderBottom};
`;

export const RightContentContainer = styled("div")`
   position: absolute;
   right: 0px;
`;
