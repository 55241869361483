import React, { FC } from "react";
import { FormElementJson } from "../../../../tools/api/forms/useServerJsonForm";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";
import { ColumnCentered } from "../../../kanopy-ui/Column/Column";
import {
   BackgroundVerificationCode,
   VerificationCodeContainer,
   VerificationCodeTitle
} from "./VerificationCode.styles";
import { useLocation } from "react-router";

const VerificationCode: FC = () => {
   const location = useLocation();

   return (
      <PageContainer variant={PageContainerVariant.Variant2} headerConfig={{ position: "fixed" }}>
         <BackgroundVerificationCode />
         <VerificationCodeContainer fixedWidth>
            <FormFromJson
               renderFormComponent={({ formElementJson }) =>
                  formElementJson.type === "title" ? (
                     <ColumnCentered fullWidth>
                        <VerificationCodeTitle>{formElementJson.label}</VerificationCodeTitle>
                     </ColumnCentered>
                  ) : null
               }
               jsonUrl={location.pathname}
            />
         </VerificationCodeContainer>
      </PageContainer>
   );
};

export default VerificationCode;
