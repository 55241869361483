import DashboardHeader from "../components/DashboardHeader/DashboardHeader";
import DashboardPageContainer from "../components/DashboardPageContainer/DashboardPageContainer";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";

const MyBusinessFinances = () => {
   return (
      <DashboardPageContainer>
         <DashboardHeader title={"My Business Finances"} subtitle={""} />
         <FormFromJson jsonUrl="dashboard/my-business-finances" />
      </DashboardPageContainer>
   );
};

export default MyBusinessFinances;
