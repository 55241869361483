import { UseQueryResult } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormJson } from "../../../../tools/api/forms/useServerJsonForm";
import { RequestError } from "../../../../tools/api/tools/typings";
import { PropsFormFromJson } from "./typings";

/**
 * Calls events that can be subscribed from outside to execute things based on the state of the form.
 */
export function useFormEvents(
   request: UseQueryResult<FormJson, RequestError>,
   formProps: PropsFormFromJson,
   formJson: FormJson | undefined
) {
   useEffect(() => {
      if (request.isSuccess) {
         formProps.onDataFromServer?.(formJson, () => request.refetch(), request.remove);
      }
      if (request.isError) {
         formProps.onErrorFromServer?.(request.error);
      }
   }, [request.isSuccess, request.isError]);
}
