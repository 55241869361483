import styled from "styled-components";
import Button from "../../../../../kanopy-ui/Button";
import Card from "../../../../../kanopy-ui/Card";
import { Row, RowCentered } from "../../../../../kanopy-ui/Row/Row";
import { Paragraph, Title7 } from "../../../../../kanopy-ui/Texts/Texts";

export const TargetCardContainer = styled(Card)`
   padding-top: 24px;
   padding-bottom: 24px;
   padding-left: 32px;
   padding-right: 52px;
`;

export const Title = styled(Title7)`
   margin-bottom: 4px;
`;

export const CardParagraph = styled(Paragraph)`
   margin-bottom: 24px;
`;

export const FormContainer = styled(Row)`
   flex-wrap: wrap;
`;

export const SetGoalButton = styled(Button)`
   position: relative;
   bottom: 3px;
   min-width: 134px;
   margin-right: 78px;
`;

export const TargetInputContainer = styled(Row)`
   align-items: center;
   column-gap: 16px;
`;

export const DifEstContainer = styled(RowCentered)`
   column-gap: 78px;
`;
