import React, { PropsWithChildren, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { httpRequest } from "../../../tools/api/tools/httpRequest";
import { tryToGetErrorMessage } from "../../../tools/api/tools/tryToGetErrorMessage";
import { Label3, Label2, Paragraph6 } from "../Texts/Texts";
import {
   ErrorText,
   EmptyUploadBoxContainer,
   TypeIcon,
   UploadBoxLabel,
   FilledUploadContainer,
   CloseButton,
   FilePartContainer,
   FileDescription,
   MainContainer,
   FileUploadedThumbnail
} from "./FileUploadBox.styles";
import AnyFileIcon from "../tools/assets/file_icon.svg";
import ImageFileIcon from "../tools/assets/image_icon.svg";
import { IconImage } from "../Icon/Icon";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

interface PropsFileUploadBox<T> {
   label?: string;
   uploadUrl: string | undefined;
   /* The file is added in a json as a value when uploading, this prop is the key name */
   fileKeyNameInJson: string;
   currentUploadedFile?: UploadedFile | undefined;
   fileTypesAllowed?: FileTypesAllowed;
   fileUploadedThumbnail?: string;
   onFileUploadComplete?: (serverResponse: T | undefined) => void;
}

export interface UploadedFile {
   name: string;
   size: string;
   url: string;
}

export enum FileTypesAllowed {
   Any,
   Images
}

const FileUploadBox = <T extends unknown>(props: PropsWithChildren<PropsFileUploadBox<T>>) => {
   const {
      label,
      uploadUrl,
      fileKeyNameInJson,
      currentUploadedFile,
      onFileUploadComplete,
      fileTypesAllowed = FileTypesAllowed.Any,
      fileUploadedThumbnail
   } = props;
   const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
   const [isUploading, setIsUploading] = useState<boolean>(false);

   const onDrop = useCallback(
      (acceptedFiles: File[]) => {
         if (uploadUrl === undefined) {
            return;
         }

         setErrorMessage(undefined);
         acceptedFiles.forEach(async file => {
            let formPayLoad = new FormData();
            formPayLoad.append(fileKeyNameInJson, file);
            setIsUploading(true);
            let uploadResponse: T | undefined = undefined;
            try {
               uploadResponse = await httpRequest<FormData, T>({
                  url: uploadUrl,
                  method: "POST",
                  params: formPayLoad
               });
            } catch (error) {
               setErrorMessage(tryToGetErrorMessage(error));
               setIsUploading(false);
               return;
            }

            setIsUploading(false);

            if (uploadResponse !== undefined) {
               onFileUploadComplete?.(uploadResponse);
            }
         });
      },
      [fileKeyNameInJson, onFileUploadComplete, uploadUrl]
   );

   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: false,
      accept: "image/*,.pdf,.txt",
      maxSize: 25000000,
      disabled: currentUploadedFile !== undefined
   });

   const handleUploadedFileClick = () => {
      window.open(currentUploadedFile!.url, "FileUploadBoxName");
   };

   const handleRemoveFileUploadedClick = () => {
      onFileUploadComplete?.(undefined);
   };

   if (isUploading) {
      return (
         <MainContainer>
            {label && <UploadBoxLabel>{label}</UploadBoxLabel>}
            <FilledUploadContainer>
               <LoadingSpinner />
            </FilledUploadContainer>
         </MainContainer>
      );
   }

   return (
      <MainContainer>
         {label && <UploadBoxLabel>{label}</UploadBoxLabel>}
         <div {...getRootProps()}>
            <input {...getInputProps()} />
            {!currentUploadedFile || isDragActive ? (
               <EmptyUploadBoxContainer
                  onClick={() => setErrorMessage(undefined)}
                  isDragActive={isDragActive}
               >
                  <TypeIcon
                     src={fileTypesAllowed === FileTypesAllowed.Any ? AnyFileIcon : ImageFileIcon}
                  />
                  <Paragraph6>
                     Drag your{" "}
                     {fileTypesAllowed === FileTypesAllowed.Images
                        ? "picture"
                        : "documents (i.e. PDF, PNG, JPG)"}{" "}
                     or <span>browse files</span>
                  </Paragraph6>
                  {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
               </EmptyUploadBoxContainer>
            ) : (
               <FilledUploadContainer>
                  <FilePartContainer onClick={handleUploadedFileClick}>
                     {!fileUploadedThumbnail && (
                        <TypeIcon
                           src={
                              fileTypesAllowed === FileTypesAllowed.Any
                                 ? AnyFileIcon
                                 : ImageFileIcon
                           }
                        />
                     )}
                     {fileUploadedThumbnail && (
                        <FileUploadedThumbnail src={fileUploadedThumbnail} />
                     )}
                     <FileDescription>
                        <Label3>{currentUploadedFile.name}</Label3>
                        <Label2>{currentUploadedFile.size}</Label2>
                     </FileDescription>
                  </FilePartContainer>
                  <CloseButton
                     iconImage={IconImage.MdCancel}
                     onClick={handleRemoveFileUploadedClick}
                  />
               </FilledUploadContainer>
            )}
         </div>
      </MainContainer>
   );
};

export default FileUploadBox;
