import React, { FC, RefObject, useRef } from "react";
import ReactDOM from "react-dom";
import { useWindowSize } from "../../../../../tools/browser/useWindowSize";
import {
   FixedBottomContainer,
   FixedBottomContent,
   FixedBottomWrapper,
   NonFixedBottomContainer
} from "./FixedBottom.styles";
import { FormFromJsonVariant } from "../../tools/typings";

export interface PropsFixedBottom {
   bottomContainerRef: RefObject<HTMLDivElement> | undefined;
   variant: FormFromJsonVariant;
}

/**
 * This component is a fixed bottom to render buttons keeping them visible when there is much content,
 * otherwise the buttons rendered at the end are more difficult to reach because are rendered at the
 * end of the scroll content.
 */
const FixedBottom: FC<PropsFixedBottom> = props => {
   const { children, bottomContainerRef, variant } = props;
   const dummyDiv = useRef<HTMLDivElement>(null);
   const windowSize = useWindowSize();
   const forceFixedBottom = variant === FormFromJsonVariant.ContainedInModal;

   // If the amount of content being rendered it's outside of the screen or close to that we need the fixed bottom
   const fixedBottomIsRequired =
      forceFixedBottom ||
      (dummyDiv.current?.getBoundingClientRect()?.bottom ?? 0) > (windowSize.height ?? 0) * 0.6;

   return (
      <>
         {/* 
            This dummy div is used to get it's position, that information is equal to
            the amount of content being rendered in the elements before this component
         */}
         <div ref={dummyDiv} />
         {fixedBottomIsRequired && bottomContainerRef?.current != null ? (
            ReactDOM.createPortal(
               <FixedBottomContainer variant={variant}>
                  <FixedBottomWrapper noMarginBottom fixedWidth={!forceFixedBottom}>
                     <FixedBottomContent>{children}</FixedBottomContent>
                  </FixedBottomWrapper>
               </FixedBottomContainer>,
               bottomContainerRef.current!
            )
         ) : (
            <NonFixedBottomContainer>{children}</NonFixedBottomContainer>
         )}
      </>
   );
};

export default FixedBottom;
