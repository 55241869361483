import { Routes, Route } from "react-router-dom";
import NotFound from "../NotFound/NotFound";

import DoneStep from "./DoneStep/DoneStep";
import GenericWizardStep from "./GenericWizardStep/GenericWizardStep";
import ReviewStep from "./ReviewStep/ReviewStep";
import WizardStartStep from "./StartStep/WizardStartStep";

const Wizard = () => {
   return (
      <Routes>
         <Route path="start" element={<WizardStartStep />} />
         <Route path="step-page/review" element={<ReviewStep />} />
         <Route path="step-page/done" element={<DoneStep />} />
         <Route path="step-page/*" element={<GenericWizardStep />} />
         <Route path="*" element={<NotFound />} />
      </Routes>
   );
};

export default Wizard;
