import styled from "styled-components";
import DisableAnimationBeforeClick from "../DisableAnimationBeforeClick/DisableAnimationBeforeClick";
import { Label10 } from "../Texts/Texts";

export const RadioButtonContainer = styled(DisableAnimationBeforeClick)`
   display: flex;
   flex-direction: row;
   align-items: center;
   cursor: pointer;
`;

export const RadioContainer = styled("div")`
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   width: 20px;
   height: 20px;
   min-width: 20px;
   min-height: 20px;
   margin-right: 6px;
`;

export const Frame = styled("div")`
   position: absolute;
   width: 100%;
   height: 100%;
   border: 2px solid ${({ theme }) => theme.radioButton.borderColorColor};
   border-radius: 50%;
`;

export const RadioFilled = styled("div")`
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   width: 50%;
   height: 50%;
   border-radius: 50%;
   background-color: ${({ theme }) => theme.radioButton.fillColor};

   animation: appear-animation-radio 200ms ease-out;
   animation-play-state: running;

   @keyframes appear-animation-radio {
      from {
         transform: scale(0);
      }
      to {
         transform: scale(1);
      }
   }
`;

export const RadioEmpty = styled(RadioFilled)`
   animation: appear-animation-empty-radio 400ms ease-out;
   animation-play-state: running;
   transform: scale(0);

   @keyframes appear-animation-empty-radio {
      from {
         transform: scale(1);
      }
      to {
         transform: scale(0);
      }
   }
`;

export const RadioLabel = styled(Label10)`
   position: relative;
   top: 2px;
   cursor: pointer;
   user-select: none;
`;
