import { useCallback, useState } from "react";

export function useFixedBottomRef() {
   const [fixedBottomRef, setFixedBottomRef] = useState<React.RefObject<HTMLDivElement>>();

   const handleFixedBottomRef = useCallback((ref: React.RefObject<HTMLDivElement>) => {
      setFixedBottomRef(ref);
   }, []);

   return { fixedBottomRef, handleFixedBottomRef };
}
