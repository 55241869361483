import styled from "styled-components";
import { Label, Label10 } from "../Texts/Texts";

export const RadioGroupContainer = styled("div")`
   display: flex;
   flex-wrap: wrap;
   column-gap: 30px;
`;

export const RadioGroupLabel = styled(Label10)`
   margin-bottom: 12px;
`;

export const RadioGroupExtraLabel = styled(Label)`
   margin-bottom: 8px;
`;
