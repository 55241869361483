import styled from "styled-components";
import { Column } from "../Column/Column";
import { RowCentered } from "../Row/Row";

export const SliderContainer = styled(Column)``;
export const ButtonsContainer = styled("div")`
   display: flex;
   flex-direction: row;
   position: relative;
   column-gap: 20px;
   left: -6px;
   margin-top: 8px;
`;

export const SliderButton = styled(RowCentered).withConfig({
   shouldForwardProp: p => !["enabled"].includes(p)
})<{ enabled?: boolean }>`
   ${({ enabled }) => (enabled ? "& * { cursor: pointer; }" : "opacity: 0.5;")}

   & label {
      position: relative;
      top: 1px;
      user-select: none;
   }
`;
