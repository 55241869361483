import React, { FC } from "react";
import { Label10, Title5 } from "../../../../kanopy-ui/Texts/Texts";
import {
   SettingsHeaderContainer,
   UserAvatarContainer,
   UserAvatarStyled,
   TitleContainer
} from "./SettingsHeader.styles";

interface PropsSettingsHeader {
   title?: string;
   subtitle: string;
   label?: string;
   messageIcon?: string;
   name?: string;
}

const SettingsHeader: FC<PropsSettingsHeader> = props => {
   return (
      <SettingsHeaderContainer>
         <UserAvatarContainer>
            <UserAvatarStyled menuPlacement={"left"} />
         </UserAvatarContainer>
         <TitleContainer>
            <Title5>{props.title}</Title5>
            {props.label && (
               <>
                  <Label10>{props.name}</Label10>
               </>
            )}
         </TitleContainer>
      </SettingsHeaderContainer>
   );
};

export default SettingsHeader;
