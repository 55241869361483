import styled from "styled-components";
import { Column } from "../../kanopy-ui/Column/Column";

export const FormContainerForModal = styled("div")`
   padding-left: 19px;
   padding-right: 19px;
   padding-top: 14px;
   margin-top: 2px;
   overflow-y: auto;
   max-height: 65vh;

   p {
      letter-spacing: 0em;
   }
`;

export const ColumnContainerForModal = styled(Column)`
   width: 100%;
   height: 100%;
   display: grid;
   grid-template-rows: 1fr auto;
`;

export const FixedBottomContainer = styled("div")`
   z-index: 3;
`;
