/**
 * To change the theme read all the comments on this file it should contain all the steps required.
 * Also you need to make the following file changes:
 *    1. copy src/tools/themes/kanopyTheme/index.html to public/index.html
 *    2. copy src/tools/themes/kanopyTheme/manifest.json to public/manifest.json
 */

/**
 * Comment the imports of the not active themes to avoid loading images from unused themes which increases build size.
 */
// import kanopyTheme from "./kanopyTheme/kanopyTheme";
import lendiViewTheme from "./lendiViewTheme/lendiViewTheme";
// import mpTheme from "./mpTheme/mpTheme";

/**
 * Here uncomment the line of the theme you want to use.
 */
// export const currentTheme = kanopyTheme;
export const currentTheme = lendiViewTheme;
// export const currentTheme = mpTheme;
