import styled from "styled-components";

export const TooltipContainer = styled("div")`
   background: ${({ theme }) => theme.card.backgroundColor};
   border-radius: ${({ theme }) => theme.card.borderRadius};
   box-shadow: ${({ theme }) => theme.card.shadow};
   padding: 5px 9px;

   span {
      font-weight: bold;
   }
`;
