import React, { FC, useEffect } from "react";
import Button from "../../../kanopy-ui/Button";
import { FormComponentProps, FormFromJsonVariant } from "../tools/typings";
import { useFormHasIncompleteField } from "../tools/useFormHasIncompleteField";

const ButtonSubmitFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form, formJson, formVariant } = formTools;

   const formHasRequiredIncompleteField = useFormHasIncompleteField(form, formJson);

   // const formIsEmpty = formElement.label === "Save & Continue" && formSaveButtonIncompleteField;

   const onClick = () => {
      formTools.submitFormTools.submitForm({
         submitUrl: formElementJson?.api?.url,
         redirectUrl: formElementJson?.next
      });
   };

   const handleKeypress = (e: any) => {
      // TODO: Maybe this if should be inside submitForm
      if (formElementJson.trigger !== undefined && formHasRequiredIncompleteField === false) {
         //it triggers by pressing the enter key
         if (e.key === formElementJson.trigger) {
            onClick();
         }
      }
   };

   // TODO: This should be inside FormFromJson logic
   useEffect(() => {
      document.addEventListener("keydown", handleKeypress);
      return () => document.removeEventListener("keydown", handleKeypress);
   }, [handleKeypress]);

   return (
      <Button
         onClick={onClick}
         loading={formTools?.submitFormTools?.isLoading}
         disabled={
            formHasRequiredIncompleteField &&
            (formTools.formProps.disableSubmitButtonWhenFormIsIncomplete ?? true)
         }
         fullWidth={formVariant === FormFromJsonVariant.TwoColumns ? false : true}
      >
         {formElementJson.label}
      </Button>
   );
};

export default ButtonSubmitFromJson;
