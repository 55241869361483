import styled from "styled-components";
import Icon from "../Icon/Icon";

export const IconStyled = styled(Icon).withConfig({
   shouldForwardProp: p =>
      !["onIcon", "offIcon", "onToggle", "value", "triggerOnMouseDown"].includes(p)
})`
   cursor: pointer;

   &:active {
      transform: translateY(1.5px);
   }
`;
