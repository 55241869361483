import styled from "styled-components";
import Icon from "../Icon/Icon";

export const IconTooltip = styled(Icon)`
   transition: color 0.5s;
   cursor: pointer;
   :hover {
      color: ${({ theme }) => theme.colors.primary1};
   }
`;
