import styled from "styled-components";
import Card from "../../../../kanopy-ui/Card";
import { Paragraph, Title3 } from "../../../../kanopy-ui/Texts/Texts";

export const StepsContainer = styled("div")`
   padding-bottom: 26px;
`;

export const Top = styled("div")`
   padding: 16px 24px;
   padding-bottom: 25px;
`;

export const StepsCard = styled(Card)`
   min-width: 304px;
   max-width: 304px;
   min-height: 100px;
   max-height: 100%;
`;

export const Bottom = styled("div")`
   border-top: ${({ theme }) => theme.borders.border4};
   padding-top: 16px;
   padding-bottom: 16px;
   overflow-y: auto;
   height: fit-content;
`;

export const LenderLogo = styled("img")`
   height: 39px;
`;

export const StepsInformationContainer = styled("div")`
   display: flex;
   flex-direction: column;
   padding-top: 16px;
`;

export const StepsTitle = styled(Title3)`
   padding-left: 24px;
   padding-right: 24px;
   margin-bottom: 4px;
`;

export const ProgressTitle = styled(Title3)`
   margin-bottom: 4px;
`;

export const StepInformationItem = styled("div").withConfig({
   shouldForwardProp: p => !["active"].includes(p)
})<{ active?: boolean }>`
   display: flex;
   flex-direction: row;
   align-items: center;
   min-height: 58px;
   cursor: pointer;
   user-select: none;
   transition: background-color 150ms ease-in;

   ${props => (props.active ? `background-color: ${props.theme.colors.grey11};` : "")}
   ${props => (props.active ? `border-top: ${props.theme.borders.border4};` : "")}
   ${props => (props.active ? `border-bottom: ${props.theme.borders.border4};` : "")}

   &:hover {
      ${props => `background-color: ${props.theme.colors.grey11}`};
   }

   p {
      margin-bottom: 0;
      position: relative;
      top: 1px;
      letter-spacing: 0;
   }

   svg {
      margin-right: 14px;
   }

   span {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 24px;
      padding-right: 24px;
   }
`;

export const StepTitle = styled(Paragraph)`
   margin-bottom: 26px;
`;
