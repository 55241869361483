import { atom } from "jotai";
import { ButtonVariant } from "../../components/kanopy-ui/Button";

export const atomGlobalDialogs = atom<Array<GlobalDialog & { dialogId: string }>>([]);

export interface GlobalDialog {
   title?: string;
   text?: string;
   buttons?: GlobalDialogButton[];
   disableClosing?: boolean;
   disableCloseButton?: boolean;
}

export interface GlobalDialogButton {
   text?: string;
   onClick?: () => void;
   variant?: ButtonVariant;
}
