import styled from "styled-components";

export const TitleIllustrationContainer = styled("div")`
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   position: relative;
   max-width: 705px;
   padding-top: 33px;
`;

export const TextsContainer = styled("div")``;

export const IllustrationContainer = styled("div")``;

export const BackgroundIllustration = styled("img")`
   width: 100%;
   position: relative;
`;

export const CubeImg = styled("img")`
   position: absolute;
   right: 155px;
   top: -15px;
   z-index: 1;
`;
