import React, { FC } from "react";
import Button, { ButtonVariant } from "../../../kanopy-ui/Button";
import { executeFormAction } from "../tools/executeFormAction";
import { FormComponentProps } from "../tools/typings";

const ButtonFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;

   const handleClick = () => {
      // Execute all actions in the form element that should be executed on click
      formElementJson.actions?.forEach(
         action => action.event === "onClick" && executeFormAction(action, formTools)
      );
   };

   return (
      <Button onClick={handleClick} variant={formElementJson.variant as ButtonVariant}>
         {formElementJson.label}
      </Button>
   );
};

export default ButtonFromJson;
