import { useState } from "react";
import SettingsPageContainer from "../components/SettingsPageContainer/SettingsPageContainer";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import SettingsHeader from "../components/SettingsHeader/SettingsHeader";
import { ButtonManageAccount, MyAccountContainer } from "./MyAccount.styles";
import FinicityConnectModal from "../../../common/FinicityConnectModal/FinicityConnectModal";

const MyAccount = () => {
   const [finicityConnectModalOpen, setFinicityConnectModalOpen] = useState(false);

   return (
      <>
         <SettingsPageContainer>
            <SettingsHeader title={"My Account"} subtitle={""} />
            <MyAccountContainer>
               <FormFromJson
                  jsonUrl="settings/my-account"
                  renderFormComponent={({ formElementJson, formTools }) =>
                     formElementJson.type === "input[type=submit]" ? (
                        <ButtonManageAccount
                           onClick={() => setFinicityConnectModalOpen(true)}
                           loading={formTools.submitFormTools.isLoading}
                        >
                           {formElementJson.label}
                        </ButtonManageAccount>
                     ) : null
                  }
               />
            </MyAccountContainer>
         </SettingsPageContainer>
         <FinicityConnectModal
            visible={finicityConnectModalOpen}
            onFinish={() => setFinicityConnectModalOpen(false)}
            onClose={() => setFinicityConnectModalOpen(false)}
         />
      </>
   );
};

export default MyAccount;
