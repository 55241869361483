import React, { FC } from "react";
import { ChildrenContainer, CircleContainer } from "./CircularButton.styles";
import Icon, { IconImage } from "../Icon/Icon";
import { useTheme } from "styled-components";

interface PropsCircularButton extends React.HTMLAttributes<HTMLDivElement> {
   icon?: IconImage;
   size?: number;
   circleSize?: number;
   /** Some content may need a X offset */
   offsetX?: number;
   /** Some content may need a Y offset */
   offsetY?: number;
}

const CircularButton: FC<PropsCircularButton> = props => {
   const { icon, size = 23, circleSize = 40, offsetX, offsetY, ...otherProps } = props;
   const theme = useTheme();

   return (
      <CircleContainer size={circleSize} {...otherProps}>
         <ChildrenContainer offsetX={offsetX} offsetY={offsetY}>
            {icon && (
               <Icon iconImage={icon} size={size} color={theme.circularButton.enabled.color} />
            )}
            {props.children}
         </ChildrenContainer>
      </CircleContainer>
   );
};

export default CircularButton;
