import React, { FC } from "react";
import { BadgeCircle, BadgeContainer, BadgeLabel } from "./Badge.styles";
import { BadgePosition } from "./Badge.types";

interface PropsBadge {
   value: number;
   position?: BadgePosition;
   /* If the badge default position does not look good by default you can use these props to move it */
   offsetX?: number;
   offsetY?: number;
   size?: number;
   /* Font size in em units, default: 0.5 */
   fontSize?: number;
   showWhenValueIs0?: boolean;
}

const Badge: FC<PropsBadge> = props => {
   const {
      children,
      value,
      position = BadgePosition.TopRight,
      offsetX = 5,
      offsetY = 5,
      size = 22,
      fontSize = 0.8,
      showWhenValueIs0 = false
   } = props;

   const finalFontSize: number = value <= 99 ? fontSize : fontSize - 0.1;

   return (
      <BadgeContainer>
         {(value > 0 || showWhenValueIs0) && (
            <BadgeCircle offsetX={offsetX} offsetY={offsetY} position={position} size={size}>
               <BadgeLabel fontSize={finalFontSize}>{value <= 99 ? value : "99+"}</BadgeLabel>
            </BadgeCircle>
         )}
         {children}
      </BadgeContainer>
   );
};

export default Badge;
