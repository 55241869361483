import styled from "styled-components";
import Button from "../../../kanopy-ui/Button";
import Card from "../../../kanopy-ui/Card";
import { Paragraph, Title } from "../../../kanopy-ui/Texts/Texts";

export const WizardStartContainer = styled("div")`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 122px;
   width: 100%;
   max-width: 733px;

   p,
   h1 {
      text-align: center;
   }

   h1 {
      margin-bottom: 20px;
   }

   p {
      margin-bottom: 36px;
   }
`;

export const WizardStartTitle = styled(Title)`
   text-align: center;
   margin-bottom: 16px;
`;

export const WizardStartFooter = styled(Paragraph)`
   max-width: 435px;
   font-weight: 700;
   font-size: 14px;
   font-style: italic;
   margin-bottom: 26px;
`;

export const WizardStartButton = styled(Button)`
   width: 450px;
   margin-bottom: 24px;
`;

export const WizardIllustrationContainer = styled("div")`
   display: flex;
   justify-content: center;
   width: 100%;
`;

export const IconsIllustrationCard = styled(Card)`
   display: flex;
   flex-direction: row;
   position: relative;
   justify-content: flex-start;
   align-items: center;
   width: fit-content;
   padding: 32px 80px;
   margin-bottom: 38px;
`;

export const ArrowImg = styled("img")`
   height: 80px;
   margin-right: 10px;
`;

export const FileImg = styled("img")`
   position: absolute;
   left: 95px;
   top: 47px;
`;

export const LogoImg = styled("img")`
   height: 59px;
`;
