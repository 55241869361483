import { useEffect } from "react";
import { FieldValues, Path, UseFormSetError } from "react-hook-form";
import { JsonErrors } from "../api/forms/useSendJsonForm";

/**
 * Form errors that comes from server are added into the form validator errors so the behavior
 * is unified no mater the source of the errors
 */
export function useAddErrorsFromServer<T extends FieldValues>(
   serverErrors: Array<JsonErrors> | undefined,
   setError: UseFormSetError<T>
) {
   useEffect(() => {
      if (serverErrors === undefined) {
         return;
      }

      serverErrors.map(formElement => {
         if (formElement.field && formElement.err) {
            const message = formElement.err;
            setError(
               formElement.field as Path<T>,
               { type: "server", message },
               { shouldFocus: true }
            );
         }
         return formElement;
      });
   }, [serverErrors, setError]);
}
