import { useEffect, useState } from "react";
import { FormGroupJson } from "../../../../tools/api/forms/useServerJsonForm";

export function useHiddenFormsTools(formGroups: FormGroupJson[] | undefined): HiddenFormsTools {
   const [formsHidden, setFormsHidden] = useState<boolean[]>([]);

   /**
    * This effect sets the starting map of which forms should be hidden
    */
   useEffect(() => {
      if (formGroups === undefined) {
         return;
      }

      const formGroupsWithHiddenLogic = formGroups.filter(
         formGroup => formGroup.hiddenFormId !== undefined
      );

      const formsHiddenRecord: boolean[] = [];
      formGroupsWithHiddenLogic.forEach(
         formGroup => (formsHiddenRecord[formGroup.hiddenFormId!] = formGroup.hidden ?? false)
      );

      setFormsHidden(formsHiddenRecord);
   }, [formGroups]);

   const formShouldBeHidden = (hiddenFormId: number | undefined): boolean => {
      if (hiddenFormId === undefined) {
         return false;
      }

      return formsHidden[hiddenFormId] ?? true;
   };

   const revealNextForm = () => {
      const newFormsHidden = [...formsHidden];
      const nextHiddenForm = formsHidden.findIndex(value => value === true);
      if (nextHiddenForm === -1) {
         return;
      }

      newFormsHidden[nextHiddenForm] = false;
      setFormsHidden(newFormsHidden);

      /**
       * This solution is temporal, in a next iteration this can be replaced by a
       * logic that specifically focuses the new revealed form using a ref or similar
       * */
      setTimeout(
         () => window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" }),
         100
      );
   };

   return { formShouldBeHidden, revealNextForm };
}

/**
 * Some forms parts require to be hidden and revealed with a button.
 * Form groups that contains the json property hiddenFormId needs to have this "hide and reveal" logic,
 * this object contains the functions of this feature.
 */
export interface HiddenFormsTools {
   formShouldBeHidden: (hiddenFormId: number | undefined) => boolean;
   revealNextForm: () => void;
}
