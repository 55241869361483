import styled from "styled-components";
import Icon from "../Icon/Icon";
import ToggleIconButton from "../ToggleIconButton/ToggleIconButton";
import { BorderVariant, InputColorVariant, InputVariant } from "./Input";

export const InputContainer = styled("div")`
   display: flex;
   flex-direction: column;
   position: relative;
   row-gap: 3px;
   width: 100%;
   margin-bottom: ${({ theme }) => theme.spacing(1, "px")};
`;

export const InputTextContainer = styled("div").withConfig({
   shouldForwardProp: p => !["error", "colorVariant", "borderVariant", "readOnly"].includes(p)
})<{
   error?: string | undefined;
   colorVariant: InputColorVariant;
   borderVariant: BorderVariant;
   readOnly?: boolean;
}>`
   display: flex;
   flex-direction: row;
   align-items: center;
   transition: border 500ms;
   border: ${props =>
      props.error === undefined ? props.theme.input.border : props.theme.input.borderError};
   ${props =>
      props.borderVariant === BorderVariant.Bottom
         ? `border: none; input {padding: 0} border-bottom: ${
              props.error === undefined ? props.theme.input.border : props.theme.input.borderError
           };`
         : ""}
   border-radius: ${({ theme }) => theme.input.borderRadius};
   background-color: ${({ colorVariant, theme }) =>
      colorVariant === InputColorVariant.Transparent ? "unset" : theme.input.backgroundColor};
   ${({ readOnly, theme }) =>
      readOnly ? `background-color: ${theme.input.backgroundColorReadOnly};` : ""}
   ${({ readOnly, theme }) => (readOnly ? `border-color: ${theme.input.borderColorReadOnly};` : "")}
   padding-right: 12px;
   column-gap: 6px;
`;

export const InputStyled = styled("input").withConfig({
   shouldForwardProp: p => !["error", "variant", "readOnly"].includes(p)
})<{ error?: string | undefined; variant: InputVariant; readOnly?: boolean }>`
   /* ${({ variant }) => (variant === InputVariant.Long ? "max-width: 400px;" : "")} */

   height: 44px;
   padding: 17px 10px 17px 22px;
   flex-grow: 1;

   font-family: ${({ theme }) => theme.input.text.fontFamily};
   color: ${({ theme }) => theme.input.text.color};
   font-size: ${({ theme }) => theme.input.text.fontSize}px;
   font-weight: ${({ theme }) => theme.input.text.weight};
   line-height: ${({ theme }) => theme.input.text.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.input.text.letterSpacing}em;
   ${({ readOnly, theme }) =>
      readOnly && theme.input.text.colorReadOnly ? `color: ${theme.input.text.colorReadOnly};` : ""}
   border: unset;
   border-radius: unset;
   background-color: unset;
`;

export const ErrorText = styled("div")`
   color: red;
   font-size: 14px;
   position: absolute;
   bottom: -23px;
   z-index: 1;
   animation: error-appear-animation 200ms ease-out;
   animation-play-state: running;

   @keyframes error-appear-animation {
      from {
         transform: translateY(-25px);
      }
      to {
         transform: translateY(0px);
      }
   }
`;

export const ToggleIconButtonStyled = styled(ToggleIconButton)`
   transition: color 350ms;
   color: ${({ theme }) => theme.input.iconColor};

   &:hover {
      color: ${({ theme }) => theme.input.text.color};
   }
`;

export const ErrorIcon = styled(Icon)`
   transition: all 350ms;
   object-position: 0% 0%;

   svg {
      width: 18px;
      height: 18px;
   }

   color: ${({ theme }) => theme.input.iconColorError};

   animation: icon-appear-animation 300ms ease-out;
   animation-play-state: running;
   @keyframes icon-appear-animation {
      from {
         transform: scale(0);
      }
      to {
         transform: scaly(1);
      }
   }
`;
