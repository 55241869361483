import React, { FC } from "react";
import { IconImage } from "../../../../../kanopy-ui/Icon/Icon";
import { Label10, Label9 } from "../../../../../kanopy-ui/Texts/Texts";
import {
   CashFlowSummaryItem,
   CashFlowContainer,
   CashFlowTitle,
   ItemsContainer,
   TooltipIcon
} from "./CashFlowCard.styles";

interface PropsCashFlowCard {
   title?: string;
   tooltip?: string;

   data: {
      label: string;
      value: string;
   }[][];
}

const CashFlowCard: FC<PropsCashFlowCard> = props => {
   return (
      <CashFlowContainer>
         <CashFlowTitle>{props.title ?? ""}</CashFlowTitle>
         <TooltipIcon
            data-tip={`${props.tooltip}`}
            data-for={`headerTooltip`}
            iconImage={IconImage.BiInfoCircle}
         ></TooltipIcon>
         <ItemsContainer>
            {props.data?.map((itemGroup, i) => (
               <div key={i}>
                  {itemGroup?.map((item, u) => (
                     <CashFlowSummaryItem
                        drawLine={i !== props.data.length - 1 || u !== itemGroup.length - 1}
                        key={u}
                     >
                        <Label10>{item.label}</Label10>
                        <Label9>{item.value}</Label9>
                     </CashFlowSummaryItem>
                  ))}
               </div>
            ))}
         </ItemsContainer>
      </CashFlowContainer>
   );
};

export default CashFlowCard;
