import styled from "styled-components";

export const AddPanelButtonContainer = styled("div").withConfig({
   shouldForwardProp: p => !["hideButton"].includes(p)
})<{ hideButton?: boolean }>`
   display: flex;
   justify-content: flex-end;
   align-items: center;
   cursor: pointer;
   user-select: none;
   width: 288px;
   height: 103px;

   span {
      position: relative;
      top: 1.35px;
   }

   ${({ hideButton }) => (hideButton ? "visibility: hidden" : "")}
`;

export const AddPanelButtonIcon = styled("img")`
   height: 16px;
   margin-right: 8px;
`;
