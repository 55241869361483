import styled from "styled-components";
import { Column } from "../../../../kanopy-ui/Column/Column";

export const ChartsContainer = styled(Column)`
   row-gap: 30px;
`;

export const CurrentContainer = styled("div")`
   display: flex;
   flex-direction: column;
   row-gap: 32px;
   max-width: 1592px;
`;

export const InfoCardsContainer = styled("div")`
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr;
   gap: ${({ theme }) => theme.spacing(3, "px")};

   @media only screen and (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
   }

   @media only screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
   }
`;

export const SummaryContainer = styled("div")`
   display: flex;
   flex-direction: row;
   column-gap: 24px;
   row-gap: 24px;
   width: 100%;

   @media ${({ theme }) => theme.mediaQueries.tabletL} {
      flex-direction: column;
   }
`;
export const CardsColumnContainer = styled("div")`
   display: flex;
   flex-direction: column;
   height: fit-content;
   column-gap: 24px;
   row-gap: 24px;
   width: 100%;
   width: 50%;

   @media ${({ theme }) => theme.mediaQueries.tabletL} {
      flex-direction: column;
      width: 100%;
   }
`;
