import React from "react";
import AutoFormatInput, { InputValue } from "../AutoFormatInput/AutoFormatInput";
import Input from "../Input/Input";
import { CurrencySymbol } from "./CurrencyInput.styles";

export interface PropsCurrencyInput extends React.ComponentProps<typeof Input> {
   currencySymbol?: string;
   locale?: string | string[] | undefined;
   minimumFractionDigits?: number;
   maximumFractionDigits?: number;
}

const CurrencyInput = React.forwardRef<HTMLInputElement, PropsCurrencyInput>((props, ref) => {
   const {
      defaultValue,
      locale = "en-US",
      currencySymbol = "$",
      minimumFractionDigits,
      maximumFractionDigits = 0,
      ...restOfProps
   } = props;

   const formatNumber = (value: InputValue) => {
      if (value === undefined || value === "") {
         return { value: "" };
      }

      value = String(value);

      /*
       * This removes all except numbers. This is important because to avoid infinite loop
       * it's important to remove characters added by this function in a previous execution.
       */
      const valueCleaned = value.replace(/[^0-9]/g, "");
      const valueAsNumber = Number(valueCleaned) || 0;
      const valueFormatted = valueAsNumber.toLocaleString(locale, {
         minimumFractionDigits,
         maximumFractionDigits
      });

      return { value: valueFormatted };
   };

   return (
      <AutoFormatInput
         {...restOfProps}
         defaultValue={defaultValue}
         ref={ref}
         formatFunc={formatNumber}
         renderLeftIcon={
            currencySymbol ? () => <CurrencySymbol>{currencySymbol}</CurrencySymbol> : undefined
         }
      />
   );
});

export default CurrencyInput;
