import React, { FC, useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { InputContainer, Input1, DropDownItems } from "./LocationSearchInput.styles";
import { FormComponentProps } from "../tools/typings";
import { Label } from "../../../kanopy-ui/Texts/Texts";

// TODO: Re-implement
const LocationSearchInput: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form } = formTools;

   const [selectedAddress, setAddress] = useState<string>(formElementJson.value);

   useEffect(() => {
      form.setValue(formElementJson.name, selectedAddress);
   }, [selectedAddress]);

   const handleSelect = async (address: any, placeId: any) => {
      // const results = await geocodeByAddress(address);
      // const latLng = await getLatLng(results[0]);
      const [place] = await geocodeByPlaceId(placeId);
      const { long_name: postalCode = "" } =
         place.address_components.find(c => c.types.includes("postal_code")) || {};
      const { long_name: City = "" } =
         place.address_components.find(c => c.types.includes("locality")) || {};
      const { long_name: State = "" } =
         place.address_components.find(c => c.types.includes("administrative_area_level_1")) || {};
      const { long_name: Street = "" } =
         place.address_components.find(c => c.types.includes("street_number")) || {};

      const { long_name: Route = "" } =
         place.address_components.find(c => c.types.includes("route")) || {};
      setAddress(`${Street} ${Route}`);
      form.setValue(formElementJson.component!.street_number, `${Street} ${Route}`);
      form.setValue(formElementJson.component!.postal_code, postalCode);
      form.setValue(formElementJson.component!.locality, City);
      form.setValue(formElementJson.component!.administrative_area_level_1, State);
   };

   return (
      <PlacesAutocomplete value={selectedAddress} onChange={setAddress} onSelect={handleSelect}>
         {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <InputContainer>
               {formElementJson.label && <Label>{formElementJson.label}</Label>}

               <Input1
                  {...getInputProps({
                     placeholder: "Search Places ...",
                     className: "location-search-input"
                  })}
               />
               <DropDownItems className="autocomplete-dropdown-container">
                  {suggestions.map(suggestion => {
                     const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                     return (
                        <div
                           {...getSuggestionItemProps(suggestion, {
                              className
                           })}
                        >
                           <span>{suggestion.description}</span>
                        </div>
                     );
                  })}
               </DropDownItems>
            </InputContainer>
         )}
      </PlacesAutocomplete>
   );
};

export default LocationSearchInput;
