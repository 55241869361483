import React, { FC, useState } from "react";
import { Placement } from "tippy.js";
import {
   ChildrenContainer,
   ItemContainerLink,
   ItemsContainer,
   MenuContainer
} from "./ContextMenu.styles";
import Floating from "../Floating/Floating";

export interface ContextMenuItemData {
   render: () => React.ReactNode;
   /** The path that will be passed to the link element */
   onClickRedirectTo?: string;
   /** If you want a redirect on click use onClickRedirectTo prop instead of this, this prop is useful when you want to execute other logic after click */
   onMouseUp?: () => void;
   /** If the menu item is not interactive set this to true to disable the interaction features */
   notInteractive?: boolean;
   disableMenuCloseOnClick?: boolean;
   disableSeparatorLine?: boolean;
   disablePadding?: boolean;
}

export interface PropsContextMenu {
   menuItems: ContextMenuItemData[];
   className?: string;
   offsetY?: number;
   placement?: Placement;
   renderHeader?: () => React.ReactNode;
   /** Matches the width of the floating element to the trigger element */
   matchWidth?: boolean;
}

const ContextMenu: FC<PropsContextMenu> = props => {
   const {
      children,
      menuItems,
      className,
      offsetY = 10,
      placement = "auto",
      renderHeader,
      matchWidth
   } = props;
   const [visible, setVisible] = useState(false);

   const handleMenuItemClick = (menuItem: ContextMenuItemData) => {
      // This setTimeout hack is here just to give more priority to the "to" parameter in the ItemContainerLink which changes the url and it should have more priority than other code that also maybe changes the url.
      setTimeout(() => {
         if (!menuItem.disableMenuCloseOnClick) {
            setVisible(false);
         }
         menuItem.onMouseUp?.();
      }, 0);
   };

   const renderMenu = () => {
      return (
         <MenuContainer className={className}>
            {renderHeader?.()}
            <ItemsContainer>
               {menuItems.map((menuItem, i) => (
                  <ItemContainerLink
                     key={i}
                     isFirstItem={i === 0}
                     isLastItem={i === menuItems.length - 1}
                     disableSeparatorLine={menuItem.disableSeparatorLine}
                     disablePadding={menuItem.disablePadding}
                     onMouseUp={() => handleMenuItemClick(menuItem)} // onMouseUp is used here instead of onClick to not override middle click, middle click is used by the "to" parameter below
                     to={menuItem.onClickRedirectTo ? menuItem.onClickRedirectTo : ""}
                     notInteractive={menuItem.notInteractive}
                  >
                     {menuItem.render()}
                  </ItemContainerLink>
               ))}
            </ItemsContainer>
         </MenuContainer>
      );
   };

   return (
      <Floating
         visible={visible}
         onClose={() => setVisible(false)}
         content={renderMenu}
         offsetY={offsetY}
         placement={placement}
         matchWidth={matchWidth}
      >
         <ChildrenContainer onClick={() => setVisible(!visible)}>{children}</ChildrenContainer>
      </Floating>
   );
};

export default ContextMenu;
