import { NavigateFunction, useNavigate } from "react-router-dom";
import { useLogout } from "../../../../tools/api/tools/useLogout";
import { GlobalDialog } from "../../../../tools/atoms/atomGlobalDialogs";
import { useDialog } from "../../GlobalDialogs/tools/useDialog";
import { OpenWindowParams, useWindow } from "../../Window/useWindow";
import { FormJson } from "../../../../tools/api/forms/useServerJsonForm";
import { HiddenFormsTools, useHiddenFormsTools } from "./useHiddenFormsTools";
import { SubmitFormTools, useSubmitForm } from "./useSubmitForm";
import { SkipFormTools, useSkipForm } from "./useSkipForm";
import { useCancelForm } from "./useCancelForm";
import { Form, FormFromJsonVariant, PropsFormFromJson } from "./typings";
import { useFormFirstTitle } from "./useFormFirstTitle";

/**
 * This hook returns an object containing data and functions of the form and functions to control the whole app.
 * Is meant to be passed to form components so they can have access to all they need.
 * The parameters of this hook are the requirements to create this tools object and it should be called only
 * in the FormFromJson component, the returned object is what is passed to the form components in a prop.
 */
export function useFormTools(params: UseFormActionsParams): FormTools {
   const { formJson, form, formProps } = params;
   const { logout } = useLogout();
   const { openDialog } = useDialog();
   const { openWindow } = useWindow();
   const navigate = useNavigate();
   const hiddenFormsTools = useHiddenFormsTools(formJson?.formGroups);
   const submitFormTools = useSubmitForm({ formJson, form, formProps });
   const skipFormTools = useSkipForm({ formJson, formProps });
   const { cancelForm } = useCancelForm({ formProps });
   const firstTitle = useFormFirstTitle(formJson, formProps.onFormTitleReceived);
   const textToNotRender = formProps.disableFirstTitleRendering ? firstTitle : undefined;
   const formVariant =
      formProps.variant ??
      (formProps.renderLeftColumn != null
         ? FormFromJsonVariant.TwoColumns
         : FormFromJsonVariant.Normal);

   return {
      logout,
      openDialog,
      openWindow,
      navigate,
      cancelForm,
      form,
      formJson,
      formProps,
      formVariant,
      textToNotRender,
      submitFormTools,
      skipFormTools,
      hiddenFormsTools
   };
}

export interface UseFormActionsParams {
   formJson: FormJson | undefined;
   form: Form;
   formProps: PropsFormFromJson;
}

/**
 * FormTools is an object containing all the functions and form data that the form components will need to read or execute.
 */
export interface FormTools {
   cancelForm: () => void;
   logout: () => void;
   openDialog: (dialogProps: GlobalDialog) => void;
   openWindow: (params: OpenWindowParams) => void;
   navigate: NavigateFunction;
   form: Form;
   formJson: FormJson | undefined;
   formProps: PropsFormFromJson;
   formVariant: FormFromJsonVariant;
   textToNotRender: string | undefined;
   submitFormTools: SubmitFormTools;
   skipFormTools: SkipFormTools;
   hiddenFormsTools: HiddenFormsTools;
}
