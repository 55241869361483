import React, { FC, useState } from "react";
import Checkbox from "../Checkbox/Checkbox";
import { Column } from "../Column/Column";
import { LabelError } from "../Texts/Texts";
import { LabelAndValue } from "../tools/types";
import {
   CheckBoxGroupContainer,
   CheckBoxGroupExtraLabel,
   CheckBoxGroupLabel
} from "./CheckboxGroup.styles";

export interface PropsCheckboxGroup {
   label?: string;
   extraLabel?: string;
   error?: string;
   options: LabelAndValue[];
   selectedByDefault?: string[];
   onChange: (selectedValues: string[]) => void;
}

const CheckboxGroup: FC<PropsCheckboxGroup> = props => {
   const { label, extraLabel, options, selectedByDefault, error, onChange } = props;
   const [selectedValues, setSelectedValues] = useState<Set<string>>(
      new Set(selectedByDefault ?? [])
   );

   const handleCheckboxClick = (value: string, checked: boolean) => {
      const newSelectedValues = new Set(selectedValues);

      if (checked) {
         newSelectedValues.add(value);
      } else {
         newSelectedValues.delete(value);
      }

      setSelectedValues(newSelectedValues);
      onChange(Array.from(newSelectedValues.values()));
   };

   return (
      <Column>
         {label && <CheckBoxGroupLabel>{label}</CheckBoxGroupLabel>}
         {extraLabel && <CheckBoxGroupExtraLabel>{extraLabel}</CheckBoxGroupExtraLabel>}
         <CheckBoxGroupContainer>
            {options.map(option => (
               <Checkbox
                  label={option.label}
                  checked={selectedValues.has(option.value)}
                  onChange={checked => handleCheckboxClick(option.value, checked)}
                  key={option.value}
               />
            ))}
         </CheckBoxGroupContainer>
         {error && <LabelError>{error}</LabelError>}
      </Column>
   );
};

export default CheckboxGroup;
