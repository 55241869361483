import { Form } from "../../components/common/FormFromJson/tools/typings";
import { FormElementJson } from "../api/forms/useServerJsonForm";
import { httpRequest } from "../api/tools/httpRequest";

enum RemoteValidationStatus {
   Error,
   Ok
}

export function handleRemoteValidation(form: Form, formElement: FormElementJson) {
   if (!formElement.remote_api) {
      return;
   }

   const currentValue = form.getValues()[formElement.name];

   httpRequest<null, { status: RemoteValidationStatus }>({
      url: formElement.remote_api + currentValue,
      method: "POST"
   }).then(response => {
      if (response.status !== RemoteValidationStatus.Error) {
         return;
      }

      form.setError(formElement.name, {
         type: "server",
         message: formElement.remote_api_error
      });
   });
}
