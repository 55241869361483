import React, { FC } from "react";
import { IconData } from "../../../../tools/api/tools/typings";
import { IconImage } from "../../Icon/Icon";
import OpacitySwitcher from "../../OpacitySwitcher/OpacitySwitcher";
import { DrawerButtonContainer, ItemIcon, DrawerButtonLabel } from "./DrawerButton.styles";

interface PropsDrawerButton {
   icon: IconData;
   label: string;
   active: boolean;
   hovered: boolean;
   onClick?: () => void;
   onMouseEnter?: () => void;
   onMouseLeave?: () => void;
}

const DrawerButton: FC<PropsDrawerButton> = props => {
   const { icon, label, active, hovered, onClick, onMouseEnter, onMouseLeave } = props;

   return (
      <DrawerButtonContainer
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
         onClick={onClick}
         active={active}
      >
         <OpacitySwitcher
            componentSwitch={Boolean(active || hovered)}
            component1={() => <ItemIcon iconImage={icon?.hovered as IconImage} />}
            component2={() => <ItemIcon iconImage={icon?.normal as IconImage} />}
         />
         <DrawerButtonLabel>{label}</DrawerButtonLabel>
      </DrawerButtonContainer>
   );
};

export default DrawerButton;
