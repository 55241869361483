import styled from "styled-components";
import { HeaderVariant } from "../Header/Header";
import { PageContainerVariant } from "./PageContainer";

export const PageStructure = styled("div")
   .attrs(props => ({
      backgroundImage: "",
      ...props
   }))
   .withConfig({
      shouldForwardProp: p => !["variant", "backgroundImage"].includes(p)
   })<{ variant?: PageContainerVariant }>`
   min-height: 100vh;
   display: grid;
   grid-template-rows: auto 1fr;
   position: relative;

   background-repeat: no-repeat;
   background-position: center 80%;
   background-size: contain;
   background-color: ${({ variant, theme }) =>
      variant === PageContainerVariant.LoggedIn
         ? theme.colors.primaryWashedMore
         : theme.colors.background1};

   ${props => props.variant === PageContainerVariant.Wizard && "margin-bottom: 100px;"};
   ${props => (props.variant === PageContainerVariant.Variant2 ? "padding: 0 40px" : "")};
   ${({ backgroundImage }) => backgroundImage && `background-image: url(${backgroundImage})`};
   font-family: ${props => props.theme.fonts.font1};
`;

export const Children = styled("div")
   .attrs(props => ({
      backgroundImage: "",
      ...props
   }))
   .withConfig({
      shouldForwardProp: p => !["variant", "headerVariant", "backgroundImage"].includes(p)
   })<{
   variant?: PageContainerVariant;
   headerVariant: HeaderVariant | undefined;
}>`
   position: relative;
   background-repeat: no-repeat;
   background-position: center center;
   background-size: auto 100%;
   ${({ backgroundImage }) => backgroundImage && `background-image: url(${backgroundImage})`};
   ${props =>
      props.variant === PageContainerVariant.InformationStep &&
      `
      display: grid;
      justify-items: center;
   `};

`;

export const AbsoluteCenter = styled("div").attrs(props => ({
   top: "50%",
   ...props
}))`
   display: flex;
   flex-direction: row;
   justify-content: center;

   @media only screen and (min-width: 600px) {
      position: absolute;
      left: 50%;
      top: ${({ top }) => top};
      transform: translate(-50%, -50%);
   }
`;
