import React, { FC } from "react";
import { IconImage } from "../../../../../kanopy-ui/Icon/Icon";
import { ProgressBarVariant } from "../../../../../kanopy-ui/ProgressBar/ProgressBar";
import {
   ExpenseCashLabel,
   ExpenseLabel,
   ExpenseProgressBar,
   ItemContainer,
   ItemsContainer,
   TopExpensesContainer,
   TopExpensesTitle,
   TooltipIcon
} from "./TopExpensesCard.styles";

interface PropsTopExpensesCard {
   title: string;
   tooltip: string;
   data: Array<{ label: string; value: string; percent: number }>;
}

const TopExpensesCard: FC<PropsTopExpensesCard> = props => {
   return (
      <TopExpensesContainer>
         <TopExpensesTitle>{props.title}</TopExpensesTitle>
         <TooltipIcon
            data-tip={`${props.tooltip}`}
            data-for={`headerTooltip`}
            iconImage={IconImage.BiInfoCircle}
         ></TooltipIcon>
         <ItemsContainer>
            {props.data?.map((item, i) => (
               <ItemContainer key={i}>
                  <ExpenseLabel>{item.label}</ExpenseLabel>
                  <ExpenseCashLabel>{item.value}</ExpenseCashLabel>
                  <ExpenseProgressBar
                     percentage={item.percent * 100}
                     showText={false}
                     variant={ProgressBarVariant.Dashboard}
                  />
               </ItemContainer>
            ))}
         </ItemsContainer>
      </TopExpensesContainer>
   );
};

export default TopExpensesCard;
