import { useEffect } from "react";
import { FormGroupJson } from "../../../../tools/api/forms/useServerJsonForm";
import { getAllFormElements } from "./getAllFormElements";
import { Form } from "./typings";

/**
 * Sets default values from the json to the form object (when there is any).
 * This means that input fields (for example) will be filled with content when initializing a
 * form, this is needed so the user can open an already completed form and edit it.
 */
export function useSetDefaultValues(form: Form, formGroups: FormGroupJson[] | undefined) {
   useEffect(() => {
      if (!formGroups) {
         return;
      }

      getAllFormElements(formGroups).forEach(element => {
         if (element.name !== undefined && element.value !== undefined) {
            // The reason shouldValidate = true here: this setValue() makes changes in the form components and it won't trigger onChange on them unless we tell the library to do it (because it uses the ref and ref editing does not trigger events), for some reason shouldValidate as true is the only way I found to tell the library to do it. Without this the form components that needs the onChange event may have issues, for example AutoFormatInput will not render the format correctly after a modal close and reopen.
            form.setValue(element.name, element.value, { shouldValidate: true });
         }
      });
   }, [formGroups]);
}
