import React, { FC } from "react";
import { Paragraph7 } from "../../../../../kanopy-ui/Texts/Texts";
import {
   InfoCardContainer,
   InfoCardIconContainer,
   InfoCardTextPart,
   InfoCardTitle,
   InfoCardSubTitle,
   ReactTooltipStyled
} from "./InfoCard.styles";
import ReactTooltip from "react-tooltip";
import { IconImage } from "../../../../../kanopy-ui/Icon/Icon";
import { IconTooltip } from "../../../../../kanopy-ui/IconTooltip/IconTooltip.styles";

interface PropsInfoCard {
   icon: string;
   title: string;
   subtitle: string;
   description: string;
   tooltip: string;
   key: number;
}

const InfoCard: FC<PropsInfoCard> = props => {
   return (
      <InfoCardContainer>
         <InfoCardIconContainer>
            <img src={props.icon} alt={props.title} />
         </InfoCardIconContainer>
         <InfoCardTextPart>
            <InfoCardTitle>{props.title}</InfoCardTitle>
            <InfoCardSubTitle>{props.subtitle}</InfoCardSubTitle>
            <Paragraph7>{props.description}</Paragraph7>
         </InfoCardTextPart>

         <IconTooltip
            data-tip={`${props.tooltip}`}
            data-for={`headerTooltip`}
            iconImage={IconImage.BiInfoCircle}
         />
         <ReactTooltipStyled>
            <ReactTooltip
               id={`headerTooltip`}
               place="bottom"
               effect="float"
               backgroundColor="white"
               textColor="#404040"
               border={true}
               borderColor="#a7afb2"
            />
         </ReactTooltipStyled>
      </InfoCardContainer>
   );
};

export default InfoCard;
