import styled from "styled-components";

export const IconContainer = styled("div").withConfig({
   shouldForwardProp: p => !["color"].includes(p)
})<{ color?: string }>`
   svg {
      display: block;
      ${({ color }) => (color ? `color: ${color};` : "")};
   }
`;
