import React, { FC } from "react";
import DashboardPageContainer from "../components/DashboardPageContainer/DashboardPageContainer";
import DashboardHeader from "../components/DashboardHeader/DashboardHeader";
import Tabs, {
   RightContentContainer,
   UnderLine,
   Tab,
   TabPanel,
   TabList
} from "../../../kanopy-ui/Tabs";
import { Label10, Label16 } from "../../../kanopy-ui/Texts/Texts";
import ChartsTabContent from "./ChartsTabContent/ChartsTabContent";
import FutureTabContent from "./FutureTabContent/FutureTabContent";
import MessageContent from "./MessageContent/MessageContent";
import {
   CurrentPageJson,
   DashboardFinancialHealthResponse,
   FuturePageJson,
   MessageContentJson,
   useDashboardFinancialHealth
} from "../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import LoadingSpinner from "../../../kanopy-ui/LoadingSpinner/LoadingSpinner";
import FormAsTab from "./LoanApplication/LoanApplication";
import { FormJson } from "../../../../tools/api/forms/useServerJsonForm";
import { readableDateFromUnixDate } from "../../../../tools/dates/readableDateFromUnixDate";
import { DateContainer, EditDateUpdateIcon } from "./FinancialHealth.styles";
import { IconImage } from "../../../kanopy-ui/Icon/Icon";
import { Container } from "../../../kanopy-ui/Container";

interface PropsHealth {
   withExternalData?: { data: DashboardFinancialHealthResponse };
}

const FinancialHealth: FC<PropsHealth> = props => {
   const usingExternalData = Boolean(props.withExternalData);

   let { data: homeData } = useDashboardFinancialHealth({
      options: { enabled: !usingExternalData }
   });

   const buttons = homeData?.pages
      ?.filter(page => page.tabTitle)
      .map(page => ({ label: page.tabTitle }));

   if (usingExternalData) {
      homeData = props.withExternalData?.data;
   }

   if (!homeData) {
      return <LoadingSpinner />;
   }

   return (
      <DashboardPageContainer>
         <DashboardHeader
            title={homeData.title}
            subtitle={homeData.paragraph}
            backButtonRedirectUrl={homeData.backButton?.link}
            label={homeData.contactClientLink?.label}
            email={homeData.contactClientLink?.mailTo}
            messageIcon={homeData.contactClientLink?.icon}
            name={homeData.contactClientLink?.name}
            clientId={homeData.clientId}
         />

         {homeData.pages && (
            <Tabs id="financial-tab">
               <Container noSideMargin>
                  <TabList>
                     {buttons?.map((button, index) => (
                        <Tab index={index} label={button.label} key={button.label} />
                     ))}
                     <RightContentContainer>
                        <DateContainer>
                           <EditDateUpdateIcon iconImage={IconImage.MdUpdate} />
                           <Label10>{homeData?.dateInfo?.label}</Label10>
                           <Label16>
                              {" "}
                              {readableDateFromUnixDate(homeData?.dateInfo?.date) ?? ""}
                           </Label16>
                        </DateContainer>
                     </RightContentContainer>
                  </TabList>
                  <UnderLine />
               </Container>
               {homeData.pages.map((page, i) => (
                  <React.Fragment key={page.tabTitle}>
                     {page.tabType === 1 && (
                        <TabPanel index={i}>
                           <ChartsTabContent serverData={page as CurrentPageJson} />
                        </TabPanel>
                     )}
                     {page.tabType === 2 && (
                        <TabPanel index={i}>
                           <FutureTabContent serverData={page as FuturePageJson} />
                        </TabPanel>
                     )}
                     {page.tabType === 3 ||
                        page.tabType === 4 ||
                        (page.tabType === 5 && (
                           <TabPanel index={i}>
                              <FormAsTab serverData={page as FormJson} />
                           </TabPanel>
                        ))}
                  </React.Fragment>
               ))}
            </Tabs>
         )}
         {homeData.page && <MessageContent serverData={homeData.page as MessageContentJson} />}
      </DashboardPageContainer>
   );
};

export default FinancialHealth;
