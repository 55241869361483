import styled from "styled-components";
import { Label7, Label8 } from "../../../../kanopy-ui/Texts/Texts";

export const OwnerInfoLine = styled("div")`
   display: flex;
   flex-direction: row;
`;

export const OwnerInfoLineLabel = styled(Label7)``;
export const OwnerInfoLineValue = styled(Label8)``;
export const OwnerInfoLineDivider = styled("div")`
   margin-left: 4px;
   margin-right: 4px;
`;
