import styled from "styled-components";

export const PageStructure = styled("div")`
   height: 100%;
`;

export const FormContainer = styled("div")`
   p {
      letter-spacing: 0em;
   }
   min-width: 420px;
`;

export const ScrollContainer = styled("div")`
   overflow-y: scroll;
   height: 100%;
   align-self: center;
`;

export const FixedBottomContainer = styled("div")`
   position: fixed;
   bottom: 0;
   width: 100%;
`;
