import styled from "styled-components";
import { Label10, Label9, Label2 } from "../../../../kanopy-ui/Texts/Texts";

export const TextElementContainer = styled("div").withConfig({
   shouldForwardProp: p => !["isLastElement"].includes(p)
})<{ isLastElement?: boolean }>`
   display: flex;
   flex-direction: column;
   width: 100%;
   padding-top: 20px;
   padding-bottom: 16px;
   padding-left: 26px;
   padding-right: 27px;
   margin-top: 5px;
   border-bottom: ${props => (!props.isLastElement ? props.theme.borders.border4 : "unset")};
`;

export const TextElementValue = styled(Label10)``;

export const TextElementLabel = styled(Label9)``;

export const AccountInfoContainer = styled("div").withConfig({
   shouldForwardProp: p => !["isLastElement"].includes(p)
})<{ isLastElement?: boolean }>`
   display: flex;
   flex-direction: row;
   width: 100%;
   padding-top: 26px;
   padding-bottom: 16px;
   padding-left: 26px;
   padding-right: 27px;
   margin-top: ${props => (props.isLastElement ? "5px" : "unset")};
   border-bottom: ${props => (!props.isLastElement ? props.theme.borders.border4 : "unset")};
`;
export const AccountInfoLabel = styled(Label9)``;

export const AccountInfoValue = styled(Label10)`
   padding-left: 50%;
   position: absolute;
`;
export const AccountInfoValue1 = styled(Label2)`
   color: #bdbdaa;
   font-size: 16px;
   padding-left: 50%;
   position: absolute;
`;
