import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import WizardLeftPanel from "../tools/WizardLeftPanel/WizardLeftPanel";
import { HeaderVariant } from "../../../common/Header/Header";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";
import Banner from "../../../kanopy-ui/Banner/Banner";
import Container from "../../../kanopy-ui/Container";

const GenericWizardStep: FC = () => {
   const location = useLocation();
   const [isShown, setIsShown] = useState<boolean>(true);
   const toggle = () => setIsShown(!isShown);

   return (
      <PageContainer
         variant={PageContainerVariant.Wizard}
         headerConfig={{ variant: HeaderVariant.RegisteredUser }}
      >
         <Container fixedWidth>
            <Banner
               isShown={isShown}
               message={"You can exit and continue later."}
               boldText={"The information entered will be saved"}
               onClose={toggle}
               style={{ borderRadius: "0px 0px 8px 8px" }}
            />
         </Container>
         <FormFromJson
            jsonUrl={location.pathname}
            // In the future left panel should have it's own endpoint because a silent refresh in this case is better
            renderLeftColumn={(formJson, form) => (
               <WizardLeftPanel leftPanelJson={formJson.leftPanel} formLeftPanel={form} />
            )}
         />
      </PageContainer>
   );
};

export default GenericWizardStep;
