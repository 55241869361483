import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useServerJsonForm } from "../../../tools/api/forms/useServerJsonForm";
import { useSetDefaultValues } from "./tools/useSetDefaultValues";
import { JsonFormData, PropsFormFromJson } from "./tools/typings";
import GlobalErrorLabel from "./GlobalErrorLabel/GlobalErrorLabel";
import FormContainerFromJson from "./FormContainerFromJson/FormContainerFromJson";
import FormGroupContainer from "./FormGroupContainer/FormGroupContainer";
import { FormComponentContainer } from "./formFromJson.styles";
import { useClearFormOnChange } from "./tools/useClearFormOnChange";
import { useFormTools } from "./tools/useFormTools";
import { renderFormComponent } from "./tools/renderFormComponent";
import { useFormEvents } from "./tools/useFormEvents";
import { useFixedBottomRef } from "./tools/useFixedBottomRef";

/**
 * Renders a form "Dynamically" based on information from server json. The json contains which form
 * components to render, where to send the form result and where to redirect after it's successfully sent,
 * among other things.
 *
 * This is required in this project because it has a lot of forms and making a form page component for each
 * form is not a good idea, it would create a lot of more work and be harder to maintain.
 *
 * There are some specific forms that are special and requires a specific behavior, styling or unique
 * "used once" form component, for cases like that we do create a page component but instead of implementing
 * another form we put this component inside of it because it has props to do special stuff externally, like
 * render a new form component, replace a existing one, change other behaviors, events and styles. This
 * should be the only component containing form logic in the whole project, it's flexible enough to be used
 * everywhere.
 *
 * We even used this component to implement the login (user and password), maybe it's not ideal but it's
 * interesting because the login form is no different than any other form so we did it for for consistency
 * and timing.
 */
const FormFromJson: FC<PropsFormFromJson> = props => {
   const usingAlreadyLoadedJson = props.alreadyLoadedJson != null;
   let request = useServerJsonForm(
      { apiFormAddress: props.jsonUrl },
      { options: { enabled: !usingAlreadyLoadedJson } }
   );
   let formJson = usingAlreadyLoadedJson ? props.alreadyLoadedJson!.json : request.data;
   // let formJson = test as unknown as FormResponse; // For testing you can uncomment this line and add your own hardcoded json file previously imported like this: import test from "./test.json"; You can do the same from outside passing the imported json in the prop: alreadyLoadedJson

   const form = useForm<JsonFormData>({ mode: "onBlur" });
   useClearFormOnChange(form, props.jsonUrl);
   useSetDefaultValues(form, formJson?.formGroups);
   useFormEvents(request, props, formJson);
   const formTools = useFormTools({ form, formJson, formProps: props });
   const { fixedBottomRef, handleFixedBottomRef } = useFixedBottomRef();

   return (
      <FormContainerFromJson
         formTools={formTools}
         onFixedBottomRef={handleFixedBottomRef}
         renderCustomContainer={props.renderCustomContainer}
         renderLeftColumn={props.renderLeftColumn}
      >
         {formJson?.formGroups?.map((formGroupJson, i) => (
            <React.Fragment key={`${JSON.stringify(formGroupJson)}${i}`}>
               <GlobalErrorLabel
                  form={form}
                  formGroup={formGroupJson}
                  errorText={formTools.submitFormTools.errorResponse}
               />
               <FormGroupContainer
                  layoutType={formGroupJson.layoutType}
                  hidden={formTools.hiddenFormsTools.formShouldBeHidden(formGroupJson.hiddenFormId)}
                  bottomContainerRef={fixedBottomRef}
                  variant={formTools.formVariant!}
                  isLastElement={i === formJson!.formGroups!.length - 2}
               >
                  {formGroupJson.formElements?.map((formElementJson, u) => (
                     <FormComponentContainer
                        layoutType={formGroupJson.layoutType}
                        key={`${JSON.stringify(formElementJson)}${u}`}
                     >
                        {renderFormComponent({
                           formElementJson,
                           formTools,
                           isLastElement: u === formGroupJson!.formElements!.length - 1
                        })}
                     </FormComponentContainer>
                  ))}
               </FormGroupContainer>
            </React.Fragment>
         ))}
      </FormContainerFromJson>
   );
};

export default FormFromJson;
