import { formComponentsList } from "./formComponentsList";
import { FormComponentProps } from "./typings";

/**
 * Renders the form component that the json requests.
 * @param props The props to pass to the form component, all form components have the same props so this will be passe to all of them.
 */
export function renderFormComponent(props: FormComponentProps) {
   const { formElementJson, formTools } = props;

   const replacement = formTools.formProps.renderFormComponent?.(props);
   if (replacement != null) {
      return <>{replacement}</>;
   }

   const Component = formComponentsList[formElementJson?.type];
   if (Component == null) {
      return null;
   }

   return <Component {...props} />;
}
