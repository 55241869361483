import { useEffect } from "react";
import { Form } from "./typings";

/**
 * When the json url changes it means there is a new form loaded, all previous form information should
 * be deleted to avoid issues like seeing form values from previous page form when the field has the same id.
 * This is needed because react re-uses the components so after a page change this component instance may be
 * re-used so it needs to have this kind of "form change" detection logic.
 */
export function useClearFormOnChange(form: Form, jsonUrl?: string) {
   useEffect(() => {
      form.reset();
   }, [jsonUrl]);
}
