import { FC } from "react";
import Header, { PropsHeader } from "../Header/Header";
import { Children, PageStructure } from "./PageContainer.styles";
import { currentTheme } from "../../../tools/themes/themeConfig";

/**
 * @TODO improve name convention for variant
 */
export enum PageContainerVariant {
   LoggedIn = "LoggedIn",
   LoggedOut = "LoggedOut",
   Wizard = "Wizard",
   Variant2 = "Variant2",
   Variant3 = "Variant3",
   InformationStep = "InformationStep"
}

interface PropsPageContainer {
   variant?: PageContainerVariant;
   headerConfig?: PropsHeader;
}

/**
 * @TODO rename PageContainer to NoLoginPageContainer
 */
const PageContainer: FC<PropsPageContainer> = props => {
   const { children, headerConfig, variant = PageContainerVariant.LoggedOut } = props;
   let backgroundAsset: string | undefined = undefined;

   const showHeader = variant !== PageContainerVariant.LoggedIn;

   switch (variant) {
      case PageContainerVariant.LoggedOut:
      case PageContainerVariant.InformationStep:
         backgroundAsset = currentTheme.images.background1;
         break;
      case PageContainerVariant.Variant2:
         break;
   }

   return (
      <PageStructure variant={variant}>
         {showHeader && <Header {...headerConfig} />}
         <Children
            variant={variant}
            headerVariant={headerConfig?.variant}
            backgroundImage={backgroundAsset}
         >
            {children}
         </Children>
      </PageStructure>
   );
};

export default PageContainer;
