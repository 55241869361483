import styled from "styled-components";
import ContextMenu from "../../kanopy-ui/ContextMenu/ContextMenu";
import ContextMenuItem from "../../kanopy-ui/ContextMenuItem/ContextMenuItem";
import { Label16 } from "../../kanopy-ui/Texts/Texts";

export const ContextMenuStyled = styled(ContextMenu)`
   position: relative;
   width: 400px;
   max-height: 65vh;
`;

export const NotificationItem = styled(ContextMenuItem).withConfig({
   shouldForwardProp: p => !["isRead"].includes(p)
})<{ isRead?: boolean }>`
   display: flex;
   align-items: center;
   ${({ isRead, theme }) => (!isRead ? `color: ${theme.colors.primary4};` : "")}
`;

export const MessageItem = styled(ContextMenuItem).withConfig({
   shouldForwardProp: p => !["isRead"].includes(p)
})<{ isRead?: boolean }>`
   display: flex;
   align-items: center;
   justify-content: center;
   ${({ isRead, theme }) => (!isRead ? `color: ${theme.colors.primary4};` : "")}
`;

export const NotificationTypeText = styled(Label16)`
   padding-left: 12px;
   color: ${({ theme }) => theme.colors.text2};
`;
