import styled from "styled-components";
import { Paragraph5 } from "../../kanopy-ui/Texts/Texts";
import { ZIndexValues } from "../../kanopy-ui/tools/ZIndexValues";
import { SnackbarsPositionX, SnackbarsPositionY } from "./GlobalSnackbars";

export const SnackbarsContainer = styled("div").withConfig({
   shouldForwardProp: p => !["positionX", "positionY", "offsetX", "offsetY"].includes(p)
})<{
   positionX: SnackbarsPositionX;
   positionY: SnackbarsPositionY;
   offsetX?: number;
   offsetY?: number;
   gap?: number;
}>`
   display: flex;
   flex-direction: column;
   position: fixed;
   z-index: ${ZIndexValues.Snackbars};
   gap: ${({ gap }) => (gap ? `${gap}px` : "unset")};

   left: ${({ positionX, offsetX }) =>
      positionX === SnackbarsPositionX.Left ? (offsetX ? `${offsetX}px` : 0) : "unset"};
   right: ${({ positionX, offsetX }) =>
      positionX === SnackbarsPositionX.Right ? (offsetX ? `${offsetX}px` : 0) : "unset"};
   top: ${({ positionY, offsetY }) =>
      positionY === SnackbarsPositionY.Top ? (offsetY ? `${offsetY}px` : 0) : "unset"};
   bottom: ${({ positionY, offsetY }) =>
      positionY === SnackbarsPositionY.Bottom ? (offsetY ? `${offsetY}px` : 0) : "unset"};

   ${({ positionX, offsetX }) =>
      positionX === SnackbarsPositionX.Center
         ? `transform: translateX(-50%);
      left: calc(50% + ${offsetX}px);`
         : ""};
`;
