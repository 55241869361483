import { FormElementType } from "../../../../tools/api/forms/useServerJsonForm";
import { FormComponentProps } from "./typings";
import TitleFromJson from "../TitleFromJson/TitleFromJson";
import ParagraphFromJson from "../ParagraphFromJson/ParagraphFromJson";
import GroupedButtonsFromJson from "../GroupedButtonsFromJson/GroupedButtonsFromJson";
import FileUploadBoxFromJson from "../FileUploadBoxFromJson/FileUploadBoxFromJson";
import TextAreaFromJson from "../TextAreaFromJson/TextAreaFromJson";
import SmallTitleFromJson from "../SmallTitleFromJson/SmallTitleFromJson";
import ReviewBoxContentFromJson from "../ReviewBoxContentFromJson/ReviewBoxContentFromJson";
import TitleImageFromJson from "../TitleImageFromJson/TitleImageFromJson";
import SelectFromJson from "../SelectFromJson/SelectFromJson";
import CurrencyInputFromJson from "../CurrencyInputFromJson/CurrencyInputFromJson";
import CheckBoxGroupFromJson from "../CheckBoxGroupFromJson/CheckBoxGroupFromJson";
import RadioButtonGroupFromJson from "../RadioButtonGroupFromJson/RadioButtonGroupFromJson";
import ButtonDownloadFromJson from "../ButtonDownloadFromJson/ButtonDownloadFromJson";
import ApiLinkButtonFromJson from "../ApiFromJson/ApiFromJson";
import LocationSearchInput from "../AddressFromJson/LocationSearchInput";
import InfoParagraphFromJson from "../InfoParagraphFromJson/InfoParagraphFromJson";
import CardsFromJson from "../CardsFromJson/CardsFromJson";
import FootNoteFromJson from "../FootNoteFromJson/FootNoteFromJson";
import ButtonFromJson from "../ButtonFromJson/ButtonFromJson";
import DatePickerFromJson from "../DatePickerFromJson/DatePickerFromJson";
import DividerFromJson from "../DividerFromJson/DividerFromJson";
import InputFromJson from "../InputFromJson/InputFromJson";
import ButtonRevealNextForm from "../ButtonRevealNextForm/ButtonRevealNextForm";
import ButtonSubmitFromJson from "../ButtonSubmitFromJson/ButtonSubmitFromJson";
import ButtonSkipFromJson from "../ButtonSkipFromJson/ButtonSkipFromJson";
import ButtonCancelFromJson from "../ButtonCancelFromJson/ButtonCancelFromJson";
import AblyListenerFromJson from "../AblyListenerFromJson/AblyListenerFromJson";
import LottieAnimationFromJson from "../LottieAnimationFromJson/LottieAnimationFromJson";
import CheckboxFromJson from "../CheckboxFromJson/CheckboxFromJson";

/**
 * This list maps the form component type from the json to the actual component
 */
export const formComponentsList: Partial<Record<FormElementType, React.FC<FormComponentProps>>> = {
   title_image: TitleImageFromJson,
   "info-cards": CardsFromJson,
   paragraph: ParagraphFromJson,
   footnote: FootNoteFromJson,
   "info-paragraph": InfoParagraphFromJson,
   "api-link": ApiLinkButtonFromJson, // TODO: Reimplement this component
   "input[money]": CurrencyInputFromJson,
   select: SelectFromJson,
   "select[multiple]": CheckBoxGroupFromJson,
   "select[radio]": RadioButtonGroupFromJson,
   "select[type=button]": GroupedButtonsFromJson,
   "select[type=buttonMultiple]": GroupedButtonsFromJson,
   "download-button": ButtonDownloadFromJson,
   "edit-panel-file": ReviewBoxContentFromJson,
   "owner-info-line": ReviewBoxContentFromJson,
   "edit-panel-text-element": ReviewBoxContentFromJson,
   "edit-panel-text-one": ReviewBoxContentFromJson,
   "edit-panel-button": ReviewBoxContentFromJson,
   date: DatePickerFromJson,
   divider: DividerFromJson,
   textarea: TextAreaFromJson,
   "input[type=address]": LocationSearchInput, // TODO: Reimplement this component
   title: TitleFromJson,
   "small-title": SmallTitleFromJson,
   "review-title": SmallTitleFromJson,
   file_upload: FileUploadBoxFromJson,
   image_upload: FileUploadBoxFromJson,
   "input[type=text]": InputFromJson,
   "input[type=password]": InputFromJson,
   "ably-listener": AblyListenerFromJson,
   animation: LottieAnimationFromJson,
   "input[type=checkbox]": CheckboxFromJson,

   // TODO: All the buttons should be just ButtonFromJson when the new json is implemented
   button: ButtonFromJson,
   "button-add-another-panel": ButtonRevealNextForm,
   "input[type=submit]": ButtonSubmitFromJson,
   "input[type=skip]": ButtonSkipFromJson,
   "button-close-panel": ButtonCancelFromJson
};
