import styled from "styled-components";
import UserAvatar from "../../../../common/UserAvatar/UserAvatar";
import { IconButton } from "../../../../kanopy-ui/IconButton/IconButton.styles";
import Icon from "../../../../kanopy-ui/Icon/Icon";

export const CornerButtonsContainer = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   column-gap: 10px;
   position: absolute;
   right: 0px;
   top: 0px;
   margin-bottom: 60px;
`;

export const UserAvatarStyled = styled(UserAvatar)``;

export const TitleRow = styled("div")`
   display: flex;
   align-items: flex-start;
`;

export const BackButton = styled(IconButton)`
   position: relative;
   top: -10px;
   padding: 10px;
`;

export const HeaderLink = styled("a")`
   display: flex;
   column-gap: 5px;
   align-items: center;
   margin-top: 10px;
   user-select: none;
`;

export const IconClientNotifications = styled(Icon)`
   /* color: ${({ theme }) => theme.colors.gray5}; */
`;
