import styled from "styled-components";

export const SnackbarContainer = styled("div")`
   ${props =>
      `  
         background-color:${props.theme.snackBar.backgroundColor};
         color: ${props.theme.snackBar.textColor};
         font-size: ${props.theme.snackBar.fontSize};
      `};

   min-width: 250px;
   text-align: center;
   border-radius: ${({ theme }) => theme.snackBar.borderRadius};
   padding: 16px;
   position: static;

   animation: clip-animation 1s;
   @keyframes clip-animation {
      0% {
         clip-path: circle(8% at 50% 50%);
         opacity: 0.2;
      }
      70% {
         opacity: 1;
      }
      100% {
         clip-path: circle(100% at 50% 50%);
      }
   }
`;

export const HeightAnimationContainer = styled("div").withConfig({
   shouldForwardProp: p => !["animate"].includes(p)
})<{ animate?: boolean }>`
   position: relative;
   animation: height-animation 80s;
   ${({ animate }) => (animate ? "" : "animation: unset;")}
   animation-timing-function: linear;
   @keyframes height-animation {
      0% {
         max-height: 0px;
      }
      100% {
         max-height: 20000px;
      }
   }
`;
