import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
   DataGridColumnsData,
   DataGridOptions,
   DataGridRowsData
} from "../../../../components/kanopy-ui/DataGrid/DataGrid.types";
import { httpRequest } from "../../tools/httpRequest";
import { IconData, RequestError } from "../../tools/typings";

export function useDashboardHome<
   Error extends RequestError,
   Response extends DashboardHomeResponse
>(props?: { options?: UseQueryOptions<void, Error, Response> }) {
   const url = "forms/dashboard/home";

   return useQuery<void, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}

export interface DashboardHomeResponse {
   title: string;
   paragraph: string;
   date: number;
   backButton?: { link: string };

   pages: Array<MyClientsPageJson | MissingDataPageJson>;
}

export interface HomeDashboardSubPage {
   tabTitle: string;
   title?: string;
   paragraph?: string;
}

export interface MyClientsPageJson extends HomeDashboardSubPage {
   clientCards?: ClientCard[];
   dataGrid?: DataGridData;
}

export interface MissingDataPageJson extends HomeDashboardSubPage {
   panelCards: PanelCard[];
   progressBar: { label: string; value: number };
   slider: HomeSliderPage[];
}

export interface ClientCard {
   initials: string;
   title: string;
   paragraph: string;
   tags: string[];
   link: string;
}
export interface PanelCard {
   icon: IconData;
   label: string;
   link: string;
}

export interface HomeSliderPage {
   paragraph: string;
   title: string;
   button?: { label: string; next: string };
}

export interface DataGridData {
   columns: DataGridColumnsData;
   rows: DataGridRowsData;
   options: DataGridOptions;
}
