import styled from "styled-components";
import { Title } from "../../../kanopy-ui/Texts/Texts";
import Container from "../../../kanopy-ui/Container";
import backgroundImage from "../../../../assets/background2.svg";

export const VerificationCodeContainer = styled(Container)`
   margin-top: 122px;
   width: 100%;
   max-width: 365px;

   p,
   h1 {
      text-align: center;
   }

   h1 {
      margin-bottom: 13px;
   }
`;

export const VerificationCodeTitle = styled(Title)`
   text-align: center;
   margin-bottom: 16px;
   width: 100%;
`;

export const BackgroundVerificationCode = styled("div")`
   position: fixed;
   width: 100%;
   height: 100%;
   background-repeat: no-repeat;
   background-position: top center;
   background-size: 1080px;
   background-image: url(${backgroundImage});
`;
