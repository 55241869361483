import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { JsonFormData } from "../../../components/common/FormFromJson/tools/typings";
import { httpRequest } from "../tools/httpRequest";

export function useSendJsonForm<
   Params extends { url: string; params?: JsonFormData },
   Error extends SendJsonFormError,
   Response extends JsonFormData
>(props?: { options?: UseMutationOptions<Response, Error, Params> }) {
   return useMutation<Response, Error, Params>(
      ["send-json-form"],
      params => httpRequest({ url: params.url, params: params.params, method: "POST" }),
      props?.options
   );
}

export type SendJsonFormError = {
   status: number;
   err_no: number;
   err: string;
   errors?: Array<JsonErrors>;
   msg: Record<string, string[]> | string;
};

export type JsonErrors = { err: string; field: string };
