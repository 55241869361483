import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import { HeaderVariant } from "../../../common/Header/Header";
import {
   DoneImage,
   DoneStepButton,
   DoneStepPageContainer,
   DoneTitle
} from "./GenericInformationStep.styles";
import { ColumnCentered } from "../../../kanopy-ui/Column/Column";
import { PropsFormFromJson } from "../../../common/FormFromJson/tools/typings";

const GenericInformationStep: FC<Partial<PropsFormFromJson>> = props => {
   const location = useLocation();

   return (
      <PageContainer
         variant={PageContainerVariant.InformationStep}
         headerConfig={{ variant: HeaderVariant.RegisteredUser }}
      >
         <DoneStepPageContainer>
            <FormFromJson
               jsonUrl={location.pathname}
               renderFormComponent={({ formElementJson, formTools }) =>
                  formElementJson.type === "title" ? (
                     <ColumnCentered>
                        <DoneTitle>{formElementJson.label}</DoneTitle>
                     </ColumnCentered>
                  ) : formElementJson.type === "title_image" ? (
                     <ColumnCentered>
                        <DoneImage src={formElementJson.image} />
                     </ColumnCentered>
                  ) : formElementJson.type === "input[type=submit]" ? (
                     <DoneStepButton
                        onClick={() =>
                           formTools.submitFormTools.submitForm({
                              submitUrl: formElementJson?.api?.url,
                              redirectUrl: formElementJson?.next
                           })
                        }
                        loading={formTools.submitFormTools.isLoading}
                     >
                        {formElementJson.label}
                     </DoneStepButton>
                  ) : null
               }
               disableSubmitButtonWhenFormIsIncomplete={false}
               {...props}
            />
         </DoneStepPageContainer>
      </PageContainer>
   );
};

export default GenericInformationStep;
