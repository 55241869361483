import { createGlobalStyle, DefaultTheme, GlobalStyleComponent } from "styled-components";

export const GlobalStyles: GlobalStyleComponent<{}, DefaultTheme> = createGlobalStyle`
   html, body, #root {
      margin: 0;
      padding: 0;
      min-height: 100vh;
   }

   a:focus {
      outline: none;
   }

   *:focus {
      outline: none;           /* Removes the selection outline from inputs and buttons */
   }
   
   *, :after, :before {
      box-sizing: border-box;  /* Do not include border and margin in the sizes numbers */
   }

   img {
      user-drag: none;         /* Disable image drag since 99% of the times it happens by accident */
   }

   button {
      cursor: pointer;
   }

   ::-webkit-scrollbar {
      width: 18px;
      height: 7px;
      margin: 0px;
   }

   ::-webkit-scrollbar-track {
      background: ${props => props.theme.colors.scrollbar};
      border-radius: ${props => props.theme.borders.borderRadius1};
   }

   ::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      background-clip: content-box;
      background-color: ${props => props.theme.colors.scrollbarThumb};
      border-radius: ${props => props.theme.borders.borderRadius1};
   }

   ::-webkit-scrollbar-thumb:hover {
      border: 5px solid transparent;
      background-clip: content-box;
      background-color: ${props => props.theme.colors.scrollbarThumbHover};
   }

   ::-webkit-scrollbar-corner {
      background: ${props => props.theme.colors.scrollbar};
      border-radius: ${props => props.theme.borders.borderRadius1};
   }
`;
