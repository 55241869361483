import React, { FC } from "react";
import { Label2, Label3 } from "../../../../kanopy-ui/Texts/Texts";
import AnyFileIcon from "../../../../kanopy-ui/tools/assets/file_icon.svg";
import ImageFileIcon from "../../../../kanopy-ui/tools/assets/image_icon.svg";
import {
   FileDescription,
   FilePartContainer,
   FileUploadedThumbnail,
   ReviewFileContainer,
   TypeIcon
} from "./ReviewFile.styles";

interface PropsReviewFile {
   thumbnailUrl?: string | undefined;
   title: string | undefined;
   label: string;
   size: string;
   downloadLink: string;
   type: ReviewFileType;
   isLastElement: boolean;
}

export enum ReviewFileType {
   Any,
   Image
}

const ReviewFile: FC<PropsReviewFile> = props => {
   const { label, size, thumbnailUrl, type, title, downloadLink, isLastElement } = props;

   return (
      <ReviewFileContainer
         onClick={() => window.open(downloadLink, "ReviewFileName")}
         isLastElement={isLastElement}
      >
         <FilePartContainer>
            {!thumbnailUrl && (
               <TypeIcon src={type === ReviewFileType.Any ? AnyFileIcon : ImageFileIcon} />
            )}
            {thumbnailUrl && <FileUploadedThumbnail src={thumbnailUrl} />}
            <FileDescription>
               <Label3>{title}</Label3>
               <Label2>{label}</Label2>
               <Label2>{size}</Label2>
            </FileDescription>
         </FilePartContainer>
      </ReviewFileContainer>
   );
};

export default ReviewFile;
