import React, { FC, InputHTMLAttributes } from "react";
import { IconImage } from "../Icon/Icon";
import { Label } from "../Texts/Texts";
import {
   ErrorIcon,
   ErrorText,
   InputContainer,
   TextAreaContainer,
   TextAreaStyled
} from "./TextArea.styles";

export interface PropsTextArea extends InputHTMLAttributes<HTMLTextAreaElement> {
   label?: string;
   error?: string;
}

const TextArea: FC<PropsTextArea> = React.forwardRef<HTMLTextAreaElement, PropsTextArea>(
   (props, ref) => {
      const { label, error } = props;

      return (
         <InputContainer>
            {label && <Label>{label}</Label>}
            <TextAreaContainer error={error}>
               <TextAreaStyled {...props} ref={ref} />
               {error !== undefined && <ErrorIcon iconImage={IconImage.MdInfo} />}
            </TextAreaContainer>
            {error && <ErrorText>{error}</ErrorText>}
         </InputContainer>
      );
   }
);

export default TextArea;
