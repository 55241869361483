import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useClient } from "../../../../tools/api/dashboard/client/useClient";
import LoadingSpinner from "../../../kanopy-ui/LoadingSpinner/LoadingSpinner";
import FinancialHealth from "../FinancialHealth/FinancialHealth";

const Client: FC = () => {
   let { clientId } = useParams();
   const { data: clientData } = useClient({ clientId });

   if (!clientData) {
      return <LoadingSpinner />;
   }

   return <FinancialHealth withExternalData={{ data: clientData }} />;
};

export default Client;
