import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MissingDataPageJson } from "../../../../../tools/api/dashboard/home/useDashboardHome";
import FinicityConnectModal from "../../../../common/FinicityConnectModal/FinicityConnectModal";
import FormFromJsonAsModal from "../../../../common/FormFromJsonAsModal/FormFromJsonAsModal";
import { ButtonVariant } from "../../../../kanopy-ui/Button";
import Icon, { IconImage } from "../../../../kanopy-ui/Icon/Icon";
import OpacitySwitcher from "../../../../kanopy-ui/OpacitySwitcher/OpacitySwitcher";
import { Label11, Label6, Label9 } from "../../../../kanopy-ui/Texts/Texts";
import {
   ExploreButton,
   ExploreButtonsContainer,
   ExploreSection,
   IconExploreButton,
   MissingDataContainer,
   PercentageLabel,
   ProgressBarContainer,
   ProgressBarStyled,
   ProgressBarTextContainer,
   ProgressCard,
   SliderButton,
   SliderContainer,
   SliderPageContainer,
   SliderStyled,
   SliderTextParagraph
} from "./MissingData.styles";

interface PropsMissingData {
   serverData: MissingDataPageJson;
}

const MissingData: FC<PropsMissingData> = props => {
   const { slider, progressBar, panelCards } = props.serverData;
   const [modalFormUrl, setModalFormUrl] = useState<string>();
   const [modalFormOpen, setModalFormOpen] = useState(false);
   const [exploreButtonHovered, setExploreButtonHovered] = useState<number | undefined>();
   const [finicityConnectModalOpen, setFinicityConnectModalOpen] = useState(false);
   const navigate = useNavigate();

   const handleSliderButtonClick = (next: string) => {
      if (next.includes("finicity-connect")) {
         setFinicityConnectModalOpen(true);
      } else {
         setModalFormUrl(next);
         setModalFormOpen(true);
      }
   };

   const handleFormModalClose = () => {
      setModalFormOpen(false);
   };

   const handleExploreButtonClick = (link: string) => {
      navigate(`/${link}`);
   };

   return (
      <>
         <MissingDataContainer>
            <ProgressCard>
               <ProgressBarContainer>
                  <ProgressBarTextContainer>
                     <Label6>{progressBar.label}</Label6>
                     <PercentageLabel>{progressBar.value}% complete</PercentageLabel>
                  </ProgressBarTextContainer>
                  <ProgressBarStyled percentage={progressBar.value} showText={false} />
               </ProgressBarContainer>
               {slider.length > 0 && (
                  <SliderContainer>
                     <SliderStyled>
                        {slider.map(sliderPage => (
                           <SliderPageContainer key={sliderPage.title}>
                              <SliderTextParagraph
                                 dangerouslySetInnerHTML={{
                                    __html: sliderPage.paragraph
                                       ? sliderPage.paragraph
                                       : sliderPage.title
                                 }}
                              />
                              {sliderPage.button && (
                                 <SliderButton
                                    variant={ButtonVariant.InvertedColors}
                                    onClick={() =>
                                       handleSliderButtonClick(sliderPage.button?.next!)
                                    }
                                 >
                                    {sliderPage.button.label}
                                 </SliderButton>
                              )}
                           </SliderPageContainer>
                        ))}
                     </SliderStyled>
                  </SliderContainer>
               )}
            </ProgressCard>
            <ExploreSection>
               <Label11>Explore your business</Label11>
               <ExploreButtonsContainer>
                  {panelCards.map((exploreButtonData, i) => (
                     <ExploreButton
                        key={exploreButtonData.label}
                        onClick={() => handleExploreButtonClick(exploreButtonData.link)}
                        onMouseOver={() => setExploreButtonHovered(i)}
                        onMouseLeave={() => setExploreButtonHovered(undefined)}
                     >
                        <OpacitySwitcher
                           componentSwitch={exploreButtonHovered === i}
                           animDurationMilliseconds={600}
                           component1={() => (
                              <IconExploreButton
                                 size={28}
                                 iconImage={exploreButtonData.icon.hovered as IconImage}
                              />
                           )}
                           component2={() => (
                              <IconExploreButton
                                 size={28}
                                 iconImage={exploreButtonData.icon.normal as IconImage}
                              />
                           )}
                        />
                        <Label9>{exploreButtonData.label}</Label9>
                     </ExploreButton>
                  ))}
               </ExploreButtonsContainer>
            </ExploreSection>
         </MissingDataContainer>
         <FormFromJsonAsModal
            disableAutomaticModalTitleFromJson
            modalVisible={modalFormOpen}
            onModalClose={handleFormModalClose}
            jsonUrl={modalFormUrl}
            onFormModified={handleFormModalClose}
         />
         <FinicityConnectModal
            visible={finicityConnectModalOpen}
            onFinish={() => setFinicityConnectModalOpen(false)}
            onClose={() => setFinicityConnectModalOpen(false)}
         />
      </>
   );
};

export default MissingData;
