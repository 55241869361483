import styled from "styled-components";

export const InputContainer = styled("div")`
   display: flex;
   align-items: center;
   width: 100%;
   height: 100%;
`;

export const InputStyled = styled("input")`
   width: 100%;
   height: 21.5px;
`;
