import styled from "styled-components";

export const CircleContainer = styled("div").withConfig({
   shouldForwardProp: p => !["size"].includes(p)
})<{ size: number }>`
   display: flex;
   align-items: center;
   justify-content: center;
   width: ${({ size }) => size}px;
   height: ${({ size }) => size}px;
   border-radius: 50%;
   color: ${({ theme }) => theme.circularButton.enabled.color};
   background-color: ${({ theme }) => theme.circularButton.enabled.backgroundColor};
   border: ${({ theme }) => theme.circularButton.enabled.border};
   border-color: ${({ theme }) => theme.circularButton.enabled.borderColor};

   cursor: pointer;
   transition: all 250ms;

   &:hover {
      color: ${({ theme }) => theme.circularButton.hover.color};
      background-color: ${({ theme }) => theme.circularButton.hover.backgroundColor};
      border: ${({ theme }) => theme.circularButton.hover.border};
      border-color: ${({ theme }) => theme.circularButton.hover.borderColor};
   }
`;

export const ChildrenContainer = styled("div").withConfig({
   shouldForwardProp: p => !["offsetX", "offsetY"].includes(p)
})<{ offsetX?: number; offsetY?: number }>`
   position: relative;
   left: ${({ offsetX }) => (offsetX ? `${offsetX}px` : "")};
   bottom: ${({ offsetY }) => (offsetY ? `${offsetY}px` : "")};
`;
