import { abbreviateNumber } from "../number/abbreviateNumber";

export interface NumberFormatOptions {
   language?: string | string[];
   options?: Intl.NumberFormatOptions & { abbreviate?: boolean };
}

/**
 * A wrapper for the javascript Intl.NumberFormat that implements more features, default values and
 * safety checks.
 */
export const formatNumber = (value: number, format?: NumberFormatOptions) => {
   const { language = "en-US", options = { style: "currency", currency: "USD" } } = format ?? {};
   let suffix = "";

   if (options.abbreviate) {
      const { abbreviated, suffix: abbreviationSuffix } = abbreviateNumber(value);
      value = abbreviated;
      suffix = abbreviationSuffix;
      // "Abbreviate" should only have a maximum of 1 fraction digit, this prevents the formatter from adding more decimals
      options.maximumFractionDigits = value % 1 !== 0 ? 1 : 0;
   }

   /**
    * This can be improved if needed: The most precise implementation here is to loop the string result of
    * Intl.NumberFormat and add the suffix after the last numeric character because we don't know all
    * posible Intl.NumberFormat outputs, it may add it's own suffix, but the current implementation is
    * simpler and works for our use case.
    */
   return Intl.NumberFormat(language, options).format(value ?? 0) + suffix;
};
