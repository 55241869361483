import styled from "styled-components";
import { Row } from "../../../../../kanopy-ui/Row/Row";

export const CardsContainer = styled("div")``;

export const Header = styled(Row)`
   align-items: center;
   column-gap: 16px;
   margin-bottom: 12px;
`;
