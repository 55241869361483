import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * When changing the page the finicity connect iframe is not closed. This hook fixes that.
 */
export function useFinicityConnectIframeFix() {
   const location = useLocation();

   useEffect(() => {
      // @ts-ignore
      window.finicityConnect.destroy();
   }, [location]);
}
