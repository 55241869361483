export function simulateMouseClick(element: HTMLElement | null) {
   if (element == null) {
      return;
   }

   const mouseClickEvents = ["mousedown", "click", "mouseup"];
   mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
         new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
         })
      )
   );
}
