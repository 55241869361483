import React, { FC } from "react";
import CashFlowCard from "./CashFlowCard/CashFlowCard";
import CashFlowCharacteristicsCard from "./CashFlowCharacteristicsCard/CashFlowCharacteristicsCard";
import InfoCard from "./InfoCard/InfoCard";
import TopExpensesCard from "../FutureTabContent/TopExpensesCard/TopExpensesCard";
import {
   CurrentContainer,
   InfoCardsContainer,
   SummaryContainer,
   ChartsContainer,
   CardsColumnContainer
} from "./ChartsTabContent.styles";
import { CurrentPageJson } from "../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import ChartsMenu from "../../components/ChartsMenu/ChartsMenu";
import RatiosTable from "./RatiosTable/RatiosTable";

interface PropsChartsTabContent {
   serverData: CurrentPageJson;
}

const ChartsTabContent: FC<PropsChartsTabContent> = props => {
   const { topCards, charts, bottomCards, ratiosTable } = props.serverData;

   return (
      <CurrentContainer>
         <InfoCardsContainer>
            {topCards?.map((card, i) => (
               <InfoCard
                  icon={card.icon}
                  title={card.title}
                  subtitle={card.value}
                  description={card.paragraph}
                  tooltip={card.tooltip}
                  key={i}
               />
            ))}
         </InfoCardsContainer>
         {charts && (
            <ChartsContainer>
               <ChartsMenu chartsData={charts} />
            </ChartsContainer>
         )}
         {ratiosTable && <RatiosTable serverData={ratiosTable!} />}
         {bottomCards && (
            <SummaryContainer>
               <CardsColumnContainer>
                  <CashFlowCard
                     title={bottomCards!.cash_flow_summary?.title}
                     tooltip={bottomCards!.cash_flow_summary?.tooltip}
                     data={[bottomCards!.cash_flow_summary?.rows]}
                  />
               </CardsColumnContainer>
               {bottomCards.transactions && bottomCards.cash_flow_characteristics && (
                  <CardsColumnContainer>
                     <TopExpensesCard
                        title={bottomCards!.transactions?.title}
                        tooltip={bottomCards!.transactions?.tooltip}
                        data={bottomCards!.transactions?.rows}
                     />
                     <CashFlowCharacteristicsCard
                        title={bottomCards!.cash_flow_characteristics?.title}
                        tooltip={bottomCards!.cash_flow_characteristics?.tooltip}
                        data={[bottomCards!.cash_flow_characteristics?.rows]}
                     />
                  </CardsColumnContainer>
               )}
            </SummaryContainer>
         )}
      </CurrentContainer>
   );
};

export default ChartsTabContent;
