export function getNumberFromString(value: string | undefined): number | undefined {
   if (value === undefined) {
      return undefined;
   }

   const valueCleaned = value.replace(/[^0-9]/g, "");
   const valueAsNumber = Number(valueCleaned);

   if (!Number.isNaN(valueAsNumber)) {
      return valueAsNumber;
   }

   return undefined;
}
