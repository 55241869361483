import styled from "styled-components";
import Card from "../../../kanopy-ui/Card";
import Container from "../../../kanopy-ui/Container";
import { Paragraph, Title, Title2 } from "../../../kanopy-ui/Texts/Texts";

export const RegistrationContainer = styled(Container)`
   display: flex;
   flex-direction: row;
`;

export const TitleIllustrationContainer = styled("div")`
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   position: relative;
   padding-top: 33px;
`;

export const TitleOfIllustration = styled(Title2)`
   margin-bottom: 16px;
`;

export const ParagraphOfIllustration = styled(Paragraph)``;

export const TextsContainer = styled("div")`
   display: flex;
   flex-direction: column;
   padding-right: 72.54px;
   z-index: 2;
`;

export const IllustrationContainer = styled(Container)`
   display: flex;
   align-items: flex-start;
   justify-content: center;
   position: relative;
   height: 100%;
   height: 500px;
`;

export const BackgroundIllustration = styled("img")`
   width: 100%;
   max-width: 100%;
   position: relative;

   @media only screen and (min-width: 600px) {
      max-height: 100%;
   }
`;

export const CubeImg = styled("img")`
   position: absolute;
   top: -15px;
   z-index: 1;
`;

export const SignupFormTitle = styled(Title)``;

export const SignupCard = styled(Card)`
   width: 450px;
   min-width: 370px;
   height: 100%;
   overflow-y: auto;
`;
