import { FieldError } from "react-hook-form";
import { FormElementJson } from "../api/forms/useServerJsonForm";
import { firstCharacterToUppercase } from "../string/firstCharacterToUppercase";
import { Form } from "../../components/common/FormFromJson/tools/typings";

export function getFieldError(props: {
   formElementJson: FormElementJson;
   form: Form;
}): string | undefined {
   const { formElementJson, form } = props;
   const errors = form.formState.errors;
   const fieldIsDirty = Boolean(form.formState.dirtyFields[formElementJson.name]);

   if (errors == null) {
      return undefined;
   }

   const fieldError = errors?.[formElementJson.name as unknown as number] as unknown as FieldError;

   if (fieldError === undefined) {
      return undefined;
   }

   if (fieldError.type === "required") {
      // If the user did not modify the field then we should not show the "is required" error since the user didn't have time to solve the problem yet.
      if (!fieldIsDirty) {
         return undefined;
      }

      return "This field is required";
   }

   if (fieldError.type === "pattern") {
      return formElementJson.regex_error ?? "Incorrect format";
   }

   if (fieldError.message !== undefined) {
      return firstCharacterToUppercase(fieldError.message);
   }

   return "This field has an error";
}
