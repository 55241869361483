/**
 * Returns the data required to create a string of an abbreviated number, example:
 * 2500 = 2.5k
 * 1500000 = 1.5m
 *
 * @returns An object with the abbreviated value and the suffix to append at the end
 */
export function abbreviateNumber(value: number): { abbreviated: number; suffix: string } {
   let suffix = "";
   let abbreviated = value;

   if (Math.abs(value) >= 1000000) {
      abbreviated = Math.sign(value) * (Math.round(Math.abs(value) / 100000) / 10);
      suffix = "m";
      return { abbreviated, suffix };
   }

   if (Math.abs(value) >= 1000) {
      abbreviated = Math.sign(value) * (Math.round(Math.abs(value) / 100) / 10);
      suffix = "k";
      return { abbreviated, suffix };
   }

   return { abbreviated, suffix };
}
