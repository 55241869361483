import React, { FC, InputHTMLAttributes, useState } from "react";
import { AnimationDisablerDiv } from "./DisableAnimationBeforeClick.styles";

/**
 * Sets to "unset" the animation css prop of all children recursively until the div is clicked
 * Useful for transition animations that should execute after interaction and not at mount.
 */
const DisableAnimationBeforeClick: FC<InputHTMLAttributes<HTMLDivElement>> = props => {
   const [enableAnimation, setEnableAnimation] = useState(false);

   const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setEnableAnimation(true);
      props.onClick?.(e);
   };

   return (
      <AnimationDisablerDiv {...props} enableAnimation={enableAnimation} onClick={handleClick}>
         {props.children}
      </AnimationDisablerDiv>
   );
};

export default React.memo(DisableAnimationBeforeClick);
