import NotFound from "../NotFound/NotFound";

import { Routes, Route } from "react-router-dom";
import MyAccount from "./MyAccount/MyAccount";

const Settings = () => {
   return (
      <Routes>
         <Route path="my-account" element={<MyAccount />} />
         <Route path="*" element={<NotFound />} />
      </Routes>
   );
};

export default Settings;
