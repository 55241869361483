import { useMemo } from "react";
import { FormElementJson, FormJson } from "../../../../tools/api/forms/useServerJsonForm";

export function useJsonFormElementsAsRecord(
   formJson: FormJson | undefined
): Record<string, FormElementJson> {
   return useMemo(() => {
      const result: Record<string, FormElementJson> = {};

      formJson?.formGroups?.forEach(formGroup => {
         formGroup.formElements?.forEach(formElement => {
            result[formElement.name] = formElement;
         });
      });

      return result;
   }, [formJson]);
}
