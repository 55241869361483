import styled from "styled-components";
import { Paragraph5 } from "../../kanopy-ui/Texts/Texts";

export const ButtonsContainer = styled("div")`
   display: flex;
   justify-content: flex-end;
   flex-direction: row;
   padding-bottom: 20px;
   padding-right: 20px;
   column-gap: 8px;
`;

export const TextContainer = styled(Paragraph5)`
   padding: 46px 60px;
   text-align: center;
`;
