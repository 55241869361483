import React, { FC } from "react";

interface PropsCheckIconSvg {
   color?: string;
}

const CheckIconSvg: FC<PropsCheckIconSvg> = ({ color }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         style={{ fill: "none" }}
         viewBox="0 0 13.878696 10.298279"
         height="10.298279"
         width="13.878696"
      >
         <path
            fill="none"
            id="path817"
            d="M 2.47806,7.8193382 0,5.3403979 0.666848,4.6735493 1.333697,4.0067008 3.126263,5.7980496 4.918829,7.5893982 8.7140978,3.7946993 12.509366,0 13.194031,0.6878519 13.878696,1.375704 9.4174078,5.8369916 4.956121,10.298279 Z"
            style={{
               fill: color ?? "black",
               fillOpacity: 1,
               fillRule: "nonzero",
               stroke: "none",
               strokeWidth: 2.42374587,
               strokeLinecap: "butt",
               strokeLinejoin: "miter",
               strokeMiterlimit: 4,
               strokeDasharray: "none",
               strokeOpacity: 1,
               paintOrder: "normal"
            }}
         />
      </svg>
   );
};

export default CheckIconSvg;
