import React, { FC, useEffect, useState } from "react";
import { getFieldError } from "../../../../tools/validation/getFieldError";
import RadioButtonGroup from "../../../kanopy-ui/RadioButtonGroup/RadioButtonGroup";
import { LabelAndValue } from "../../../kanopy-ui/tools/types";
import { FormComponentProps } from "../tools/typings";

const RadioButtonGroupFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form } = formTools;

   const [selection, setSelection] = useState<string>(formElementJson.value ?? []);

   useEffect(() => {
      form.setValue(formElementJson.name, selection);
   }, [selection]);

   return (
      <RadioButtonGroup
         label={formElementJson.label ?? ""}
         extraLabel={formElementJson.note}
         options={formElementJson.options as LabelAndValue[]}
         selectedByDefault={selection}
         onChange={setSelection}
         error={getFieldError({ formElementJson, form })}
      />
   );
};

export default RadioButtonGroupFromJson;
