import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ButtonVariant } from "../../../components/kanopy-ui/Button";
import { LabelAndValue } from "../../../components/kanopy-ui/tools/types";
import { httpRequest } from "../tools/httpRequest";
import { RequestError } from "../tools/typings";
import { AblyData } from "../../ably/useAbly";

export function useServerJsonForm<
   Params extends GetFormParams,
   Error extends RequestError,
   Response extends FormJson
>(params: Params, props?: { options?: UseQueryOptions<Params, Error, Response> }) {
   const url = `forms/${params.apiFormAddress}`;

   return useQuery<Params, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}

export interface GetFormParams {
   apiFormAddress?: string;
}

/**
 * This object is the complete parsed form json
 */
export interface FormJson {
   leftPanel?: LeftPanel;
   formGroups: FormGroupJson[];
   next?: string;
}

export interface FormGroupJson {
   layoutType: LayoutType;
   hiddenFormId?: number;
   hidden?: boolean;
   formElements?: FormElementJson[];
}

export interface LeftPanel {
   lender_icon?: string;
   progress_title?: string;
   progress?: number;
   steps_title?: string;
   steps?: ProgressStep[];
   menu?: MenuOption[];
}

/**
 * The parsed json part that describes a form element, for example an input or a checkbox.
 */
export interface FormElementJson {
   name: string;
   type: FormElementType;
   variant?: string;
   paddingVariant?: string;
   label?: string;
   placeholder?: string;
   value?: any;
   note?: string;
   required: number;
   regex?: string;
   regex_error?: string;
   match?: string;
   match_error?: string;
   api?: Api;
   icon?: string;
   options?: string[] | LabelAndValue[];
   tooltip?: string;
   src?: string;
   document_icon?: string;
   lender_icon?: string;
   cards?: Cards[];
   exit?: string;
   next?: string;
   link?: string;
   key?: string;
   channel?: string;
   subscription?: string;
   image?: string;
   trigger?: string;
   style?: string;
   component?: FormElementComponentParameter;
   footnote?: FormElementFootNote;
   remote_api?: string;
   remote_api_error?: string;
   actions?: Action[];
   event?: "ablyListener";
   readOnly?: boolean;
}

export interface Api {
   url: string;
   api?: string;
   next?: string;
   params?: string[] | null;
}

export interface Cards {
   layoutType: LayoutType;
   formElements?: FormElementJson[];
   icon?: string;
   paragraph?: string;
   title?: string;
}

export interface User {
   id: number;
}

export interface ProgressStep {
   active: number;
   checked: number;
   title: string;
   link: string;
}

export interface MenuOption {
   name: string;
   icon: string;
   active: number;
   link: string;
}

export interface FileInServer {
   value: { text: string; size: string; link: string };
}

export type LayoutType =
   | "row-start"
   | "row-end"
   | "row-center"
   | "row-space-between"
   | "column"
   | "column-centered"
   | "columns"
   | "footer"
   | "fixed-bottom"
   | "edit-panel"
   | "edit-panel-owner"
   | "column-centered-small"
   | "column-small-buttons"
   | "row-small-buttons"
   | "iframe";

export type FormElementType =
   // Non-interactive components
   | "title"
   | "small-title"
   | "paragraph"
   | "info-paragraph"
   | "divider"
   | "title_image"
   | "footnote"
   | "animation"
   | "edit-panel-text-one"
   | "edit-panel-text-element"

   // Form interactive components
   | "api-link"
   | "input[type=text]"
   | "input[type=password]"
   | "input[type=checkbox]"
   | "input[type=submit]"
   | "input[type=skip]"
   | "input[money]"
   | "input[type=address]"
   | "select[multiple]"
   | "select[type=button]"
   | "select[radio]"
   | "select[type=buttonMultiple]"
   | "start-panel"
   | "info-cards"
   | "finicity-connect"
   | "ably-listener"
   | "download-button"
   | "review-title"
   | "edit-panel-button"
   | "button-add-another-panel"
   | "image_upload"
   | "file_upload"
   | "edit-panel-file"
   | "textarea"
   | "select"
   | "owner-info-line"
   | "button"
   | "button-close-panel"
   | "date";

export interface FormElementComponentParameter {
   street_number: string;
   postal_code: string;
   locality: string;
   administrative_area_level_1: string;
}

export interface FormElementFootNote {
   label: string;
   link: string;
}

export type ActionType = "openDialog" | "logout" | "openWindow" | "redirect";
export type ActionEvent = "onClick" | "onBlur" | "onFocus" | "onChange" | "ablyListener";

export interface Action {
   type: ActionType;
   event: ActionEvent;
   params?: ActionParam[];
}

export interface ActionParam {
   title?: string;
   text?: string;
   buttons?: Array<{ text: string; variant?: ButtonVariant; actions?: Action[] }>;
   key?: string;
   channel?: string;
   subscription?: string;
   windowUrl?: string;
   redirectUrl?: string;
   ablyData?: AblyData;
}
