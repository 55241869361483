/**
 * Returns a date in readable format, example: "November 26, 2020"
 * Uses localization from i18n package.
 *
 * @param unixDate Unix date in seconds
 */
import Moment from 'moment';

export function readableDateFromUnixDate(
   unixDate: number | undefined,
   format: string = "MMM DD, YYYY"
): string | undefined {
   if (unixDate === undefined) {
      return undefined;
   }

   return Moment(new Date(unixDate * 1000)).format(format);
}
