import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { strIsNumber } from "../string/isNumber";

/**
 * Gets the url search params and parses all as an object, currently supports:
 *
 * 1. Strings written as: url.com?item=stringItem
 * 2. Numbers written as: url.com?item=123
 * 3. String arrays in this format: url.com?array=item1,item2,item3
 *
 * Note: String arrays with a single items are parsed as strings (not arrays)
 */
export function useUrlParams<T>(): Partial<T> {
   const { search } = useLocation();
   return useMemo(() => {
      const hashContent = search.replace("?", "");
      const urlSearchParams = new URLSearchParams(hashContent);
      const object = Object.fromEntries(urlSearchParams.entries());
      Object.keys(object).forEach(key => {
         try {
            const value = object[key];
            if (typeof value === "string" && value.includes(",")) {
               object[key] = value.split(",") as unknown as string;
            }

            if (typeof value === "string" && strIsNumber(value)) {
               object[key] = Number(value) as unknown as string;
            }
         } catch (err) {}
      });

      return object as Partial<T>;
   }, [search]);
}
