import { FocusEvent, useState, MutableRefObject } from "react";

/**
 * Allows to disable onBlur event for an input just once, to avoid a specific unwanted
 * blur event triggering.
 * It requires your onBlur function and then this hook returns the final
 * function to assign to the input on the onBlur event.
 * Also the input ref is required.
 * Use disableNextBlurEvent when required.
 *
 * Warning:
 * If you call disableNextBlurEvent inside onClick it won't work and may cause a
 * bug, use it inside onMouseDown instead. This is due to onClick event being
 * called after onBlur is already called.
 */
export function useBlurEventDisabler(props: {
   onBlur: ((event: FocusEvent<HTMLInputElement>) => void) | undefined;
   inputRef: MutableRefObject<HTMLInputElement | undefined> | undefined;
}) {
   const { onBlur, inputRef } = props;
   const [blurEventEnabled, setBlurEventEnabled] = useState<boolean>(true);

   const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (blurEventEnabled) {
         onBlur?.(e);
      } else {
         // Since blur is disabled we need to keep the input focused
         inputRef?.current?.focus();
         // Since we are disabling blur event just once we need to enable it again now
         setBlurEventEnabled(true);
      }
   };

   const disableNextBlurEvent = () => {
      setBlurEventEnabled(false);
   };

   return { disableNextBlurEvent, handleBlur };
}
