import styled from "styled-components";
import DisableAnimationBeforeClick from "../DisableAnimationBeforeClick/DisableAnimationBeforeClick";
import { Label10 } from "../Texts/Texts";
import Icon from "../Icon/Icon";

export const CheckBoxContainer = styled(DisableAnimationBeforeClick)`
   display: flex;
   flex-direction: row;
   align-items: center;
   cursor: pointer;
`;

export const BoxContainer = styled("div")`
   position: relative;
   width: 18px;
   height: 18px;
   min-width: 18px;
   min-height: 18px;
   margin-right: 7px;
`;

export const Frame = styled("div")`
   position: absolute;
   width: 100%;
   height: 100%;
   border: 2px solid ${({ theme }) => theme.checkBox.boxColor};
   border-radius: 3px;
   box-sizing: border-box;
`;

export const BoxEmpty = styled(Frame)`
   animation: appear-animation-empty-checkbox 400ms ease-out;
   animation-play-state: running;

   @keyframes appear-animation-empty-checkbox {
      from {
         background-color: ${({ theme }) => theme.checkBox.boxColor};
      }
      to {
         background-color: transparent;
      }
   }
`;

export const BoxFilled = styled("div")`
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   width: 100%;
   height: 100%;
   border-radius: 3px;
   background-color: ${({ theme }) => theme.checkBox.boxColor};

   svg {
      width: 14px;
      height: 14px;
   }

   animation: appear-animation-checkbox 200ms ease-out;
   animation-play-state: running;

   @keyframes appear-animation-checkbox {
      from {
         transform: scale(0);
      }
      to {
         transform: scale(1);
      }
   }
`;

export const CheckBoxLabel = styled(Label10)`
   position: relative;
   top: 2px;
   cursor: pointer;
   user-select: none;
   line-height: 16px;
`;

export const IconStyled = styled(Icon)`
   color: ${({ theme }) => theme.colors.gray4};
`;
