import { FC, useCallback } from "react";
import { ButtonFilled, ButtonInverted, ButtonOutlined, ButtonTextOnly } from "./Button.styles";
import { ButtonSize, ButtonVariant, PropsButton } from "./Button.types";

const Button: FC<PropsButton> = props => {
   const { size = ButtonSize.Large, ...otherProps } = props;

   const onClickHandler = useCallback(
      e => {
         // Don't execute onClick if the button is in loading mode or disabled
         if (!props.loading && !props.disabled) {
            props.onClick?.(e);
         }
      },
      [props]
   );

   const getButtonByVariant = () => {
      switch (props.variant) {
         case ButtonVariant.InvertedColors:
            return ButtonInverted;
         case ButtonVariant.Outlined:
            return ButtonOutlined;
         case ButtonVariant.TextOnly:
            return ButtonTextOnly;
         default:
            return ButtonFilled;
      }
   };

   const Component = getButtonByVariant();

   return (
      <Component
         {...otherProps}
         variant={props.variant ?? ButtonVariant.Filled}
         onClick={onClickHandler}
         disabled={props.disabled}
         size={size}
      >
         {props.children && <span>{props.children}</span>}
      </Component>
   );
};

export default Button;
