import styled from "styled-components";
import { Label5, Label6 } from "../../../kanopy-ui/Texts/Texts";

export const TitleNormal = styled(Label5)`
   margin-top: 32px;
   margin-top: 24px;
`;

export const TitleInsideCard = styled(Label6)`
   padding-top: 21px;
   padding-bottom: 0px;
   padding-left: 26px;
   padding-right: 27px;
   margin-top: 17px;
`;
