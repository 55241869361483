import styled, { isStyledComponent } from "styled-components";
import Button from "../../../../kanopy-ui/Button";
import Card from "../../../../kanopy-ui/Card";
import { Column } from "../../../../kanopy-ui/Column/Column";
import Icon from "../../../../kanopy-ui/Icon/Icon";
import ProgressBar from "../../../../kanopy-ui/ProgressBar/ProgressBar";
import { Row } from "../../../../kanopy-ui/Row/Row";
import Slider from "../../../../kanopy-ui/Slider/Slider";
import { Paragraph2, Paragraph8 } from "../../../../kanopy-ui/Texts/Texts";

export const MissingDataContainer = styled(Column)`
   max-width: 1300px;
   row-gap: 32px;
`;

export const ProgressCard = styled(Card)`
   display: flex;
   flex-direction: column;
`;

export const ProgressBarContainer = styled(Column)`
   padding: 24px 32px;
   row-gap: 9px;
`;

export const ProgressBarTextContainer = styled(Row)`
   column-gap: 8px;
`;

export const PercentageLabel = styled(Paragraph2)`
   color: ${({ theme }) => theme.colors.primary4};
`;

export const ProgressBarStyled = styled(ProgressBar)`
   height: 8px;
`;

export const SliderContainer = styled(Column)`
   background-color: ${({ theme }) => theme.missingDataPanel.backgroundColor};
   border-bottom-right-radius: inherit;
   border-bottom-left-radius: inherit;
   padding: 24px 32px;
`;

export const SliderTextParagraph = styled(Paragraph8)`
   color: ${({ theme }) => theme.missingDataPanel.color};
`;

export const SliderPageContainer = styled(Row)`
   align-items: center;
   justify-content: space-between;
   column-gap: 50px;
   margin-bottom: 18px;
`;

export const SliderButton = styled(Button)`
   min-width: 144px;
`;

export const SliderStyled = styled(Slider)`
   & * {
      color: ${({ theme }) => theme.missingDataPanel.color};
   }
`;

export const ExploreSection = styled("div")``;

export const ExploreButtonsContainer = styled("div")`
   display: flex;
   flex-direction: row;
   gap: 24px;
   margin-top: 16px;
`;

export const ExploreButton = styled(Card)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
   user-select: none;
   padding: 27px;

   &,
   & * {
      cursor: pointer;
   }

   &:hover,
   & * :hover {
      background-color: ${({ theme }) => theme.exploreBusinessButton.hover.backgroundColor};
   }

   & label {
      text-align: center;
   }
`;

export const IconExploreButton = styled(Icon)`
   color: black;
`;
