import React, { FC } from "react";
import GroupedButtons from "../../../kanopy-ui/GroupedButtons";
import { ValueType } from "../../../kanopy-ui/GroupedButtons/GroupedButtons.types";
import { FormComponentProps } from "../tools/typings";

const GroupedButtonsFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form } = formTools;

   const isMultiSelect = formElementJson.type === "select[type=buttonMultiple]";

   const onChange = (values: ValueType) => {
      if (values !== undefined) {
         form.setValue(formElementJson.name, values);
      }
   };

   const options = formElementJson.options as string[];
   const buttons = options?.map(option => ({ label: option, value: option }));

   return (
      <GroupedButtons
         buttons={buttons!}
         onChange={onChange}
         multiSelect={isMultiSelect}
         selectedValues={formElementJson.value}
      />
   );
};

export default GroupedButtonsFromJson;
