import React, { FC } from "react";
import { TooltipContainer } from "./HoverTooltip.styles";
import { NumberFormatOptions, formatNumber } from "../../../../../tools/string/formatNumber";
import { applyFormat } from "../tools/applyFormat";

interface PropsHoverTooltip {
   valueX: string | number | Date;
   valueY: string | number | Date;
   label?: string | number;
   valueYFormat?: NumberFormatOptions;
}

const HoverTooltip: FC<PropsHoverTooltip> = props => {
   const { valueX, valueY, label, valueYFormat } = props;

   return (
      <TooltipContainer>
         {label && (
            <span>
               {label && label}
               {label && valueX ? " " : ""}
               {valueX && valueX}:
               <br />
            </span>
         )}
         {applyFormat(valueY, valueYFormat)}
      </TooltipContainer>
   );
};

export default HoverTooltip;
