import React, { FC } from "react";
import { Title11 } from "../../../kanopy-ui/Texts/Texts";
import Button, { ButtonSize, ButtonVariant } from "../../../kanopy-ui/Button";
import { ButtonMarkAllAsRead, ButtonsContainer, MainContainer } from "./HeaderNotifications.styles";

export enum NotificationsListMode {
   ShowAll,
   ShowUnread,
   ShowRead
}

interface PropsHeaderNotifications {
   titleText: string;
   mode: NotificationsListMode;
   onModeChange: (mode: NotificationsListMode) => void;
   onMarkAllAsRead: () => void;
   hideMarkAllAsReadButton?: boolean;
}

const HeaderNotifications: FC<PropsHeaderNotifications> = props => {
   const { mode, onModeChange, onMarkAllAsRead, hideMarkAllAsReadButton, titleText } = props;

   return (
      <MainContainer>
         <Title11>{titleText}</Title11>
         <ButtonsContainer>
            <Button
               variant={
                  mode === NotificationsListMode.ShowAll
                     ? ButtonVariant.Filled
                     : ButtonVariant.TextOnly
               }
               size={ButtonSize.Medium}
               onClick={() => onModeChange(NotificationsListMode.ShowAll)}
            >
               All
            </Button>
            <Button
               variant={
                  mode === NotificationsListMode.ShowRead
                     ? ButtonVariant.Filled
                     : ButtonVariant.TextOnly
               }
               size={ButtonSize.Medium}
               onClick={() => onModeChange(NotificationsListMode.ShowRead)}
            >
               Read
            </Button>
            <Button
               variant={
                  mode === NotificationsListMode.ShowUnread
                     ? ButtonVariant.Filled
                     : ButtonVariant.TextOnly
               }
               size={ButtonSize.Medium}
               onClick={() => onModeChange(NotificationsListMode.ShowUnread)}
            >
               Unread
            </Button>
            {!hideMarkAllAsReadButton && (
               <ButtonMarkAllAsRead
                  variant={ButtonVariant.TextOnly}
                  size={ButtonSize.Medium}
                  onClick={() => onMarkAllAsRead()}
               >
                  Mark all as read
               </ButtonMarkAllAsRead>
            )}
         </ButtonsContainer>
      </MainContainer>
   );
};

export default HeaderNotifications;
