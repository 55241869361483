import React, { FC } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ColumnCentered } from "../../../../../kanopy-ui/Column/Column";
import { Row } from "../../../../../kanopy-ui/Row/Row";
import { Label9, Paragraph5 } from "../../../../../kanopy-ui/Texts/Texts";
import {
   CashText,
   CircularProgressContainer,
   ContentContainer,
   ForecastCardContainer,
   PercentageSymbolText,
   PercentageText,
   TextContainer,
   UnderlineText
} from "./ForecastCard.styles";

interface PropsForecastCard {
   color: string;
   trailColor: string;
   completionPercentage: number;
   title: string;
   cashText: string;
   description: string;
}

const ForecastCard: FC<PropsForecastCard> = props => {
   const { color, trailColor, completionPercentage, title, cashText, description } = props;

   return (
      <ForecastCardContainer>
         <Row>
            <CircularProgressContainer>
               <CircularProgressbarWithChildren
                  value={completionPercentage}
                  strokeWidth={10}
                  styles={buildStyles({
                     trailColor: trailColor,
                     pathColor: color
                  })}
               >
                  <ColumnCentered>
                     <TextContainer percentage={completionPercentage}>
                        {/*
                           // @TODO: check if this component is necessary
                           <PlusText color={color}>+</PlusText>
                        */}
                        <PercentageText color={color}>{completionPercentage}</PercentageText>
                        <PercentageSymbolText color={color}>%</PercentageSymbolText>
                     </TextContainer>
                     <UnderlineText color={color}>Expected</UnderlineText>
                  </ColumnCentered>
               </CircularProgressbarWithChildren>
            </CircularProgressContainer>
            <ContentContainer>
               <Label9>{title}</Label9>
               <CashText color={color}>{cashText}</CashText>
               <Paragraph5>{description}</Paragraph5>
            </ContentContainer>
         </Row>
      </ForecastCardContainer>
   );
};

export default ForecastCard;
