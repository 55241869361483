import styled from "styled-components";
import { Column } from "../../../../kanopy-ui/Column/Column";
import { Row } from "../../../../kanopy-ui/Row/Row";

export const FutureContainer = styled(Column)`
   width: fit-content;
`;

export const ForecastCardsContainer = styled(Row)`
   flex-wrap: wrap;
   row-gap: 24px;
   column-gap: 24px;
   max-width: 1592px;
   margin-top: 16px;
   margin-bottom: 32px;
`;
