import styled from "styled-components";
import { Label12 } from "../../../../kanopy-ui/Texts/Texts";

export const DashboardPageContent = styled("div")`
   display: flex; /** This allows for example the children to occupy remaining vertical space with flex-grow: 1 */
   flex-direction: column;
   width: 100%;
   height: 100%;
   padding-top: ${({ theme }) => theme.spacing(6, "px")};
   padding-bottom: 32px;
`;

export const DrawerContentContainer = styled("div")`
   padding-left: 13px;
   padding-right: 21px;
`;

export const LogoImg = styled("img")`
   margin-left: 12px;
   margin-top: 28px;
   margin-bottom: 64px;
   width: 120px;

   @media only screen and (min-width: 600px) {
      width: 168px;
   }
`;

export const SeparatorText = styled(Label12)`
   padding-left: 10px;
   user-select: none;
   margin-bottom: 4px;
`;

export const ButtonGroup = styled("div")`
   width: 100%;
   margin-bottom: 26px;
`;
