import React, { FC } from "react";
import { FormJson } from "../../../../../tools/api/forms/useServerJsonForm";
import FormFromJson from "../../../../common/FormFromJson/FormFromJson";

interface PropsFormAsTab {
   serverData: FormJson;
}

const FormAsTab: FC<PropsFormAsTab> = ({ serverData }) => {
   return <FormFromJson alreadyLoadedJson={{ json: serverData }} />;
};

export default FormAsTab;
