import React, { FC, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./tools/themes/tools/GlobalStyles/GlobalStyles";
import KanopyUiProvider from "./components/kanopy-ui/tools/KanopyUIProvider";
import MainRoutes from "./MainRoutes";
import { httpRequest } from "./tools/api/tools/httpRequest";
import GlobalDialogs from "./components/common/GlobalDialogs/GlobalDialogs";
import { currentTheme } from "./tools/themes/themeConfig";
import Highlighter from "./components/common/Highlighter/Highlighter";
import HttpRequestsHandler from "./components/common/HttpRequestsHandler/HttpRequestsHandler";
import GlobalSnackbars from "./components/common/GlobalSnackbars/GlobalSnackbars";

const App: FC = () => {
   const [loaded, setLoaded] = useState(false);

   // TODO: This should have it's own hook
   useEffect(() => {
      //  Google Maps API Key
      httpRequest<any, any>({
         url: "env?type=GOOGLE_STREETVIEW_KEY",
         method: "POST"
      }).then(response => {
         const script = document.createElement("script");
         script.src = `https://maps.googleapis.com/maps/api/js?key=${response.value}&libraries=places`;
         script.async = true;
         document.body.appendChild(script);
         setLoaded(true);
      });
   }, []);

   if (!loaded) {
      return null;
   }

   return (
      <HttpRequestsHandler>
         <ThemeProvider theme={currentTheme}>
            <KanopyUiProvider>
               <GlobalStyles />
               <BrowserRouter>
                  <Highlighter />
                  <MainRoutes />
               </BrowserRouter>
               <GlobalDialogs />
               <GlobalSnackbars />
            </KanopyUiProvider>
         </ThemeProvider>
      </HttpRequestsHandler>
   );
};

export default App;
