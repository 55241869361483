import React, { FC } from "react";
import FinicityConnectModal from "../../../common/FinicityConnectModal/FinicityConnectModal";
import { HeaderVariant } from "../../../common/Header/Header";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";

const FinicityConnectStep: FC = () => {
   return (
      <PageContainer
         variant={PageContainerVariant.Variant3}
         headerConfig={{ variant: HeaderVariant.RegisteredUser, applyZIndex: false }}
      >
         <FinicityConnectModal redirectOnFinish />
      </PageContainer>
   );
};

export default FinicityConnectStep;
