/**
 * Converts the first character of a string to upper case
 */
export function firstCharacterToUppercase(str: string): string {
   if (str === null || str === undefined) {
      return str;
   }

   if (str.length < 1) {
      return str;
   }

   return str.charAt(0).toUpperCase() + str.slice(1);
}
