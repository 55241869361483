import styled from "styled-components";

export const DimmerDiv = styled("div")`
   position: absolute;
   width: 100%;
   height: 100%;
   background-color: black;
   opacity: ${({ theme }) => theme.modal.dimmerOpacity};

   animation: appear-animation-dimmer 200ms ease-out;
   animation-play-state: running;

   @keyframes appear-animation-dimmer {
      from {
         opacity: 0;
      }
      to {
         opacity: ${({ theme }) => theme.modal.dimmerOpacity};
      }
   }
`;
