/**
 * If the array contains the item returns an array with the item removed,
 * if the array does not contain the item returns a new array with the item added.
 */
export function arrToggleItem<T>(arr: T[], item: T) {
   if (arr.includes(item)) {
      return arr.filter(i => i !== item);
   } else {
      return [...arr, item];
   }
}
