import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpRequest } from "../tools/httpRequest";
import { RequestError } from "../tools/typings";

export function useUser<Error extends RequestError, Response extends UserResponse>(props?: {
   options?: UseQueryOptions<void, Error, Response>;
}) {
   const url = "users/profile";

   return useQuery<void, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}

export interface UserResponse {
   links: Array<{ label: string; link: string }>;
   initials: string;
   fullName: string;
   email: string;
   logoutApi: string;
}
export interface UserAccountLink {
   label: string;
   link: string;
}
