import React, { FC } from "react";
import FormFromJsonAsModal, {
   PropsFormFromJsonAsModal
} from "../FormFromJsonAsModal/FormFromJsonAsModal";
import { AnimationContainer, InProgressContainer } from "./InProgressModal.styles";
import { useQueryClient } from "@tanstack/react-query";

interface PropsInProgressModal extends PropsFormFromJsonAsModal {
   onSuccess?: () => void;
}

const InProgressModal: FC<PropsInProgressModal> = props => {
   const queryClient = useQueryClient();

   const onAblyMessage = () => {
      queryClient.refetchQueries();
      props.onSuccess?.();
   };

   const onModalClose = (formWasModified: boolean) => {
      queryClient.refetchQueries();
      props.onModalClose?.(formWasModified);
   };

   return (
      <FormFromJsonAsModal
         jsonUrl={"edit-modal/in-progress"}
         renderCustomContainer={() => InProgressContainer}
         lottie={{ renderCustomLottieAnimationContainer: () => AnimationContainer }}
         disableSubmitButtonWhenFormIsIncomplete={false}
         redirectWithoutHistory
         disableClosing
         disableAutomaticModalTitleFromJson
         {...props}
         ably={{ onAblyMessage }}
         onModalClose={onModalClose}
      />
   );
};

export default InProgressModal;
