import React, { FC } from "react";
import DashboardPageContainer from "../components/DashboardPageContainer/DashboardPageContainer";
import DashboardHeader from "../components/DashboardHeader/DashboardHeader";
import { Label10 } from "../../../kanopy-ui/Texts/Texts";
import {
   DashboardHomeResponse,
   MissingDataPageJson,
   MyClientsPageJson,
   useDashboardHome
} from "../../../../tools/api/dashboard/home/useDashboardHome";
import LoadingSpinner from "../../../kanopy-ui/LoadingSpinner/LoadingSpinner";
import { readableDateFromUnixDate } from "../../../../tools/dates/readableDateFromUnixDate";
import MissingData from "./MissingData/MissingData";
import { RightContentContainer, TabList, UnderLine } from "../../../kanopy-ui/Tabs/Tabs.styles";
import Tabs, { Tab, TabPanel } from "../../../kanopy-ui/Tabs";
import MyClients from "./MyClients/MyClients";

interface PropsHome {
   withExternalData?: boolean;
   homeData?: DashboardHomeResponse;
}

const Home: FC<PropsHome> = props => {
   let { data: homeData } = useDashboardHome({
      options: { enabled: !props.withExternalData }
   });

   const buttons = homeData?.pages
      ? homeData.pages.filter(page => page.tabTitle).map(page => ({ label: page.tabTitle }))
      : [];

   if (props.withExternalData) {
      homeData = props.homeData;
   }

   if (!homeData) {
      return <LoadingSpinner />;
   }

   return (
      <DashboardPageContainer>
         <DashboardHeader
            title={homeData.title}
            subtitle={homeData.paragraph}
            backButtonRedirectUrl={homeData.backButton?.link}
         />
         {homeData.pages && (
            <Tabs id="home-tab">
               <TabList>
                  {buttons?.map((button, index) => (
                     <Tab index={index} label={button.label} />
                  ))}
                  <RightContentContainer>
                     <Label10>
                        {readableDateFromUnixDate(homeData?.date)?.toUpperCase() ?? ""}
                     </Label10>
                  </RightContentContainer>
               </TabList>
               {buttons.length > 0 && <UnderLine />}
               {homeData.pages.map((page, i) => (
                  <React.Fragment key={i}>
                     {page.hasOwnProperty("dataGrid") && (
                        <TabPanel
                           index={i}
                           style={{
                              ...{ maxWidth: 1000 },
                              ...((page as MyClientsPageJson).dataGrid?.options?.pagination
                                 ? { flexGrow: 1 }
                                 : {})
                           }}
                        >
                           <MyClients serverData={page} />
                        </TabPanel>
                     )}
                     {page.hasOwnProperty("slider") && (
                        <TabPanel index={i}>
                           <MissingData serverData={page as MissingDataPageJson} />
                        </TabPanel>
                     )}
                  </React.Fragment>
               ))}
            </Tabs>
         )}
      </DashboardPageContainer>
   );
};

export default Home;
