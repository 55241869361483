import React, { FC, HTMLAttributes, useEffect, useRef } from "react";
import { DimmerDiv } from "./DimmerBackground.styles";

const DimmerBackground: FC<HTMLAttributes<HTMLDivElement>> = props => {
   const ref = useRef<HTMLDivElement>(null);

   // Prevents scrolling the content behind the dimmer background
   useEffect(() => {
      ref.current?.addEventListener("wheel", e => {
         e.stopPropagation();
         e.preventDefault();
         return null;
      });
   }, []);

   return (
      <DimmerDiv {...props} ref={ref}>
         {props.children}
      </DimmerDiv>
   );
};

export default DimmerBackground;
