import React, { FC, useEffect, useState } from "react";
import { getFieldError } from "../../../../tools/validation/getFieldError";
import CheckboxGroup from "../../../kanopy-ui/CheckboxGroup/CheckboxGroup";
import { LabelAndValue } from "../../../kanopy-ui/tools/types";
import { FormComponentProps } from "../tools/typings";

const CheckBoxGroupFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form } = formTools;

   const [selection, setSelection] = useState<string[]>(formElementJson.value ?? []);

   useEffect(() => {
      if (formElementJson.type !== "select[multiple]") {
         return;
      }

      if (selection?.length > 0) {
         form.setValue(formElementJson.name, JSON.stringify(selection));
      } else {
         form.unregister(formElementJson.name);
      }
   }, [selection]);

   return (
      <CheckboxGroup
         label={formElementJson.label ?? ""}
         extraLabel={formElementJson.note}
         options={formElementJson.options as LabelAndValue[]}
         selectedByDefault={selection}
         onChange={setSelection}
         error={getFieldError({ formElementJson, form })}
      />
   );
};

export default CheckBoxGroupFromJson;
