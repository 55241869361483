import styled from "styled-components";
import Icon from "../../../kanopy-ui/Icon/Icon";

export const DateContainer = styled("div")`
   display: flex;
   white-space: break-spaces;
`;
export const EditDateUpdateIcon = styled(Icon)`
   color: black;
   margin-right: 0.2rem;
`;
