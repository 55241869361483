import React, { FC } from "react";
import { Paragraph } from "../../../kanopy-ui/Texts/Texts";
import { ParagraphStyled } from "./ParagraphFromJson.styles";
import { FormComponentProps } from "../tools/typings";

const ParagraphFromJson: FC<FormComponentProps> = ({ formElementJson }) => {
   return (
      <>
         {formElementJson.style === "font_unbold" || formElementJson.style === "text-center" ? (
            <ParagraphStyled dangerouslySetInnerHTML={{ __html: formElementJson.label ?? "" }} />
         ) : (
            <Paragraph dangerouslySetInnerHTML={{ __html: formElementJson.label ?? "" }} />
         )}
      </>
   );
};

export default ParagraphFromJson;
