import React, { FC, useState, Children, useEffect, useRef, useLayoutEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import Icon, { IconImage } from "../Icon/Icon";
import { Label9 } from "../Texts/Texts";
import { ButtonsContainer, SliderButton, SliderContainer } from "./Slider.styles";

interface PropsSlider {
   className?: string | undefined;
}

const Slider: FC<PropsSlider> = props => {
   const { children, className } = props;
   const [activePage, setActivePage] = useState(0);
   const totalPages = Children.count(children);

   const containerRef = useRef<HTMLDivElement>(null);
   const [containerWidth, setContainerWidth] = useState(0);

   // Effect to reset the slider position when the number of children changes
   useEffect(() => {
      if (activePage >= totalPages && activePage > 0) {
         setActivePage(0);
      }
   }, [totalPages, activePage]);

   /**
    * This is here to get the width of the container since we need it to fix
    * a react-swipeable-views responsive bug
    * */
   useLayoutEffect(() => {
      setContainerWidth(containerRef.current!.offsetWidth);
   }, []);

   const handleNextPageClick = () => {
      if (activePage < totalPages - 1) {
         setActivePage(activePage + 1);
      }
   };

   const handlePreviousPageClick = () => {
      if (activePage > 0) {
         setActivePage(activePage - 1);
      }
   };

   return (
      <SliderContainer ref={containerRef}>
         <SwipeableViews
            style={{ width: containerWidth }} // Fixes a responsive bug in react-swipeable-views. By default this should behave like "100%" but it does not.
            index={activePage}
            onChangeIndex={newIndex => setActivePage(newIndex)}
         >
            {children}
         </SwipeableViews>
         <ButtonsContainer className={className}>
            <SliderButton enabled={activePage > 0} onClick={handlePreviousPageClick}>
               <Icon iconImage={IconImage.IoIosArrowBack} size={24} />
               <Label9>Previous</Label9>
            </SliderButton>
            <SliderButton enabled={activePage < totalPages - 1} onClick={handleNextPageClick}>
               <Label9>Next</Label9>
               <Icon iconImage={IconImage.IoIosArrowForward} size={24} />
            </SliderButton>
         </ButtonsContainer>
      </SliderContainer>
   );
};

export default Slider;
