import React, { FC } from "react";
import { useTheme } from "styled-components";
import { simulateMouseClick } from "../../../tools/browser/simulateMouseClick";
import { useUrlParams } from "../../../tools/browser/useUrlParams";
import { useGetElementById } from "../../../tools/browser/useGetElementById";

/**
 * When a search url params contains a highlight parameter like this: url.com/#highlight=myElementId this component
 * finds that html element with id=myElementId and shows an animation to highlight it.
 *
 * This is currently used when clicking a notification to highlight the element that the
 * notification is talking about. It uses getElementById().
 * Also scrolls the page to focus that element.
 *
 * Another value can be added to the url ?click=myElement that will create a
 * fake click event in the element with that id, ideally to be used with a button.
 * This is used when clicking on a notification to go to a specific part of a page.
 * Both the highlight and click features can be used at the same time, for example:
 * url.com/#highlight=myElementId&click=otherElementId
 *
 * If you need a new component to be highlighted you only need to add an "id" property
 * to the html element and then when when the url matches the id that is all,
 * for example: <MyComponent id="whateverId"/>
 */
const Highlighter: FC = () => {
   const urlHashParams = useUrlParams<{ highlight: string; click: string }>();
   const theme = useTheme();
   const highlightColor = theme.colors.highlight;

   useGetElementById(urlHashParams?.click, element => {
      simulateMouseClick(element);
   });

   useGetElementById(urlHashParams?.highlight, element => {
      /** This timeout gives some time for the page elements to get into their final size so
      we make sure the final scroll position is going to be accurate **/
      setTimeout(() => {
         element?.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);

      element?.animate(
         [
            { boxShadow: `0px 0px 0px ${highlightColor}` },
            { boxShadow: `0px 0px 25px ${highlightColor}` },
            { boxShadow: `0px 0px 0px ${highlightColor}` }
         ],
         {
            duration: 800,
            delay: 500,
            iterations: 2
         }
      );
   });

   return <></>;
};

export default Highlighter;
