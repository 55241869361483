import React, { FC, useEffect } from "react";
import { BigCardButton, GroupedButtonsContainer } from "./GroupedButtons.styles";
import { PropsGroupedButtons } from "./GroupedButtons.types";
import useSetValues from "./useSetValues.hook";
import { optionExistInArray } from "./utils";

const GroupedButtons: FC<PropsGroupedButtons> = ({
   selectedValues,
   buttons,
   onChange,
   multiSelect
}) => {
   const { values, setValues } = useSetValues({ initialValues: selectedValues, multiSelect });

   useEffect(() => {
      onChange(values);
   }, [values, onChange]);

   return (
      <GroupedButtonsContainer>
         {buttons.map((button, i) => (
            <BigCardButton
               key={i}
               onClick={() => setValues(button.value)}
               selected={optionExistInArray(button.value, values)}
            >
               {button.label}
            </BigCardButton>
         ))}
      </GroupedButtonsContainer>
   );
};

export default GroupedButtons;
