import React, { FC } from "react";
import { useTheme } from "styled-components";
import { FuturePageJson } from "../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import { Label10, Title7 } from "../../../../kanopy-ui/Texts/Texts";
import ForecastCard from "./ForecastCard/ForecastCard";
import { ForecastCardsContainer, FutureContainer } from "./FutureTabContent.styles";
import TargetCard from "./TargetCard/TargetCard";

interface PropsFutureTabContent {
   serverData: FuturePageJson;
}

const FutureTabContent: FC<PropsFutureTabContent> = props => {
   const { serverData } = props;
   const theme = useTheme();
   const cardColors = [
      { main: theme.colors.primary4, trail: theme.colors.washedBlue },
      { main: theme.colors.primary1, trail: theme.colors.primaryWashed }
   ];

   const getCardColor = (cardIndex: number) =>
      cardIndex % 2 === 0 ? cardColors[0] : cardColors[1];

   return (
      <FutureContainer>
         <Title7>{serverData.title}</Title7>
         <Label10>{serverData.paragraph}</Label10>
         <ForecastCardsContainer>
            {serverData.forecastCards?.map((cardData, i) => (
               <ForecastCard
                  color={getCardColor(i).main}
                  trailColor={getCardColor(i).trail}
                  completionPercentage={cardData.progress}
                  title={cardData.title}
                  cashText={cardData.value}
                  description={cardData.paragraph}
                  key={i}
               />
            ))}
         </ForecastCardsContainer>
         <TargetCard serverData={serverData.targetCard} />
      </FutureContainer>
   );
};

export default FutureTabContent;
