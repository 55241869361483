import React, { FC } from "react";
import Drawer from "../../../../kanopy-ui/Drawer/Drawer";
import PageContainer, {
   PageContainerVariant
} from "../../../../common/PageContainer/PageContainer";
import { DashboardPageContent } from "./DashboardPageContainer.styles";
import { useSidePanel } from "../../../../../tools/api/dashboard/side-panel/useSidePanel";
import LoadingSpinner from "../../../../kanopy-ui/LoadingSpinner/LoadingSpinner";
import RenderDrawer from "./RenderDrawer";

const DashboardPageContainer: FC = ({ children }) => {
   const { data: sidePanelData } = useSidePanel();

   if (sidePanelData === undefined) {
      return <LoadingSpinner />;
   }

   return (
      <PageContainer variant={PageContainerVariant.LoggedIn}>
         <Drawer renderContent={() => <RenderDrawer data={sidePanelData} />}>
            <DashboardPageContent>{children}</DashboardPageContent>
         </Drawer>
      </PageContainer>
   );
};

export default DashboardPageContainer;
