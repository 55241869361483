import styled from "styled-components";

export const SpinnerContainer = styled("div")`
   display: flex;
   justify-content: center;
   width: 100%;
   height: 100px;
`;

export const Spinner = styled("div")`
   width: fit-content;
   text-align: center;
   position: absolute;
   left: 50%;
   top: 45%;
   transform: translate(-50%, -50%);

   & > div {
      width: 18px;
      height: 18px;
      background-color: ${({ theme }) => theme.loadingSpinner.color};

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
   }

   @-webkit-keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
         -webkit-transform: scale(0);
      }
      40% {
         -webkit-transform: scale(1);
      }
   }

   @keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
         -webkit-transform: scale(0);
         transform: scale(0);
      }
      40% {
         -webkit-transform: scale(1);
         transform: scale(1);
      }
   }
`;

export const Circle1 = styled("div")`
   -webkit-animation-delay: -0.32s !important;
   animation-delay: -0.32s !important;
   margin-right: 8px;
`;
export const Circle2 = styled("div")`
   -webkit-animation-delay: -0.16s !important;
   animation-delay: -0.16s !important;
   margin-right: 8px;
`;
export const Circle3 = styled("div")``;
