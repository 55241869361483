import React, { FC } from "react";
import { ValidationRule } from "react-hook-form";
import { getFieldError } from "../../../../tools/validation/getFieldError";
import TextArea from "../../../kanopy-ui/TextArea/TextArea";
import { FormComponentProps } from "../tools/typings";
import { TextAreaFromJsonContainer } from "./TextAreaFromJson.styles";

const TextAreaFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools, isLastElement } = props;
   const { form } = formTools;

   return (
      <TextAreaFromJsonContainer isLastElement={isLastElement}>
         <TextArea
            label={formElementJson.label ?? ""}
            placeholder={formElementJson.placeholder ?? ""}
            {...form.register<any>(formElementJson.name, {
               required: Boolean(formElementJson.required),
               pattern: (formElementJson.regex
                  ? new RegExp(formElementJson.regex)
                  : undefined) as ValidationRule<RegExp>
            })}
            error={getFieldError({ formElementJson, form })}
         />
      </TextAreaFromJsonContainer>
   );
};

export default TextAreaFromJson;
