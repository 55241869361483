import React, { FC, useEffect, useRef } from "react";
import {
   ColumnContainerForModal,
   FixedBottomContainer,
   FormContainerForModal
} from "./ContainedInModalPageStructure.styles";

interface PropsContainedInModaPageStructure {
   onFixedBottomRef: (ref: React.RefObject<HTMLDivElement>) => void;
   renderCustomContainer?: () => React.ComponentType;
}

const ContainedInModaPageStructure: FC<PropsContainedInModaPageStructure> = props => {
   const { onFixedBottomRef, renderCustomContainer, children } = props;
   const fixedBottomRef = useRef<HTMLDivElement>(null);
   const CustomContainer = renderCustomContainer?.() ?? React.Fragment;

   useEffect(() => {
      onFixedBottomRef(fixedBottomRef);
   }, [fixedBottomRef, onFixedBottomRef]);

   return (
      <ColumnContainerForModal>
         <FormContainerForModal>
            <CustomContainer>{children}</CustomContainer>
         </FormContainerForModal>
         <FixedBottomContainer ref={fixedBottomRef} />
      </ColumnContainerForModal>
   );
};

export default ContainedInModaPageStructure;
