import { FC } from "react";
import PageContainer from "../../../common/PageContainer/PageContainer";
import { LoginCard, SignInFormTitle } from "./Login.styles";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import { CardVariant } from "../../../kanopy-ui/Card";
import { AbsoluteCenter } from "../../../common/PageContainer/PageContainer.styles";

interface PropsLogin {
   /**
    * This login form works like any other form in the application, this means
    * it can also redirect to a specific url after clicking submit like other
    * forms do, as long as the form json contains that instruction.
    * If you set it to false the redirect instruction of the json will be ignored.
    * By default this is "false" and it's what makes sense in general, because is the MainRoutes
    * component the one that will change the page after clicking submit on the login
    * form, based on the current login status.
    */
   allowJsonFormToRedirect?: boolean;
}

const Login: FC<PropsLogin> = props => {
   const { allowJsonFormToRedirect = true } = props;

   return (
      <PageContainer>
         <AbsoluteCenter top="42%">
            <LoginCard variant={CardVariant.StrongShadow}>
               <FormFromJson
                  jsonUrl={"sign-in"}
                  renderFormComponent={({ formElementJson }) =>
                     formElementJson.type === "title" ? (
                        <SignInFormTitle>{formElementJson.label}</SignInFormTitle>
                     ) : null
                  }
                  redirectToNextFormOnSubmit={allowJsonFormToRedirect}
               />
            </LoginCard>
         </AbsoluteCenter>
      </PageContainer>
   );
};

export default Login;
