import { useQueryClient } from "@tanstack/react-query";
import { FormJson } from "../../../../tools/api/forms/useServerJsonForm";
import { Form, JsonFormData, PropsFormFromJson } from "./typings";
import { tryToGetErrorStatusCode } from "../../../../tools/api/tools/tryToGetErrorStatusCode";
import { useSendJsonForm } from "../../../../tools/api/forms/useSendJsonForm";
import { tryToGetErrorMessage } from "../../../../tools/api/tools/tryToGetErrorMessage";
import { useState } from "react";
import { useAddErrorsFromServer } from "../../../../tools/validation/useAddErrorsFromServer";
import { useRedirect } from "../../../../tools/browser/useRedirect";
import { useSnackbar } from "../../GlobalSnackbars/tools/useSnackbar";

/**
 * This hook returns the function that submits the form, also a string containing a submit form error if any.
 */
export function useSubmitForm(props: {
   formJson?: FormJson;
   form: Form;
   formProps: PropsFormFromJson;
}): SubmitFormTools {
   const { formJson, formProps, form } = props;
   const { redirect } = useRedirect();
   const queryClient = useQueryClient();
   const sendFormMutation = useSendJsonForm();
   const [errorResponse, setErrorResponse] = useState<string>();
   useAddErrorsFromServer(sendFormMutation.error?.errors, form.setError);
   const { openSnackbar } = useSnackbar();

   const submitForm = async (submitProps?: { submitUrl?: string; redirectUrl?: string }) => {
      form.handleSubmit(async dataToSubmit => {
         const dataToSend = {
            ...(dataToSubmit ?? {})
         };

         let response: JsonFormData | undefined = undefined;

         if (submitProps?.submitUrl !== undefined) {
            setErrorResponse(undefined);
            try {
               response = await sendFormMutation.mutateAsync({
                  url: submitProps?.submitUrl,
                  params: dataToSend
               });
            } catch (e) {
               if (tryToGetErrorStatusCode(e) === 412) {
                  setErrorResponse(tryToGetErrorMessage(e));
               }
               return;
            }
         }

         if (response?.msg !== undefined) {
            openSnackbar({ message: String(response.msg) });
         }

         /**
          * If this is executed it means the await above didn't throw error (or wasn't called) so we can proceed
          */
         if (response !== undefined) {
            // check this only if submit return with an API call
            if (response?.token !== undefined) {
               localStorage.setItem("session", String(response.token));
            } else {
               localStorage.removeItem("session");
            }
         }

         /**
          * When the form is submitted all cache needs update to reflect any possible server change
          */
         queryClient.refetchQueries();

         if (formProps.redirectToNextFormOnSubmit !== false) {
            // We search for the rute to redirect after sending the form
            const url = (response?.next || submitProps?.redirectUrl || formJson?.next) as string;
            const withoutHistory = formProps.redirectWithoutHistory;

            if (url) {
               redirect({ url, withoutHistory });
            } else {
               console.error("The server didn't return a route to go after this form");
            }
         }
         formProps.onSubmitFinish?.(response);
      })();
   };

   return { submitForm, errorResponse, isLoading: sendFormMutation.isLoading };
}

/**
 * Contains the function that submits the form, also a string containing a submit form response error if any.
 */
export interface SubmitFormTools {
   submitForm: SubmitFormFn;
   errorResponse?: string;
   isLoading: boolean;
}

export type SubmitFormFn = (submitProps?: {
   submitUrl?: string;
   redirectUrl?: string;
}) => Promise<void>;
