import React, { FC } from "react";

interface PropsOpacitySwitcher {
   /** When this is true the component1 is visible, otherwise component2 is visible */
   componentSwitch: boolean;
   component1: () => React.ReactElement;
   component2: () => React.ReactElement;
   animDurationMilliseconds?: number;
}

/**
 * Switches between 2 components with an opacity effect. It's meant to be used for small component animations, not to animate
 * ui with complexity since both components will be rendered at the same time all the time one of them with opacity 0.
 * The component that will occupy the space is the component1 the component2 will be floating on top, this means that ideally
 * this should be used when both components have the same size to avoid unwanted results.
 * Currently the use case for this is to make a smooth transition between 2 icon versions.
 */
const OpacitySwitcher: FC<PropsOpacitySwitcher> = props => {
   const { componentSwitch, component1, component2, animDurationMilliseconds = 750 } = props;

   return (
      <div style={{ position: "relative" }}>
         <div
            style={{
               opacity: componentSwitch ? 1 : 0,
               transition: `opacity ${animDurationMilliseconds}ms`
            }}
         >
            {component1()}
         </div>
         <div
            style={{
               position: "absolute",
               top: 0,
               left: 0,
               opacity: componentSwitch ? 0 : 1,
               transition: `opacity ${animDurationMilliseconds}ms`
            }}
         >
            {component2()}
         </div>
      </div>
   );
};

export default OpacitySwitcher;
