import styled from "styled-components";

export const PanelContainer = styled("div")`
   display: flex;
   flex-wrap: wrap;
   gap: ${({ theme }) => theme.spacing(3, "px")};

   @media only screen and (min-width: 1200px) {
      & > div {
         flex-basis: calc(50% - ${({ theme }) => theme.spacing(3, "px")});
      }
   }

   @media only screen and (min-width: 1400px) {
      & > div {
         flex-basis: calc(33% - ${({ theme }) => theme.spacing(3, "px")});
      }
   }
`;
