import { FC } from "react";
import PageContainer from "../../common/PageContainer/PageContainer";
import {
   BackgroundIllustration,
   CubeImg,
   IllustrationContainer,
   TextsContainer,
   TitleIllustrationContainer
} from "./NotFound.styles";
import signupIllustration from "../../../assets/signup-illustration.svg";
import cubeImg from "../../../assets/cube.svg";
import { Title2 } from "../../kanopy-ui/Texts/Texts";
import { AbsoluteCenter } from "../../common/PageContainer/PageContainer.styles";
import { Container } from "../../kanopy-ui/Container/Container.styles";

const SignUp: FC = () => {
   return (
      <PageContainer>
         <AbsoluteCenter>
            <Container>
               <TitleIllustrationContainer>
                  <CubeImg src={cubeImg} />
                  <TextsContainer>
                     <Title2>Nothing found here</Title2>
                  </TextsContainer>
                  <IllustrationContainer>
                     <BackgroundIllustration src={signupIllustration} />
                  </IllustrationContainer>
               </TitleIllustrationContainer>
            </Container>
         </AbsoluteCenter>
      </PageContainer>
   );
};

export default SignUp;
