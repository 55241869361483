import { FC, useRef } from "react";
import { Types } from "ably";
import { AblyData, useAbly } from "../../../../tools/ably/useAbly";
import { FormComponentProps } from "../tools/typings";
import { useRedirect } from "../../../../tools/browser/useRedirect";

export type AblyMessage = Types.Message | undefined;

const AblyListenerFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;

   const onListenerCalled = useRef(false);
   const { connect } = useAbly();
   const { redirect } = useRedirect();

   const ablyData: AblyData = {
      key: formElementJson.key!,
      channel: formElementJson.channel!,
      subscription: formElementJson.subscription!,
      next: formElementJson.next
   };

   /**
    * Currently in this project we only use ably to redirect
    */
   const onAblyMessage = (message?: AblyMessage) => {
      formTools.formProps.ably?.onAblyMessage?.(message);
      if (formTools.formProps.redirectToNextFormOnSubmit) {
         redirect({
            url: ablyData.next,
            withoutHistory: formTools.formProps.redirectWithoutHistory
         });
      }
   };

   if (onListenerCalled.current === false) {
      // Just in case ably doesn't work. We don't rely on the library to continue
      const timeout = setTimeout(() => {
         onAblyMessage();
         console.log(
            "WARNING: 100 seconds passed and Ably did not respond. The ably function was called with a timeout instead"
         );
      }, 100000);
      const automaticConnection = formTools.formProps.ably?.onAblyListener?.(ablyData);
      if (automaticConnection !== false) {
         connect(ablyData, (message: AblyMessage) => {
            clearTimeout(timeout);
            onAblyMessage(message);
         });
      }
      onListenerCalled.current = true;
   }

   return null;
};

export default AblyListenerFromJson;
