import styled from "styled-components";
import Button from "../../../kanopy-ui/Button";

export const MainContainer = styled("div")`
   display flex;
   flex-direction: column;
   row-gap: 20px;
   padding-top: 20px;
   padding-bottom: 14px;
   align-items: center;
   border-bottom: ${({ theme }) => theme.borders.border7};
`;

export const ButtonsContainer = styled("div")`
   display: grid;
   grid-template-columns: auto auto auto 1fr;
   justify-items: center;
   width: 100%;
   align-items: center;
   padding-left: 12px;
   padding-right: 12px;
`;

export const ButtonMarkAllAsRead = styled(Button)`
   justify-self: right;
`;
