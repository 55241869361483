import styled from "styled-components";
import { ButtonStyled } from "../../../../kanopy-ui/Button";
import Card from "../../../../kanopy-ui/Card";
import { Label11, LabelError } from "../../../../kanopy-ui/Texts/Texts";
import { Row } from "../../../../kanopy-ui/Row/Row";

export const LineChartCardContainer = styled(Card).withConfig({
   shouldForwardProp: p => !["chartsAmount", "animDurationMs", "animDelayMs"].includes(p)
})<{ chartsAmount: number; animDurationMs: number; animDelayMs: number }>`
   transition: all ${({ animDurationMs }) => `${animDurationMs}ms`};
   transition-delay: ${({ animDelayMs }) => `${animDelayMs}ms`};
   transition-timing-function: ease-in-out;
   padding: 30px;
   max-width: 1592px;
`;

export const ChartTitle = styled(Label11)``;

export const ChartControlsContainer = styled("div")`
   position: relative;
   margin-bottom: 4px;
`;

export const TitleContainer = styled("div")`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 23px;
`;

export const TitleTextContainer = styled(Row)`
   column-gap: 8px;
`;

export const ChartButtonsContainer = styled("div")`
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   gap: 32px;

   @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      gap: 16px;
   }
`;

export const ButtonDashboard = styled(ButtonStyled).withConfig({
   shouldForwardProp: p => !["active"].includes(p)
})<{ active?: boolean }>`
   color: ${({ active, theme }) =>
      active ? theme.buttonDashboard.colorActive : theme.buttonDashboard.color};
   background-color: ${({ active, theme }) =>
      active ? theme.buttonDashboard.backgroundColorActive : theme.buttonDashboard.backgroundColor};
   border: ${({ theme }) => theme.buttonDashboard.border};
   border-radius: ${({ theme }) => theme.buttonDashboard.borderRadius};
   font-family: ${({ theme }) => theme.buttonDashboard.fontFamily};
   font-size: ${({ theme }) => theme.buttonDashboard.fontSize}px;
   font-weight: ${({ theme }) => theme.buttonDashboard.weight};
   line-height: ${({ theme }) => theme.buttonDashboard.lineHeight}px;
   letter-spacing: ${({ theme }) => theme.buttonDashboard.letterSpacing}em;

   &:hover {
      color: ${({ theme }) => theme.buttonDashboard.hoverColor};
      background-color: ${({ theme }) => theme.buttonDashboard.hoverBackgroundColor};
   }
`;

export const AlertLabel = styled(LabelError)`
   font-size: 14px;
   margin-top: 13px;
   width: fit-content;

   padding: 5px 20px;
   border: 0.5px;
   border-color: red;
   border-style: solid;
   border-radius: 20px;
`;

export const ChartContainer = styled("div").withConfig({
   shouldForwardProp: p => !["chartsAmount"].includes(p)
})<{ chartsAmount: number }>`
   display: grid;
   height: 500px;
   ${({ chartsAmount }) => (chartsAmount === 1 ? `grid-template-rows: 1fr;` : "")}
   ${({ chartsAmount }) => (chartsAmount === 2 ? `grid-template-rows: 1fr 0.6fr;` : "")};
   ${({ chartsAmount }) => (chartsAmount > 2 ? `grid-template-rows: 1fr repeat(0.6fr);` : "")};
`;
