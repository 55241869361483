import styled from "styled-components";
import Card from "../Card";
import { IconButton } from "../IconButton/IconButton.styles";
import { Paragraph3, Paragraph4 } from "../Texts/Texts";

export const MainContainer = styled("div")`
   display: flex;
   flex-direction: column;
   width: 100%;
`;

export const EmptyUploadBoxContainer = styled("div").withConfig({
   shouldForwardProp: p => !["isDragActive"].includes(p)
})<{ isDragActive?: boolean }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 17px;
   height: 105px;
   user-select: none;
   cursor: pointer;
   transition: background-color 150ms;

   border: ${({ theme }) => theme.fileUploadBox.border};
   border-radius: ${({ theme }) => theme.fileUploadBox.borderRadius};

   ${props =>
      props.isDragActive
         ? `background-color: ${props.theme.fileUploadBox.backgroundColorFileDrag};`
         : ""}

   p {
      text-align: center;
   }

   &:hover {
      background-color: ${({ theme }) => theme.fileUploadBox.backgroundColorFileDrag};
   }
`;

export const FilledUploadContainer = styled(Card)`
   display: flex;
   flex-direction: row;
   align-items: center;
   position: relative;
   padding-top: 15px;
   padding-bottom: 19px;
   padding-left: 29px;
   padding-right: 60px;
   height: 105px;
   width: 100%;
   margin: 0;

   span {
      line-height: unset;
   }
`;

export const FilePartContainer = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100%;
   cursor: pointer;
   user-select: none;
`;

export const FileDescription = styled("div")`
   display: flex;
   flex-direction: column;
   margin-left: 13.3px;
`;

export const UploadBoxLabel = styled(Paragraph4)`
   margin-bottom: 6px;
`;

export const ErrorText = styled(Paragraph3)`
   color: ${({ theme }) => theme.fileUploadBox.errorTextColor};
`;

export const TypeIcon = styled("img")`
   height: 27px;
   margin: 0;
   margin-bottom: 5px;
`;

export const FileUploadedThumbnail = styled("img")`
   height: 70px;
   border-radius: ${({ theme }) => theme.fileUploadBox.borderRadiusThumbnail};
`;

export const CloseButton = styled(IconButton)`
   position: absolute;
   right: 0px;
   top: 50%;
   transform: translateY(-50%);

   svg {
      width: 20px;
      height: 20px;
   }
`;
