import * as Ably from "ably";

export function useAbly() {
   const connect = (ablyData: AblyData, onMessage: (message: Ably.Types.Message) => void) => {
      const { key, channel, subscription } = ablyData;

      let ably = new Ably.Realtime({ key });
      ably.connection.on("connected", stateChange => {
         console.log("New connection state is " + stateChange.current);
      });

      let channelInstance = ably.channels.get(channel);
      channelInstance.subscribe(subscription, message => {
         onMessage(message);
         ably.close();
      });
   };

   return { connect };
}

export interface AblyData {
   key: string;
   channel: string;
   subscription: string;
   next?: string;
}
