import React, { FC } from "react";
import Button from "../../../kanopy-ui/Button";
import { httpRequest } from "../../../../tools/api/tools/httpRequest";
import { ButtonVariant } from "../../../kanopy-ui/Button";
import { FormComponentProps } from "../tools/typings";
import { useSnackbar } from "../../GlobalSnackbars/tools/useSnackbar";

const ApiLinkButtonFromJson: FC<FormComponentProps> = props => {
   const { formElementJson } = props;
   const { openSnackbar } = useSnackbar();

   const handleClick = async () => {
      if (!formElementJson.api?.url) {
         return;
      }

      const response = await httpRequest<any, any>({
         url: formElementJson.api?.url,
         method: "POST"
      });

      if (response.msg !== undefined) {
         openSnackbar(response.msg);
      }
   };

   return (
      <Button variant={ButtonVariant.TextOnly} onClick={handleClick}>
         {formElementJson.label}
      </Button>
   );
};

export default ApiLinkButtonFromJson;
