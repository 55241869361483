import React, { FC } from "react";
import { RatioTable } from "../../../../../../tools/api/dashboard/financial-health/useDashboardFinancialHealth";
import DataGrid from "../../../../../kanopy-ui/DataGrid/DataGrid";
import Card, { CardVariant } from "../../../../../kanopy-ui/Card";
import { CardsContainer, Header } from "./RatiosTable.styles";
import MasonryGrid from "../../../../../kanopy-ui/MasonryGrid/MasonryGrid";
import { ColorVariant } from "../../../../../kanopy-ui/DataGrid/DataGrid.types";
import { Label11 } from "../../../../../kanopy-ui/Texts/Texts";
import { IconTooltip } from "../../../../../kanopy-ui/IconTooltip/IconTooltip.styles";
import { IconImage } from "../../../../../kanopy-ui/Icon/Icon";

interface PropsRatiosTable {
   serverData: RatioTable[];
}

const RatiosTable: FC<PropsRatiosTable> = props => {
   const { serverData } = props;

   return (
      <CardsContainer>
         <MasonryGrid columnsCountBreakPoints={{ 350: 1, 1200: 2 }} gutter="26px">
            {serverData.map((cardData, i) => (
               <Card variant={CardVariant.DashboardCard} key={i}>
                  <Header>
                     <Label11>{cardData.title}</Label11>
                     <IconTooltip
                        data-tip={`${cardData.tooltip}`}
                        data-for={`headerTooltip`}
                        iconImage={IconImage.BiInfoCircle}
                     />
                  </Header>
                  <DataGrid
                     {...cardData.dataGrid}
                     columnSettings={{ label: { flex: 2, fontWeight: 400 } }}
                     options={{ colorVariant: ColorVariant.Transparent }}
                  />
               </Card>
            ))}
         </MasonryGrid>
      </CardsContainer>
   );
};

export default RatiosTable;
