import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import { HeaderVariant } from "../../../common/Header/Header";
import Button from "../../../kanopy-ui/Button";
import {
   ProfileSetupContainer,
   StartStepPageContainer,
   StartStepParagraph,
   StartSubTitle,
   StartTitle
} from "./ProfileSetupStartStep.styles";

import Container from "../../../kanopy-ui/Container";
import { AbsoluteCenter } from "../../../common/PageContainer/PageContainer.styles";
import { PropsFormFromJson } from "../../../common/FormFromJson/tools/typings";

const ProfileSetupStartStep: FC<Partial<PropsFormFromJson>> = props => {
   const location = useLocation();

   return (
      <PageContainer
         variant={PageContainerVariant.LoggedOut}
         headerConfig={{ variant: HeaderVariant.RegisteredUser }}
      >
         <AbsoluteCenter top="40%">
            <Container>
               <ProfileSetupContainer>
                  <StartStepPageContainer>
                     <FormFromJson
                        jsonUrl={location.pathname}
                        renderFormComponent={({ formElementJson, formTools }) =>
                           formElementJson.type === "title" ? (
                              formElementJson.style === "font_size_25" ? (
                                 <StartSubTitle>{formElementJson.label}</StartSubTitle>
                              ) : (
                                 <StartTitle>{formElementJson.label}</StartTitle>
                              )
                           ) : formElementJson.type === "paragraph" ? (
                              <StartStepParagraph
                                 dangerouslySetInnerHTML={{ __html: formElementJson.label ?? "" }}
                              />
                           ) : formElementJson.type === "input[type=submit]" ? (
                              <Button
                                 onClick={() =>
                                    formTools.submitFormTools.submitForm({
                                       submitUrl: formElementJson?.api?.url,
                                       redirectUrl: formElementJson?.next
                                    })
                                 }
                                 loading={formTools.submitFormTools.isLoading}
                              >
                                 {formElementJson.label}
                              </Button>
                           ) : null
                        }
                        disableSubmitButtonWhenFormIsIncomplete={false}
                        {...props}
                     />
                  </StartStepPageContainer>
               </ProfileSetupContainer>
            </Container>
         </AbsoluteCenter>
      </PageContainer>
   );
};

export default ProfileSetupStartStep;
