import React, { FC } from "react";
import { TitleInsideCard, TitleNormal } from "./SmallTitleFromJson.styles";
import { FormComponentProps } from "../tools/typings";

const SmallTitleFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { textToNotRender } = formTools;

   if (textToNotRender === formElementJson.label) {
      return null;
   }

   if (formElementJson.type === "small-title") {
      return <TitleNormal>{formElementJson.label}</TitleNormal>;
   } else {
      return <TitleInsideCard>{formElementJson.label}</TitleInsideCard>;
   }
};

export default SmallTitleFromJson;
