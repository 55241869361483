import React, { FC } from "react";
import { ProgressBarContainer, ProgressBarContent, ProgressBarText } from "./ProgressBar.styles";

interface PropsProgressBar {
   percentage: number;
   showText?: boolean;
   className?: string;
   variant?: ProgressBarVariant;
}

export enum ProgressBarVariant {
   Default,
   Dashboard,
   LeftPanel
}

const ProgressBar: FC<PropsProgressBar> = props => {
   const { percentage, showText = true, className, variant = ProgressBarVariant.Default } = props;

   return (
      <>
         <ProgressBarContainer className={className} variant={variant}>
            <ProgressBarContent percentage={percentage} variant={variant} />
         </ProgressBarContainer>
         {showText && <ProgressBarText variant={variant}>{percentage}% complete</ProgressBarText>}
      </>
   );
};

export default ProgressBar;
