import styled from "styled-components";
import { Label, Label10 } from "../Texts/Texts";

export const CheckBoxGroupContainer = styled("div")`
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 24px;
   row-gap: 12px;

   & > div {
      width: 50%;
   }
`;

export const CheckBoxGroupLabel = styled(Label10)`
   margin-bottom: 12px;
`;

export const CheckBoxGroupExtraLabel = styled(Label)`
   margin-bottom: ${({ theme }) => theme.spacing(2, "px")};
`;
