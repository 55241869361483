import React, { FC } from "react";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";
import {
   BackgroundIllustration,
   IllustrationContainer,
   ParagraphOfIllustration,
   RegistrationContainer,
   SignupCard,
   SignupFormTitle,
   TextsContainer,
   TitleIllustrationContainer,
   TitleOfIllustration
} from "./SignUp.styles";
import FormFromJson from "../../../common/FormFromJson/FormFromJson";
import { CardVariant } from "../../../kanopy-ui/Card";
import { useLocation } from "react-router-dom";
import { useLeftPanel } from "../../../../tools/api/registration/left-panel/useLeftPanel";

const SignUp: FC = () => {
   const search = useLocation().search;
   const source = new URLSearchParams(search).get("source");

   const { data: leftPanelData } = useLeftPanel({ source });

   return (
      <PageContainer variant={PageContainerVariant.Variant2}>
         <RegistrationContainer fixedWidth>
            <TitleIllustrationContainer>
               <TextsContainer>
                  <TitleOfIllustration>
                     {leftPanelData ? leftPanelData.title : ""}
                  </TitleOfIllustration>
                  <ParagraphOfIllustration
                     dangerouslySetInnerHTML={{
                        __html: leftPanelData ? leftPanelData.paragraph : ""
                     }}
                  ></ParagraphOfIllustration>
               </TextsContainer>
               <IllustrationContainer noMarginBottom>
                  <BackgroundIllustration src={leftPanelData ? leftPanelData.image : ""} />
               </IllustrationContainer>
            </TitleIllustrationContainer>
            <SignupCard variant={CardVariant.StrongShadow}>
               <FormFromJson
                  jsonUrl={"sign-up/user-create" + (source !== null ? "?source=" + source : "")}
                  renderFormComponent={({ formElementJson }) =>
                     formElementJson.type === "title" ? (
                        <SignupFormTitle>{formElementJson.label}</SignupFormTitle>
                     ) : null
                  }
               />
            </SignupCard>
         </RegistrationContainer>
      </PageContainer>
   );
};

export default SignUp;
