import { PropsFormFromJson } from "./typings";

/**
 * Cancelling a form means we don't make a form change and we don't get redirected, we just call an event.
 * This is mostly used in modal forms when the modal is closed by the user or to close the modal by code.
 */
export function useCancelForm(props: { formProps: PropsFormFromJson }) {
   const { formProps } = props;

   const cancelForm = () => {
      formProps.onCancelForm?.();
   };

   return { cancelForm };
}
