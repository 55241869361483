import styled from "styled-components";
import { IconButton } from "../IconButton/IconButton.styles";
import { ModalVariant } from "./Modal";
import { ZIndexValues } from "../tools/ZIndexValues";

export const ModalContainer = styled("div").withConfig({
   shouldForwardProp: p => !["zIndex"].includes(p)
})<{ zIndex?: number }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   position: fixed;
   width: 100vw;
   height: 100vh;
   top: 0;
   left: 0;
   z-index: ${props => props.zIndex ?? ZIndexValues.Modals};
`;

export const ModalPaddingsContainer = styled("div")`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   display: grid;
`;

export const ModalWindow = styled("div").withConfig({
   shouldForwardProp: p => !["variant"].includes(p)
})<{ variant: ModalVariant }>`
   display: flex;
   flex-direction: column;
   position: relative;
   width: 96vw;
   max-height: 100%;
   max-width: 618px;
   z-index: 1;
   background-color: ${({ theme }) => theme.modal.backgroundColor};
   border-radius: ${({ theme }) => theme.modal.borderRadius};
   ${({ variant }) => (variant === ModalVariant.AdjustToContent ? "height: fit-content;" : "")}
   ${({ variant }) => (variant === ModalVariant.AdjustToContent ? "width: fit-content;" : "")}
   ${({ variant }) => (variant === ModalVariant.AdjustToContent ? "max-width: unset;" : "")}
   ${({ variant }) => (variant === ModalVariant.AdjustToContent ? "max-height: unset;" : "")}

   animation: appear-animation-window 200ms ease-out;
   animation-play-state: running;

   @keyframes appear-animation-window {
      from {
         transform: translateY(10%);
      }
      to {
         transform: translateY(0);
      }
   }
`;

export const ModalTitleBar = styled("div")`
   display: flex;
   align-items: center;
   padding-left: 24px;
   padding-right: 70px;
   min-height: 54px;
   border-bottom: ${({ theme }) => theme.modal.titleBarBorderBottom};
`;

export const ModalChildren = styled("section")`
   width: 100%;
   flex: 1 1 auto;
   overflow: hidden;
   border-radius: ${({ theme }) => theme.modal.borderRadius};
`;

export const CloseButton = styled(IconButton)`
   position: absolute;
   padding-top: 14px;
   padding-bottom: 12px;
   padding-left: 20px;
   padding-right: 20px;
   top: 0;
   right: 0;
`;
