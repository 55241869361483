import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import GenericWizardStep from "../Wizard/GenericWizardStep/GenericWizardStep";
import ProfileSetupReviewStep from "./ProfileSetupReviewStep/ProfileSetupReviewStep";
import ProfileSetupDoneStep from "./ProfileSetupDoneStep/ProfileSetupDoneStep";
import ProfileSetupStartStep from "./ProfileSetupStartStep/ProfileSetupStartStep";
import NotFound from "../NotFound/NotFound";

const ProfileSetup: FC = () => {
   return (
      <Routes>
         <Route path="step-page/review" element={<ProfileSetupReviewStep />} />
         <Route path="step-page/done" element={<ProfileSetupDoneStep />} />
         <Route path="step-page/*" element={<GenericWizardStep />} />
         <Route path="start" element={<ProfileSetupStartStep />} />
         <Route path="/" element={<ProfileSetupStartStep />} />
         <Route path="*" element={<NotFound />} />
      </Routes>
   );
};

export default ProfileSetup;
