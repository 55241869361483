import React, { FC, useEffect, useState } from "react";
import { FileInServer } from "../../../../tools/api/forms/useServerJsonForm";
import FileUploadBox, {
   FileTypesAllowed,
   UploadedFile
} from "../../../kanopy-ui/FileUploadBox/FileUploadBox";
import { FormComponentProps } from "../tools/typings";
import { FileUploadBoxFromJsonContainer } from "./FileUploadBoxFromJson.styles";

const FileUploadBoxFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools, isLastElement } = props;
   const { form } = formTools;

   const [uploadedFile, setUploadedFile] = useState<UploadedFile | undefined>(
      formElementJson.value
         ? {
              name: formElementJson.value?.text,
              size: formElementJson.value?.size,
              url: formElementJson.value?.link
           }
         : undefined
   );

   useEffect(() => {
      if (formElementJson?.value === undefined) {
         return;
      }

      const valueFromJson = formElementJson?.value;

      setUploadedFile({
         name: valueFromJson.text,
         size: valueFromJson.size,
         url: valueFromJson.link
      });
   }, [formElementJson?.value]);

   const handleFileUploadComplete = (file: FileInServer | undefined) => {
      if (file === undefined) {
         setUploadedFile(undefined);
         form.setValue(formElementJson.name, "");
         return;
      }

      setUploadedFile({
         name: file.value.text,
         size: file.value.size,
         url: file.value.link
      });

      form.setValue(formElementJson.name, file.value);
   };

   return (
      <FileUploadBoxFromJsonContainer isLastElement={isLastElement}>
         <FileUploadBox
            label={formElementJson.label}
            uploadUrl={formElementJson.api?.api}
            fileKeyNameInJson={formElementJson.name}
            currentUploadedFile={uploadedFile}
            fileTypesAllowed={
               formElementJson.type === "image_upload" ? FileTypesAllowed.Images : undefined
            }
            fileUploadedThumbnail={
               formElementJson.type === "image_upload" ? uploadedFile?.url ?? undefined : undefined
            }
            onFileUploadComplete={handleFileUploadComplete}
         />
      </FileUploadBoxFromJsonContainer>
   );
};

export default FileUploadBoxFromJson;
