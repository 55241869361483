import React, { FC } from "react";
import { InfoParagraph, InfoIcon } from "./InfoParagraphFromJson.styles";
import { IconImage } from "../../../kanopy-ui/Icon/Icon";
import { FormComponentProps } from "../tools/typings";

const InfoParagraphFromJson: FC<FormComponentProps> = ({ formElementJson }) => {
   return (
      <>
         <InfoIcon iconImage={IconImage.BiInfoCircle} />
         <InfoParagraph dangerouslySetInnerHTML={{ __html: formElementJson.label ?? "" }} />
      </>
   );
};

export default InfoParagraphFromJson;
