import styled from "styled-components";
import Card from "../../../kanopy-ui/Card";
import { Title } from "../../../kanopy-ui/Texts/Texts";

export const SignInFormTitle = styled(Title)`
   margin-bottom: 0px;
`;

export const LoginCard = styled(Card)`
   width: 450px;
   min-width: 370px;
   padding-top: 52px;
   padding-bottom: 20px;
   overflow-y: auto;
   height: 100%;
`;
