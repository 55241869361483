import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { FormJson } from "../../forms/useServerJsonForm";
import { httpRequest } from "../../tools/httpRequest";
import { RequestError } from "../../tools/typings";
import { useLocation } from "react-router-dom";
import { NumberFormatOptions } from "../../../string/formatNumber";
import { DataGridData } from "../home/useDashboardHome";
import { CartesianMarkerProps } from "@nivo/core";

export function useDashboardFinancialHealth<
   Error extends RequestError,
   Response extends DashboardFinancialHealthResponse
>(props?: { options?: UseQueryOptions<void, Error, Response> }) {
   const location = useLocation();
   const url = "forms" + location.pathname;

   return useQuery<void, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}

export interface DashboardFinancialHealthResponse {
   title: string;
   paragraph: string;
   dateInfo: { label: string; date: number };
   backButton?: { link: string };
   contactClientLink: { label: string; icon: string; mailTo: string; name: string };
   clientId: number;
   pages: Array<CurrentPageJson | FuturePageJson | (FormJson & FinancialHealthDashboardSubPage)>;
   page: MessageContentJson;
}

export interface FinancialHealthDashboardSubPage {
   tabTitle: string;
   tabType: number;
   title?: string;
   paragraph?: string;
}

export interface CurrentPageJson extends FinancialHealthDashboardSubPage {
   topCards?: FinancialHealthTopCardData[];
   charts: DashboardChartData[];
   bottomCards?: BottomCards;
   ratiosTable?: RatioTable[];
}

export interface DashboardChartData {
   title: string;
   tabButtonTitle: string;
   id: string;
   tooltip: string;
   chartGraphics: ChartGraphic[];
}

export interface ChartGraphic {
   id: string;
   tooltipValueYFormat?: NumberFormatOptions;
   valueYFormat?: NumberFormatOptions;
   data: DashboardChartContent[];
   defaultFilter: string[];
   markers: CartesianMarkerProps[];
}

export interface FuturePageJson extends FinancialHealthDashboardSubPage {
   forecastCards?: ForecastCardData[];
   targetCard?: TargetCardData;
}

export interface FinancialHealthTopCardData {
   title: string;
   icon: string;
   value: string;
   paragraph: string;
   tooltip: string;
}

export interface DashboardChartContent {
   id: string;
   title: string;
   data: DataPoint[];
}
export interface DataPoint {
   x: string;
   y: number;
}
export interface BottomCards {
   cash_flow_summary: CashFlowSummary;
   cash_flow_characteristics: CashFlowCharacteristics;
   transactions: Transactions;
}
export interface CashFlowSummary {
   title: string;
   tooltip: string;
   rows: CashFlowRow[];
}
export interface CashFlowCharacteristics {
   title: string;
   tooltip: string;
   rows: CashFlowRow[];
}
export interface CashFlowRow {
   label: string;
   value: string;
}
export interface Transactions {
   title: string;
   tooltip: string;
   rows: TransactionsItem[];
}
export interface TransactionsItem {
   label: string;
   value: string;
   percent: number;
}
export interface ForecastCardData {
   title: string;
   value: string;
   paragraph: string;
   progress: number;
}
export interface TargetCardData {
   title: string;
   paragraph: string;
   columns?: TargetCardColumns[];
}
export interface TargetCardColumns {
   title: string;
   value?: string;
   form?: TargetCardForm;
}
export interface TargetCardForm {
   inputPrice: InputPrice;
   buttonSubmit: ButtonSubmit;
}
export interface InputPrice {
   name: string;
   value: string;
}
export interface ButtonSubmit {
   label: string;
   api: ButtonSubmitApi;
}
export interface ButtonSubmitApi {
   url: string;
   params?: string[];
}
export interface MessageContentJson {
   titleImage: string;
   title: string;
   paragraph: string;
   type?: string;
   button?: { label: string; next: string };
}

export interface RatioTable {
   title?: string;
   tooltip?: string;
   dataGrid: DataGridData;
}
