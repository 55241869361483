import React, { FC } from "react";
import "@lottiefiles/lottie-player";
import { FormComponentProps } from "../tools/typings";

const LottieAnimationFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;

   const CustomContainer =
      formTools.formProps.lottie?.renderCustomLottieAnimationContainer?.() ?? React.Fragment;

   // Documentation of lottie-player: https://github.com/LottieFiles/lottie-player
   return (
      <CustomContainer>
         <lottie-player autoplay loop src={JSON.stringify(formElementJson.label)}></lottie-player>
      </CustomContainer>
   );
};

export default LottieAnimationFromJson;
