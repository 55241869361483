import React, { FC, useRef } from "react";
import Tippy from "@tippyjs/react";
import { Placement as TippyPlacement } from "tippy.js";
import { FloatingContentContainer } from "./Floating.styles";
import { useFloatCompGlobalList } from "./tools/useFloatCompGlobalList";
//https://atomiks.github.io/tippyjs/v6/animations/#included-animations
import "tippy.js/animations/shift-away-subtle.css";

export type Placement = TippyPlacement;

export interface PropsFloatingContainer {
   content: () => React.ReactNode;
   visible: boolean;
   onClose?: () => void;
   offsetY?: number;
   placement?: Placement;
   /** If this is true the floating component state will be restarted after is visible again, it will be re-created */
   preserveState?: boolean;
   /** Matches the width of the floating element to the trigger element */
   matchWidth?: boolean;
}

/**
 * This renders an element floating on top of the rest of the UI, to be used to implement other UI
 * components like a context menu or tooltip. The floating component will have the correct position
 * near the "trigger" as configured and without overflow or outside of the screen issues, also
 * animations are implemented.
 *
 * Currently is implemented using @tippyjs/react in the future this can be re-written using the new
 * @floating-ui/react library to have more flexibility so it can be used more widely in the project.
 * // TODO: Re-write using @floating-ui/react
 */
const Floating: FC<PropsFloatingContainer> = props => {
   const {
      children,
      visible,
      onClose,
      offsetY = 10,
      placement = "auto",
      content,
      preserveState,
      matchWidth
   } = props;

   useFloatCompGlobalList(visible);

   const ref = useRef<Element>(null);
   const childrenWidth = ref.current?.clientWidth;

   const renderContent = () => {
      if (!visible && !preserveState) {
         return null;
      }

      return (
         <FloatingContentContainer
            style={matchWidth ? { width: childrenWidth, maxWidth: childrenWidth } : {}}
         >
            {content()}
         </FloatingContentContainer>
      );
   };

   return (
      <Tippy
         visible={visible}
         content={renderContent()}
         animation={"shift-away-subtle"}
         interactive={true}
         arrow={false}
         maxWidth={99999}
         offset={[0, offsetY]}
         placement={placement}
         onClickOutside={onClose}
         // This is here because when the menu it's appended to the parent the placement can be hidden by a scrollable "overflow: hidden" parent
         appendTo={document.body}
         ref={ref}
      >
         {children as React.ReactElement}
      </Tippy>
   );
};

export default Floating;
