import React, { FC, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DrawerButton from "../../../../kanopy-ui/Drawer/DrawerButton/DrawerButton";
import { ButtonGroup, SettingsDrawerContainer, LogoImg } from "./SettingsPageContainer.styles";
import {
   SidePanelButton,
   SidePanelResponse
} from "../../../../../tools/api/settings/side-panel/useSidePanel";
import Button, { ButtonVariant } from "../../../../kanopy-ui/Button";
import { IconImage } from "../../../../kanopy-ui/Icon/Icon";
import Container from "../../../../kanopy-ui/Container";
import { currentTheme } from "../../../../../tools/themes/themeConfig";

const RenderDrawer: FC<{ data: SidePanelResponse }> = ({ data }) => {
   const navigate = useNavigate();
   const location = useLocation();
   const [isHover, setHover] = useState<any>(null);

   const icons = [
      { normal: IconImage.VscHome, hovered: IconImage.MdHome },
      { normal: IconImage.VscHeart, hovered: IconImage.MdFavorite },
     { normal: IconImage.MdOutlineAnalytics, hovered: IconImage.MdAnalytics },
      { normal: IconImage.IoBusinessOutline, hovered: IconImage.IoBusiness },
      { normal: IconImage.RiBriefcase4Line, hovered: IconImage.MdBusinessCenter },
      { normal: IconImage.BiHelpCircle, hovered: IconImage.MdHelp }
   ];

   const getStateActive = (button: SidePanelButton) => {
      return location.pathname.split("/").join("") === button.link.split("/").join("");
   };

   const handleOnClick = (button: SidePanelButton) => {
      button.type === "external-link" ? window.open(`${button.link}`) : navigate(`/${button.link}`);
   };

   return (
      <SettingsDrawerContainer>
         <LogoImg src={currentTheme.images.logoForDrawer} />
         {Object.keys(data.sidePanelButtons).map((buttonGroupKey, i) => (
            <ButtonGroup key={i}>
               {/*
                   // @TODO: check if this component is necessary
                   <SeparatorText>{buttonGroupKey}</SeparatorText>
                */}
               {data.sidePanelButtons[buttonGroupKey].map((button, i) => {
                  const isActive = getStateActive(button);
                  return (
                     <DrawerButton
                        onMouseEnter={() => setHover(i)}
                        onMouseLeave={() => setHover(null)}
                        label={button.name}
                        icon={icons[i]}
                        hovered={i === isHover}
                        onClick={() => handleOnClick(button)}
                        active={isActive}
                        key={i}
                     />
                  );
               })}
            </ButtonGroup>
         ))}
         <Container>
            {data.contactAdvisorButton && (
               <a
                  href={
                     "mailto:?to=" +
                     data.contactAdvisorButton.name +
                     "<" +
                     data.contactAdvisorButton.mailTo +
                     ">"
                  }
               >
                  <Button variant={ButtonVariant.Outlined} fullWidth>
                     {data.contactAdvisorButton.label}
                  </Button>
               </a>
            )}
         </Container>
      </SettingsDrawerContainer>
   );
};

export default RenderDrawer;
