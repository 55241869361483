import React, { FC } from "react";
import { FormElementJson } from "../../../../tools/api/forms/useServerJsonForm";
import EditButton from "./EditButton/EditButton";
import {
   OwnerInfoLine,
   OwnerInfoLineDivider,
   OwnerInfoLineLabel,
   OwnerInfoLineValue
} from "./OwnerInfoLine/OwnerInfoLine.styles";
import ReviewFile, { ReviewFileType } from "./ReviewFile/ReviewFile";
import {
   TextElementContainer,
   TextElementLabel,
   TextElementValue,
   AccountInfoContainer,
   AccountInfoLabel,
   AccountInfoValue,
   AccountInfoValue1
} from "./TextElement/TextElement.styles";
import LogoutModal from "./LogoutModal/LogoutModal";
import { FormComponentProps } from "../tools/typings";

/**
 * Review box item used in the review page, including the edit button, it's also
 * treated as an item in the json
 */
const ReviewBoxContentFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, isLastElement } = props;

   if (formElementJson.type === "edit-panel-file") {
      return (
         <ReviewFile
            title={formElementJson.label}
            label={formElementJson.value.text}
            size={formElementJson.value.size}
            downloadLink={formElementJson.value.link}
            type={ReviewFileType.Any}
            isLastElement={isLastElement}
         />
      );
   }

   if (formElementJson.type === "owner-info-line") {
      return (
         <OwnerInfoLine>
            <OwnerInfoLineLabel>{formElementJson.label}</OwnerInfoLineLabel>{" "}
            <OwnerInfoLineDivider>-</OwnerInfoLineDivider>
            <OwnerInfoLineValue>{formElementJson.value}</OwnerInfoLineValue>
         </OwnerInfoLine>
      );
   }

   if (formElementJson.type === "edit-panel-text-element") {
      return (
         <TextElementContainer isLastElement={isLastElement}>
            <TextElementLabel>{formElementJson.label}</TextElementLabel>{" "}
            <TextElementValue>{formElementJson.value}</TextElementValue>
         </TextElementContainer>
      );
   }

   if (
      formElementJson.type === "edit-panel-text-one" &&
      formElementJson.style === "font_color_gray"
   ) {
      return (
         <AccountInfoContainer isLastElement={isLastElement}>
            <AccountInfoLabel>{formElementJson.label}</AccountInfoLabel>
            <AccountInfoValue1>{formElementJson.value}</AccountInfoValue1>
         </AccountInfoContainer>
      );
   }

   if (formElementJson.type === "edit-panel-text-one") {
      return (
         <AccountInfoContainer isLastElement={isLastElement}>
            <AccountInfoLabel>{formElementJson.label}</AccountInfoLabel>
            <AccountInfoValue>{formElementJson.value}</AccountInfoValue>
         </AccountInfoContainer>
      );
   }

   if (formElementJson.type === "edit-panel-button") {
      return <EditButton editFormJsonUrl={formElementJson.next!} />;
   }
   if (formElementJson.footnote !== undefined) {
      // TODO: API change required

      return (
         <LogoutModal
            editFormJsonUrl={formElementJson.footnote.link}
            text={formElementJson.footnote?.label}
         />
      );
   }

   return null;
};

export default ReviewBoxContentFromJson;
