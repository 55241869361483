import { useMemo } from "react";
import { useAtom } from "jotai";
import { atomGlobalDialogs, GlobalDialog } from "../../../../tools/atoms/atomGlobalDialogs";

export function useDialog() {
   const [dialogs, setDialogs] = useAtom(atomGlobalDialogs);
   const dialogId = useMemo(() => String(Math.random()), []);

   const openDialog = (dialogProps: GlobalDialog) => {
      const isAlreadyOpen = dialogs.find(d => d.dialogId === dialogId) !== undefined;

      if (isAlreadyOpen) {
         return;
      }

      setDialogs([...dialogs, { ...dialogProps, dialogId }]);
   };

   const closeDialog = () => {
      setDialogs(dialogs => dialogs.filter(dialog => dialog.dialogId !== dialogId));
   };

   return { openDialog, closeDialog };
}
