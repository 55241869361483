import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { httpRequest } from "../../tools/httpRequest";
import { RequestError } from "../../tools/typings";

export function useLeftPanel<
   Error extends RequestError,
   Response extends LeftPanelResponse
>(props?: { source: string | null; options?: UseQueryOptions<void, Error, Response> }) {
   const url =
      "forms/sign-up/left-panel" + (props?.source !== null ? "?source=" + props?.source : "");

   return useQuery<void, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}

export interface LeftPanelResponse {
   title: string;
   paragraph: string;
   image: string;
}
