import React, { FC } from "react";
import { ChildrenContainer, DrawerContainer } from "./Drawer.styles";

interface PropsDrawer {
   renderContent?: () => React.ReactNode;
}

const Drawer: FC<PropsDrawer> = ({ children, renderContent }) => {
   return (
      <ChildrenContainer>
         <DrawerContainer>{renderContent?.()}</DrawerContainer>
         {children}
      </ChildrenContainer>
   );
};

export default Drawer;
