import { useNavigate } from "react-router-dom";

/**
 * A wrapper for react-router-dom's useNavigate hook for redirecting to a given url that simplifies syntax.
 * If the url is undefined it will do nothing.
 *
 * @returns A function that redirects to a given url
 */
export function useRedirect() {
   const navigate = useNavigate();

   const redirect = (props: { url?: string; withoutHistory?: boolean }) => {
      if (props.url == null) {
         return;
      }

      if (!props.withoutHistory) {
         navigate(`/${props.url.replace(/^\//, "")}`);
      } else {
         navigate(`/${props.url.replace(/^\//, "")}`, { replace: true });
      }
   };

   return { redirect };
}
