import "./fonts/fonts.css";
import { mediaQueries } from "../tools/mediaQueries/mediaQueries";
import { KanopyUITheme } from "../tools/typings/KanopyUITheme";
import { Theme } from "../tools/typings/Theme";
import logo from "../../../assets/lendi/logo-horizontal-smaller.svg";
import logoForDrawer from "../../../assets/lendi/logo-horizontal.svg";
import background1 from "../../../assets/lendi/background1.svg";
import startArrow from "../../../assets/lendi/start-arrow.svg";

const spacing = (size: number = 1, suffix: string = "") => size * 8 + suffix;

const pallette = {
   transparent: "#00000000",
   white: "#FFFFFF",
   black: "#000000",
   edgewater: "#BFC9E1",
   primary1: "#6587A8",
   primary2: "#5ABBDD",
   primary3: "#2951C3",
   primary4: "#4A5475",
   primaryWashed: "#DEE4E8",
   primaryWashedMore: "#F1F3F6",
   textColor1: "#0B2354",
   gray1: "#C4C4C4",
   gray2: "#A8A8A8",
   gray3: "#868686",
   gray4: "#404040",
   gray5: "#323232",
   gray6: "#F1F1ED",
   gray7: "#BDBDAA",
   gray8: "#C9C9C9",
   grey9: "#F6F4F4",
   grey10: "#F2F0F0",
   grey11: "#E9E7E7",
   grey12: "#DDDBDB",
   grayLighten: "#E2E2E2",
   neutralBlack: "#25282B",
   disabled: "#BCBCBC",
   grayColorized: "#C8D3F9",
   washedBlue: "#B1CCE4",
   grayedBlue: "#d3dfeb",
   cadetBlue: "#A0AEC0",
   importantColor: "#E95832",
   gold: "#FFD700"
};

const fontFamilies = {
   hanken: '"Hanken Grotesk", sans-serif',
   montserrat: '"Montserrat", sans-serif'
};

const shadows = {
   shadow1: "0px 4px 24px 0px rgb(0 0 0 / 25%)",
   shadow2: "0px 4px 4px rgba(0, 0, 0, 0.1)",
   shadow3: "0px 1px 2px rgba(0, 0, 0, 0.15)",
   shadow4: "0 4px 4px 0 rgba(0, 0, 0, 0.15)",
   shadow5: "0px 2px 50px rgba(0, 0, 0, 0.2)",
   shadow6: "0px -7px 24px rgba(0, 0, 0, 0.05)",
   shadow7: "0px 4px 20px rgba(0, 0, 0, 0.15)"
};

const borders = {
   border1: `1px solid ${pallette.gray1}`,
   border2: `2px solid ${pallette.gray1}`,
   border3: `2px solid ${pallette.primary1}`,
   border4: `1px solid ${pallette.grey11}`,
   border5: `1px solid rgba(0, 0, 0, 0.10)`,
   border6: `1px solid ${pallette.gray8}`,
   border7: `1px solid ${pallette.grey12}`,
   dashed: `1px dashed ${pallette.primary2}`,
   borderError: `1px solid ${pallette.importantColor}`
};

const borderRadius = {
   radius1: "20px",
   radius2: "4px",
   radius3: "8px",
   radius4: "55px",
   radius5: "6.3px",
   radius6: "2px",
   radius7: "50px"
};

const kanopyUiTheme: KanopyUITheme = {
   title: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 32,
      weight: 800,
      lineHeight: 34
   },
   title2: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 40,
      weight: 700,
      lineHeight: 34
   },
   title3: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 18,
      weight: 700,
      lineHeight: 34
   },
   title4: {
      fontFamily: fontFamilies.montserrat,
      color: pallette.gray4,
      fontSize: 16,
      weight: 800,
      lineHeight: 20
   },
   title5: {
      fontFamily: fontFamilies.montserrat,
      color: pallette.gray4,
      fontSize: 32,
      weight: 800,
      lineHeight: 34
   },
   title6: {
      fontFamily: fontFamilies.hanken,
      color: pallette.neutralBlack,
      fontSize: 18,
      weight: 700,
      lineHeight: 20
   },
   title7: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 22,
      weight: 700,
      lineHeight: 26
   },
   title8: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 40,
      weight: 700,
      lineHeight: 36
   },
   title9: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 32,
      weight: 700,
      lineHeight: 35
   },
   title10: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 24,
      weight: 700,
      lineHeight: 36
   },
   title11: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 20,
      weight: 700,
      lineHeight: 20
   },
   paragraph: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 16,
      weight: 400,
      lineHeight: 22,
      spanWeight: 700
   },
   paragraph2: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 18,
      weight: 600,
      lineHeight: 27,
      spanColor: pallette.importantColor
   },
   paragraph3: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 16,
      weight: 600,
      lineHeight: 27,
      spanColor: pallette.primary2
   },
   paragraph4: {
      fontFamily: fontFamilies.hanken,
      color: pallette.black,
      fontSize: 16,
      weight: 400,
      lineHeight: 22
   },
   paragraph5: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 16,
      weight: 400,
      lineHeight: 19
   },
   paragraph6: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 14,
      weight: 600,
      lineHeight: 27,
      spanColor: pallette.primary2
   },
   paragraph7: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 12,
      weight: 400,
      lineHeight: 12
   },
   paragraph8: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 18,
      weight: 400,
      lineHeight: 24
   },
   paragraph9: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 14,
      weight: 400,
      lineHeight: 20
   },
   link: {
      fontFamily: fontFamilies.hanken,
      color: pallette.primary1,
      fontSize: 16,
      weight: 700,
      lineHeight: 22,
      hoverColor: pallette.primary2
   },
   link2: {
      fontFamily: fontFamilies.hanken,
      color: pallette.primary2,
      fontSize: 16,
      weight: 600,
      lineHeight: 22,
      hoverColor: pallette.primary1
   },
   label: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 14,
      weight: 400,
      lineHeight: 25
   },
   label2: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray3,
      fontSize: 14,
      weight: 400,
      lineHeight: 25
   },
   label3: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 14,
      weight: 600,
      lineHeight: 25
   },
   label4: {
      fontFamily: fontFamilies.hanken,
      color: pallette.primary2,
      fontSize: 16,
      weight: 400,
      lineHeight: 22,
      letterSpacing: 0.05
   },
   label5: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 18,
      weight: 800,
      lineHeight: 24
   },
   label6: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 18,
      weight: 700,
      lineHeight: 24
   },
   label7: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 14,
      weight: 700,
      lineHeight: 24
   },
   label8: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 14,
      weight: 400,
      lineHeight: 25
   },
   label9: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 16,
      weight: 700,
      lineHeight: 25
   },
   label10: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 16,
      weight: 400,
      lineHeight: 25
   },
   label11: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 20,
      weight: 700,
      lineHeight: 24
   },
   label12: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 15,
      weight: 800,
      lineHeight: 25
   },
   label13: {
      fontFamily: fontFamilies.hanken,
      color: pallette.primary1,
      fontSize: 18,
      weight: 700,
      lineHeight: 24
   },
   label14: {
      fontFamily: fontFamilies.hanken,
      color: pallette.primary1,
      fontSize: 16,
      weight: 400,
      lineHeight: 24
   },
   label15: {
      fontFamily: fontFamilies.hanken,
      color: pallette.gray4,
      fontSize: 11,
      weight: 700,
      lineHeight: 15
   },
   label16: {
      fontFamily: fontFamilies.hanken,
      color: pallette.primary3,
      fontSize: 16,
      weight: 400,
      lineHeight: 24
   },
   labelError: {
      fontFamily: fontFamilies.hanken,
      color: pallette.importantColor,
      fontSize: 14,
      weight: 400,
      lineHeight: 19
   },
   card: {
      backgroundColor: pallette.white,
      shadow: shadows.shadow3,
      borderRadius: borderRadius.radius3,
      shadowVariant2: shadows.shadow1,
      shadowVariant3: shadows.shadow7
   },
   input: {
      border: borders.border1,
      borderRadius: borderRadius.radius2,
      borderError: borders.borderError,
      borderColorReadOnly: pallette.primaryWashed,
      iconColor: pallette.gray5,
      iconColorError: pallette.importantColor,
      backgroundColor: pallette.white,
      backgroundColorReadOnly: pallette.transparent,
      text: {
         color: pallette.gray4,
         colorReadOnly: pallette.disabled,
         fontFamily: fontFamilies.hanken,
         fontSize: 16,
         weight: 400,
         lineHeight: 22
      }
   },
   currencyInput: {
      currencySymbol: {
         color: pallette.gray4,
         fontFamily: fontFamilies.hanken,
         fontSize: 16,
         weight: 600,
         lineHeight: 22
      }
   },
   button: {
      fontFamily: fontFamilies.hanken,
      fontSize: 16,
      weight: 700,
      lineHeight: 1,
      letterSpacing: 0.1,
      filled: {
         borderRadius: borderRadius.radius4,
         effectColor: pallette.primary2,
         enabled: {
            color: pallette.white,
            backgroundColor: pallette.primary1,
            border: "unset"
         },
         hover: {
            color: pallette.white,
            backgroundColor: pallette.primary2,
            border: "unset"
         },
         disabled: {
            color: pallette.white,
            backgroundColor: pallette.gray7,
            border: "unset"
         }
      },
      outlined: {
         borderRadius: borderRadius.radius4,
         effectColor: pallette.primary1,
         enabled: {
            color: pallette.primary1,
            backgroundColor: pallette.transparent,
            border: borders.border3
         },
         hover: {
            color: pallette.white,
            backgroundColor: pallette.primary1,
            border: borders.border3
         },
         disabled: {
            color: pallette.gray7,
            backgroundColor: pallette.transparent,
            border: borders.border3
         },
         loading: {
            color: pallette.primary2,
            backgroundColor: pallette.transparent
         }
      },
      invertedColors: {
         borderRadius: borderRadius.radius4,
         effectColor: pallette.primary1,
         enabled: {
            color: pallette.primary1,
            backgroundColor: pallette.white,
            border: "unset"
         },
         hover: {
            color: pallette.primary1,
            backgroundColor: pallette.grey9,
            border: "unset"
         },
         disabled: {
            color: pallette.gray7,
            backgroundColor: pallette.grey9,
            border: "unset"
         }
      },
      textOnly: {
         borderRadius: "unset",
         effectColor: pallette.primary1,
         enabled: {
            color: pallette.primary1,
            backgroundColor: "unset",
            border: "unset"
         },
         hover: {
            color: pallette.primary1,
            backgroundColor: "unset",
            border: "unset"
         },
         disabled: {
            color: pallette.gray4,
            backgroundColor: "unset",
            border: "unset"
         }
      }
   },
   buttonDashboard: {
      color: pallette.gray4,
      colorActive: pallette.white,
      backgroundColor: pallette.gray6,
      backgroundColorActive: pallette.primary1,
      backgroundColorDisabled: pallette.disabled,
      colorDisabled: pallette.white,
      hoverColor: pallette.white,
      hoverBackgroundColor: pallette.primary1,
      effectColor: pallette.primary2,
      border: "unset",
      borderRadius: borderRadius.radius2,
      fontFamily: fontFamilies.hanken,
      fontSize: 16,
      weight: 700,
      lineHeight: 22
   },
   buttonOutlinedSpecial: {
      color: pallette.gray3,
      backgroundColor: pallette.white,
      hoverBackgroundColor: pallette.grayColorized,
      effectColor: pallette.primary1,
      border: borders.border2,
      borderRadius: borderRadius.radius5
   },
   iconButton: {
      color: pallette.gray4,
      hoverColor: pallette.primary1
   },
   divider: {
      line: {
         main: pallette.grayLighten,
         primary: pallette.grayColorized
      },
      text: {
         fontFamily: fontFamilies.hanken,
         color: pallette.gray4,
         fontSize: 16,
         weight: 400,
         lineHeight: 22,
         letterSpacing: 0.1
      }
   },
   loadingSpinner: {
      color: pallette.primary1
   },
   progressBar: {
      containerColor: pallette.grey11,
      contentColor: pallette.primary1,
      borderRadius: borderRadius.radius3,
      textColor: pallette.primary2,
      primary3: pallette.primary3,
      textColor1: pallette.textColor1
   },
   progressBarDashboardVariant: {
      containerColor: pallette.transparent,
      contentColor: pallette.primary1,
      borderRadius: borderRadius.radius2
   },
   fileUploadBox: {
      backgroundColor: pallette.grey10,
      backgroundColorFileDrag: pallette.grey11,
      border: borders.dashed,
      borderRadius: borderRadius.radius3,
      borderRadiusThumbnail: borderRadius.radius2,
      errorTextColor: pallette.importantColor
   },
   menu: {
      highlightedItemColor: pallette.gray6,
      itemColor: pallette.white
   },
   modal: {
      backgroundColor: pallette.grey9,
      borderRadius: borderRadius.radius3,
      titleBarBorderBottom: borders.border5,
      dimmerOpacity: 0.7
   },
   avatar: {
      label: {
         fontFamily: fontFamilies.hanken,
         color: pallette.white,
         weight: 700,
         lineHeight: 21.82
      }
   },
   contextMenu: {
      shadow: shadows.shadow1,
      separatorLine: borders.border1,
      borderRadius: borderRadius.radius3,
      itemHoverBackgroundColor: pallette.grey9
   },
   select: {
      toggleIconColor: pallette.gray5,
      toggleIconColorOnHover: pallette.primary1
   },
   checkBox: {
      boxColor: pallette.primary1,
      checkColor: pallette.grey9
   },
   radioButton: {
      borderColorColor: pallette.primary1,
      fillColor: pallette.primary1
   },
   drawer: {
      backgroundColor: pallette.white,
      shadow: shadows.shadow3,
      borderRadius: borderRadius.radius3
   },
   drawerButton: {
      activeColor: pallette.gray6,
      hoverColor: pallette.gray6,
      iconColor: pallette.gray4,
      borderRadius: borderRadius.radius2
   },
   tabs: {
      borderBottom: borders.border6,
      underlineColor: pallette.primary1
   },
   tag: {
      color: pallette.gray6,
      borderRadius: borderRadius.radius7,
      font: {
         fontFamily: fontFamilies.hanken,
         color: pallette.gray4,
         fontSize: 14,
         weight: 400,
         lineHeight: 12
      }
   },
   snackBar: {
      backgroundColor: pallette.primary1,
      textColor: pallette.white,
      fontSize: 17,
      borderRadius: borderRadius.radius2
   },
   calendar: {
      selectedDayBackgroundColor: pallette.primary1,
      notSelectedDayHoverBackgroundColor: pallette.grayLighten
   },
   circularButton: {
      enabled: {
         color: pallette.white,
         backgroundColor: pallette.primary4,
         borderColor: "unset",
         border: "unset"
      },
      hover: {
         color: pallette.white,
         backgroundColor: pallette.primary3,
         borderColor: "unset",
         border: "unset"
      }
   },
   banner: {
      color: pallette.primary4,
      backgroundColor: pallette.grayedBlue
   }
};

const appTheme: Theme = {
   spacing,
   baseSize: 4,
   colors: {
      background1: pallette.grey9,
      cadetBlue: pallette.cadetBlue,
      edgewater: pallette.edgewater,
      gold: pallette.gold,
      gray1: pallette.gray1,
      gray2: pallette.gray2,
      gray4: pallette.gray4,
      gray5: pallette.gray5,
      gray6: pallette.gray6,
      gray7: pallette.gray7,
      grey9: pallette.grey9,
      grey10: pallette.grey10,
      grey11: pallette.grey11,
      grayLighten: pallette.grayLighten,
      header: pallette.white,
      importantColor: pallette.importantColor,
      primary1: pallette.primary1,
      primary2: pallette.primary2,
      primary4: pallette.primary4,
      primaryWashed: pallette.primaryWashed,
      primaryWashedMore: pallette.primaryWashedMore,
      scrollbar: pallette.transparent,
      scrollbarThumb: pallette.gray3,
      scrollbarThumbHover: pallette.gray2,
      text1: pallette.gray2,
      text2: pallette.gray4,
      washedBlue: pallette.washedBlue,
      white: pallette.white,
      grayedBlue: pallette.grayedBlue,
      highlight: pallette.primary3
   },
   shadows: {
      shadow1: shadows.shadow1,
      shadow2: shadows.shadow2,
      shadow3: shadows.shadow3,
      shadow4: shadows.shadow4,
      shadow5: shadows.shadow5,
      shadow6: shadows.shadow6,
      headerShadow: shadows.shadow2,
      footerShadow: shadows.shadow4
   },
   fonts: {
      font1: fontFamilies.hanken,
      font2: fontFamilies.montserrat,
      size1: "20px",
      weight1: "600",
      letterSpacing1: "-0.26px",
      lineHeight1: "21px"
   },
   borders: {
      border1: borders.border1,
      border4: borders.border4,
      border7: borders.border7,
      borderRadius1: borderRadius.radius1,
      borderRadius2: borderRadius.radius2,
      borderRadius3: borderRadius.radius3
   },
   infoCard: {
      iconColor: pallette.white,
      iconContainerColor: pallette.primary1
   },
   wizardLeftPanel: {
      checkCircleColor: pallette.primary1,
      tickColor: pallette.white,
      borderColor: pallette.gray2
   },
   missingDataPanel: {
      color: pallette.primaryWashedMore,
      backgroundColor: pallette.primary1
   },
   exploreBusinessButton: {
      hover: {
         backgroundColor: pallette.grey9
      }
   },
   lineChart: {
      lineColors: [
         "#6D6BB9",
         "#6B94B9",
         "#6BB97F",
         "#E18335",
         "#AF7A6D",
         "#004346",
         "#FFE74C",
         "#38023B",
         "#F4989C",
         "#FFEAEE",
         "#483519",
         "#C98686",
         "#06070E",
         "#000000"
      ],
      pointColor: pallette.gray6,
      backgroundGridLinesColor: pallette.gray7,
      indicatorsIconRadius: borderRadius.radius6,
      axisTexts: {
         fontFamily: fontFamilies.hanken,
         color: pallette.gray4,
         fontSize: 12,
         weight: 700,
         lineHeight: 26,
         letterSpacing: 0.2
      }
   },
   images: {
      logo,
      logoForDrawer,
      background1,
      startArrow
   },
   mediaQueries,
   ...kanopyUiTheme
};

export default appTheme;
