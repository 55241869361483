import { FC, useEffect } from "react";
import { useLoginStatus } from "../../../tools/api/tools/useLoginStatus";
import { useNavigate } from "react-router-dom";

export const RequireLogout: FC<{ children: React.ReactElement | null }> = ({ children }) => {
   const userIsLogged = useLoginStatus();
   const navigate = useNavigate();

   // This useEffect replaces the functionality of react-router-dom: <Navigate replace to="/" />
   useEffect(() => {
      if (userIsLogged) {
         navigate(`/`);
      }
   }, [userIsLogged]);

   if (userIsLogged) {
      /**
       * <Navigate replace to="/" /> is broken in safari with the current react-router-dom
       * version (^6.2.1) when the site is running outside of localhost.
       * The useEffect from above does the same and works perfectly.
       * Another example of why many developers avoid libraries very often.
       */
      // return <Navigate replace to="/" />;
      return null;
   }

   return children;
};
