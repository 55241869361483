import React, { FC } from "react";
import { HeaderVariant } from "../../../common/Header/Header";
import PageContainer, { PageContainerVariant } from "../../../common/PageContainer/PageContainer";
import { ConnectPageContainer } from "./ConnectModalStep.styles";
import { useLocation } from "react-router";
import FormFromJsonAsModal from "../../../common/FormFromJsonAsModal/FormFromJsonAsModal";

const ConnectModalStep: FC = () => {
   const location = useLocation();

   return (
      <PageContainer
         variant={PageContainerVariant.Variant3}
         headerConfig={{ variant: HeaderVariant.RegisteredUser }}
      >
         <FormFromJsonAsModal
            jsonUrl={location.pathname}
            disableSubmitButtonWhenFormIsIncomplete={false}
            disableClosing
            disableAutomaticModalTitleFromJson
            redirectToNextFormOnSubmit
            renderCustomContainer={() => ConnectPageContainer}
         />
      </PageContainer>
   );
};

export default ConnectModalStep;
