import styled from "styled-components";
import { Paragraph3 } from "../Texts/Texts";
import { ProgressBarVariant } from "./ProgressBar";

export const ProgressBarContainer = styled("div").withConfig({
   shouldForwardProp: p => !["variant"].includes(p)
})<{ variant: ProgressBarVariant }>`
   position: relative;
   width: 100%;
   height: 11px;
   margin-bottom: 4px;

   ${({ variant, theme }) =>
      variant === ProgressBarVariant.Default
         ? `
            background-color: ${theme.progressBar.containerColor};
            border-radius: ${theme.progressBar.borderRadius};
   `
         : ""}

   ${({ variant, theme }) =>
      variant === ProgressBarVariant.Dashboard
         ? `
            background-color: ${theme.progressBarDashboardVariant.containerColor};
            border-radius: ${theme.progressBarDashboardVariant.borderRadius};
   `
         : ""}
            ${({ variant, theme }) =>
      variant === ProgressBarVariant.LeftPanel
         ? `
         background-color: ${theme.progressBar.containerColor};
            border-radius: ${theme.progressBar.borderRadius};
   `
         : ""}
`;

export const ProgressBarContent = styled("div").withConfig({
   shouldForwardProp: p => !["percentage", "variant"].includes(p)
})<{ percentage?: number; variant: ProgressBarVariant }>`
   position: absolute;
   width: ${({ percentage }) => percentage}%;
   height: 100%;

   ${({ variant, theme }) =>
      variant === ProgressBarVariant.Default
         ? `
            background-color: ${theme.progressBar.contentColor};
            border-radius: ${theme.progressBar.borderRadius};
   `
         : ""}

   ${({ variant, theme }) =>
      variant === ProgressBarVariant.Dashboard
         ? `
            background-color: ${theme.progressBarDashboardVariant.contentColor};
            border-radius: ${theme.progressBarDashboardVariant.borderRadius};
   `
         : ""}
          ${({ variant, theme }) =>
      variant === ProgressBarVariant.LeftPanel
         ? `
         background-color: ${theme.progressBar.primary3};
         border-radius: ${theme.progressBar.borderRadius};
   `
         : ""}
`;

export const ProgressBarText = styled(Paragraph3).withConfig({
   shouldForwardProp: p => !["percentage"].includes(p)
})<{ percentage?: number; variant: ProgressBarVariant }>`
   color: ${({ theme }) => theme.progressBar.textColor};
   ${({ variant, theme }) =>
      variant === ProgressBarVariant.LeftPanel ? ` color: ${theme.progressBar.textColor1}; ` : ""}
`;
