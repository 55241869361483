import { Link } from "react-router-dom";
import styled from "styled-components";
import { Label7 } from "../../Texts/Texts";

export const LinkStyled = styled(Link)`
   width: 100%;
   height: 100%;
   cursor: pointer;
`;

export const CellLabel = styled(Label7).withConfig({
   shouldForwardProp: p => !["handCursor"].includes(p)
})<{ handCursor?: boolean }>`
   display: flex;
   align-items: center;
   width: 100%;
   height: 100%;
   padding-left: 17px;
   padding-right: 17px;
   ${props => (props.handCursor ? "cursor: pointer;" : "")}
`;
