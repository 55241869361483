import React, { FC } from "react";
import { FormComponentProps } from "../tools/typings";
import { getFieldError } from "../../../../tools/validation/getFieldError";
import Moment from "moment";
import DatePicker from "../../../kanopy-ui/DatePicker/DatePicker";

const DatePickerFromJson: FC<FormComponentProps> = props => {
   const { formElementJson, formTools } = props;
   const { form } = formTools;

   const onChange = (date: Date) => {
      form.setValue(formElementJson.name, Moment(date).format("MM/DD/YYYY"));
   };

   return (
      <DatePicker
         label={formElementJson.label ?? ""}
         defaultValue={formElementJson.value}
         onChange={onChange}
         error={getFieldError({ formElementJson, form })}
         readOnly={formElementJson.readOnly}
      />
   );
};

export default DatePickerFromJson;
