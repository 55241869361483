import { tryToGetErrorMessage } from "../../../../tools/api/tools/tryToGetErrorMessage";
import { tryToGetErrorStatusCode } from "../../../../tools/api/tools/tryToGetErrorStatusCode";
import { Browser, getBrowser } from "../../../../tools/browser/getBrowser";
import { isRunningOnLocalhost } from "../../../../tools/browser/isRunningOnLocalhost";
import { useDialog } from "../../GlobalDialogs/tools/useDialog";

/**
 * Global errors handler
 */
export function useErrorHandler() {
   const { openDialog } = useDialog();

   const onError = (err: any) => {
      let errorText = tryToGetErrorMessage(err);
      const errorCode = tryToGetErrorStatusCode(err);

      if (errorCode === 401) {
         /**
          * 401 error means that probably there is something wrong with the session stored on
          * the browser so we get rid of it, this solves the error and without this there could
          * be a blank page unrecoverable error because all requests fail in that potential situation.
          */
         localStorage.removeItem("session");

         // TODO: Install a babel or webpack plugin to have conditional compilation features and then use it to exclude code like this from the final build, at the time of writing this the following if statement is the only code that needs it so we can stay without conditional compilation for the moment
         if (isRunningOnLocalhost() && getBrowser() === Browser.Safari) {
            errorText =
               errorText +
               "<br/><br/>Safari + localhost detected, for the login to work in localhost safari uncheck the following setting: Safari > Preferences > Privacy > Prevent cross-site tracking. This is a safari security feature triggered because the server api writes cookies and we are not in the same domain because we are in localhost";
         }
      }

      /**
       * Error 412 is a form error that needs to return and display on the form
       */
      if (errorCode === 412) {
         return;
      }

      openDialog({
         // title: `Error${errorCode ? " " + errorCode : ""}`,
         text: `${errorText}`,
         buttons: [{ text: "OK" }],
         disableCloseButton: true
      });
   };

   return { onError };
}
