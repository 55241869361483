import React, { FC, useContext } from "react";
import { ButtonTab } from "./Tabs.styles";
import { ITabPanelProps, ITabProps, ITabsProps } from "./Tabs.types";
import TabsProvider, { TabContext } from "./TabsProvider";

export const TabPanel: FC<ITabPanelProps> = ({ children, index, ...rest }) => {
   const { id, selectedTab } = useContext(TabContext);
   return (
      <div
         role="tabpanel"
         hidden={selectedTab !== index}
         id={`${id}-tabpanel-${index}`}
         aria-labelledby={`${id}-tab-${index}`}
         {...rest}
      >
         {children}
      </div>
   );
};

export const Tab: FC<ITabProps> = ({ onClick, index, label }) => {
   const { id, selectedTab, setSelectedTab } = useContext(TabContext);
   const isSelected = selectedTab === index;

   const handleSelectTab = (event: React.SyntheticEvent) => {
      setSelectedTab(index);
      onClick?.(index, event);
   };

   const a11yProps = {
      role: "tab",
      id: `${id}-tab-${index}`,
      "aria-selected": isSelected,
      "aria-controls": `${id}-tabpanel-${index}`
   };

   return (
      <ButtonTab onClick={handleSelectTab} selected={isSelected} {...a11yProps}>
         {label}
      </ButtonTab>
   );
};

const Tabs: FC<ITabsProps> = ({ id, initialTabSelected = 0, children }) => {
   return (
      <TabsProvider id={id} initialTab={initialTabSelected}>
         {children}
      </TabsProvider>
   );
};

export default Tabs;
