import React, { FC } from "react";
import { useAtom } from "jotai";
import { atomGlobalDialogs, GlobalDialogButton } from "../../../tools/atoms/atomGlobalDialogs";
import Button, { ButtonVariant } from "../../kanopy-ui/Button";
import Modal, { ModalVariant } from "../../kanopy-ui/Modal/Modal";
import { ButtonsContainer, TextContainer } from "./styles.GlobalDialogs";

/**
 * This component needs to be placed at the root of the project. Not to be used as a normal component.
 */
const GlobalDialogs: FC = () => {
   const [dialogs, setDialogs] = useAtom(atomGlobalDialogs);

   const genericButtons: GlobalDialogButton[] = [
      { text: "Cancel", variant: ButtonVariant.TextOnly },
      { text: "Continue" }
   ];

   const closeDialog = (dialogId: string) => {
      setDialogs(dialogs.filter(dialog => dialog.dialogId !== dialogId));
   };

   const handleButtonClick = (dialogId: string, button: GlobalDialogButton) => {
      closeDialog(dialogId);
      button.onClick?.();
   };

   return (
      <>
         {dialogs.map(dialog => (
            <Modal
               title={dialog.title}
               onClose={() => closeDialog(dialog.dialogId)}
               variant={ModalVariant.AdjustToContent}
               renderOnDocumentBody={false}
               visible={true}
               key={dialog.dialogId}
               disableClosing={dialog.disableClosing}
               disableCloseButton={dialog.disableCloseButton}
               closeOnLinkClick={true}
            >
               <TextContainer dangerouslySetInnerHTML={{ __html: dialog.text ?? "" }} />
               <ButtonsContainer>
                  {(dialog.buttons ?? genericButtons).map((button, i) => (
                     <Button
                        variant={button.variant}
                        onClick={() => handleButtonClick(dialog.dialogId, button)}
                        key={i}
                     >
                        {button.text}
                     </Button>
                  ))}
               </ButtonsContainer>
            </Modal>
         ))}
      </>
   );
};

export default GlobalDialogs;
