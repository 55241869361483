import React, { FC } from "react";
import { Placement } from "tippy.js";
import { useLogout } from "../../../tools/api/tools/useLogout";
import { useUser } from "../../../tools/api/user/useUser";
import Avatar, { AvatarVariant } from "../../kanopy-ui/Avatar/Avatar";
import ContextMenuItem from "../../kanopy-ui/ContextMenuItem/ContextMenuItem";
import { ContextMenuStyled, LogoutMenuItem, RevealMyAccountMenuItem } from "./UserAvatar.styles";
import { ContextMenuItemData } from "../../kanopy-ui/ContextMenu/ContextMenu";

interface PropsUserAvatar {
   menuPlacement?: Placement;
   className?: string;
}

const UserAvatar: FC<PropsUserAvatar> = props => {
   const { data: user } = useUser();
   const { logout } = useLogout();

   if (!user) {
      return null;
   }

   return (
      <ContextMenuStyled
         placement={props.menuPlacement}
         className={props.className}
         menuItems={[
            {
               notInteractive: true,
               disableMenuCloseOnClick: true,
               render: () => (
                  <ContextMenuItem
                     renderIcon={() => (
                        <Avatar label={user.initials} variant={AvatarVariant.Small} />
                     )}
                     title={user.fullName}
                     text={user.email}
                     adjustIcon={true}
                  />
               )
            },
            ...(user.links?.map(
               page =>
                  ({
                     render: () => <RevealMyAccountMenuItem text={page.label} />,
                     onClickRedirectTo: page.link
                  } as ContextMenuItemData)
            ) ?? []),
            {
               render: () => <LogoutMenuItem text={"Sign out"} />,
               onMouseUp: logout
            }
         ]}
      >
         <Avatar label={user.initials} />
      </ContextMenuStyled>
   );
};

export default UserAvatar;
