import styled from "styled-components";
import Icon from "../../Icon/Icon";
import { Label9 } from "../../Texts/Texts";

export const DrawerButtonContainer = styled("div").withConfig({
   shouldForwardProp: p => !["active"].includes(p)
})<{ active?: boolean }>`
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100%;
   height: 44px;
   padding-left: ${({ theme }) => theme.spacing(2, "px")};
   padding-right: ${({ theme }) => theme.spacing(2, "px")};
   margin-bottom: 10px;
   border-radius: ${({ theme }) => theme.drawerButton.borderRadius};
   transition: background-color 250ms;

   ${({ active }) =>
      !active
         ? ` 
         cursor: pointer;
         & * {
         cursor: pointer;
         }`
         : ""}

   ${({ active, theme }) => (active ? `background-color:${theme.drawerButton.activeColor};` : "")}

&:hover {
      background-color: ${({ theme }) => theme.drawerButton.hoverColor};
   }

   label {
      position: relative;
      top: 1px;
      margin-left: 8px;
      user-select: none;
   }
`;

export const ItemIcon = styled(Icon)`
   margin-right: 8px;
   width: 24px;
   height: 24px;
   color: black;

   svg {
      &:hover {
         cursor: pointer;
      }
   }
`;

export const DrawerButtonLabel = styled(Label9)`
   display: none;

   @media only screen and (min-width: 600px) {
      display: inline-block;
   }
`;
