import styled from "styled-components";
import Icon from "../Icon/Icon";

export const IconButton = styled(Icon)`
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 25px;
   user-select: none;
   cursor: pointer;

   svg {
      transition: color 250ms;
      color: ${props => props.theme.iconButton.color};
   }

   &:hover {
      svg {
         color: ${props => props.theme.iconButton.hoverColor};
      }
   }
`;
