import React, { FC } from "react";
import { IconImage, PropsIcon } from "../Icon/Icon";
import { IconStyled } from "./ToggleIconButton.styles";

interface PropsToggleIconButton extends Omit<PropsIcon, "iconImage" | "onClick"> {
   value: boolean;
   onIcon: IconImage;
   offIcon: IconImage;
   onToggle: (newValue: boolean) => void;
   triggerOnMouseDown?: boolean;
}

const ToggleIconButton: FC<PropsToggleIconButton> = props => {
   const { value, onIcon, offIcon, onToggle, triggerOnMouseDown } = props;

   const handleClick = () => {
      if (triggerOnMouseDown) {
         return;
      }
      onToggle(!value);
   };

   const handleMouseDown = () => {
      if (!triggerOnMouseDown) {
         return;
      }
      onToggle(!value);
   };

   return (
      <IconStyled
         {...props}
         iconImage={value ? onIcon : offIcon}
         onClick={handleClick}
         onMouseDown={handleMouseDown}
      />
   );
};

export default ToggleIconButton;
