import { ButtonVariant, ButtonVariantType } from "../../kanopy-ui/Button";
import { LabelAndValue } from "../../kanopy-ui/tools/types";

export enum GoalStatus {
   NotStarted,
   Activated,
   PastDue,
   Done
}

export interface GoalActionButtons {
   label: string;
   type?: ButtonVariant | ButtonVariantType;
   next?: string;
}

export interface GoalCardProps {
   title: string; // @TODO define as interface/
   paragraph: string; // @TODO change paragraph to subtitle
   statusType: GoalStatus;
   status: string; // @TODO define as interface
   cardType: number; // @TODO define as interface
   number: LabelAndValue;
   target: LabelAndValue;
   started: LabelAndValue;
   timeLeft: LabelAndValue;
   description: string;
   delete: { next: string };
   actionButtons: GoalActionButtons[];
   onClick?: (next?: string) => void;
}
