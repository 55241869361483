import styled from "styled-components";
import { LayoutType } from "../../../tools/api/forms/useServerJsonForm";

export const FormComponentContainer = styled("div").withConfig({
   shouldForwardProp: p => !["layoutType"].includes(p)
})<{ layoutType: LayoutType }>`
   display: flex;
   ${({ layoutType }) => layoutType === "row-center" && "width: 48%;"}
`;

/**
 * Just an empty div with the provided dimensions
 */
export const Divider = styled("div").withConfig({
   shouldForwardProp: p => !["width", "height"].includes(p)
})<{ width?: number; height?: number }>`
   ${({ width }) => (width ? `min-width: ${width}px;` : "")}
   ${({ height }) => (height ? `min-height: ${height}px;` : "")}
`;
