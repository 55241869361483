import styled from "styled-components";

export const LabelContainer = styled("div").withConfig({
   shouldForwardProp: p => !["labelFontSize", "labelDisplacement"].includes(p)
})<{ labelFontSize: number; labelDisplacement: number }>`
   position: relative;
   top: ${({ labelDisplacement }) => labelDisplacement}px;
   user-select: none;
   color: ${({ theme }) => theme.avatar.label.color};
   font-family: ${({ theme }) => theme.avatar.label.fontFamily};
   font-size: ${({ labelFontSize }) => labelFontSize}px;
   font-weight: ${({ theme }) => theme.avatar.label.weight};
   line-height: 1;
   letter-spacing: ${({ theme }) => theme.avatar.label.letterSpacing}em;
`;
