export enum Browser {
   Firefox = "Firefox",
   Chrome = "Chrome",
   Safari = "Safari",
   Opera = "Opera",
   IE = "IE",
   Edge = "Edge",
   EdgeChromium = "EdgeChromium",
   Blink = "Blink",
   Unknown = "Unknown"
}

export function getBrowser(): Browser {
   let isOpera =
      // @ts-ignore
      (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
   // @ts-ignore
   let isFirefox = typeof InstallTrigger !== "undefined";
   let isSafari =
      // @ts-ignore
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
         return p.toString() === "[object SafariRemoteNotification]";
         // @ts-ignore
      })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification));
   // @ts-ignore
   let isIE = /*@cc_on!@*/ false || !!document.documentMode;
   // @ts-ignore
   let isEdge = !isIE && !!window.StyleMedia;
   // @ts-ignore
   let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
   // @ts-ignore
   let isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1;
   let isBlink = (isChrome || isOpera) && !!window.CSS;

   if (isFirefox) {
      return Browser.Firefox;
   } else if (isChrome) {
      return isEdgeChromium ? Browser.EdgeChromium : Browser.Chrome;
   } else if (isSafari) {
      return Browser.Safari;
   } else if (isOpera) {
      return Browser.Opera;
   } else if (isIE) {
      return Browser.IE;
   } else if (isEdge) {
      return Browser.Edge;
   } else if (isBlink) {
      return Browser.Blink;
   } else {
      return Browser.Unknown;
   }
}
