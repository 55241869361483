import styled from "styled-components";

export const ResponsiveContainer = styled("div")`
   position: relative;
   width: 100%;
   height: 100%;
   min-width: 50px;
   min-height: 50px;
`;

export const ChartContainer = styled("div")`
   position: absolute;

   /**
   * The Nivo line chart library has many "overflow hidden bugs", it may be tempting to fix them all
   * with the following code but it't not a good idea because it unwraps other rendering bugs of the
   * library that are hard to find but it may happen to the users, for example with the line gradients
   * in some situations when there is a single line visible. Instead research the library api for ways
   * to change the size or position of the specific element to not overflow.
   */
   /* svg {
      overflow: visible;
   } */
`;

export const ChartErrorMessage = styled("div")`
   font-size: large;
   font-weight: bold;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
`;
