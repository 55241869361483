import styled from "styled-components";
import UserAvatar from "../../../../common/UserAvatar/UserAvatar";
import Container from "../../../../kanopy-ui/Container";
import { Row } from "../../../../kanopy-ui/Row/Row";

export const SettingsHeaderContainer = styled("div")`
   position: relative;
   margin-bottom: 32px;
`;

export const UserAvatarContainer = styled("div")`
   position: absolute;
   right: 0px;
   top: -16px;
   margin-bottom: 32px;
`;

export const UserAvatarStyled = styled(UserAvatar)`
   top: 16px;
`;

export const TitleRow = styled(Row)`
   align-items: flex-start;
`;

export const TitleContainer = styled(Container).attrs(props => ({
   fixedWidth: true,
   ...props
}))``;
