import styled from "styled-components";

export const InProgressContainer = styled("div")`
   margin-top: 36px;
   margin-bottom: 36px;
`;

export const AnimationContainer = styled("div")`
   width: 250px;
   margin-bottom: 20px;
`;
