import styled from "styled-components";
import Card from "../../../../../kanopy-ui/Card";
import { RowCentered } from "../../../../../kanopy-ui/Row/Row";
import { Label11, Label15, Label9, Title9, Title10 } from "../../../../../kanopy-ui/Texts/Texts";

export const ForecastCardContainer = styled(Card)`
   padding-top: 18px;
   padding-bottom: 18px;
   padding-left: 26px;
   padding-right: 26px;
   flex-grow: 1;

   @media only screen and (min-width: 1200px) {
      flex-basis: calc(50% - 24px);
   }

   @media only screen and (min-width: 1400px) {
      flex-basis: calc(33% - 24px);
   }
`;

export const CircularProgressContainer = styled("div")`
   width: 120px;
   max-width: 120px;
   margin-right: 25px;
   flex-basis: fit-content;
`;

export const TextContainer = styled(RowCentered).withConfig({
   shouldForwardProp: p => !["percentage"].includes(p)
})<{ percentage: number }>`
   ${({ percentage }) => (percentage >= 100 ? "transform: scale(0.8);" : "")}
`;

export const PlusText = styled(Label11).withConfig({
   shouldForwardProp: p => !["color"].includes(p)
})<{ color: string }>`
   color: ${({ color }) => color} !important;
`;

export const PercentageText = styled(Title10).withConfig({
   shouldForwardProp: p => !["color"].includes(p)
})<{ color: string }>`
   color: ${({ color }) => color} !important;
   user-select: none;
`;

export const PercentageSymbolText = styled(Label9).withConfig({
   shouldForwardProp: p => !["color"].includes(p)
})<{ color: string }>`
   color: ${({ color }) => color} !important;
`;

export const UnderlineText = styled(Label15).withConfig({
   shouldForwardProp: p => !["color"].includes(p)
})<{ color: string }>`
   color: ${({ color }) => color} !important;
`;

export const CashText = styled(Title9).withConfig({
   shouldForwardProp: p => !["color"].includes(p)
})<{ color: string }>`
   color: ${({ color }) => color} !important;
   margin-bottom: 4px;
`;

export const ContentContainer = styled("div")`
   padding-top: 7px;
   padding-bottom: 7px;
`;
