import { useEffect } from "react";

/**
 * This hook calls document.getElementById() in an interval until the element is present.
 * If the element id passed is null it does nothing.
 */
export const useGetElementById = (
   elementId: string | null | undefined,
   fn: (element: HTMLElement) => void,
   pollingRate = 250
) => {
   useEffect(() => {
      if (elementId == null) {
         return;
      }

      const interval = setInterval(() => {
         const element = document.getElementById(elementId);
         if (element) {
            clearInterval(interval);
            fn(element);
         }
      }, pollingRate);
      return () => clearInterval(interval);
   });
};
