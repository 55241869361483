import styled from "styled-components";
import {
   ButtonVariant,
   ButtonSize as IButtonSize,
   ButtonVariantType,
   ButtonSizeType
} from "./Button.types";

export const ButtonBase = styled("button")`
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   text-align: center;
   line-height: 1;
   border: none;

   span,
   &,
   &:hover {
      cursor: pointer;
   }
   ${({ disabled }) => disabled && `span, &, &:hover { cursor: default; }`}
`;

const animationLoading = () => `
   transition: all 180ms ease-in;

   & > span {
      opacity: 0;
   }

   &::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-top-color: #ffffff;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
   }

   @keyframes button-loading-spinner {
      from {
         transform: rotate(0turn);
      }
      to {
         transform: rotate(1turn);
      }
   }
`;

const animationScale = () => `
   transition: all 0.2s ease-in-out;

   &:hover {
      // transform: scale(1.03);
   }
`;

export const ButtonStyled = styled(ButtonBase)
   .withConfig({
      shouldForwardProp: p =>
         !["variant", "loading", "fullWidth", "height", "wrap", "size"].includes(p)
   })
   .attrs(props => ({
      size: IButtonSize.Large,
      ...props
   }))<{
   variant?: ButtonVariant | ButtonVariantType;
   fullWidth?: boolean;
   loading?: boolean;
   size?: IButtonSize | ButtonSizeType;
}>`
   font-family: ${({ theme }) => theme.button.fontFamily};
   font-weight: ${({ theme }) => theme.button.weight};
   font-size: ${({ theme }) => theme.button.fontSize};
   line-height: ${({ theme }) => theme.button.lineHeight};
   letter-spacing: ${({ theme }) => theme.button.letterSpacing};

   ${({ fullWidth }) => fullWidth && "width: 100%;"}
   ${({ theme }) => (theme.button.minWidth ? `min-width: ${theme.button.minWidth};` : "")}
   ${({ loading }) => loading && animationLoading}


   ${({ size, theme }) =>
      size === IButtonSize.Large &&
      `
      font-size: 1rem;
      padding: ${
         theme.button.padding?.large
            ? theme.button.padding?.large
            : `${theme.spacing(1.5, "px")} ${theme.spacing(2, "px")}`
      };
   `};

   ${({ size, theme }) =>
      size === IButtonSize.Medium &&
      `
      font-size: 0.875rem;
      padding: ${
         theme.button.padding?.medium
            ? theme.button.padding?.medium
            : `${theme.spacing(1, "px")} ${theme.spacing(1.5, "px")}`
      };
   `};

   ${({ size, theme }) =>
      size === IButtonSize.Small &&
      `
      font-size: 0.75rem;
      padding: ${
         theme.button.padding?.small ? theme.button.padding?.small : `${theme.spacing(1, "px")}}`
      };
   `};
`;

export const ButtonFilled = styled(ButtonStyled)`
   background-color: ${({ theme }) => theme.button.filled.enabled.backgroundColor};
   color: ${({ theme }) => theme.button.filled.enabled.color};
   border: ${({ theme }) => theme.button.filled.enabled.border};
   border-radius: ${({ theme }) => theme.button.filled.borderRadius};
   ${({ theme }) =>
      theme.button.filled.enabled.borderColor
         ? `border-color: ${theme.button.filled.enabled.borderColor};`
         : ""};

   transition: all 0.2s ease-in-out;

   ${({ theme }) => `
      &:hover {
         color: ${theme.button.filled.hover.color};
         background-color: ${theme.button.filled.hover.backgroundColor};
         border: ${theme.button.filled.hover.border};
         ${
            theme.button.filled.hover.borderColor
               ? `border-color: ${theme.button.filled.hover.borderColor};`
               : ""
         }
      }
   `};

   ${({ disabled }) => !disabled && animationScale};

   ${({ disabled, theme }) =>
      disabled &&
      `
         box-shadow: 0px 0px 0px 2px #EDF2F7 inset;
         &, &:hover {
            color: ${theme.button.filled.disabled.color};
            background-color: ${theme.button.filled.disabled.backgroundColor};
            border: ${theme.button.filled.disabled.border};
            ${
               theme.button.filled.disabled.borderColor
                  ? `border-color: ${theme.button.filled.disabled.borderColor};`
                  : ""
            }
         }
   `};
`;

export const ButtonInverted = styled(ButtonStyled)`
   ${({ theme }) => `
         color: ${theme.button.invertedColors.enabled.color};
         background-color: ${theme.button.invertedColors.enabled.backgroundColor};
         border: ${theme.button.invertedColors.enabled.border};
         border-radius: ${theme.button.invertedColors.borderRadius};
         &:hover {
            color: ${theme.button.invertedColors.hover.color};
            background-color: ${theme.button.invertedColors.hover.backgroundColor};
            border: ${theme.button.invertedColors.hover.border};
            ${
               theme.button.invertedColors.hover.borderColor
                  ? `border-color: ${theme.button.invertedColors.hover.borderColor};`
                  : ""
            }
         }
   `}

   ${({ disabled }) => !disabled && animationScale};

   ${({ disabled, theme }) =>
      disabled &&
      `
         &, &:hover {
            color: ${theme.button.invertedColors.disabled.color};
            background-color: ${theme.button.invertedColors.disabled.backgroundColor};
            border: ${theme.button.invertedColors.disabled.border};
            ${
               theme.button.invertedColors.disabled.borderColor
                  ? `border-color: ${theme.button.invertedColors.disabled.borderColor};`
                  : ""
            }
         }
      `}

   ${({ loading, theme }) =>
      loading &&
      `
      &:after {
         border-top-color: ${theme.button.invertedColors.effectColor};
      }
   `};
`;

export const ButtonOutlined = styled(ButtonStyled)`
   ${({ theme }) => `
      border-radius: ${theme.button.outlined.borderRadius};

      color: ${theme.button.outlined.enabled.color};
      background-color: ${theme.button.outlined.enabled.backgroundColor};

      // outline: ${theme.button.outlined.enabled.border};
      // TODO: border and outline are expanding the size of the buttons which makes the outlined button seem larger compared to the one on the other ones, we need to fully replace border with this box-shadow alternative that does not have the problem
      box-shadow: 0px 0px 0px 2px ${theme.button.outlined.enabled.color} inset;

      ${
         theme.button.outlined.enabled.borderColor
            ? `box-shadow: 0px 0px 0px 2px ${theme.button.outlined.enabled.borderColor} inset;`
            : ""
      }

      &:hover {
         color: ${theme.button.outlined.hover.color};
         background-color: ${theme.button.outlined.hover.backgroundColor};
         // outline: ${theme.button.outlined.hover.border};
         ${
            theme.button.outlined.hover.borderColor
               ? `box-shadow: 0px 0px 0px 2px ${theme.button.outlined.hover.borderColor} inset;`
               : ""
         }
      }
   `};

   ${({ disabled }) => !disabled && animationScale};

   ${({ disabled, theme }) =>
      disabled &&
      `
         &, &:hover {
            color: ${theme.button.outlined.disabled.color};
            background-color: ${theme.button.outlined.disabled.backgroundColor};
            ${
               theme.button.outlined.disabled.borderColor
                  ? `outline-color: ${theme.button.outlined.disabled.borderColor};`
                  : ""
            }
            box-shadow: 0px 0px 0px 2px ${theme.colors.gray7} inset;
      }
   `}

   ${({ loading, theme }) =>
      loading &&
      `
         &, &:hover {
            background-color: transparent;
            color: ${theme.button.outlined.loading?.color ?? "unset"};
            border-color: ${theme.button.outlined.loading?.color ?? "unset"};

            &:after {
               border-top-color: ${theme.button.outlined.loading?.color ?? "unset"};
            }
         }
   `}
`;

export const ButtonTextOnly = styled(ButtonStyled)`
   ${({ theme }) => `
      border-radius: ${theme.button.textOnly.borderRadius};
      padding-top: 0px;
      padding-bottom: 0px;

      color: ${theme.button.textOnly.enabled.color};
      background-color: ${theme.button.textOnly.enabled.backgroundColor};
      border: ${theme.button.textOnly.enabled.border};
      
      &:hover {
         color: ${theme.button.textOnly.hover.color};
         background-color: ${theme.button.textOnly.hover.backgroundColor};
         border: ${theme.button.textOnly.hover.border};
      }
   `};

   ${({ disabled, theme }) =>
      disabled &&
      `
      &, &:hover {
         color: ${theme.button.textOnly.disabled.color};
         background-color: ${theme.button.textOnly.disabled.backgroundColor};
         border: ${theme.button.textOnly.disabled.border};
      }
   `};

   ${({ loading, theme }) =>
      loading &&
      `
      &:after {
         border-top-color: ${theme.button.textOnly.effectColor};
      }
   `};
`;
