import styled from "styled-components";
import Card from "../../../../../kanopy-ui/Card";
import Icon from "../../../../../kanopy-ui/Icon/Icon";
import { Label11 } from "../../../../../kanopy-ui/Texts/Texts";

export const CashFlowContainer = styled(Card)`
   flex-grow: 1;
   padding-top: 24px;
   padding-left: 32px;
   padding-right: 32px;
   padding-bottom: 24px;
`;

export const CashFlowTitle = styled(Label11)`
   margin-bottom: 23px;
`;

export const CashFlowSummaryItem = styled("div").withConfig({
   shouldForwardProp: p => !["drawLine"].includes(p)
})<{ drawLine?: boolean }>`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   min-height: 36px;
   align-items: center;
   column-gap: 5px;
   ${({ drawLine, theme }) => (drawLine ? `border-bottom: ${theme.borders.border7};` : "")}
`;

export const ItemsContainer = styled("div")`
   display: flex;
   flex-direction: column;
   row-gap: 30px;
`;
export const TooltipIcon = styled(Icon)`
   transition: color 0.5s;
   height: 0;
   position: absolute;
   padding-left: 200px;
   cursor: pointer;
   :hover {
      color: #6bb97f;
   }
`;
