import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormElementJson, FormJson } from "../../../tools/api/forms/useServerJsonForm";
import { getFormElement } from "../../../tools/api/tools/getFormElement";
import { ModalContainer } from "../../kanopy-ui/Modal/Modal.styles";
import FormFromJson from "../FormFromJson/FormFromJson";
import InProgressModal from "../InProgressModal/InProgressModal";
import DimmerBackground from "../../kanopy-ui/Modal/DimmerBackground/DimmerBackground";

interface PropsFinicityConnectModal {
   visible?: boolean; // We have internal state for showing/hiding modals in this component but also visibility is needed to be controlled with a prop so we also have it
   onFinish?: () => void;
   onClose?: () => void;
   redirectOnFinish?: boolean;
}

const FinicityConnectModal: FC<PropsFinicityConnectModal> = props => {
   const { visible = true, onFinish, onClose, redirectOnFinish } = props;
   const connectAlreadyCalled = useRef(false);
   const [finicityConnectData, setFinicityConnectData] = useState<FormElementJson>();
   const [finicityModalVisible, setFinicityModalVisible] = useState(true);
   const [inProgressModalVisible, setInProgressModalVisible] = useState(false);
   const navigate = useNavigate();

   /**
    * This closes the finicity connect iframe
    */
   const destroyFinicity = useCallback(() => {
      // @ts-ignore
      window.finicityConnect.destroy();
      if (!redirectOnFinish) {
         connectAlreadyCalled.current = false;
      }
   }, [redirectOnFinish]);

   const onDataFromServer = (formJson: FormJson | undefined) => {
      setFinicityConnectData(getFormElement(formJson, "finicity-connect"));
   };

   const onSuccess = useCallback(
      (nextFromProp?: string) => {
         destroyFinicity();
         setFinicityModalVisible(false); // Just in case is open
         setInProgressModalVisible(false); // Just in case is open
         onFinish?.();

         if (redirectOnFinish) {
            navigate(`/${nextFromProp}`);
         }
      },
      [destroyFinicity, navigate, onFinish, redirectOnFinish]
   );

   const onCancel = useCallback(
      (exitFromProp?: string) => {
         destroyFinicity();
         onFinish?.();

         if (redirectOnFinish) {
            navigate(`/${exitFromProp}`);
         }
      },
      [destroyFinicity, navigate, onFinish, redirectOnFinish]
   );

   const onError = useCallback(
      (exitFromProp?: string) => {
         onCancel(exitFromProp);
      },
      [onCancel]
   );

   const connectToFinicity = useCallback(
      (finicityConnectUrl: string, next: string, exit: string) => {
         setFinicityModalVisible(true);

         // @ts-ignore
         window.finicityConnect.launch(finicityConnectUrl, {
            // selector: "#connect-container",
            overlay: "transparent",
            success: (event: any) => {
               // console.log("Yay! User went through Connect", event);
               if (redirectOnFinish) {
                  onSuccess(next);
               } else {
                  setInProgressModalVisible(true);
                  setFinicityModalVisible(false);
               }
            },
            cancel: (event: any) => {
               // console.log("The user cancelled the iframe", event);
               onCancel(exit);
            },
            error: (error: any) => {
               console.error("Some runtime error was generated during insideConnect ", error);
               onError(exit);
            },
            loaded: () => {
               // console.log("This gets called only once after the iframe has finished loading");
            },
            route: (event: any) => {
               // console.log("This is called as the user navigates through Connect ", event);
            },
            user: (event: any) => {
               // console.log("This is called as the user interacts with Connect ", event);
            }
         });
      },
      [onCancel, onError, onSuccess, redirectOnFinish]
   );

   useEffect(() => {
      if (finicityConnectData == null || connectAlreadyCalled.current === true || !visible) {
         return;
      }

      connectToFinicity(
         finicityConnectData.src!,
         finicityConnectData.next!,
         finicityConnectData.exit!
      );
      connectAlreadyCalled.current = true;
   }, [finicityConnectData, visible, connectToFinicity]);

   if (!visible) {
      return null;
   }

   return (
      <>
         {finicityModalVisible && (
            <ModalContainer zIndex={1}>
               <DimmerBackground />
               <FormFromJson
                  jsonUrl={"edit-modal/finicity-connect"}
                  onDataFromServer={onDataFromServer}
                  onErrorFromServer={onClose}
               />
            </ModalContainer>
         )}
         <InProgressModal
            modalVisible={inProgressModalVisible}
            onSuccess={onSuccess}
            onModalClose={() => onSuccess()}
         />
      </>
   );
};

export default FinicityConnectModal;
