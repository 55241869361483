import { FC } from "react";
import { useLoginStatus } from "../../../tools/api/tools/useLoginStatus";
import Login from "../../pages/Authentication/Login/Login";

export const RequireLogin: FC<{ children: React.ReactElement | null }> = ({ children }) => {
   const userIsLogged = useLoginStatus();
   if (!userIsLogged) {
      return <Login />;
   }
   return children;
};
