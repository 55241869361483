import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { httpRequest } from "../../tools/httpRequest";
import { RequestError } from "../../tools/typings";
import { DashboardFinancialHealthResponse } from "../financial-health/useDashboardFinancialHealth";

export function useClient<
   Error extends RequestError,
   Response extends DashboardFinancialHealthResponse
>(props?: { clientId?: string; options?: UseQueryOptions<void, Error, Response> }) {
   const url = `forms/dashboard/client/${props?.clientId}`;

   return useQuery<void, Error, Response>(
      [url],
      () => httpRequest({ url, method: "GET" }),
      props?.options
   );
}
