import styled from "styled-components";
import { IconButton } from "../../../../kanopy-ui/IconButton/IconButton.styles";
import Button from "../../../../kanopy-ui/Button";

export const EditButtonStyled = styled(IconButton)`
   position: absolute;
   top: 0px;
   right: 0px;
`;
export const LogoutButtonStyled = styled(Button)`
   right: 162px;
   margin-top: 66px;
   position: absolute;
   font-size: 14px;
`;
