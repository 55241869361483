import styled from "styled-components";
import { LayoutType } from "../../../../tools/api/forms/useServerJsonForm";
import Card from "../../../kanopy-ui/Card";
import Icon from "../../../kanopy-ui/Icon/Icon";

export const FormGroupContainerStyles = styled("div").withConfig({
   shouldForwardProp: p => !["layoutType", "hidden"].includes(p)
})<{ layoutType: LayoutType; hidden: boolean | undefined }>`
   display: flex;
   flex-wrap: wrap;

   & > div > div {
      margin-top: 20px;
   }

   & {
      margin-top: 20px;

      &:first-child {
         margin-top: 0;
      }
   }

   ${({ hidden }) => (hidden === true ? "display: none;" : "")}

   ${({ layoutType }) =>
      layoutType === "column" || layoutType === "column-centered"
         ? "flex-direction: column;"
         : "flex-direction: row;"}

   ${({ layoutType }) => (layoutType === "column-centered" ? "align-items: center;" : "")}

   ${({ layoutType }) => (layoutType === "row-end" ? "justify-content: flex-end;" : "")}
   ${({ layoutType }) => (layoutType === "row-end" ? "column-gap: 13.5px;" : "")}
   
   ${({ layoutType }) => (layoutType === "row-center" ? "justify-content: center;" : "")}
   ${({ layoutType }) => (layoutType === "row-center" ? "column-gap: 4%;" : "")}

   ${({ layoutType }) =>
      layoutType === "row-space-between" ? "justify-content: space-between;" : ""}

   ${({ layoutType }) => (layoutType === "footer" ? "justify-content: center;" : "")}
`;

export const EditPanelContainer = styled(Card).withConfig({
   shouldForwardProp: p => !["isLastElement", "space"].includes(p)
})<{ isLastElement?: boolean; space?: boolean }>`
   position: relative;
   max-width: 744px;
   margin: 0 auto;

   ${({ isLastElement }) => (!isLastElement ? "margin-top: 16px;" : "")}
   ${({ space }) => (!space ? "margin-top: 20px;" : "")}
`;

export const EditPanelOwnersContainer = styled(Card).withConfig({
   shouldForwardProp: p => !["isLastElement"].includes(p)
})<{ isLastElement?: boolean }>`
   display: flex;
   flex-direction: row;
   position: relative;
   ${({ isLastElement }) => (!isLastElement ? "margin-top: 16px;" : "")}
`;

export const EditPanelOwnersChildren = styled("div")`
   display: flex;
   flex-direction: column;
   padding-top: 16px;
   padding-bottom: 21px;
   padding-left: 19px;
`;

export const EditPanelOwnersLeftDecoration = styled("div")`
   display: flex;
   width: 48px;
   background-color: ${({ theme }) => theme.colors.primary1};
   border-top-left-radius: ${({ theme }) => theme.borders.borderRadius3};
   border-bottom-left-radius: ${({ theme }) => theme.borders.borderRadius3};
`;

export const EditPanelOwnersIcon = styled(Icon)`
   position: absolute;
   top: 18px;
   left: 10px;
   color: ${({ theme }) => theme.colors.white};
   svg {
      width: 28px;
      height: 28px;
   }
`;

export const ColumnCenteredSmallContainer = styled("div").withConfig({
   shouldForwardProp: p => !["layoutType"].includes(p)
})<{ layoutType: LayoutType }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
`;

export const ColumnCenteredSmallContent = styled("div")`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   max-width: 377px;

   label,
   p {
      text-align: center;
   }
`;

export const RowCenteredSmallContent = styled("div")`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   width: 100%;
   column-gap: 10px;
`;

export const ColumnMyAccountContainer = styled("div")`
   div:nth-child(3) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
   }
`;
