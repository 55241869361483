export const mediaQueries: MediaQueries = {
   mobileS: `(max-width: ${320}px)`,
   mobileM: `(max-width: ${375}px)`,
   mobileL: `(max-width: ${425}px)`,
   mobileXl: `(max-width: ${650}px)`,
   tablet: `(max-width: ${768}px)`,
   tabletL: `(max-width: ${1024}px)`,
   tabletXL: `(max-width: ${1100}px)`,
   desktop: `(max-width: ${2560}px)`
};

export interface MediaQueries {
   mobileS: string;
   mobileM: string;
   mobileL: string;
   mobileXl: string;
   tablet: string;
   tabletL: string;
   tabletXL: string;
   desktop: string;
}
