import React, { FC } from "react";
import { FormGroupJson } from "../../../../tools/api/forms/useServerJsonForm";
import { LabelError } from "../../../kanopy-ui/Texts/Texts";
import { Form } from "../tools/typings";
import { LabelErrorContainer } from "./GlobalError.styles";

interface PropsGlobalErrorLabel {
   form: Form;
   formGroup: FormGroupJson;
   errorText: string | undefined;
}

const GlobalErrorLabel: FC<PropsGlobalErrorLabel> = props => {
   const { formGroup, errorText, form } = props;

   const containsSubmitButton =
      formGroup.formElements?.find(element => element.type === "input[type=submit]") != null;

   if (!containsSubmitButton) {
      return null;
   }

   if (!errorText) {
      return null;
   }

   return (
      <LabelErrorContainer>
         <LabelError dangerouslySetInnerHTML={{ __html: errorText }} />
      </LabelErrorContainer>
   );
};

export default GlobalErrorLabel;
