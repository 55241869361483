/**
 * Checks if a string contains a valid number or not
 */
export function strIsNumber(str: string | null | undefined): boolean {
   if (str == null) {
      return false;
   }

   if (typeof str != "string") {
      return false;
   }

   return !isNaN(Number(str)) && !isNaN(parseFloat(str));
}
