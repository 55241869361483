import { stringify } from "query-string";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { serverUrlComposer } from "./serverUrlComposer";

/**
 * Axios wrapper that simplifies syntax
 */
export async function httpRequest<Params = null, Response = null>(
   props: HttpRequestParams<Params>
): Promise<Response> {
   let { url, method = "GET", params } = props;
   url = serverUrlComposer(url);
   const settings: AxiosRequestConfig = { url, method };

   if (method === "GET" && params) {
      settings.url += "?" + stringify(params);
   }

   if (method === "POST" && params) {
      settings.data = params;
   }

   settings.withCredentials = true;

   const client: AxiosInstance = axios.create(settings);
   let result: AxiosResponse<Response>;

   try {
      result = await client(settings);
   } catch (e) {
      throw e;
   }

   return result.data;
}

export interface HttpRequestParams<T> {
   url: string;
   method?: "POST" | "GET" | "PUT" | "DELETE";
   params?: T;
}
