import styled from "styled-components";
import Button from "../../../kanopy-ui/Button";
import { Title } from "../../../kanopy-ui/Texts/Texts";

export const DoneStepPageContainer = styled("div")`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100vw;
   max-width: 588px;
`;

export const DoneImage = styled("img")`
   height: 347px;
   margin-bottom: -10px;
`;

export const DoneTitle = styled(Title)`
   text-align: center;
   font-size: 35px;
   font-weight: 800;
   margin-bottom: 16px;
`;

export const DoneStepButton = styled(Button)`
   width: 550px;
`;
