import { useNavigate } from "react-router-dom";
import { httpRequest } from "./httpRequest";
import { useQueryClient } from "@tanstack/react-query";

export function useLogout() {
   const navigate = useNavigate();
   const queryClient = useQueryClient();

   const logout = async (logoutApi: string = "logout") => {
      await httpRequest({ url: logoutApi, method: "POST" });
      localStorage.removeItem("session");
      queryClient.clear(); // Keeping a cache of the requests response after logout generate some issues, it needs to be all cleared.
      navigate(`/sign-in`);
   };

   return { logout };
}
