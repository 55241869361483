import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { IDoesFilterPassParams, IFilterParams, IRowNode, RowNode } from "ag-grid-community";
import Checkbox from "../../Checkbox/Checkbox";
import { CheckboxesContainer } from "./CheckboxesFilter.styles";

export default forwardRef((props: IFilterParams, ref) => {
   const { api, colDef } = props;
   const floatingFilterText = useRef<string | undefined>(undefined);
   const [allColumnValues, setAllColumnValues] = useState<string[]>([]);
   const [selectAllChecked, setSelectAllChecked] = useState<boolean>(true);
   const [valuesToFilter, setValuesToFilter] = useState(new Set<string>());
   const valuesToFilterAsRef = useRef(new Set<string>());

   // Expose AG Grid Filter Lifecycle callbacks, this is where the filter logic is implemented
   useImperativeHandle(ref, () => {
      return {
         // AgGrid calls this once for each field on the column
         doesFilterPass(params: IDoesFilterPassParams) {
            const { api, colDef, column, columnApi, context } = props;
            const { node } = params;

            let passed = true;

            // This gets the value of the field
            const value = props.valueGetter({
               api,
               colDef,
               column,
               columnApi,
               context,
               data: node.data,
               getValue: field => node.data[field],
               node
            });

            if (valuesToFilterAsRef.current.has(value)) {
               passed = false;
            }

            // Make sure each word passes separately
            if (floatingFilterText.current) {
               floatingFilterText.current
                  .toLowerCase()
                  .split(" ")
                  .forEach(filterWord => {
                     if (value.toString().toLowerCase().indexOf(filterWord) < 0) {
                        passed = false;
                     }
                  });
            }

            return passed;
         },

         isFilterActive() {
            return (
               valuesToFilterAsRef.current?.size > 0 ||
               (floatingFilterText.current != null && floatingFilterText.current !== "")
            );
         },

         /**
          * This function is accessed in the floating filter component and called there, the logic of
          * the floating filter is implemented here, the floating filter component does not contain
          * logic itself, only calls this. This is how filters and floating filters should be
          * implemented according to the ag grid documentation:
          * https://www.ag-grid.com/react-data-grid/component-floating-filter/#floating-filter-lifecycle
          */
         onFloatingFilterChanged(type: string | null, value: string) {
            floatingFilterText.current = value ?? "";
            // This will execute the filter logic, doesFilterPass will be called.
            props.filterChangedCallback();
         }
      };
   });

   // Effect to collect and save all column values and rows to use later
   useEffect(() => {
      if (!colDef.field) {
         return;
      }

      const columnValues = new Set<string>();
      const rows: IRowNode[] = [];

      api.forEachNode(row => {
         rows.push(row);
         columnValues.add(row.data[colDef.field!]);
      });

      setAllColumnValues(
         Array.from(columnValues).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      );
   }, []);

   const onCheckboxChange = (value: string, checked: boolean) => {
      if (checked) {
         valuesToFilter.delete(value);
         if (valuesToFilter.size === 0) {
            setSelectAllChecked(true);
         }
      } else {
         valuesToFilter.add(value);
         setSelectAllChecked(false);
      }

      const newValuesToFilter = new Set(valuesToFilter);

      setValuesToFilter(newValuesToFilter);

      // We also need to have this as a ref to not wait until re render to have the value updated
      valuesToFilterAsRef.current = newValuesToFilter;

      // This will execute the filter logic, doesFilterPass will be called.
      props.filterChangedCallback();
   };

   const onSelectAllCheckboxChange = (checked: boolean) => {
      let newValuesToFilter: Set<string>;
      if (checked) {
         newValuesToFilter = new Set();
      } else {
         newValuesToFilter = new Set(allColumnValues);
      }

      setValuesToFilter(newValuesToFilter);
      valuesToFilterAsRef.current = newValuesToFilter;
      setSelectAllChecked(checked);
      // This will execute the filter logic, doesFilterPass will be called.
      props.filterChangedCallback();
   };

   return (
      <CheckboxesContainer>
         <Checkbox
            label={"(Select All)"}
            checked={selectAllChecked}
            onChange={onSelectAllCheckboxChange}
         />
         {allColumnValues.map((value, i) => (
            <Checkbox
               label={value}
               checked={!valuesToFilter.has(value)}
               onChange={checked => onCheckboxChange(value, checked)}
               key={i}
            />
         ))}
      </CheckboxesContainer>
   );
});
