import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import { ColorVariant } from "./DataGrid.types";

//@ts-ignore
export const AgGridReactStyled = styled(AgGridReact).withConfig({
   shouldForwardProp: p => !["useHandCursor", "useMinHeight", "colorVariant"].includes(p)
})<{ useHandCursor?: boolean; useMinHeight?: boolean; colorVariant?: ColorVariant }>`
   --ag-row-hover-color: ${props => props.theme.colors.primaryWashed};
   --ag-selected-row-background-color: ${props => props.theme.colors.primaryWashed};
   --ag-alpine-active-color: ${props => props.theme.colors.primaryWashed};
   --ag-material-accent-color: ${props => props.theme.colors.primaryWashed};
   --ag-material-primary-color: ${props => props.theme.colors.primaryWashed};

   .ag-cell {
      display: flex;
      align-items: center;
   }

   ${props =>
      props.colorVariant === ColorVariant.Transparent
         ? `
      --ag-header-column-separator-color: transparent;
      --ag-header-column-resize-handle-color: transparent;
      --ag-header-background-color: transparent;
      --ag-borders: none;
      // --ag-row-border-style: none;
      --ag-border-color: transparent;
      --ag-odd-row-background-color: transparent;
      
      .ag-header-cell-text {
         text-transform: uppercase;
      }

      .ag-center-cols-container > :last-child {
         border-style: none;
      }
   `
         : ""};

   /*
      This fixes an incompatibility with the global styles
   */
   .ag-body-horizontal-scroll ::-webkit-scrollbar-thumb,
   .ag-body-horizontal-scroll ::-webkit-scrollbar-thumb:hover {
      background-clip: border-box;
   }

   .ag-root-wrapper {
      border: none !important;
      /* There has to be enough space to the filters menu otherwise it cuts */
      ${props => (props.useMinHeight ? "min-height: 250px;" : "")}
   }

   .ag-center-cols-clipper {
      ${props => (props.useMinHeight ? "" : "min-height: unset !important;")}
   }

   .ag-cell {
      ${props => (props.useHandCursor ? "cursor: pointer;" : "")}
   }

   .ag-row-focus::before {
      background-image: none !important;
   }

   .ag-cell {
      padding-left: 0; /* it was 17px, we need to remove the padding because affects the click area of the links the padding is added inside the DataGridCell component instead */
      padding-right: 0;
   }
`;
