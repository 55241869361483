import { useNavigate } from "react-router-dom";
import { AblyData, useAbly } from "../../../tools/ably/useAbly";
import { useQueryClient } from "@tanstack/react-query";

export interface OpenWindowParams {
   windowUrl?: string;
   redirectUrl?: string;
   ablyData?: AblyData;
}

/**
 * This hook provides a function to open a window, not a modal, an actual browser window,
 * and then the window is closed by an ably event. This is required because of third party
 * forms that does not provide a redirect when the user finishes so the form need to be opened
 * in a window and when the user finishes with the form ably notifies and the window is closed
 * with the main window redirecting.
 * If no ably data is provided then the main window redirects at the same time the form window opens.
 */
export function useWindow() {
   const navigate = useNavigate();
   const { connect } = useAbly();
   const queryClient = useQueryClient();

   const openWindow = (params: OpenWindowParams) => {
      const openedWindow = window.open(
         params.windowUrl,
         "OpenWindowFromJsonName",
         "scrollbars=yes,width=600,height=700"
      );

      if (params.ablyData) {
         connect(params.ablyData, () => {
            openedWindow?.close();
            queryClient.refetchQueries(); // The window form maybe makes changes in the server that needs client refetch to see the updates
            navigate(`/${params.redirectUrl}`);
         });
      } else {
         queryClient.refetchQueries();
         navigate(`/${params.redirectUrl}`);
      }
   };

   return { openWindow };
}
