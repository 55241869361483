import React, { FC, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useDialog } from "../GlobalDialogs/tools/useDialog";
import { tryToGetErrorMessage } from "../../../tools/api/tools/tryToGetErrorMessage";
import { tryToGetErrorStatusCode } from "../../../tools/api/tools/tryToGetErrorStatusCode";
import { useErrorHandler } from "./tools/useErrorHandler";

/**
 * A wrapper of QueryClientProvider with more related things added
 */
const HttpRequestsHandler: FC = props => {
   const { onError } = useErrorHandler();

   /**
    * Here the cache config default options can be changed
    */
   const queryClient = useMemo<QueryClient>(
      () =>
         new QueryClient({
            defaultOptions: {
               queries: {
                  staleTime: Infinity,
                  structuralSharing: false,
                  retry: false,
                  refetchOnWindowFocus: false,
                  refetchOnMount: false,
                  /**
                   * This setting (retryOnMount) should be true, only has effect on an error situation
                   * If set to `false`, the query will not be retried on mount when the last time returned
                   * an error, preventing recovery after error.
                   */
                  retryOnMount: true,
                  onError
               },
               mutations: { onError }
            }
         }),
      []
   );

   return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};

export default HttpRequestsHandler;
