import React, { FC } from "react";
import { KanopyUiGlobalStyles } from "./KanopyUiGlobalStyles";

const KanopyUiProvider: FC = props => {
   return (
      <>
         <KanopyUiGlobalStyles />
         {props.children}
      </>
   );
};

export default KanopyUiProvider;
