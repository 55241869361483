import React, { FC, useState } from "react";
import { LogoutButtonStyled } from "./LogoutModal.styles";
import { ButtonVariant } from "../../../../kanopy-ui/Button";
import FormFromJsonAsModal from "../../../FormFromJsonAsModal/FormFromJsonAsModal";

interface PropsEditButton {
   editFormJsonUrl: string;
   text: string | undefined;
}

const LogoutModal: FC<PropsEditButton> = props => {
   const { editFormJsonUrl } = props;
   const [editModalVisible, setEditModalVisible] = useState(false);

   const handleEditButtonClick = () => {
      setEditModalVisible(true);
   };

   const handleEditModalClose = () => {
      setEditModalVisible(false);
   };

   return (
      <>
         <LogoutButtonStyled variant={ButtonVariant.TextOnly} onClick={handleEditButtonClick}>
            {props.text}
         </LogoutButtonStyled>
         <FormFromJsonAsModal
            jsonUrl={editFormJsonUrl}
            modalVisible={editModalVisible}
            onModalClose={handleEditModalClose}
            redirectToNextFormOnSubmit={true}
         />
      </>
   );
};

export default LogoutModal;
